// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { BrochureMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import BrochureMasterPopup from "../popup-containers/BrochuresMasterPopup";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, IUDAPI } from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import FileUpload from "../ReusableComponents/FileUpload";
import CameraComponent from "../ReusableComponents/Camera";
import MultipleFileUpload from "../ReusableComponents/MultipleFileUpload";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function BrochureMaster() {
  const location = useLocation();
  const [bindBrochureMasterDatas, setBindBrochureMasterDatas] = useState([]);
  const [mstBrochureId, setMstBrochureId] = useState("");
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // View details start

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [helpPopUpOpen, setHelpPopUpOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [rowIndex, setRowIndex] = useState("");
  const [viewDetails, setViewDetails] = useState(false);

  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [popUpData, setPopUpData] = useState({});

  // view details end
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [brochureType, setBrochureType] = useState("1");
  const [showImage1, setShowImage1] = useState(false);
  const [bindLanguage, setbindLanguagesDD] = useState([]);
  const [bindTopic, setbindTopic] = useState([]);
  const [brochureTypeBind, setBrochureTypeBind] = useState([
    { value: "1", label: "Image" },
    { value: "2", label: "Video" },
    { value: "3", label: "PDF" },
  ]);

  const [isOpen, setIsOpen] = useState(false);

  //Value store States
  const [brochureFields, setBrochureFields] = useState({
    topics: "",
    language: "",
    brochureName: "",
    showImage: "",
    videoURL: "",
  });

  const [brochureFieldsErrorImage, setBrochureFieldsErrorImage] = useState({
    topicsError: "",
    languageError: "",
    brochureNameError: "",
    showImageError: "",
  });

  const [brochureFieldsErrorVideo, setBrochureFieldsErrorVideo] = useState({
    topicsError: "",
    languageError: "",
    brochureNameError: "",
    videoURLError: "",
  });

  const [brochureFieldsErrorPdf, setBrochureFieldsErrorPdf] = useState({
    topicsError: "",
    languageError: "",
    brochureNameError: "",
    videoURLError: "",
  });

  const handleShowImage = (showImage) => {
    //
    console.log("image", showImage);
    // showImage &&
      setBrochureFields({
        ...brochureFields,
        showImage: showImage || "",
      });
    setBrochureFieldsErrorImage({
      ...brochureFieldsErrorImage,
      showImageError: "",
    });
  };

  const handleBrochureFieldsChange = (value, name) => {
    //
    console.log("Values:", value, name);
    setBrochureFields({
      ...brochureFields,
      [name]: value,
    });
  };

  const validate = (flag, brochureType) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the Topics input
      if (brochureFields.topics === "") {
        errors.topics = "Select Topics";
      }

      // Validation logic for the Language input
      if (brochureFields.language === "") {
        errors.language = "Select Language";
      }

      // Validation logic for the Brochure Name input
      if (!brochureFields.brochureName) {
        errors.brochureName = "Enter Brochure Name";
      } else if (!/^[A-Za-z ]+$/.test(brochureFields.brochureName)) {
        errors.brochureName = "Only alphabet and space allowed";
      }
      if (brochureType === "1") {
        if (brochureFields.showImage == "") {
          errors.showImage = "Please Upload ";
        }
      }

      if (brochureType === "2") {
        if (!brochureFields.videoURL) {
          errors.videoURL = "Enter Video URL";
        }
      }
      // Validation logic for the Video URL input
    }

    if (brochureType === "1") {
      setBrochureFieldsErrorImage({
        topicsError: errors.topics || "",
        languageError: errors.language || "",
        brochureNameError: errors.brochureName || "",
        showImageError: errors.showImage || "",
      });
    } else if (brochureType === "2") {
      setBrochureFieldsErrorVideo({
        topicsError: errors.topics || "",
        languageError: errors.language || "",
        brochureNameError: errors.brochureName || "",
        videoURLError: errors.videoURL || "",
      });
    } else {
      setBrochureFieldsErrorPdf({
        topicsError: errors.topics || "",
        languageError: errors.language || "",
        brochureNameError: errors.brochureName || "",
        showImageError: errors.showImage || "",
      });
    }

    return errors; // Return the errors object containing validation errors (if any)
  };

  const bindBrochureLanguageDrpData = async () => {
    const brochureLanguageDrpData = await BindAPI("LanguageAPI/GetLanguage");
    const brochureLanguageDD =
      brochureLanguageDrpData.data.Table.length > 0 &&
      brochureLanguageDrpData.data.Table.filter(
        (item) => item.BlockedBy === null
      ).map((m) => ({
        value: m.MstLanguageId,
        label: m.Language,
      }));
    setbindLanguagesDD(brochureLanguageDD);
  };
  const bindBrochureTopicDrpData = async () => {
    const brochureTopicDrpData = await BindAPI("MasterDataAPI/BindTopic");
    const brochureTopicDD =
      brochureTopicDrpData.data.Table.length > 0 &&
      brochureTopicDrpData.data.Table.filter(
        (item) => item.BlockedBy === null
      ).map((m) => ({
        value: m.MstIECTopicsId,
        label: m.IECTopic_en,
      }));
    setbindTopic(brochureTopicDD);
  };
  const showAdd = () => {
    setVisibleAdd(true);
    setAddScreenTitle(true); // Add Screen title & button
    setVisibleTable(false);
    setUpdateScreenTitle(false);
  };

  const handleEdit = async (rowIndex) => {
    // Edit functionality
    setUpdateScreenTitle(true); // Update Screen title & button
    setVisibleAdd(true);
    setAddScreenTitle(false); // Add Screen title & button
    setVisibleTable(false);

    setBrochureFields({
      topics: bindBrochureMasterDatas[rowIndex].BrochureTopic,
      language: bindBrochureMasterDatas[rowIndex].BrochureLanguage,
      brochureName: bindBrochureMasterDatas[rowIndex].BrochureName,
      showImage: bindBrochureMasterDatas[rowIndex].BrochurePhoto,
      videoURL: bindBrochureMasterDatas[rowIndex].BrochureVedio,
    });

    setMstBrochureId(bindBrochureMasterDatas[rowIndex].MstBrochureId);
    const BrochureType = bindBrochureMasterDatas[rowIndex].BrochureType;
    const BrochureTypeString = BrochureType ? BrochureType.toString() : "";
    setBrochureType(BrochureTypeString);
  };

  const handleBrochureDetails = (rowIndex, flag) => {
    // Setting visibility in popup container
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const BrochureType = bindBrochureMasterDatas[rowIndex].BrochureType;
    const BrochureTypeString = BrochureType ? BrochureType.toString() : "";

    const brochureLanguageRowData = bindBrochureMasterDatas[rowIndex];
    const brochureLanguageInfo = bindLanguage.find(
      (L) => L.value === brochureLanguageRowData.BrochureLanguage
    );
    const ResultBrochureLanguage = {
      value: brochureLanguageRowData.BrochureLanguage,
      label: brochureLanguageInfo
        ? brochureLanguageInfo.label
        : "DefaultLocationLabel",
    };

    const brochureTopicRowData = bindBrochureMasterDatas[rowIndex];
    const brochureTopicInfo = bindTopic.find(
      (L) => L.value === brochureTopicRowData.BrochureTopic
    );
    const ResultBrochureTopic = {
      value: brochureTopicRowData.BrochureTopic,
      label: brochureTopicInfo
        ? brochureTopicInfo.label
        : "DefaultLocationLabel",
    };

    const popupData = [
      ResultBrochureLanguage,
      BrochureTypeString,
      ResultBrochureTopic,
    ];
    setPopUpData(popupData);
    setCurrentData(bindBrochureMasterDatas[rowIndex]);
  };

  const handleVideo = () => {
    setShowImage1(true);
  };

  const handleSubmit = async (items, flag) => {
    // Submit functionality
    setSubmitBtnDisabled(true);
    const brochureValidation = validate(flag, brochureType);
    if (Object.keys(brochureValidation).length !== 0) {
      setIsOpen(true);
    }

    if (Object.keys(brochureValidation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1 || flag == 2) {
        const brochurePayload = [
          {
            mstBrochureId: mstBrochureId ? mstBrochureId : 0,
            brochureType: brochureType,
            brochureTopic: brochureFields.topics,
            brochureLanguage: brochureFields.language,
            brochureName: brochureFields.brochureName,
            brochurePhoto: brochureFields.showImage,
            brochureVedio: brochureFields.videoURL,
            createdBy: createdBy,
            flag: flag,
          },
        ];

        const response = await IUDAPI(
          brochurePayload,
          "BrochuresAPI/IUDBrochure"
        );
        if (flag == 1) {
          // Submit

          if (response.data.Table[0].Result === "S") {
            toast.success("Brochure data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
            brochureMaster();
          } else {
            toast.error("Failed to add Brochure data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("Brochure data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
            brochureMaster();
          } else {
            toast.error("Failed to update Brochure data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        const brochurePayload = [
          {
            mstBrochureId: bindBrochureMasterDatas[items].MstBrochureId,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(
          brochurePayload,
          "BrochuresAPI/IUDBrochure"
        );
        if (flag == 3) {
          // Delete
          if (response.data.Table[0].Result === "D") {
            toast.success("Brochure data deleted successfully 🗑️", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
            brochureMaster();
          } else {
            toast.error("Failed to delete Brochure data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          // Block
          if (response.data.Table[0].Result === "B") {
            toast.success("Brochure data blocked successfully 🚫", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
            brochureMaster();
          } else {
            toast.error("Failed to block Brochure data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          // Unblock
          if (response.data.Table[0].Result === "UB") {
            toast.success("Brochure data unblocked successfully ✅", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
            brochureMaster();
          } else {
            toast.error("Failed to unblock Brochure data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const brochureMaster = async () => {
    // Bind Data functionality
    const response = await BindAPI("BrochuresAPI/BindBrochure");
    setBindBrochureMasterDatas(response.data.Table);
  };
  useEffect(() => {
    bindBrochureLanguageDrpData();
    bindBrochureTopicDrpData();

    brochureMaster();
  }, []);
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    setBrochureType("1");
    setBrochureFields({
      topics: "",
      language: "",
      brochureName: "",
      showImage: "",
      videoURL: "",
    });

    setBrochureFieldsErrorImage({
      topicsError: "",
      languageError: "",
      brochureNameError: "",
      showImageError: "",
    });
    setBrochureFieldsErrorVideo({
      topicsError: "",
      languageError: "",
      brochureNameError: "",
      videoURLError: "",
    });
    setBrochureFieldsErrorPdf({
      topicsError: "",
      languageError: "",
      brochureNameError: "",
      videoURLError: "",
    });
    brochureMaster();
    setShowImage1(false);
  };
  useEffect(() => {
    if (location.pathname == "/brochuremaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      brochureMaster();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Brochures"
          header={BrochureMasterHeader}
          records={bindBrochureMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleBrochureDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Brochures"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className="mb-4">
                    <CheckboxOrRadioGroup
                      // classStar="text-red-500"
                      // title="Items Provided"
                      type="radio"
                      setName="itemsProvided"
                      selectedOptions={brochureType}
                      options={brochureTypeBind}
                      controlFunc={(e) => {
                        setBrochureType(e.target.value);
                      }}
                      disabled={false}
                      tabIndex="1"
                    />
                  </div>
                  {brochureType === "1" && (
                    <div>
                      <div className={mainTable.grid3}>
                        <div>
                          <AutoDropDown
                            title="Topics"
                            classStar="text-red-500"
                            options={bindTopic}
                            placeholder="Select"
                            name="topics"
                            selectedOption={bindTopic.find(
                              (x) => x.value == brochureFields.topics
                            )}
                            controlFunc={(value, e) => {
                              setBrochureFieldsErrorImage({
                                ...brochureFields,
                                topicsError: value ? "" : "Select Topics",
                              });
                              handleBrochureFieldsChange(value.value, e.name);
                            }}
                            controlFuncBlur={() => {
                              setBrochureFieldsErrorImage({
                                brochureFieldsErrorImage,
                                brochureFieldsErrorImage: brochureFields.topics
                                  ? ""
                                  : "Select Topics",
                              });
                            }}
                            Class="custom-class"
                            //disabled="false"
                            tabIndex="1"
                            errorMessage={brochureFieldsErrorImage.topicsError}
                          />
                        </div>
                        <div>
                          <AutoDropDown
                            title="Language"
                            classStar="text-red-500"
                            options={bindLanguage}
                            placeholder="Select"
                            name="language"
                            selectedOption={bindLanguage.find(
                              (x) => x.value == brochureFields.language
                            )}
                            controlFunc={(value, e) => {
                              setBrochureFieldsErrorImage({
                                ...brochureFields,
                                languageError: value ? "" : "Select Language",
                              });
                              handleBrochureFieldsChange(value.value, e.name);
                            }}
                            controlFuncBlur={() => {
                              setBrochureFieldsErrorImage({
                                brochureFieldsErrorImage,
                                brochureFieldsErrorImage:
                                  brochureFields.language
                                    ? ""
                                    : "Select Language",
                              });
                            }}
                            Class="custom-class"
                            //disabled="false"
                            tabIndex="1"
                            errorMessage={
                              brochureFieldsErrorImage.languageError
                            }
                          />
                        </div>
                        <div>
                          <InputText
                            inputType="text"
                            title="Brochure Name"
                            name="brochureName"
                            placeholder="Brochure Name"
                            classStar="text-red-500"
                            content={brochureFields.brochureName}
                            controlFunc={(e) =>
                              setBrochureFields({
                                ...brochureFields,
                                brochureName: e.target.value,
                              })
                            }
                            controlFuncBlur={(e) => {
                              setBrochureFieldsErrorImage(
                                (brochureFieldsErrorImage) => ({
                                  ...brochureFieldsErrorImage,
                                  brochureNameError:
                                    brochureFields.brochureName &&
                                    brochureFields.brochureName.length > 0
                                      ? /^[A-Za-z ]+$/.test(
                                          brochureFields.brochureName
                                        )
                                        ? ""
                                        : "Only Alphabet and space allowed"
                                      : "Enter Brochure Name",
                                })
                              );
                            }}
                            errorMessage={
                              brochureFieldsErrorImage.brochureNameError
                            }
                          />
                        </div>
                        {/* <div>
                        <InputText
                          inputType="file"
                          classStar="text-red-500"
                          title="Photo"
                          name="image"
                          handleImageUpload={(e) =>
                            setimageUploaded(e.target.files[0])
                          }
                        />
                        <span class="text-neutral-700 text-xs break-all">
                          File size limit: 2MB for images, 10MB for videos.
                          Accepted file types: JPEG, PNG, MP4.
                        </span>
                        {imageUploadedError && (
                          <span class="text-red-500">{imageUploadedError}</span>
                        )}
                      </div> */}

                        {/* <div className="flex">
                        <div className="mt-7 mr-7">
                          <button
                            type="button"
                            className={mainTable.submitButton}
                            onClick={() => handleImageUpload()}
                          >
                            UPLOAD
                          </button>
                        </div>
                        <div>
                          <img
                            src={showImage ? showImage : download}
                            alt="Image"
                            className="rounded-full w-20 h-20"
                          />
                        </div>
                      </div> */}
                      </div>
                      <FileUpload
                        title="Image"
                        // classDiv="col-span-2"
                        classStar="text-red-500"
                        apiCall="BrochuresAPI/BrochuresImageUpload"
                        modalName="BrochuresImage"
                        showImage={brochureFields.showImage}
                        showImageData={handleShowImage}
                        disabledBtn={updateScreenTitle}
                        imageUploadedError={
                          brochureFieldsErrorImage.showImageError
                        }
                        limitMassage={
                          "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                        }
                        MAX_FILE_SIZE_MB={2}
                        ALLOWED_FORMATS={[
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                          "application/pdf",
                        ]}
                      />
                    </div>
                  )}
                  {brochureType === "2" && (
                    <div className={mainTable.grid3}>
                      <div>
                        <AutoDropDown
                          title="Topics"
                          classStar="text-red-500"
                          options={bindTopic}
                          placeholder="Select"
                          name="topics"
                          selectedOption={bindTopic.find(
                            (x) => x.value == brochureFields.topics
                          )}
                          controlFunc={(value, e) => {
                            handleBrochureFieldsChange(value.value, e.name);
                          }}
                          controlFuncBlur={() => {
                            setBrochureFieldsErrorVideo({
                              brochureFieldsErrorVideo,
                              brochureFieldsErrorVideo: brochureFields.topics
                                ? ""
                                : "Select Topics",
                            });
                          }}
                          Class="custom-class"
                          //disabled="false"
                          tabIndex="1"
                          errorMessage={brochureFieldsErrorVideo.topicsError}
                        />
                      </div>
                      <div>
                        <AutoDropDown
                          title="Language"
                          classStar="text-red-500"
                          options={bindLanguage}
                          placeholder="Select"
                          name="language"
                          selectedOption={bindLanguage.find(
                            (x) => x.value == brochureFields.language
                          )}
                          controlFunc={(value, e) => {
                            handleBrochureFieldsChange(value.value, e.name);
                          }}
                          controlFuncBlur={() => {
                            setBrochureFieldsErrorVideo({
                              brochureFieldsErrorVideo,
                              brochureFieldsErrorVideo: brochureFields.language
                                ? ""
                                : "Select Language",
                            });
                          }}
                          Class="custom-class"
                          //disabled="false"
                          tabIndex="1"
                          errorMessage={brochureFieldsErrorVideo.languageError}
                        />
                      </div>
                      <div>
                        <InputText
                          inputType="text"
                          title="Brochure Name"
                          name="brochureName1"
                          placeholder="Brochure Name"
                          classStar="text-red-500"
                          content={brochureFields.brochureName}
                          controlFunc={(e) =>
                            setBrochureFields({
                              ...brochureFields,
                              brochureName: e.target.value,
                            })
                          }
                          controlFuncBlur={(e) => {
                            setBrochureFieldsErrorVideo(
                              (brochureFieldsErrorVideo) => ({
                                ...brochureFieldsErrorVideo,
                                brochureNameError:
                                  brochureFields.brochureName &&
                                  brochureFields.brochureName.length > 0
                                    ? /^[A-Za-z ]+$/.test(
                                        brochureFields.brochureName
                                      )
                                      ? ""
                                      : "Only Alphabet and space allowed"
                                    : "Enter Brochure Name",
                              })
                            );
                          }}
                          errorMessage={
                            brochureFieldsErrorVideo.brochureNameError
                          }
                        />
                      </div>
                      <div>
                        <InputText
                          inputType="text"
                          classStar="text-red-500"
                          classLabel="flex block mb-2 text-sm capitalize font-medium text-neutral-700"
                          title={
                            <>
                              Video URL{" "}
                              <svg
                                viewBox="0 0 512 512"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                                onClick={() => setHelpPopUpOpen(true)}
                              >
                                <path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64zm-6 304a20 20 0 1120-20 20 20 0 01-20 20zm33.44-102C267.23 276.88 265 286.85 265 296a14 14 0 01-28 0c0-21.91 10.08-39.33 30.82-53.26C287.1 229.8 298 221.6 298 203.57c0-12.26-7-21.57-21.49-28.46-3.41-1.62-11-3.2-20.34-3.09-11.72.15-20.82 2.95-27.83 8.59C215.12 191.25 214 202.83 214 203a14 14 0 11-28-1.35c.11-2.43 1.8-24.32 24.77-42.8 11.91-9.58 27.06-14.56 45-14.78 12.7-.15 24.63 2 32.72 5.82C312.7 161.34 326 180.43 326 203.57c0 33.83-22.61 49.02-42.56 62.43z" />
                              </svg>
                            </>
                          }
                          name="Youtube1"
                          placeholder="Youtube embed link"
                          content={brochureFields.videoURL}
                          controlFunc={(e) =>
                            setBrochureFields({
                              ...brochureFields,
                              videoURL: e.target.value,
                            })
                          }
                          controlFuncBlur={(e) => {
                            setBrochureFieldsErrorVideo(
                              (brochureFieldsErrorVideo) => ({
                                ...brochureFieldsErrorVideo,
                                videoURLError:
                                  brochureFields.videoURL &&
                                  brochureFields.videoURL.length > 0
                                    ? null
                                    : "Enter Youtube embed link",
                              })
                            );
                          }}
                          errorMessage={brochureFieldsErrorVideo.videoURLError}
                        />
                      </div>
                      <div className="flex mt-2">
                        <div className="mt-6 mr-7">
                          <button
                            type="button"
                            className={mainTable.submitButton}
                            onClick={handleVideo}
                          >
                            SUBMIT
                          </button>
                        </div>
                        <div>
                          {showImage1 && (
                            <iframe
                              title="Video"
                              className=" w-40 h-40"
                              src={`https://www.youtube.com/embed/${brochureFields.videoURL}`}
                              frameBorder="0"
                              allowFullScreen
                            ></iframe>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {brochureType === "3" && (
                    <div>
                      <div className={mainTable.grid3}>
                        <div>
                          <AutoDropDown
                            title="Topics"
                            classStar="text-red-500"
                            options={bindTopic}
                            placeholder="Select"
                            name="topics"
                            selectedOption={bindTopic.find(
                              (x) => x.value == brochureFields.topics
                            )}
                            controlFunc={(value, e) => {
                              setBrochureFieldsErrorPdf({
                                ...brochureFields,
                                topicsError: value ? "" : "Select Topics",
                              });
                              handleBrochureFieldsChange(value.value, e.name);
                            }}
                            controlFuncBlur={() => {
                              setBrochureFieldsErrorPdf({
                                brochureFieldsErrorPdf,
                                brochureFieldsErrorPdf: brochureFields.topics
                                  ? ""
                                  : "Select Topics",
                              });
                            }}
                            Class="custom-class"
                            //disabled="false"
                            tabIndex="1"
                            errorMessage={brochureFieldsErrorPdf.topicsError}
                          />
                        </div>
                        <div>
                          <AutoDropDown
                            title="Language"
                            classStar="text-red-500"
                            options={bindLanguage}
                            placeholder="Select"
                            name="language"
                            selectedOption={bindLanguage.find(
                              (x) => x.value == brochureFields.language
                            )}
                            controlFunc={(value, e) => {
                              setBrochureFieldsErrorPdf({
                                ...brochureFields,
                                languageError: value ? "" : "Select Language",
                              });
                              handleBrochureFieldsChange(value.value, e.name);
                            }}
                            controlFuncBlur={() => {
                              setBrochureFieldsErrorPdf({
                                brochureFieldsErrorPdf,
                                brochureFieldsErrorPdf: brochureFields.language
                                  ? ""
                                  : "Select Language",
                              });
                            }}
                            Class="custom-class"
                            //disabled="false"
                            tabIndex="1"
                            errorMessage={brochureFieldsErrorPdf.languageError}
                          />
                        </div>
                        <div>
                          <InputText
                            inputType="text"
                            title="Brochure Name"
                            name="brochureName"
                            placeholder="Brochure Name"
                            classStar="text-red-500"
                            content={brochureFields.brochureName}
                            controlFunc={(e) =>
                              setBrochureFields({
                                ...brochureFields,
                                brochureName: e.target.value,
                              })
                            }
                            controlFuncBlur={(e) => {
                              setBrochureFieldsErrorPdf(
                                (brochureFieldsErrorPdf) => ({
                                  ...brochureFieldsErrorPdf,
                                  brochureNameError:
                                    brochureFields.brochureName &&
                                    brochureFields.brochureName.length > 0
                                      ? /^[A-Za-z ]+$/.test(
                                          brochureFields.brochureName
                                        )
                                        ? ""
                                        : "Only Alphabet and space allowed"
                                      : "Enter Brochure Name",
                                })
                              );
                            }}
                            errorMessage={
                              brochureFieldsErrorPdf.brochureNameError
                            }
                          />
                        </div>
                      </div>
                      <FileUpload
                        title="PDF"
                        // classDiv="col-span-2"
                        classStar="text-red-500"
                        apiCall="BrochuresAPI/BrochuresImageUpload"
                        modalName="BrochuresImage"
                        showImage={brochureFields.showImage}
                        showImageData={handleShowImage}
                        disabledBtn={updateScreenTitle}
                        imageUploadedError={
                          brochureFieldsErrorPdf.showImageError
                        }
                        limitMassage={
                          "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                        }
                        MAX_FILE_SIZE_MB={2}
                        ALLOWED_FORMATS={["application/pdf"]}
                      />
                    </div>
                  )}
                  {/* <CameraComponent />
                   <MultipleFileUpload  /> */}
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <PopUpScreenTitle
            title="Brochures"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <BrochureMasterPopup data={currentData} data1={popUpData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
      <MainPopup
        popUpOpen={helpPopUpOpen}
        setpopUpOpen={setHelpPopUpOpen}
        Open={() => setHelpPopUpOpen(true)}
      >
        <div class="flex flex-col p-4">
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setHelpPopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <label class="mb-2 font-bold">Help Notes</label>
          <label class="mb-1">To embed a YouTube video:</label>
          <ol class="list-decimal pl-5 mb-4">
            <li class="mb-1">Right-click on the video on YouTube.</li>
            <li class="mb-1">Select "Copy embed code."</li>
            <li class="mb-1">
              Copy the URL in the src attribute of the embed code.
            </li>
          </ol>
          <label class="mb-1 font-semibold ">Example:</label>
          <div class="mb-4 break-all">
            &lt;iframe class="w-full" width="853" height="480" src="
            <span className=" bg-yellow-300">
              https://www.youtube.com/embed/99KL7xvNFHk
            </span>
            " title="FAMILY PLANNING ASSOCIATION OF INDIA | FPA IN ENGLISH |
            FAMILY WELFARE ASSOCIATION" frameborder="0" allow="accelerometer;
            autoplay; clipboard-write; encrypted-media; gyroscope;
            picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
            &gt; &lt;/iframe&gt;
          </div>

          <label class="mb-1 font-semibold">Example URL:</label>
          <label class="mb-4 break-all">
            https://www.youtube.com/embed/99KL7xvNFHk
          </label>
          <label>This is the correct embed link for the video URL.</label>
          <div className={mainTable.submitCancelMainDiv}>
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.cancelButton}
              onClick={() => setHelpPopUpOpen(false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </MainPopup>
    </MainLayout>
  );
}
export default BrochureMaster;
