// Code review - In Progress
// Documentation - In Progress
import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { InterPersonalContactHeader } from "../components/HeaderAttribute";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import MultiSelect from "../ReusableComponents/MultiSelect";
import InterpersonalContactsPopup from "../popup-containers/InterpersonalContactsPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function InterpersonalContacts() {
  const location = useLocation();
  useEffect(() => {
    getCurrentDate();
  }, []);
  const { userProfileState } = useUserCapability();
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [currentData, setCurrentData] = useState("");
  const [currentData2, setCurrentData2] = useState({});
  const [currentData3, setCurrentData3] = useState({});
  const [currentData4, setCurrentData4] = useState({});

  const [bindIPCAwarenessDatas, setBindIPCDatas] = useState([]);
  const [bindIPCParticipantDetailDatas, setBindIPCParticipantDetailDatas] =
    useState([]);
  const [bindIPCTopicsDatas, setBindIPCTopicsDatas] = useState([]);
  const [bindgenderRadioDD, setBindGenderRadioDD] = useState([]);
  const [bindtopics, setBindTopics] = useState([]);
  const [bindStaffName, setBindStaffName] = useState([]);
  const [bindreferredtoDD, setBindreferredtoDD] = useState([]);
  const [bindreferelserviceDD, setBindreferelserviceDD] = useState([]);
  const [bindState, setBindState] = useState([]);
  const [stateCodeDD, setStateCodeDD] = useState([]);
  const [bindBranchProject, setBindBranchProjectDD] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [bindBlock, setBindBlock] = useState([]);
  const [bindCityVillage, setBindCityVillage] = useState([]);
  const [bindDistrict, setBindDistrict] = useState([]);
  const [bindAgeDD, setBindAgeDD] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [getDistrictDatas, setGetDistrictDatas] = useState([]);
  const [getBlockDatas, setGetBlockDatas] = useState([]);
  const [getCityDatas, setGetCityDatas] = useState([]);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [IECParticipantDetails, setIECParticipantDetails] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [topics, setTopics] = useState([]);
  const [sdp, setSdp] = useState("");
  const [sdpCode, setSdpCode] = useState("");
  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [area, setArea] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [branchProject, setBranchProject] = useState("");
  const [branchProjectCode, setBranchProjectCode] = useState("");
  const [IECNo, setIECNo] = useState("");
  const [localityStreet, setLocalityStreet] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [channel, setChannel] = useState("");
  const [staffName1, setStaffName1] = useState("");
  const [staffName2, setStaffName2] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [cityVillage, setCityVillage] = useState("");

  const [dateError, setDateError] = useState("");
  // const [IECNoError, setIECNoError] = useState("");
  const [localityStreetError, setLocalityStreetError] = useState("");
  const [stateError, setStateError] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSdpError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [staffNameError1, setStaffNameError1] = useState("");
  // const [staffNameError2, setStaffNameError2] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [blockError, setBlockError] = useState("");
  const [cityVillageError, setCityVillageError] = useState("");
  const [channelError, setChannelError] = useState("");
  const [topicsError, setTopicsError] = useState("");
  const [participantError, setParticipantError] = useState("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [editMstICPAwarenessId, setEditMstICPAwarenessId] = useState("");
  const createdBy = sessionStorage.getItem("createdBy");
  const [IECType, setIECType] = useState("IPC");
  const translateParticipantText = [];
  const [issue, setIssue] = useState([
    { value: "1", label: "In-person" },
    { value: "2", label: "Virtual" },
  ]);
  const handleTopicsNameChange = (updatedOptions) => {
    setTopics(updatedOptions);
  };
  const userProfileDatas = userProfileState.userProfile;
  useEffect(() => {
    BindIECGridData(userProfileDatas);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  // const BindIECGridData = async () => {

  //   const Binddata = {
  //     iecType: IECType,
  //   };
  //   const response = await BindPostAPI([Binddata], "IECAPI/BindIECData");
  //   setBindIPCDatas(response.data.Table);
  //   setBindIPCParticipantDetailDatas(response.data.Table1);
  //   setBindIPCTopicsDatas(response.data.Table2);
  // };

  const BindIECGridData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const Binddata = {
        iecType: IECType,
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI([Binddata], "IECAPI/BindIECData");
      setBindIPCDatas(response.data.Table);
      setBindIPCParticipantDetailDatas(response.data.Table1);
      setBindIPCTopicsDatas(response.data.Table2);
    }
  };

  const cancel = () => {
    setIECParticipantDetails("");
    setParticipantError("");
    setEditMode(false);
    setSelectedRowIndex("");
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    Clearvalues();
    BindIECGridData(userProfileDatas);
    GenerateId();
    setSubmitBtnDisabled(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      binddrpTopicsdata();
      binddrpstatedata();
      binddrpAgedata();
      binddrpGenderdata();
      binddrpReferredtodata();
      binddrpReferredBydata();
    };

    fetchData();
  }, []);

  const binddrpTopicsdata = async () => {
    try {
      const bindTopicsdatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindTopicsdatadrp.data.Table.length > 0) {
        const Topicsdatadrp = bindTopicsdatadrp.data.Table.map((m) => ({
          value: m.MstIECTopicsId,
          label: m.IECTopic_en,
        }));
        setBindTopics(Topicsdatadrp);
      } else {
        setBindTopics([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpstatedata = async () => {
    try {
      const bindStatedatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");
      setStateCodeDD(bindStatedatadrp.data.Table1);
      if (bindStatedatadrp.data.Table1.length > 0) {
        const stateDD = bindStatedatadrp.data.Table1.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));
        setBindState(stateDD);
      } else {
        setBindState([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpAgedata = async () => {
    try {
      const bindAgedatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindAgedatadrp.data.Table2.length > 0) {
        const AgeDD = bindAgedatadrp.data.Table2.map((m) => ({
          value: m.MstAgeId,
          label: m.Age,
        }));
        setBindAgeDD(AgeDD);
      } else {
        setBindAgeDD([]);
      }
    } catch (error) {
      console.error("Error fetching setAwarenessAge data:", error);
    }
  };

  const binddrpGenderdata = async () => {
    try {
      const bindGenderdatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindGenderdatadrp.data.Table3.length > 0) {
        const GenderDD = bindGenderdatadrp.data.Table3.map((m) => ({
          value: m.MstGenderId,
          label: m.Gender_en,
        }));
        setBindGenderRadioDD(GenderDD);
      } else {
        setBindGenderRadioDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindGenderRadioDD data:", error);
    }
  };
  const binddrpReferredtodata = async () => {
    try {
      const bindReferredTodatadrp = await BindAPI(
        "IECAPI/BindIECAllMasterDatas"
      );

      if (bindReferredTodatadrp.data.Table4.length > 0) {
        const ReferredTodatadrpDD = bindReferredTodatadrp.data.Table4.map(
          (m) => ({
            value: m.MstReferredToId,
            label: m.ReferredTo_en,
          })
        );
        setBindreferredtoDD(ReferredTodatadrpDD);
      } else {
        setBindreferredtoDD([]);
      }
    } catch (error) {
      console.error("Error fetching ReferredTo data:", error);
    }
  };

  const binddrpReferredBydata = async () => {
    try {
      const bindReferredBydatadrp = await BindAPI(
        "IECAPI/BindIECAllMasterDatas"
      );

      if (bindReferredBydatadrp.data.Table5.length > 0) {
        const ReferredBydatadrpDD = bindReferredBydatadrp.data.Table5.map(
          (m) => ({
            value: m.MstReferredById,
            label: m.ReferredBy_en,
          })
        );
        setBindreferelserviceDD(ReferredBydatadrpDD);
      } else {
        setBindreferelserviceDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };

  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    const getSelectedArea = async () => {
      const locationResponse = await BindAPI("AreaAPI/GetArea");
      setGetAreaDatas(locationResponse.data.Table);
    };

    const getSelectedDistrict = async () => {
      const locationResponse = await BindAPI("IECAPI/BindDistrict");
      setGetDistrictDatas(locationResponse.data.Table);
    };

    const getSelectedBlock = async () => {
      const locationResponse = await BindAPI("IECAPI/BindBlock");
      setGetBlockDatas(locationResponse.data.Table);
    };

    const getSelectedCity = async () => {
      const locationResponse = await BindAPI("IECAPI/BindCityVillage");
      setGetCityDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    getSelectedSDP();
    getSelectedArea();
    getSelectedDistrict();
    getSelectedBlock();
    getSelectedCity();
  }, []);

  useEffect(() => {

    const staffDropDownDatas = async () => {
      try {
        const staffNameResponse = await BindAPI("StaffNameAPI/BindStaffName");
        const staffNameData = staffNameResponse.data.Table.filter(
          (item) =>
            item.MstBranchProjecctId === userProfileDatas[0].MstLocationId
        ).map((item) => ({
          value: item.MstStaffNameId,
          label: item.StaffName_en,
        }));

        setBindStaffName(staffNameData);
      } catch (error) {}
    };

    staffDropDownDatas();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getDistrictDatas.find(
      (item) => item.MstStateId == state.value // Replace with the desired MstStateId
    );
    setBindDistrict([]);
    if (stateSelectedValue) {
      const districtLabels = getDistrictDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstDistrictId: item.MstDistrictId,
          District_en: item.District_en,
        }));

      const districtSelected = districtLabels.map((LC) => ({
        value: LC.MstDistrictId, // Replace with the appropriate value
        label: LC.District_en,
      }));
      setBindDistrict(districtSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const BlockSelectedValue = getBlockDatas.find(
      (item) => item.MstDistrictId == district.value // Replace with the desired MstStateId
    );
    setBindBlock([]);
    if (BlockSelectedValue) {
      const blockLabels = getBlockDatas
        .filter(
          (item) => item.MstDistrictId === BlockSelectedValue.MstDistrictId
        )
        .map((item) => ({
          MstBlockId: item.MstBlockId,
          Block_en: item.Block_en,
        }));

      const blockSelected = blockLabels.map((SD) => ({
        value: SD.MstBlockId, // Replace with the appropriate value
        label: SD.Block_en,
      }));
      setBindBlock(blockSelected);
    } else {
    }
  }, [district]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const citySelectedValue = getCityDatas.find(
      (item) => item.MstBlockId == block.value // Replace with the desired MstStateId
    );
    setBindCityVillage([]);
    if (citySelectedValue) {
      const cityLabels = getCityDatas
        .filter((item) => item.MstBlockId === citySelectedValue.MstBlockId)
        .map((item) => ({
          MstCityId: item.MstCityId,
          City_en: item.City_en,
        }));

      const citySelected = cityLabels.map((AR) => ({
        value: AR.MstCityId, // Replace with the appropriate value
        label: AR.City_en,
      }));
      setBindCityVillage(citySelected);
    } else {
    }
  }, [block]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state.value // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindBranchProjectDD(locationSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == branchProject.value // Replace with the desired MstStateId
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId, // Replace with the appropriate value
        label: SD.SDP_en,
      }));
      setBindSDP(sdpSelected);
    } else {
    }
  }, [branchProject]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = getAreaDatas.find(
      (item) => item.MstSDPId == sdp.value // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = getAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));
      setBindAreaDD(areaSelected);
    } else {
    }
  }, [sdp]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, "0");
    setDate(`${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    getCurrentDate();
    Clearvalues();
    const stateLable = bindState.find(
      (ST) => ST.value === userProfileDatas[0].MstStateId
    );
    const stateValue = {
      value: userProfileDatas[0].MstStateId,
      label: stateLable ? stateLable.label : "Select",
    };
    setState(stateValue);

    const locationLable = getLocationDatas.find(
      (L) => L.MstLocationId === userProfileDatas[0].MstLocationId
    );
    const locationValue = {
      value: userProfileDatas[0].MstLocationId,
      label: locationLable ? locationLable.LocationName_en : "Select",
    };
    setBranchProject(locationValue);
    if (userProfileDatas[0].MstOrganizationId == 3) {
      const SDPInLable = getSDPDatas.find(
        (SD) => SD.MstSDPId === userProfileDatas[0].MstSDPId
      );
      const SDPValue = {
        value: userProfileDatas[0].MstSDPId,
        label: SDPInLable ? SDPInLable.SDP_en : "Select",
      };
      setSdp(SDPValue);
    }
  };

  const Clearvalues = () => {
    setIECNo("");
    setTopics("");
    setState("");
    setSdpCode("");
    setAreaCode("");
    setStateCode("");
    setBranchProjectCode("");
    setBranchProject("");
    setSdp("");
    setArea("");
    setLocalityStreet("");
    setDescription("");
    setChannel("");
    setStaffName1("");
    setStaffName2("");
    setDistrict("");
    setBlock("");
    setCityVillage("");
    setTableData([
      {
        participantNameEn: "",
        participantNameTa: "",
        participantNameHi: "",
        participantNameMr: "",
        participantNameGu: "",
        participantNameKn: "",
        participantNameMl: "",
        participantNameTe: "",
        participantNameBn: "",
        participantNameOr: "",
        age: "",
        gender: "",
        mobileNumber: "",
        referralService: "",
        referredTo: "",
        fallowUpDate: "",
      },
    ]);
    // setIECNoError("");
    setDateError("");
    setStateError("");
    setBranchProjectError("");
    setSdpError("");
    setAreaError("");
    setLocalityStreetError("");
    setCityVillageError("");
    setBlockError("");
    setDistrictError("");
    setStaffNameError1("");
    // setStaffNameError2("");
    setChannelError("");
    setTopicsError("");
  };

  const dropdownValuesEffect = () => {
    if (editMode && selectedRowIndex !== null) {
      dropdownstate(selectedRowIndex);
      dropdownsdp(selectedRowIndex);
      dropdownlocation(selectedRowIndex);
      dropdownArea(selectedRowIndex);
      dropdownStaff(selectedRowIndex);
      dropdownStaff2(selectedRowIndex);
      dropdownDistrict(selectedRowIndex);
      dropdownBlock(selectedRowIndex);
      dropdownCity(selectedRowIndex);
    }
  };

  useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);

  const dropdownsdp = (rowIndex) => {
    try {
      const SDProwData = bindIPCAwarenessDatas[rowIndex];
      const SDPInfo = getSDPDatas.find(
        (SD) => SD.MstSDPId === SDProwData.MstSDPId
      );
      const ResultSDP = {
        value: SDProwData.MstSDPId,
        label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
      };
      setSdp(ResultSDP);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownlocation = (rowIndex) => {
    try {
      const locationrowData = bindIPCAwarenessDatas[rowIndex];
      const LocationInfo = getLocationDatas.find(
        (L) => L.MstLocationId === locationrowData.MstLocationId
      );
      const ResultLocation = {
        value: locationrowData.MstLocationId,
        label: LocationInfo
          ? LocationInfo.LocationName_en
          : "DefaultStateLabel",
      };
      setBranchProject(ResultLocation);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownArea = (rowIndex) => {
    try {
      const ArearowData = bindIPCAwarenessDatas[rowIndex];
      const ArearowDataInfo = getAreaDatas.find(
        (AT) => AT.MstAreaId === ArearowData.MstAreaId
      );
      const ResultArea = {
        value: ArearowData.MstAreaId,
        label: ArearowDataInfo
          ? ArearowDataInfo.AreaName_en
          : "DefaultStateLabel",
      };
      setArea(ResultArea);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };
  const dropdownstate = (rowIndex) => {
    try {
      const StaterowData = bindIPCAwarenessDatas[rowIndex];
      const StateInfo = bindState.find(
        (ST) => ST.value === StaterowData.MstStateId
      );

      const resultState = {
        value: StaterowData.MstStateId,
        label: StateInfo ? StateInfo.label : "DefaultStateLabel",
      };

      setState(resultState);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownStaff = (rowIndex) => {
    try {
      const staffDropdown = bindIPCAwarenessDatas[rowIndex];

      const staffInfo = bindStaffName.find(
        (SD) => SD.value === staffDropdown.StaffId1
      );
      const ResultStaff = {
        value: staffDropdown.StaffId1,
        label: staffInfo ? staffInfo.label : "DefaultStateLabel",
      };
      setStaffName1(ResultStaff);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownStaff2 = (rowIndex) => {
    try {
      const staffDropdown = bindIPCAwarenessDatas[rowIndex];

      const staffInfo = bindStaffName.find(
        (SD) => SD.value === staffDropdown.StaffId2
      );
      const ResultStaff = {
        value: staffDropdown.StaffId2,
        label: staffInfo ? staffInfo.label : "DefaultStateLabel",
      };
      if (staffDropdown.StaffId2 != null) {
        setStaffName2(ResultStaff);
      } else {
        setStaffName2(ResultStaff.value);
      }
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownDistrict = (rowIndex) => {
    try {
      const districtData = bindIPCAwarenessDatas[rowIndex];
      const districtInfo = getDistrictDatas.find(
        (L) => L.MstDistrictId === districtData.MstDistrictId
      );
      const ResultDistrict = {
        value: districtData.MstDistrictId,
        label: districtInfo ? districtInfo.District_en : "DefaultStateLabel",
      };
      setDistrict(ResultDistrict);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownBlock = (rowIndex) => {
    try {
      const blockData = bindIPCAwarenessDatas[rowIndex];
      const blockDataInfo = getBlockDatas.find(
        (AT) => AT.MstBlockId === blockData.MstBlockId
      );
      const ResultBlock = {
        value: blockData.MstBlockId,
        label: blockDataInfo ? blockDataInfo.Block_en : "DefaultStateLabel",
      };
      setBlock(ResultBlock);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };
  const dropdownCity = (rowIndex) => {
    try {
      const cityData = bindIPCAwarenessDatas[rowIndex];
      const cityInfo = getCityDatas.find(
        (ST) => ST.MstCityId === cityData.MstCityId
      );

      const resultCity = {
        value: cityData.MstCityId,
        label: cityInfo ? cityInfo.City_en : "DefaultStateLabel",
      };
      setCityVillage(resultCity);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  // const handleEdit = (rowIndex) => {
  //   setEditMode(true);
  //   setSelectedRowIndex(rowIndex);
  //   setUpdateScreenTitle(true);
  //   setVisibleAdd(true);
  //   setVisibleTable(false);
  //   setAddScreenTitle(false);
  //   const bindMstIECId = bindIPCAwarenessDatas[rowIndex].MstIECId;
  //   setEditMstICPAwarenessId(bindIPCAwarenessDatas[rowIndex].MstIECId);
  //   setIECNo(bindIPCAwarenessDatas[rowIndex].IECNo);
  //   setDate(bindIPCAwarenessDatas[rowIndex].OpeningDate);
  //   setLocalityStreet(bindIPCAwarenessDatas[rowIndex].LocalityStreet_en);
  //   setDescription(bindIPCAwarenessDatas[rowIndex].Descripition_en);
  //   const ParticipantDetailDatas = Object.values(
  //     bindIPCParticipantDetailDatas
  //   ).filter((item) => {
  //     return String(item.MstIECId) === String(bindMstIECId);
  //   });
  //   if (ParticipantDetailDatas.length === 0) {
  //     setTableData([
  //       {
  //         participantNameEn: "",
  //         participantNameTa: "",
  //         participantNameHi: "",
  //         participantNameMr: "",
  //         participantNameGu: "",
  //         participantNameKn: "",
  //         participantNameMl: "",
  //         participantNameTe: "",
  //         participantNameBn: "",
  //         participantNameOr: "",
  //         age: "",
  //         gender: "",
  //         mobileNumber: "",
  //         referralService: "",
  //         referredTo: "",
  //         fallowUpDate: "",
  //       },
  //     ]);
  //   } else {
  //     appendDataToState(JSON.stringify(ParticipantDetailDatas));
  //   }
  //   const ParticipantDetailsfilteredData = Object.values(
  //     bindIPCTopicsDatas
  //   ).filter((item) => item.MstIECId === bindMstIECId);

  //   const topics = ParticipantDetailsfilteredData.map(
  //     (item) => item.MstTopicId
  //   );

  //   console.log("bindtopic", bindIPCTopicsDatas);
  //   setTopics(topics);

  //   const IssueRadio = bindIPCAwarenessDatas[rowIndex].Channel;
  //   const IssueRadioString = IssueRadio ? IssueRadio.toString() : "";
  //   setChannel(IssueRadioString);
  // };

  const handleEdit = (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);

    const bindMstIECId = bindIPCAwarenessDatas[rowIndex].MstIECId;
    setEditMstICPAwarenessId(bindIPCAwarenessDatas[rowIndex].MstIECId);
    setIECNo(bindIPCAwarenessDatas[rowIndex].IECNo);
    setDate(bindIPCAwarenessDatas[rowIndex].OpeningDate);
    setLocalityStreet(bindIPCAwarenessDatas[rowIndex].LocalityStreet_en);
    setDescription(bindIPCAwarenessDatas[rowIndex].Descripition_en);

    const ParticipantDetailDatas = Object.values(
      bindIPCParticipantDetailDatas
    ).filter((item) => String(item.MstIECId) === String(bindMstIECId));

    // Reset table data first
    setTableData([
      {
        participantNameEn: "",
        participantNameTa: "",
        participantNameHi: "",
        participantNameMr: "",
        participantNameGu: "",
        participantNameKn: "",
        participantNameMl: "",
        participantNameTe: "",
        participantNameBn: "",
        participantNameOr: "",
        age: "",
        gender: "",
        mobileNumber: "",
        referralService: "",
        referredTo: "",
        fallowUpDate: "",
      },
    ]);

    // If participant data exists, append it
    if (ParticipantDetailDatas.length > 0) {
      const formattedDataArray = ParticipantDetailDatas.map((data) => {
        const formattedDateOfExpiry = data.FallowUpDate;
        const formattedDate1 = formattedDateOfExpiry
          ? formattedDateOfExpiry.split("T")[0]
          : null;
        return {
          participantNameEn: data.ParticipantName_en
            ? data.ParticipantName_en
            : "",
          age: data.MstAgeId ? data.MstAgeId : "",
          gender: data.MstGenderId ? data.MstGenderId : "",
          mobileNumber: data.MobileNumber ? data.MobileNumber : "",
          referralService: data.MstReferredById ? data.MstReferredById : "",
          referredTo: data.MstReferredToId ? data.MstReferredToId : "",
          fallowUpDate: formattedDate1 ? formattedDate1 : "",
        };
      });

      // Remove any rows with all empty fields
      const nonEmptyRows = formattedDataArray.filter((row) => {
        return Object.values(row).some((value) => value !== "");
      });

      setTableData(nonEmptyRows);
      convertArrayToJSONString(nonEmptyRows);
    } else {
      // If there's no participant data, add a new row
      setTableData((prevData) => [...prevData]);
    }

    const ParticipantDetailsfilteredData = Object.values(
      bindIPCTopicsDatas
    ).filter((item) => item.MstIECId === bindMstIECId);

    const topics = ParticipantDetailsfilteredData.map(
      (item) => item.MstTopicId
    );

    setTopics(topics);

    const IssueRadio = bindIPCAwarenessDatas[rowIndex].Channel;
    const IssueRadioString = IssueRadio ? IssueRadio.toString() : "";
    setChannel(IssueRadioString);
  };

  const handleSubmit = async (rowIndex, flag) => {

    setSubmitBtnDisabled(true);
    // Wait for all the updates to the table data to complete before continuing
    // await new Promise((resolve) => {
    //   setTableData((prevTableData) => {
    //     resolve(); // Resolve the promise after state update is done
    //     return [...prevTableData]; // Make sure you're correctly handling table updates here
    //   });
    // });
    if (flag === 1 || flag === 2) {
      // Validation start
      const dateValidation = date ? "" : "Enter DOB";
      setDateError(dateValidation);

      const channelValidate = channel ? "" : "Choose Channel";
      setChannelError(channelValidate);

      const stateValidate = state ? "" : "Select State";
      setStateError(stateValidate);

      const locationValidate = branchProject ? "" : "Select Branch/Project";
      setBranchProjectError(locationValidate);

      const sdpValidate = sdp ? "" : "Select SDP";
      setSdpError(sdpValidate);

      const areaValidate = area ? "" : "Select Area";
      setAreaError(areaValidate);

      const staffNameValidate1 = staffName1 ? "" : "Select Staff Name1";
      setStaffNameError1(staffNameValidate1);

      // const staffNameValidate2 = staffName2 ? "" : "Select Staff Name2";
      // setStaffNameError2(staffNameValidate2);

      const cityValidate = cityVillage ? "" : "Select Village / City";
      setCityVillageError(cityValidate);

      const districtValidate = district ? "" : "Select District";
      setDistrictError(districtValidate);

      const blockValidate = block ? "" : "Select Block";
      setBlockError(blockValidate);

      const placeValidation = localityStreet ? "" : "Enter Locality / Street";
      setLocalityStreetError(placeValidation);

      const topicsValidation = topics.length > 0 ? "" : "Select Topics";
      setTopicsError(topicsValidation);

      if (
        dateValidation ||
        stateValidate ||
        locationValidate ||
        sdpValidate ||
        areaValidate ||
        placeValidation ||
        staffNameValidate1 ||
        // staffNameValidate2 ||
        cityValidate ||
        districtValidate ||
        blockValidate ||
        channelValidate ||
        topicsValidation
      ) {
        setIsOpen(true);
      }

      const jsonString = await convertArrayToJSONString(tableData);

      if (!jsonString.trim()) {
        setParticipantError("Fill the above table");
        setSubmitBtnDisabled(false);
        setIsOpen(true);
        return; // Stop further execution
      }

      let participantData = tableData;
      // try {
      //   participantData = JSON.parse(IECParticipantDetails);
      // } catch (error) {
      //   console.error("Error parsing participant data:", error);
      //   participantData = [];
      // }

      // Check if participant data is an array
      if (!Array.isArray(participantData)) {
        console.error("Participant data is not an array");
        participantData = [];
      }

      // Map over participant data to check for errors and collect error messages
      const participantErrors = participantData.map((participant, index) => {
        if (!participant) {
          setSubmitBtnDisabled(false);
          return `Participant at row ${index + 1} is missing.`;
        }
        if (!participant.participantNameEn) {
          setSubmitBtnDisabled(false);
          return `Participant at row ${index + 1} is missing a Name.`;
        }
        if (!participant.age) {
          setSubmitBtnDisabled(false);
          return `Participant at row ${index + 1} is missing an Age.`;
        }
        if (!participant.gender) {
          setSubmitBtnDisabled(false);
          return `Participant at row ${index + 1} is missing a Gender.`;
        }
        if (participant.referralService && !participant.referredTo) {
          setSubmitBtnDisabled(false);
          return `Participant at row ${
            index + 1
          } has Referral Service provided but is missing Referred To field.`;
        }
        return null;
      });

      // Filter out null errors
      const filteredErrors = participantErrors.filter(
        (error) => error !== null
      );

      if (filteredErrors.length > 0) {
        setIsOpen(true);
      }

      // Check if there are any errors in participant data and set the error message accordingly
      if (filteredErrors.length > 0) {
        setParticipantError(filteredErrors.join("; "));
        setSubmitBtnDisabled(false);
        return; // Stop further execution if there are participant errors
      } else {
        setParticipantError(""); // No errors found
      }

      if (
        !dateValidation &&
        !stateValidate &&
        !locationValidate &&
        !sdpValidate &&
        !areaValidate &&
        !placeValidation &&
        !staffNameValidate1 &&
        // !staffNameValidate2 &&
        !cityValidate &&
        !districtValidate &&
        !blockValidate &&
        !channelValidate &&
        !topicsValidation
      ) {
        setIsOpen(false);
        var topicsArrayString = JSON.stringify(topics);
        topicsArrayString = topicsArrayString.slice(1, -1);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = []; // Create an array to store translations
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(localityStreet, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const translatedRemarks = []; // Create an array to store translations
        if (description) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(description, i);
            translatedRemarks.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }

        console.log(
          "translatedTexts",
          translatedTexts,
          translateParticipantText
        );
        const data = {
          MstIECId: editMstICPAwarenessId ? editMstICPAwarenessId : 0,
          IECNo: IECNo,
          iecType: IECType,
          topics: topicsArrayString,
          iecDate: date,
          channel: channel,
          mstCountryId: 1,
          mstStateId: state.value,
          mstLocationId: branchProject.value,
          mstSDPId: sdp.value,
          mstAreaId: area.value,
          staffId1: staffName1.value,
          staffId2: staffName2 ? staffName2.value : null,
          localityStreetEn: localityStreet,
          localityStreetTa: translatedTexts[0],
          localityStreetHi: translatedTexts[1],
          localityStreetMr: translatedTexts[2],
          localityStreetGu: translatedTexts[3],
          localityStreetKn: translatedTexts[4],
          localityStreetMl: translatedTexts[5],
          localityStreetTe: translatedTexts[6],
          localityStreetBn: translatedTexts[7],
          localityStreetOr: translatedTexts[8],
          mstDistrictId: district.value,
          mstBlockId: block.value,
          mstCityId: cityVillage.value,
          iecParticipantDetailsBulkUpload: jsonString,

          descripition_en: description,
          descripition_ta: translatedRemarks[0],
          descripition_hi: translatedRemarks[1],
          descripition_mr: translatedRemarks[2],
          descripition_gu: translatedRemarks[3],
          descripition_kn: translatedRemarks[4],
          descripition_ml: translatedRemarks[5],
          descripition_te: translatedRemarks[6],
          descripition_bn: translatedRemarks[7],
          descripition_or: translatedRemarks[8],
          createdBy: createdBy,
          flag: flag,
        };

        const response = await IUDAPI([data], "IECAPI/IUDIECICAPI");

        if (flag === 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Interpersonal Contacts data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Interpersonal Contacts data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag === 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success(
              "Interpersonal Contacts data updated successfully 👍",
              {
                duration: 5000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Interpersonal Contacts data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        MstIECId:
          bindIPCAwarenessDatas[rowIndex]?.MstIECId || editMstICPAwarenessId,
        iecType: IECType,
        createdBy: createdBy,
        flag: flag,
      };

      const response = await IUDAPI([data], "IECAPI/IUDIECICAPI");

      if (flag === 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success("Interpersonal Contacts data deleted successfully 🗑️", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Interpersonal Contacts data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag === 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success("Interpersonal Contacts data blocked successfully 🚫", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Interpersonal Contacts data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag === 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Interpersonal Contacts data unblocked successfully ✅",
            {
              duration: 5000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Interpersonal Contacts data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    // setPopUpOpenCountry(false);
  };

  const HandleICPDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }

    setCurrentData(bindIPCAwarenessDatas[rowIndex]);
    const EbindMstIECId = bindIPCAwarenessDatas[rowIndex].MstIECId;
    setEditMstICPAwarenessId(EbindMstIECId);

    const StaterowData = bindIPCAwarenessDatas[rowIndex];
    const StateInfo = bindState.find(
      (ST) => ST.value === StaterowData.MstStateId
    );

    const resultState = {
      value: StaterowData.MstStateId,
      label: StateInfo ? StateInfo.label : "DefaultStateLabel",
    };

    const locationrowData = bindIPCAwarenessDatas[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstLocationId
    );
    const ResultLocation = {
      value: locationrowData.MstLocationId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };

    const SDProwData = bindIPCAwarenessDatas[rowIndex];
    const SDPInfo = getSDPDatas.find(
      (SD) => SD.MstSDPId === SDProwData.MstSDPId
    );
    const ResultSDP = {
      value: SDProwData.MstSDPId,
      label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
    };

    const ArearowData = bindIPCAwarenessDatas[rowIndex];
    const ArearowDataInfo = getAreaDatas.find(
      (AT) => AT.MstAreaId === ArearowData.MstAreaId
    );
    const ResultArea = {
      value: ArearowData.MstAreaId,
      label: ArearowDataInfo
        ? ArearowDataInfo.AreaName_en
        : "DefaultStateLabel",
    };

    const staffDropdown1 = bindIPCAwarenessDatas[rowIndex];
    const staffInfo1 = bindStaffName.find(
      (SD) => SD.value === staffDropdown1.StaffId1
    );
    const ResultStaff1 = {
      value: staffDropdown1.StaffId1,
      label: staffInfo1 ? staffInfo1.label : "DefaultStateLabel",
    };

    const staffDropdown2 = bindIPCAwarenessDatas[rowIndex];
    const staffInfo2 = bindStaffName.find(
      (SD) => SD.value === staffDropdown2.StaffId2
    );
    const ResultStaff2 = {
      value: staffDropdown2.StaffId2,
      label: staffInfo2 ? staffInfo2.label : "Select",
    };

    const districtData = bindIPCAwarenessDatas[rowIndex];
    const districtInfo = getDistrictDatas.find(
      (L) => L.MstDistrictId === districtData.MstDistrictId
    );
    const ResultDistrict = {
      value: districtData.MstDistrictId,
      label: districtInfo ? districtInfo.District_en : "DefaultStateLabel",
    };

    const blockData = bindIPCAwarenessDatas[rowIndex];
    const blockDataInfo = getBlockDatas.find(
      (AT) => AT.MstBlockId === blockData.MstBlockId
    );
    const ResultBlock = {
      value: blockData.MstBlockId,
      label: blockDataInfo ? blockDataInfo.Block_en : "DefaultStateLabel",
    };

    const cityData = bindIPCAwarenessDatas[rowIndex];
    const cityInfo = getCityDatas.find(
      (ST) => ST.MstCityId === cityData.MstCityId
    );

    const resultCity = {
      value: cityData.MstCityId,
      label: cityInfo ? cityInfo.City_en : "DefaultStateLabel",
    };

    const bindMstIECId = bindIPCAwarenessDatas[rowIndex].MstIECId;

    const ParticipantDetailDatas = Object.values(
      bindIPCParticipantDetailDatas
    ).filter((item) => {
      return String(item.MstIECId) === String(bindMstIECId);
    });

    const TopicsfilteredData = Object.values(bindIPCTopicsDatas).filter(
      (item) => item.MstIECId === bindMstIECId
    );
    const resultTopics = TopicsfilteredData.map((item) => item.MstTopicId);

    const IssueRadio = bindIPCAwarenessDatas[rowIndex].Channel;
    const IssueRadioString = IssueRadio ? IssueRadio.toString() : "";

    const IPCData2 = [
      resultTopics,
      resultState,
      ResultLocation,
      ResultSDP,
      ResultArea,
      ResultStaff1,
      ResultDistrict,
      ResultBlock,
      resultCity,
      IssueRadioString,
      ResultStaff2,
    ];
    setCurrentData2(IPCData2);
    setCurrentData3(ParticipantDetailDatas);
    const drpdetailsData = [
      bindtopics,
      bindAgeDD,
      bindgenderRadioDD,
      bindreferredtoDD,
      bindreferelserviceDD,
    ];
    setCurrentData4(drpdetailsData);
  };

  // Add screen table grid function

  const [tableData, setTableData] = useState([
    {
      participantNameEn: "",
      participantNameTa: "",
      participantNameHi: "",
      participantNameMr: "",
      participantNameGu: "",
      participantNameKn: "",
      participantNameMl: "",
      participantNameTe: "",
      participantNameBn: "",
      participantNameOr: "",
      age: "",
      gender: "",
      mobileNumber: "",
      referralService: "",
      referredTo: "",
      fallowUpDate: "",
    },
  ]);

  const handleInputChange = (selectedOption, rowIndex, columnName) => {

    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;
      // convertArrayToJSONString(newData); // Call convertArrayToJSONString inside setTableData
      return newData;
    });
  };

  const updateTableData = (value, index, property) => {

    setSubmitBtnDisabled(true);
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[index] = { ...updatedData[index], [property]: value };
      // convertArrayToJSONString(updatedData); // Call convertArrayToJSONString inside setTableData
      return updatedData;
    });
    setSubmitBtnDisabled(false);
  };

  // const updateTableData = (value, index, property) => {
  //   return new Promise((resolve) => {
  //     setTableData((prevTableData) => {
  //       const updatedData = [...prevTableData];
  //       updatedData[index] = { ...updatedData[index], [property]: value };
  //       convertArrayToJSONString(updatedData); // Call your custom function after state update
  //       resolve(updatedData); // Resolve the promise with the updated data
  //       return updatedData;
  //     });
  //   });
  // }
  const convertArrayToJSONString = async (array) => {
    try {
      const languageKeys = [
        "ta",
        "hi",
        "mr",
        "gu",
        "kn",
        "ml",
        "te",
        "bn",
        "or",
      ];

      const formattedDataArray = await Promise.all(
        array.map(async (data) => {
          const translateParticipantText = await Promise.all(
            languageKeys.map((lang) =>
              LanguageTranslatorAPI(data.participantNameEn, lang)
            )
          );

          const translations = translateParticipantText.map(
            (t) => t.data.data.translations[0].translatedText
          );

          return {
            participantNameEn: data.participantNameEn,
            participantNameTa: translations[0],
            participantNameHi: translations[1],
            participantNameMr: translations[2],
            participantNameGu: translations[3],
            participantNameKn: translations[4],
            participantNameMl: translations[5],
            participantNameTe: translations[6],
            participantNameBn: translations[7],
            participantNameOr: translations[8],
            age: data.age,
            gender: data.gender,
            mobileNumber: data.mobileNumber,
            referralService: data.referralService,
            referredTo: data.referredTo,
            fallowUpDate: data.fallowUpDate,
          };
        })
      );

      const jsonString = JSON.stringify(formattedDataArray);
      setIECParticipantDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  // const handleDeleteRow = (index) => {
  //   setTableData((prevData) => {
  //     const updatedTableData = prevData.filter((_, i) => i !== index);
  //     convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString inside setTableData
  //     return updatedTableData;
  //   });
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          participantNameEn: "",
          age: "",
          gender: "",
          mobileNumber: "",
          referralService: "",
          referredTo: "",
          fallowUpDate: "",
        };
        updatedTableData.push(newRow); // Add a default row if the table is empty
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const handleAddRow = () => {
    if (tableData.length > 3) {
      return; // Exit the function if there are already 4 rows
    }
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.participantNameEn = ""; // Clear the values of the new row
    newRow.age = "";
    newRow.gender = "";
    newRow.mobileNumber = "";
    newRow.referralService = "";
    newRow.referredTo = "";
    newRow.fallowUpDate = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };
  const headerData = [
    {
      title: "Name",
      key: "participantNameEn",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Age",
      key: "age",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Gender",
      key: "gender",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Mobile No.",
      key: "mobileNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral Service",
      key: "referralService",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral To",
      key: "referredTo",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Follow-up",
      key: "fallowUpDate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  const appendDataToState = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    // handleDeleteRow(0);
    const formattedDataArray = newDataArray.map((data) => {
      const formattedDateOfExpiry = data.FallowUpDate;
      const formattedDate1 = formattedDateOfExpiry
        ? formattedDateOfExpiry.split("T")[0]
        : null;
      return {
        participantNameEn: data.ParticipantName_en
          ? data.ParticipantName_en
          : "",
        age: data.MstAgeId ? data.MstAgeId : "",
        gender: data.MstGenderId ? data.MstGenderId : "",
        mobileNumber: data.MobileNumber ? data.MobileNumber : "",
        referralService: data.MstReferredById ? data.MstReferredById : "",
        referredTo: data.MstReferredToId ? data.MstReferredToId : "",
        fallowUpDate: formattedDate1 ? formattedDate1 : "",
      };
    });

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };
  const GenerateId = () => {
    if (addScreenTitle) {
      if (state && branchProject && sdp && area) {
        console.log("All required data is available");

        const filteredCategory = stateCodeDD
          .filter((item) => item.MstStateId === state.value)
          .map((item) => item.StateCode);
        const filteredBranchProject = getLocationDatas
          .filter((item) => item.MstLocationId === branchProject.value)
          .map((item) => item.LocationCode);
        const filteredSdp = getSDPDatas
          .filter((item) => item.MstSDPId === sdp.value)
          .map((item) => item.SDPCode);
        const filteredArea = getAreaDatas
          .filter((item) => item.MstAreaId === area.value)
          .map((item) => item.AreaCode);

        if (
          filteredCategory.length > 0 &&
          filteredBranchProject.length > 0 &&
          filteredSdp.length > 0 &&
          filteredArea.length > 0
        ) {
          console.log("Setting state codes");
          setStateCode(filteredCategory[0]);
          setBranchProjectCode(filteredBranchProject[0]);
          setSdpCode(filteredSdp[0]);
          setAreaCode(filteredArea[0]);
        }
      }
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      GenerateId();
    }
  }, [
    addScreenTitle,
    state,
    branchProject,
    sdp,
    area,
    stateCode,
    branchProjectCode,
    sdpCode,
    areaCode,
  ]);

  useEffect(() => {
    if (addScreenTitle) {
      if (stateCode && branchProjectCode && sdpCode) {
        const outputString = `${stateCode}/${branchProjectCode}/${sdpCode}/${areaCode}`;
        setIECNo(outputString);
        console.log("IEC No:", outputString);
      }
    }
  }, [addScreenTitle, stateCode, branchProjectCode, sdpCode, areaCode]);

  useEffect(() => {
    if (location.pathname == "/interpersonalcontacts") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // BindIECGridData(userProfileDatas);
      cancel();
    }
  }, [location, userProfileDatas]);

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Interpersonal Contacts (1-4)"
          header={InterPersonalContactHeader}
          records={bindIPCAwarenessDatas}
          handleEdit={handleEdit}
          handleAction={HandleICPDetails}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Interpersonal Contacts (1-4)"
          />
          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form class={mainTable.formMaster}>
                <div className={mainTable.grid3}>
                  <div>
                    <InputText
                      inputType="date"
                      classStar="text-red-500"
                      title=" Date"
                      name="date"
                      content={date}
                      controlFunc={(e) => setDate(e.target.value)}
                      controlFuncBlur={(e) => {
                        setDate(e.target.value);
                        setDateError(date ? "" : "Please Select Opening Date");
                      }}
                      max={new Date().toISOString().split("T")[0]}
                    />
                    {dateError && <span class="text-red-500">{dateError}</span>}
                  </div>
                  <div>
                    <AutoDropDown
                      title="State"
                      classStar="text-red-500"
                      options={bindState}
                      placeholder="Select"
                      name="state"
                      selectedOption={state}
                      disabled={true}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setState(value);
                        setStateError(value ? "" : "Select State");
                        setBranchProject("");
                        setSdp("");
                        setArea("");
                        setDistrict("");
                        setBlock("");
                        setCityVillage("");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select State");
                      }}
                    />
                    {stateError && (
                      <span className="text-red-500">{stateError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Branch/Project"
                      classStar="text-red-500"
                      options={bindBranchProject}
                      placeholder="Select"
                      name="branchProject"
                      selectedOption={branchProject}
                      disabled={true}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setBranchProject(value);
                        setBranchProjectError(
                          value ? "" : "Select Branch/Project"
                        );
                        setSdp("");
                        setArea("");
                      }}
                      controlFuncBlur={() => {
                        setBranchProjectError(
                          branchProject ? "" : "Select Branch/Project"
                        );
                      }}
                    />
                    {branchProjectError && (
                      <span className="text-red-500">{branchProjectError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="S.D.P."
                      classStar="text-red-500"
                      options={bindSDP}
                      placeholder="Select"
                      name="sdp"
                      selectedOption={sdp}
                      disabled={
                        editMode || userProfileDatas[0].MstOrganizationId == 3
                      }
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setSdp(value);
                        setSdpError(value ? "" : "Select S.D.P.");
                        setArea("");
                      }}
                      controlFuncBlur={() => {
                        setSdpError(sdp ? "" : "Select S.D.P.");
                      }}
                    />
                    {sdpError && (
                      <span className="text-red-500">{sdpError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Area"
                      classStar="text-red-500"
                      options={bindArea}
                      placeholder="Select"
                      name="area"
                      selectedOption={area}
                      disabled={editMode}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setArea(value);
                        setAreaError(value ? "" : "Select Area");
                      }}
                      controlFuncBlur={() => {
                        setAreaError(area ? "" : "Select Area");
                      }}
                    />
                    {areaError && (
                      <span className="text-red-500">{areaError}</span>
                    )}
                  </div>
                  <div>
                    <InputText
                      inputType="text"
                      classStar="text-red-500"
                      title="Interpersonal Contact No. "
                      placeholder="MH/MUM/DHI/MAN/IPC/2023/0001"
                      name="IECNo"
                      content={IECNo}
                      maxLength={30}
                      disabled="disabled"
                      controlFunc={(e) => setIECNo(e.target.value)}
                      controlFuncBlur={(e) => {
                        setIECNo(e.target.value);
                      }}
                    />
                    {/* {IECNoError && (
                      <span class="text-red-500">{IECNoError}</span>
                    )} */}
                  </div>
                  <div>
                    <MultiSelect
                      classStar="text-red-500"
                      label="Topics"
                      filteredOptions={bindtopics}
                      classFilterLabel="p-1 flex gap-1"
                      class="custom-class"
                      placeholder={
                        topics.length > 0 ? "More Selected" : "Select..."
                      }
                      name="topicsNames"
                      selectedOptions={topics}
                      classDiv="relative w-full inline-block"
                      onChange={handleTopicsNameChange}
                      controlFuncBlur={() => {
                        setTopicsError(
                          topics.length === 0 ? "Select Topics" : null
                        );
                      }}
                    />
                    {topicsError && (
                      <span class="text-red-500">{topicsError}</span>
                    )}
                  </div>

                  <div>
                    <CheckboxOrRadioGroup
                      classStar="text-red-500"
                      title="Channel"
                      type={"radio"}
                      setName="channel"
                      selectedOptions={channel}
                      options={issue}
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(e) => {
                        setChannel(e.target.value);
                        setChannelError(e.target.value ? "" : "Choose Chennel");
                      }}
                      controlFuncBlur={(e) => {
                        setChannel(e.target.value);
                        setChannelError(channel ? "" : "Choose Chennel");
                      }}
                    />
                    {channelError && (
                      <span class="text-red-500">{channelError}</span>
                    )}
                  </div>

                  <div>
                    <AutoDropDown
                      title="Staff Name1"
                      classStar="text-red-500"
                      options={bindStaffName}
                      placeholder="Select"
                      name="staffName1"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={staffName1}
                      controlFunc={(value) => {
                        setStaffName1(value);
                        setStaffNameError1(value ? "" : "Select Staff Name1");
                      }}
                      controlFuncBlur={() => {
                        setStaffNameError1(
                          staffName1 ? "" : "Select Staff Name1"
                        );
                      }}
                    />
                    {staffNameError1 && (
                      <span className="text-red-500">{staffNameError1}</span>
                    )}
                  </div>

                  <div>
                    <AutoDropDown
                      title="Staff Name2"
                      options={bindStaffName}
                      placeholder="Select"
                      name="staffName2"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={staffName2}
                      controlFunc={(value) => {
                        setStaffName2(value);
                        // setStaffNameError2(value ? "" : "Select Staff Name2");
                      }}
                      // controlFuncBlur={() => {
                      //   setStaffNameError2(
                      //     staffName2 ? "" : "Select Staff Name2"
                      //   );
                      // }}
                      // classStar="text-red-500"
                    />
                    {/* {staffNameError2 && (
                      <span className="text-red-500">{staffNameError2}</span>
                    )} */}
                  </div>

                  <div>
                    <InputText
                      title="Locality / Street"
                      type="text"
                      name="localityStreet"
                      classStar="text-red-500"
                      maxLength={100}
                      placeholder="Locality/Street "
                      content={localityStreet}
                      controlFunc={(e) => {
                        const value = e.target.value;
                        setLocalityStreet(value);
                        setLocalityStreetError(
                          value.trim().length > 0
                            ? ""
                            : "Enter Locality / Street"
                        );
                      }}
                      controlFuncBlur={(e) => {
                        const trimmedVenue = e.target.value.trim();
                        setLocalityStreet(trimmedVenue);
                        setLocalityStreetError(
                          trimmedVenue.length > 0
                            ? ""
                            : "Enter Locality / Street"
                        );
                      }}
                    />
                    {localityStreetError && (
                      <span class="text-red-500">{localityStreetError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="District"
                      classStar="text-red-500"
                      options={bindDistrict}
                      placeholder="Select"
                      name="district"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={district}
                      controlFunc={(value) => {
                        setDistrict(value);
                        setDistrictError(value ? "" : "Select District");
                        setBlock("");
                        setCityVillage("");
                      }}
                      controlFuncBlur={() => {
                        setDistrictError(district ? "" : "Select a District");
                      }}
                    />
                    {districtError && (
                      <span className="text-red-500">{districtError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Block"
                      classStar="text-red-500"
                      options={bindBlock}
                      placeholder="Select"
                      name="block"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={block}
                      controlFunc={(value) => {
                        setBlock(value);
                        setBlockError(value ? "" : "Select Block");
                        setCityVillage("");
                      }}
                      controlFuncBlur={() => {
                        setBlockError(block ? "" : "Select Block");
                      }}
                    />
                    {blockError && (
                      <span className="text-red-500">{blockError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="City / Village"
                      classStar="text-red-500"
                      options={bindCityVillage}
                      placeholder="Select"
                      name="cityVillage"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={cityVillage}
                      controlFunc={(value) => {
                        setCityVillage(value);
                        setCityVillageError(
                          value ? "" : "Select City / Village"
                        );
                      }}
                      controlFuncBlur={() => {
                        setCityVillageError(
                          cityVillage ? "" : "Select City / Village"
                        );
                      }}
                    />
                    {cityVillageError && (
                      <span className="text-red-500">{cityVillageError}</span>
                    )}
                  </div>
                </div>
                <div class="block text-base capitalize font-bold text-neutral-700">
                  List Of Participants
                </div>
                <div className="hidden md:block overflow-x-auto ">
                  <div className="table-container w-screen md:w-max xl:w-full max-h-96">
                    <table className="w-full border border-gray-300 ">
                      <thead>
                        <tr>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Name
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Age
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Gender
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Mobile No.
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Referral Service
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Referred To
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Follow-up
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 ">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index}>
                            <td className="border border-gray-300 p-2">
                              <input
                                type="text"
                                className={mainTable.input}
                                value={row.participantNameEn}
                                maxLength={100}
                                placeholder="Name"
                                onChange={(e) => {
                                  const inputText = e.target.value;
                                  if (/^[a-zA-Z\s]*$/.test(inputText)) {
                                    handleInputChange(
                                      inputText,
                                      index,
                                      "participantNameEn"
                                    );
                                    updateTableData(
                                      inputText,
                                      index,
                                      "participantNameEn"
                                    );
                                  }
                                }}
                              />
                            </td>

                            <td className="border border-gray-300 p-2 w-28">
                              <input
                                type="text"
                                className={mainTable.input}
                                value={row.age}
                                maxLength={2}
                                placeholder="Age"
                                onChange={(e) => {
                                  const inputText = e.target.value;
                                  if (/^\d{0,2}$/.test(inputText)) {
                                    handleInputChange(inputText, index, "age");
                                    updateTableData(inputText, index, "age");
                                  }
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <DropDown
                                options={bindgenderRadioDD}
                                placeholder="Select"
                                name="gender"
                                selectedOption={row.gender}
                                controlFunc={(e) =>
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "gender"
                                  )
                                }
                              />
                            </td>

                            <td className="border border-gray-300 p-2">
                              <input
                                type="text"
                                className={mainTable.input}
                                value={row.mobileNumber}
                                maxLength={11}
                                onChange={(e) => {
                              
                                  setSubmitBtnDisabled(true);
                                  const InputText = e.target.value;
                                  if (/^[0-9]*$/.test(InputText)) {
                                    handleInputChange(
                                      InputText,
                                      index,
                                      "mobileNumber"
                                    );
                                    updateTableData(
                                      InputText,
                                      index,
                                      "mobileNumber"
                                    );
                                  }
                                  setSubmitBtnDisabled(false);
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <DropDown
                                options={bindreferelserviceDD}
                                placeholder="Select"
                                name="referralService"
                                selectedOption={row.referralService}
                                controlFunc={(e) =>
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "referralService"
                                  )
                                }
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <DropDown
                                options={bindreferredtoDD}
                                placeholder="Select"
                                name="referredTo"
                                selectedOption={row.referredTo}
                                controlFunc={(e) =>
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "referredTo"
                                  )
                                }
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <input
                                type="date"
                                className={mainTable.input}
                                value={row.fallowUpDate}
                                onChange={(e) => {
                                  const selectedDate = new Date(e.target.value);
                                  const currentDate = new Date();
                                  // if (selectedDate >= currentDate) {
                                  handleInputChange(
                                    e.target,
                                    index,
                                    "fallowUpDate"
                                  );
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "fallowUpDate"
                                  );
                                  // } else {
                                  //   console.log("Please select a future date.");
                                  // }
                                }}
                                min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                              />
                            </td>

                            <td className="border border-gray-300 w-16">
                              <div className="flex gap-4 align-baseline items-center justify-center">
                                <svg
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  class="w-6 h-6 text-black cursor-pointer"
                                  onClick={() => handleDeleteRow(index)}
                                >
                                  <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                </svg>
                                <svg
                                  viewBox="0 0 1024 1024"
                                  fill="currentColor"
                                  onClick={handleAddRow}
                                  class="w-6 h-6 text-black cursor-pointer"
                                >
                                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="hidden md:block">
                  {participantError && (
                    <div className="text-red-500">{participantError}</div>
                  )}
                </div>
                <div className={mainTable.childTableMobile} role="table">
                  <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                    {tableData.length > 0 &&
                      tableData.map((row, index) => (
                        <div scope="col" class={mainTable.mobileTable}>
                          {headerData.map((keyData) => (
                            <div
                              scope="cell"
                              class={mainTable.tableData}
                              role="cell"
                            >
                              {keyData.key === "Action" ? (
                                <div class={mainTable.tableData}>
                                  <div className="md:block hidden">
                                    <button
                                      id="dropdownMenuIconButton"
                                      data-dropdown-toggle="dropdownDots"
                                      class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                      type="button"
                                    >
                                      <svg
                                        class="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                  <div className="md:hidden flex justify-center">
                                    <input
                                      type="button"
                                      className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                      onClick={handleAddRow}
                                      value="Add"
                                    />
                                    <input
                                      type="button"
                                      className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                      onClick={() => handleDeleteRow(index)}
                                      value="Delete"
                                    />
                                  </div>
                                </div>
                              ) : keyData.key === "participantNameEn" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="text"
                                    className={mainTable.input}
                                    value={row.participantNameEn}
                                    maxLength={100}
                                    placeholder="Name"
                                    onChange={(e) => {
                                      const inputText = e.target.value;
                                      if (/^[a-zA-Z\s]*$/.test(inputText)) {
                                        handleInputChange(
                                          inputText,
                                          index,
                                          "participantNameEn"
                                        );
                                        updateTableData(
                                          inputText,
                                          index,
                                          "participantNameEn"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "age" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="text"
                                    className={mainTable.input}
                                    value={row.age}
                                    maxLength={2}
                                    placeholder="Age"
                                    onChange={(e) => {
                                      const inputText = e.target.value;
                                      if (/^\d{0,2}$/.test(inputText)) {
                                        handleInputChange(
                                          inputText,
                                          index,
                                          "age"
                                        );
                                        updateTableData(
                                          inputText,
                                          index,
                                          "age"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "gender" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <DropDown
                                    options={bindgenderRadioDD}
                                    placeholder="Select"
                                    name="gender"
                                    selectedOption={row.gender}
                                    controlFunc={(e) =>
                                      updateTableData(
                                        e.target.value,
                                        index,
                                        "gender"
                                      )
                                    }
                                  />
                                </div>
                              ) : keyData.key === "mobileNumber" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="text"
                                    className={mainTable.input}
                                    value={row.mobileNumber}
                                    maxLength={11}
                                    onChange={(e) => {
                                      const InputText = e.target.value;
                                      if (/^[0-9]*$/.test(InputText)) {
                                        handleInputChange(
                                          InputText,
                                          index,
                                          "mobileNumber"
                                        );
                                        updateTableData(
                                          InputText,
                                          index,
                                          "mobileNumber"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "referralService" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <DropDown
                                    options={bindreferelserviceDD}
                                    placeholder="Select"
                                    name="referralService"
                                    selectedOption={row.referralService}
                                    controlFunc={(e) =>
                                      updateTableData(
                                        e.target.value,
                                        index,
                                        "referralService"
                                      )
                                    }
                                  />
                                </div>
                              ) : keyData.key === "referredTo" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <DropDown
                                    options={bindreferredtoDD}
                                    placeholder="Select"
                                    name="referredTo"
                                    selectedOption={row.referredTo}
                                    controlFunc={(e) =>
                                      updateTableData(
                                        e.target.value,
                                        index,
                                        "referredTo"
                                      )
                                    }
                                  />
                                </div>
                              ) : keyData.key === "fallowUpDate" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="date"
                                    className={mainTable.input}
                                    value={row.fallowUpDate}
                                    onChange={(e) => {
                                      const selectedDate = new Date(
                                        e.target.value
                                      );
                                      const currentDate = new Date();
                                      if (selectedDate >= currentDate) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "fallowUpDate"
                                        );
                                        updateTableData(
                                          e.target.value,
                                          index,
                                          "fallowUpDate"
                                        );
                                      } else {
                                        console.log(
                                          "Please select a future date."
                                        );
                                      }
                                    }}
                                    min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                                  />
                                </div>
                              ) : (
                                <div className="flex border p-1">
                                  <div className="flex basis-1/2 font-bold">
                                    {keyData.title}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="md:hidden">
                  {participantError && (
                    <div className="text-red-500">{participantError}</div>
                  )}
                </div>
                <div class="lg:col-span-5 sm:col-span-1">
                  <TextArea
                    inputType="text"
                    title="Description"
                    name="description"
                    rows="4"
                    maxLength={500}
                    content={description}
                    placeholder="Description"
                    controlFunc={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
              <Buttons
                addScreenTitle={addScreenTitle}
                updateScreenTitle={updateScreenTitle}
                handleSubmit={handleSubmit}
                cancel={cancel}
                disabledBtn={submitBtnDisabled ? true : false}
              />
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleICPDetails}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="Interpersonal Contacts (1-4)"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
          />
          <InterpersonalContactsPopup
            data={currentData}
            data1={currentData2}
            data2={currentData3}
            data3={currentData4}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default InterpersonalContacts;
