import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";
import { ImageAPI } from "../services/APIConnection";
import download from "../image/download.png";
import pdf from "../image/pdf.png";

const FileUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showImage, setShowImage] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [cancelImage, setCancelImage] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(props.disabledBtn);
  const fileInputRef = useRef(null);
  // const MAX_FILE_SIZE_MB = props.MAX_FILE_SIZE_MB || 2; // Default value 2 if not provided
  // const ALLOWED_FORMATS = props.ALLOWED_FORMATS || ["image/jpeg", "image/jpg", "image/png"];

  const MAX_FILE_SIZE_MB = props.MAX_FILE_SIZE_MB;
  const ALLOWED_FORMATS = props.ALLOWED_FORMATS;

  const isFileFormatAllowed = (file) => ALLOWED_FORMATS.includes(file.type);

  const isFileSizeBelowLimit = (file) =>
    file.size <= MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFileChange = (event) => {

    setButtonDisable(false);
    setShowImage(download);
    // props.showImageData(download);
    setSelectedFiles([]);
    const files = event.target.files;

    const selectedFilesLimited = Array.from(files)
      .filter((file) => isFileFormatAllowed(file) && isFileSizeBelowLimit(file))
      .slice(0, 3);

    if (selectedFiles.length + selectedFilesLimited.length > 3) {
      setUploadError("Cannot select more than 3 files");
      return;
    } else {
      setUploadError("");
    }

    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...selectedFilesLimited.map((file) => ({
        file,
        previewUrl: URL.createObjectURL(file),
      })),
    ]);

    const unsupportedFormatFiles = Array.from(files).filter(
      (file) => !isFileFormatAllowed(file)
    );
    if (unsupportedFormatFiles.length > 0) {
      const unsupportedFilesNames = unsupportedFormatFiles
        .map((file) => file.name)
        .join(", ");
      setUploadError(`Unsupported file format: ${unsupportedFilesNames}`);
      return;
    }

    const oversizedFiles = Array.from(files).filter(
      (file) => !isFileSizeBelowLimit(file)
    );
    if (oversizedFiles.length > 0) {
      const errorMessage = `File size exceeds the limit (${MAX_FILE_SIZE_MB}MB)`;
      setUploadError(errorMessage);
      return;
    }
  };

  const handleUpload = async () => {

    setButtonDisable(true);
    setCancelImage(false);
    if (selectedFiles.length === 0) {
      setUploadError("Please select files to upload");
      return;
    }

    const formData = new FormData();
    formData.append(props.modalName, selectedFiles[0].file); // Assuming you only want to upload one file here

    try {
      const response = await ImageAPI(formData, props.apiCall);
      //  setShowImage(response.data);
      if (showImage) {
        props.showImageData(response.data);
        setShowImage(response.data);
      }
      setUploadError(""); // Reset upload error on successful upload
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadError("Failed to upload image");
    }
  };
  const handleCancel = () => {

    setSelectedFiles([]);
    setShowImage("");
    setUploadError("");
    setCancelImage(true);
    setButtonDisable(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clears the file input value
    }
    // Call the parent component's function to reset `showImage`
    if (props.showImageData) {
      props.showImageData(""); // Clear parent component's image data if applicable
    }
  };

  return (
    <div className={props.classDiv ? props.classDiv : mainTable.grid3}>
      <div>
        <label
          className={props.classLabel ? props.classLabel : mainTable.label}
        >
          {props.title}
          <span className={props.classStar ? props.classStar : "hidden"}>
            *
          </span>
        </label>
        <input
          ref={fileInputRef}
          className={props.Class ? props.Class : mainTable.input}
          name={props.name}
          type="file"
          onChange={handleFileChange}
          disabled={props.disabled}
          tabIndex={props.tabIndex}
          accept=".jpg, .jpeg, .png, .pdf"
        />
        <span class="text-neutral-700 text-xs whitespace-normal block">
          {props.limitMassage}
        </span>
        {props.imageUploadedError && (
          <span class="text-red-500">{props.imageUploadedError}</span>
        )}
        {uploadError && (
          <span className="text-red-500 whitespace-normal block">
            {uploadError}
          </span>
        )}
      </div>
      <div className="flex py-4 md:py-0">
        <div className="mt-7 mr-7">
          <button
            type="button"
            className={
              buttonDisable
                ? mainTable.disabledSubmitButton
                : mainTable.submitButton
            }
            onClick={handleUpload}
            disabled={buttonDisable ? true : false}
          >
            UPLOAD
          </button>
        </div>
        {props.showImage && props.showImage.endsWith(".pdf") ? (
          // If the file is a PDF, show a PDF icon and a link to view/download it
          <a href={props.showImage} target="_blank" rel="noopener noreferrer">
            <img
              src={pdf} // You can replace this with a custom PDF icon
              alt="PDF"
              className="w-20 h-20"
            />
            <p>View PDF</p>
          </a>
        ) : (
          <div>
            {props.showImage && !cancelImage ? (
              <div>
                <img
                  src={showImage ? showImage:props.showImage}
                  alt="Image"
                  className="rounded-full w-20 h-20"
                />
                <button
                  type="button"
                  className="text-red-500 ml-4"
                  onClick={handleCancel}
                >
                  Delete
                </button>
              </div>
            ) : (
              <img
                src={download}
                alt="Image"
                className="rounded-full w-20 h-20"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  modalName: PropTypes.string.isRequired,
  apiCall: PropTypes.string.isRequired,
  showImageData: PropTypes.func,
  classDiv: PropTypes.string,
  showImage: PropTypes.string,
  classLabel: PropTypes.string,
  classStar: PropTypes.string,
  Class: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  MAX_FILE_SIZE_MB: PropTypes.number, // Prop types for MAX_FILE_SIZE_MB
  ALLOWED_FORMATS: PropTypes.arrayOf(PropTypes.string),
  limitMassage: PropTypes.string,
  imageUploadedError: PropTypes.string,
  disabledBtn:PropTypes.bool,
};

export default FileUpload;
