import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import {
  cseOutSchoolHeaders,
  outOfSchoolYouthHeaders,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import moment from "moment/moment";

import Checkbox from "../ReusableComponents/Checkbox";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import FileUpload from "../ReusableComponents/FileUpload";
import Buttons from "../components/Buttons";
import { Link, useLocation } from "react-router-dom";
import OutSchoolPopup from "../popup-containers/OutSchoolPopup";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function OutSchoolSession() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const [mstBranchId, setMstBranchId] = useState("");
  const [mstYouthId, setMstYouthId] = useState("");
  //Value store States
  const [outOfSchoolFields, setOutOfSchoolFields] = useState({
    session: "",
    mstYouthGroupId: "",
    resourcePersonType: "",
    resourcePersonName1: "",
    resourcePersonName2: "",
    date: new Date(),
    startTime: getCurrentTime(),
    endTime: getCurrentTime(),
    showImage: "",
    remarks: "",
    mstBranchId: "",
  });
  const [outOfSchoolFieldsError, setOutOfSchoolFieldsError] = useState({
    sessionError: "",
    mstYouthGroupIdError: "",
    resourcePersonTypeError: "",
    resourcePersonNameError: "",
    dateError: "",
    startTimeError: "",
    endTimeError: "",
    showImageError: "",
    remarksError: "",
  });

  const handleOutSchoolFieldsChange = (value, name) => {
    setOutOfSchoolFields({
      ...outOfSchoolFields,
      [name]: value,
    });
  };

  const [outSchoolId, setOutSchoolId] = useState("");
  const [minYouthDetailsError, setMinYouthDetailsError] = useState("");
  const [editSessionNumber, setEditSessionNumber] = useState(0);
  const [sessionAddDisability, setSessionAddDisability] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [bindDBResource, setBindDBResource] = useState([]);
  const [bindDBYouths, setBindDBYouths] = useState([]);
  const [bindResourcePersonType, setBindResourcePersonType] = useState([]);
  const [bindSession, setBindSession] = useState([]);
  const [bindClass, setBindClass] = useState([]);

  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedModules1, setSelectedModules1] = useState([]);
  const [selectedModules2, setSelectedModules2] = useState([]);
  const [viewAction, setViewAction] = useState(false);

  const [bindCSEAgeDD, setBindCSEAgeDD] = useState([]);
  const [youthData, setYouthData] = useState([]);
  const [bindServicesName, setBindreferelserviceDD] = useState([]);
  const [bindGenderDD, setBindGenderDD] = useState([]);
  const [bindReferedTo, setBindReferedTo] = useState([]);
  const [bindYouthGroupDD, setBindYouthGroupDD] = useState([]);
  const [bindResourcePersonName, setBindResourcePersonName] = useState([]);
  const [bindCSEDatas, setBindCSEDatas] = useState([]);
  const [bindModules, setBindModules] = useState([]);

  const [submitServiceButton, setSubmitServiceButton] = useState(false);
  const bindAllDropdownData = async () => {
    try {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        setMstBranchId(userProfileDatas[0].MstLocationId);
        const data = {
          mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          mstBranchId: userProfileDatas[0].MstLocationId,
          mstSdpId: userProfileDatas[0].MstSDPId
            ? userProfileDatas[0].MstSDPId
            : 0,
          mstUserId: userProfileDatas[0].MstUserId,
          mstRoleId: userProfileDatas[0].MstRoleId,
        };
        // const bindSchooldatadrp = await BindPostAPI([data], "CSEInSchoolAPI/BindCSEAllMasterDatas");
        const response = await BindPostAPI(
          [data],
          "CSEInSchoolAPI/BindCSEAllMasterDatas"
        );

        if (response.data) {
          // Extracting Modules data
          const Modulesdatadrp =
            response.data.Table.length > 0
              ? response.data.Table.map((m) => ({
                  value: m.MstCSEModuleID,
                  label: m.CSEModule,
                }))
              : [];
          setBindModules(Modulesdatadrp);

          // Extracting Age data
          const AgeDD =
            response.data.Table1.length > 0
              ? response.data.Table1.map((m) => ({
                  value: m.MstAgeId,
                  label: m.Age,
                }))
              : [];
          setBindCSEAgeDD(AgeDD);

          // Extracting Gender data
          const GenderDD =
            response.data.Table2.length > 0
              ? response.data.Table2.map((m) => ({
                  value: m.MstGenderId,
                  label: m.Gender,
                }))
              : [];
          setBindGenderDD(GenderDD);

          // Extracting Referral Services data
          const ReferredBydatadrpDD =
            response.data.Table3.length > 0
              ? response.data.Table3.map((m) => ({
                  value: m.MstReferralServicesId,
                  label: m.ReferralServices,
                }))
              : [];
          setBindreferelserviceDD(ReferredBydatadrpDD);

          // Extracting Referred To data
          const ReferredTodatadrpDD =
            response.data.Table4.length > 0
              ? response.data.Table4.map((m) => ({
                  value: m.MstReferredToId,
                  label: m.ReferredTo_en,
                }))
              : [];
          setBindReferedTo(ReferredTodatadrpDD);

          // Extracting YouthGroup data

          const YouthGroupDD =
            response.data.Table8.length > 0
              ? response.data.Table8.map((m) => ({
                  value: m.MstYouthGroupId,
                  label: m.YouthGroupName,
                }))
              : [];
          setBindYouthGroupDD(YouthGroupDD);
        } else {
          setBindYouthGroupDD([]);
        }

        // Extracting Resource Person Type data
        const ResourceDataDD =
          response.data.Table7.length > 0
            ? response.data.Table7.map((m) => ({
                value: m.MstResourcepersonTypeId,
                label: m.ResourcepersonType,
              }))
            : [];
        setBindResourcePersonType(ResourceDataDD);

        const ResourcePersonNameDD =
          response.data.Table9.length > 0
            ? response.data.Table9.map((m) => ({
                value: m.MstResourcesId,
                label: m.ResourcesName,
              }))
            : [];
        setBindResourcePersonName(ResourcePersonNameDD);
      } else {
        // Handle the case where response.data is undefined or has no data
        setBindModules([]);
        setBindCSEAgeDD([]);
        setBindGenderDD([]);
        setBindreferelserviceDD([]);
        setBindReferedTo([]);
        setBindYouthGroupDD([]);
        setBindResourcePersonType([]);
        setBindResourcePersonName([]);
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      // Handle error cases for each dropdown if needed
    }
  };

  const handleModulesChange = (value) => {
    // Assuming setSelectedModules1 is a state setter and selectedModules1 is the current state value
    const newValues = value.filter((item) => !selectedModules1.includes(item));

    setSelectedModules(value);
    setSelectedModules1(value);
  };

  const [modulesError, setModulesError] = useState("");
  const [youthDetailsError, setYouthDetailsError] = useState("");
  // const [minYouthDetailsError, setMinStudentDetailsError] = useState("");

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleAddPage, setVisibleAddPage] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [sessionIdEdit, setSessionIdEdit] = useState("");

  const [currentData1, setCurrentData1] = useState({});
  const [currentData2, setCurrentData2] = useState({});
  const [currentData3, setCurrentData3] = useState({});
  const [currentData4, setCurrentData4] = useState({});

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [rowIndex, setRowIndex] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [youthDetails, setYouthDetails] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  const showAdd = () => {
    // handleDeleteRow(0);
    tableData.splice(0, 1);
    setOutOfSchoolFields({
      session: 1,
    });
    setViewAction(false);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setViewAction(false);
    getBranch(userProfileDatas);
  };
  const showAddSession = async (rowIndex) => {
    setSessionAddDisability(true);
    setVisibleAddPage(true);
    getBranch(userProfileDatas);
    const bind = bindCSEDatas[rowIndex];

    const lastSession = bind.Sessions.split(",").map(
      (value) => parseInt(value.trim(), 10) % 10
    ); // % 10 gives the last digit
    const length = lastSession.length - 1;
    setOutOfSchoolFields({
      session: lastSession[length] + 1,
      mstYouthGroupId: bind.MstYouthGroupId,
      startTime: getCurrentTime(),
      endTime: getCurrentTime(),
      date: new Date(),
    });
    const data = {
      year: parseInt(bind.Date.split("-")[0]),
      mstYouthGroupId: parseInt(bind.MstYouthGroupId),
    };
    const response = await BindPostAPI(
      [data],
      "CSEOutSchoolAPI/BindCSEOutSchoolPreviousData"
    );
    const modules = response.data.Table1[0]?.Modules
      ? response.data.Table1[0].Modules.split(",").map((value) =>
          parseInt(value.trim(), 10)
        )
      : [];
    setSelectedModules([]);
    setSelectedModules1(modules);
    setSelectedModules2(modules);

    appendDataToYouthTable(JSON.stringify(response.data.Table));
    setViewAction(false);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setViewAction(false);
  };
  const cancel = () => {
    setVisibleAddPage(false);
    setMinYouthDetailsError("");
    setSessionAddDisability(false);
    setSubmitBtnDisabled(false);
    setModulesError("");
    setViewAction(false);
    const reset = {
      youthName: "",
      youthId: "",
      gender: "",
      age: "",
      referralService: "",
      referredTo: "",
      type: "",
    };
    setTableData([reset]);
    setOutOfSchoolFields({
      ...outOfSchoolFields,
      session: "",
      mstYouthGroupId: "",
      cseClass: "",
      division: "",
      resourcePersonType: "",
      resourcePersonName1: "",
      resourcePersonName2: "",
      date: "",
      startTime: "",
      endTime: "",
      showImage: "",
      remarks: "",
    });

    setOutOfSchoolFieldsError({
      ...outOfSchoolFieldsError,
      sessionError: "",
      mstYouthGroupIdError: "",
      cseClassError: "",
      divisionError: "",
      resourcePersonNameError: "",
      resourcePersonTypeError: "",
      dateError: "",
      startTimeError: "",
      endTimeError: "",
      showImageError: "",
      remarksError: "",
    });
    BindCSEGridData(userProfileDatas);
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setSelectedModules([]);
    setSelectedModules1([]);
    setUpdateScreenTitle(false);
    setYouthDetailsError("");
  };
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      const isFormError = {
        ...outOfSchoolFieldsError,
        sessionError: outOfSchoolFields.session ? "" : "Select Session",
        mstYouthGroupIdError: outOfSchoolFields.mstYouthGroupId
          ? ""
          : "Select Youth Group Name",
        resourcePersonNameError: outOfSchoolFields.resourcePersonName1
          ? ""
          : "Select Resource Person Name 1",
        resourcePersonTypeError: outOfSchoolFields.resourcePersonType
          ? ""
          : "Select Type Of Resource Person",
        dateError: outOfSchoolFields.date ? "" : "Select Date",
        startTimeError: outOfSchoolFields.startTime ? "" : "Select Start Time",
        endTimeError: outOfSchoolFields.endTime ? "" : "Select End Time",
        // showImageError: outOfSchoolFields.showImage
        //   ? ""
        //   : "Please Upload an Image",
      };
      setOutOfSchoolFieldsError(isFormError);

      var topicsArrayString;
      if (updateScreenTitle)
        topicsArrayString =
          selectedModules1.length > 0
            ? JSON.stringify(selectedModules1)
            : JSON.stringify(selectedModules);
      if (addScreenTitle) {
        const newValues = selectedModules.filter(
          (item) => !selectedModules2.includes(item)
        );
        topicsArrayString = JSON.stringify(newValues);
      }
      const modulesValidation =
        topicsArrayString != "[]" ? "" : "Select Module";
      setModulesError(modulesValidation);
      const isFormValid = Object.values(isFormError).every(
        (error) => error === ""
      );

      if (!isFormValid || modulesValidation || youthDetailsError ) {
        setIsOpen(true);
        setSubmitBtnDisabled(false);
      }

      if (!youthDetails || youthDetails === "") {
        setYouthDetailsError("Add list of participant details");
        setSubmitBtnDisabled(false);
        return; // Stop further execution
      }

      let youthData;
      try {
        youthData = JSON.parse(youthDetails);
      } catch (error) {
        console.error("Error parsing youth data:", error);
        youthData = [];
      }

      // Check if youth data is an array
      if (!Array.isArray(youthData)) {
        console.error("Youth data is not an array");
        youthData = [];
      }

      // Map over youth data to check for errors and collect error messages
      const youthErrors = youthData.map((youth, index) => {
        if (!youth) {
          return `Youth at row ${index + 1} is missing.`;
        }
        if (!youth.youthName) {
          return `Youth at row ${index + 1} is missing a name.`;
        }
        if (!youth.gender) {
          return `Youth at row ${index + 1} is missing a Gender.`;
        }
        if (!youth.age) {
          return `Youth at row ${index + 1} is missing an age.`;
        }

        if (youth.referralService && !youth.referredTo) {
          return `Youth at row ${
            index + 1
          } has Referral Service provided but is missing Referred To field.`;
        }

        return null;
      });
      // Filter out null errors
      const filteredErrors = youthErrors.filter((error) => error !== null);

      // Check if there are any errors in student data and set the error message accordingly
      if (filteredErrors.length > 0) {
        setSubmitBtnDisabled(false);
        setYouthDetailsError(filteredErrors.join("; "));
        return; // Stop further execution if there are student errors
      } else {
        setYouthDetailsError(""); // No errors found
      }
      
      if (youthData.length < 5) {
        setIsOpen(true);
        setYouthDetailsError(
          "Add list of participant details"
        );
        const youthErrorsMin =
          "Add list of participant details";
        setMinYouthDetailsError(youthErrorsMin);
      }
      //  else {
      //   setYouthDetailsError(""); // No errors found
      //   setMinYouthDetailsError("");
      // }
      console.log("youthDetailsError", youthDetailsError);
      if (
        isFormValid &&
        !modulesValidation &&
        filteredErrors.length === 0 &&
        youthDetailsError == "" &&
        youthData.length >= 5 
      ) {
        setIsOpen(false);
        // Assuming your API expects a format like "YYYY-MM-DDTHH:mm:ss"
        const currentDate = new Date().toISOString().split("T")[0];

        if (updateScreenTitle)
          var modulesArrayString =
            selectedModules1.length > 0
              ? JSON.stringify(selectedModules1)
              : JSON.stringify(selectedModules);
        if (addScreenTitle) {
          const newValues = selectedModules.filter(
            (item) => !selectedModules2.includes(item)
          );
          var modulesArrayString = JSON.stringify(newValues);
        }
        modulesArrayString = modulesArrayString.slice(1, -1);
        modulesArrayString = modulesArrayString.replace(/\"/g, "");

        var sessionDate = "2023-10-22";
        var fromTimes = sessionDate + "T" + outOfSchoolFields.startTime;
        var toTimes = sessionDate + "T" + outOfSchoolFields.endTime;

        const data = {
          TxnOutSchoolSessionId: sessionIdEdit ? sessionIdEdit : 0,
          session: parseInt(outOfSchoolFields.session),
          mstYouthGroupId: outOfSchoolFields.mstYouthGroupId,
          resourcePersonType: parseInt(outOfSchoolFields.resourcePersonType),
          resourcePersonName1: outOfSchoolFields.resourcePersonName1,
          resourcePersonName2:
            outOfSchoolFields.resourcePersonName2 != ""
              ? outOfSchoolFields.resourcePersonName2
              : 0,
          date: outOfSchoolFields.date,
          startTime: fromTimes,
          endTime: toTimes,
          modules: modulesArrayString,
          fileUpload: outOfSchoolFields.showImage,
          remarks: outOfSchoolFields.remarks,
          mstBranchId: mstBranchId,
          cseOutSchoolYouths: youthDetails,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [data],
          "CSEOutSchoolAPI/IUDCSEOutSchool"
        );
        if (flag == 1) {
          if (response.data.Table[0].result === "S") {
            toast.success("Out-of School session data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Out-of School session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
        if (flag == 2) {
          if (response.data.Table[0].result === "U") {
            toast.success(
              "Out-of School session data updated successfully 👍",
              {
                duration: 5000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Out-of School session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else if (flag == 3 || flag == 4 || flag == 5) {
      const data = {
        TxnOutSchoolSessionId: outSchoolId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "CSEOutSchoolAPI/IUDCSEOutSchool");
      if (flag == 3) {
        if (response.data.Table[0].result === "D") {
          toast.success("Out-of School session data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Out-of School session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].result === "B") {
          toast.success("Out-of School session data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Out-of School session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].result === "UB") {
          toast.success(
            "Out-of School session data unblocked successfully 👍",
            {
              duration: 5000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Out-of School session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
    // else {
    // setSubmitBtnDisabled(false);
    // }
  };
  useEffect(() => {
    if (addScreenTitle) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setOutOfSchoolFields({
        ...outOfSchoolFields,
        date: formattedDate,
      });
    }
  }, [addScreenTitle ? addScreenTitle : false]);
  const handleOSSDetails = async (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }

    const sessionsDD = bindCSEDatas[rowIndex].Sessions.split(",").map(
      (value) => ({ value: parseInt(value), label: value })
    );
    const dataArray = bindCSEDatas[rowIndex].Sessions.split(",");
    const editSessionNumber = parseInt(dataArray[dataArray.length - 1]);
    setCurrentData2(editSessionNumber);
    console.log("editSessionNumber", editSessionNumber);
    const data = {
      year: parseInt(bindCSEDatas[rowIndex].Date.split("-")[0]),
      mstYouthGroupId: parseInt(bindCSEDatas[rowIndex].MstYouthGroupId),
      sessionNumber: editSessionNumber,
    };
    const response = await BindPostAPI(
      [data],
      "CSEOutSchoolAPI/GetCSEOutSchoolData"
    );
    setCurrentData1(response.data.Table);
    console.log("response.data.Table", response.data.Table);
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    // setCurrentData2(modules);
    setCurrentData3(response.data.Table1);

    const drpdetailsData = [
      bindModules,
      bindCSEAgeDD,
      bindGenderDD,
      bindReferedTo,
      bindServicesName,
      bindResourcePersonType,
      bindYouthGroupDD,
      bindClass,
      sessionsDD,
    ];
    setCurrentData4({});
    setCurrentData4(drpdetailsData);
  };
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });

    if (tableData.length > 0) {
      setYouthDetailsError("");
      setSubmitBtnDisabled(false);
    }
  };
  const convertArrayToJSONString = (array) => {
    try {
      console.log("array", array);
      const jsonString = JSON.stringify(array);
      setYouthDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };
  const userProfileDatas = userProfileState.userProfile;
  const [usedSequenceNumbers, setUsedSequenceNumbers] = useState([]); // Initial sequence number
  const [availableSequenceNumbers, setAvailableSequenceNumbers] = useState([]);
  const [highestSequenceNumber, setHighestSequenceNumber] = useState(0);
  const getBranch = async (userProfileDatas) => {
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setMstBranchId(userProfileDatas[0].MstLocationId);

      console.log("branch", userProfileDatas[0].MstLocationId);
      // setLocation(userProfileDatas[0].MstLocationId);
    } else {
      // handle else case if needed
    }
    const data = {
      mstBranchId: userProfileDatas[0].MstLocationId,
    };
    const response = await BindPostAPI([data], "CSEOutSchoolAPI/GetCSEYouthId");
    let fetchedYouthId = "";
    if (
      response.data &&
      Array.isArray(response.data.Table) &&
      response.data.Table.length > 0 &&
      response.data.Table[0].YouthId
    ) {
      fetchedYouthId = response.data.Table[0].YouthId.toString();
    }

    const generateId = (sequenceNumber) => {
      const prefix = "YTH";
      const year = new Date().getFullYear().toString().slice(2);
      const formattedSequence = String(sequenceNumber).padStart(5, "0"); // Padding to 5 digits
      return `${prefix}${year}${formattedSequence}`;
    };

    if (!fetchedYouthId) {
      fetchedYouthId = generateId(0);
    }
    const initialSequenceNumber = parseInt(fetchedYouthId.slice(-5), 10);
    const incrementedSequenceNumber = isNaN(initialSequenceNumber)
      ? 1
      : initialSequenceNumber + 1;
    if (addScreenTitle && sessionAddDisability) {
      const newYouthId = generateId(initialSequenceNumber);
      setMstYouthId(newYouthId);
      setHighestSequenceNumber(initialSequenceNumber);
      setUsedSequenceNumbers([initialSequenceNumber]);
      setAvailableSequenceNumbers([]);
      const newRow = {
        youthId: newYouthId,
        youthName: "", // Clear the values of the new row
        gender: "",
        age: "",
        referralService: "",
        referredTo: "",
        type: "A",
      };
      setTableData((prevData) => [...prevData]);
    } else if (addScreenTitle) {
      const newYouthId = generateId(incrementedSequenceNumber);
      setMstYouthId(newYouthId);
      setHighestSequenceNumber(incrementedSequenceNumber);
      setUsedSequenceNumbers([incrementedSequenceNumber]);
      setAvailableSequenceNumbers([]);
      const newRow = {
        youthId: newYouthId,
        youthName: "", // Clear the values of the new row
        gender: "",
        age: "",
        referralService: "",
        referredTo: "",
        type: "A",
      };
      setTableData((prevData) => [...prevData, newRow]);
    } else {
      const newYouthId = generateId(initialSequenceNumber);
      setMstYouthId(newYouthId);
      setHighestSequenceNumber(initialSequenceNumber);
      setUsedSequenceNumbers([initialSequenceNumber]);
      setAvailableSequenceNumbers([]);
      const newRow = {
        youthId: newYouthId,
        youthName: "", // Clear the values of the new row
        gender: "",
        age: "",
        referralService: "",
        referredTo: "",
        type: "A",
      };
      setTableData((prevData) => [...prevData]);
    }

    // setTableData([]);
    // Initialize the table data with the fetched youth ID
  };
  useEffect(() => {
    if (addScreenTitle || updateScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, [addScreenTitle]);

  const generateId = (sequenceNumber) => {
    const prefix = "YTH";
    const year = new Date().getFullYear().toString().slice(2);
    const formattedSequence = String(sequenceNumber).padStart(5, "0"); // Padding to 5 digits
    return `${prefix}${year}${formattedSequence}`;
  };
  const getNextSequenceNumber = () => {
    if (availableSequenceNumbers.length > 0) {
      return availableSequenceNumbers.shift();
    }

    let nextSequenceNumber = highestSequenceNumber + 1;
    while (usedSequenceNumbers.includes(nextSequenceNumber)) {
      nextSequenceNumber++;
    }
    setHighestSequenceNumber(nextSequenceNumber);
    return nextSequenceNumber;
  };
  const [tableData, setTableData] = useState([
    {
      youthId: mstYouthId,
      youthName: "",
      gender: "",
      age: "",
      referralService: "",
      referredTo: "",
      type: "A",
    },
  ]);
  const handleDeleteRow = async (index) => {

    // Ensure at least one row exists
    if (tableData.length === 1) {
      console.log("TableData", tableData);
      const data = {
        mstBranchId: userProfileDatas[0].MstLocationId,
      };
      const response = await BindPostAPI(
        [data],
        "CSEOutSchoolAPI/GetCSEYouthId"
      );
      let fetchedYouthId = "";
      if (
        response.data &&
        Array.isArray(response.data.Table) &&
        response.data.Table.length > 0 &&
        response.data.Table[0].YouthId
      ) {
        fetchedYouthId = response.data.Table[0].YouthId.toString();
      }

      const generateId = (sequenceNumber) => {
        const prefix = "YTH";
        const year = new Date().getFullYear().toString().slice(2);
        const formattedSequence = String(sequenceNumber).padStart(5, "0"); // Padding to 5 digits
        return `${prefix}${year}${formattedSequence}`;
      };

      if (!fetchedYouthId) {
        fetchedYouthId = generateId(0);
      }
      const initialSequenceNumber = parseInt(fetchedYouthId.slice(-5), 10);
      const incrementedSequenceNumber = isNaN(initialSequenceNumber)
        ? 1
        : initialSequenceNumber + 1;
      const newYouthId = generateId(incrementedSequenceNumber);

      const newRow = {
        youthId: newYouthId,
        youthName: "",
        gender: "",
        age: "",
        referralService: "",
        referredTo: "",
        type: "A",
      };

      setTableData([newRow]);
      console.log("TableData", tableData);
      // setTableData([]);

      // tableData.push(newRow); // Add a default row if the table is empty
    } else if (tableData.length > 1) {
      const updatedTableData = [...tableData];
      const deletedSequenceNumber = parseInt(
        updatedTableData[index].youthId.slice(-5),
        10
      );
      updatedTableData.splice(index, 1);
      setTableData(updatedTableData);
      convertArrayToJSONString(updatedTableData);
      setUsedSequenceNumbers((prev) =>
        prev.filter((seq) => seq !== deletedSequenceNumber)
      );
      setAvailableSequenceNumbers((prev) => [...prev, deletedSequenceNumber]); // Add deleted sequence number to available sequence numbers
      // convertArrayToJSONString(updatedTableData);
    }
  };

  const handleAddRow = () => {
    let sequenceNumber;

    // Check if there are available sequence numbers
    if (availableSequenceNumbers.length === 0) {
      // If no available sequence numbers, generate the next sequence number
      sequenceNumber = getNextSequenceNumber();
    } else {
      // If available sequence numbers exist, use the next one
      sequenceNumber = availableSequenceNumbers.shift();
    }

    // Generate new youth ID using the obtained sequence number
    const newYouthId = generateId(sequenceNumber);

    // Add the new row to the table data
    setTableData((prevData) => [
      ...prevData,
      {
        youthId: newYouthId,
        youthName: "",
        gender: "",
        age: "",
        referralService: "",
        referredTo: "",
        // type: addScreenTitle ? "A":"E",
        type: "A",
      },
    ]);
    console.log("TableData", tableData);
    // Add the new sequence number to used sequence numbers
    setUsedSequenceNumbers((prev) => [...prev, sequenceNumber]);
  };

  // const [bindResourcePersonType, setBindResourcePersonType] = useState([]);
  // useEffect(() => {
  //   BindCSEGridData();
  // }, []);
  const BindCSEGridData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        setMstBranchId(userProfileDatas[0].MstLocationId);
      } else {
        // handle else case if needed
      }
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "CSEOutSchoolAPI/BindCSEOutSchoolData"
      );
      setBindCSEDatas(response.data.Table);
      setBindDBResource(response.data.Table1);
      setBindDBYouths(response.data.Table2);
    }
  };
  const headerData = [
    {
      title: "Youth Name",
      key: "YouthName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Youth ID.",
      key: "YouthId",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Gender",
      key: "Gender_en",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Age",
      key: "Age",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral Service",
      key: "ReferralServices",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referred To",
      key: "ReferredTo_en",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  const handleSession = async (value) => {
    setVisibleAddPage(true);
    getBranch(userProfileDatas);
    // const reset = {
    //   youthName: "",
    //   youthId: "",
    //   gender: "",
    //   age: "",
    //   referralService: "",
    //   referredTo: "",
    // };
    // setTableData([]);
    setOutOfSchoolFields({
      ...outOfSchoolFields,
      session: parseInt(value),
    });
    const data = {
      year: parseInt(outOfSchoolFields.date.split("-")[0]),
      mstYouthGroupId: parseInt(outOfSchoolFields.mstYouthGroupId),
      sessionNumber: parseInt(value),
    };
    const response = await BindPostAPI(
      [data],
      "CSEOutSchoolAPI/GetCSEOutSchoolData"
    );

    const sessionId = response.data.Table[0].TxnOutSchoolSessionId;
    setSessionIdEdit(sessionId);
    setOutOfSchoolFields({
      ...outOfSchoolFields,
      session: parseInt(value),
      // mstYouthGroupId: bindCSEDatas[rowIndex].MstYouthGroupId,
      // cseClass: bindCSEDatas[rowIndex].Class,
      // division: bindCSEDatas[rowIndex].Division,
      resourcePersonType: response.data.Table[0].ResourcePersonType,
      resourcePersonName1: response.data.Table[0].ResourcePersonName1,
      resourcePersonName2: response.data.Table[0].ResourcePersonName2,
      date: moment(response.data.Table[0].Date).format("YYYY-MM-DD"),
      startTime: moment(response.data.Table[0].StartTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      endTime: moment(response.data.Table[0].EndTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      showImage: response.data.Table[0].FileUpload,
      remarks: response.data.Table[0].Remarks,
    });
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    setSelectedModules1(modules);
    appendDataToYouthTable(JSON.stringify(response.data.Table1));
  };
  const handleEdit = async (rowIndex) => {
    setSessionAddDisability(true);
    setVisibleAddPage(true);
    const sessionsDD = bindCSEDatas[rowIndex].Sessions.split(",").map(
      (value) => ({ value: parseInt(value), label: value })
    );

    setBindSession(sessionsDD);
    const dataArray = bindCSEDatas[rowIndex].Sessions.split(",");
    const editSessionNumber = parseInt(dataArray[dataArray.length - 1]);

    const data = {
      year: parseInt(bindCSEDatas[rowIndex].Date.split("-")[0]),
      mstYouthGroupId: parseInt(bindCSEDatas[rowIndex].MstYouthGroupId),
      sessionNumber: editSessionNumber,
    };
    const response = await BindPostAPI(
      [data],
      "CSEOutSchoolAPI/GetCSEOutSchoolData"
    );

    setViewAction(false);
    const sessionId = bindCSEDatas[rowIndex].TxnOutSchoolSessionId;
    setSessionIdEdit(sessionId);
    setOutOfSchoolFields({
      ...outOfSchoolFields,
      session: editSessionNumber,
      mstYouthGroupId: bindCSEDatas[rowIndex].MstYouthGroupId,
      resourcePersonType: response.data.Table[0].ResourcePersonType,
      resourcePersonName1: response.data.Table[0].ResourcePersonName1,
      resourcePersonName2: response.data.Table[0].ResourcePersonName2,
      date: moment(response.data.Table[0].Date).format("YYYY-MM-DD"),
      startTime: moment(response.data.Table[0].StartTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      endTime: moment(response.data.Table[0].EndTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      showImage: response.data.Table[0].FileUpload,
      remarks: response.data.Table[0].Remarks,
    });
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    setSelectedModules1(modules);
    // setSelectedModules(modules);
    // if (response.data.Table1.length === 0) {
    //   setTableData([
    //     {
    //       youthId: mstYouthId,
    //       youthName: "",
    //       gender: "",
    //       age: "",
    //       referralService: "",
    //       referredTo: "",
    //     },
    //   ]);
    // } else {
    //   // Append the filtered data to your state using appendDataToState function
    //   appendDataToYouthTable(JSON.stringify(response.data.Table1));
    // }

    if (response.data.Table1.length === 0) {
      setTableData([
        {
          youthName: "",
          studentId: "",
          gender: "",
          age: "",
          referralService: "",
          referredTo: "",
          type: "A",
        },
      ]);
    } else {
      // Append the filtered data to your state using appendDataToState function
      appendDataToYouthTable(JSON.stringify(response.data.Table1));
    }
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    getBranch(userProfileDatas);
  };

  const appendDataToYouthTable = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    // handleDeleteRow(0);
    setTableData([]);
    getBranch(userProfileDatas);
    const formattedDataArray = newDataArray.map((data) => ({
      youthName: data.YouthName,
      youthId: data.YouthId,
      age: data.MstAgeId,
      gender: data.Gender,
      referralService: data.MstReferralServicesId
        ? data.MstReferralServicesId
        : "",
      referredTo: data.ReferredToId ? data.ReferredToId : "",
      type: data.type != null ? "A" : "E",
    }));

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    console.log("newDataString", newDataString, formattedDataArray, tableData);
    convertArrayToJSONString(formattedDataArray);
  };

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setOutOfSchoolFields({
        ...outOfSchoolFields,
        date: formattedDate,
      });
    };

    fetchData();
  }, []);
  const handleYouthGroupView = async (rowIndex) => {
    const filterSessionId1 = bindCSEDatas[rowIndex];
    const filterSessionId = bindDBYouths?.filter(
      (item) =>
        item.TxnOutSchoolSessionId ===
        bindCSEDatas[rowIndex].TxnOutSchoolSessionId
    );
    const classLabel = bindClass?.find(
      (x) => x.value == filterSessionId1.Class
    );
    setOutOfSchoolFields({
      ...outOfSchoolFields,
      mstYouthGroupId: filterSessionId1.YouthGroupName,
      date: filterSessionId1.Date,
    });
    setViewAction(true);
    const data = {
      year: parseInt(filterSessionId1.Date.split("-")[0]),
      mstYouthGroupId: parseInt(filterSessionId1.MstYouthGroupId),
    };
    const response = await BindPostAPI(
      [data],
      "CSEOutSchoolAPI/GetCSEYouthData"
    );
    // Assuming setYouthData accepts an array
    setYouthData(response.data.Table);
  };
  const handleShowImage = (showImage) => {
    // showImage &&
      setOutOfSchoolFields({
        ...outOfSchoolFields,
        showImage: showImage || "",
      });
    setOutOfSchoolFieldsError({
      ...outOfSchoolFieldsError,
      showImageError: "",
    });
  };
  useEffect(() => {
    bindAllDropdownData()
      .then(() => BindCSEGridData(userProfileDatas))
      .catch((error) => {
        // Handle errors if any function fails
        console.error("Error in useEffect:", error);
      });
  }, []);
  useEffect(() => {
    if (location.pathname == "/outofschoolsession") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      BindCSEGridData(userProfileDatas);
    }
  }, [location, userProfileDatas]);
  const validateSchoolData = () => {
    const { mstYouthGroupId, date } = outOfSchoolFields;
    const newYear = new Date(date).getFullYear();
    let isDuplicate = false; // Initialize as false

    const isFormError = {
      ...outOfSchoolFieldsError,
      mstYouthGroupIdError: outOfSchoolFields.mstYouthGroupId
        ? ""
        : "Select Youth Group Name",
      dateError: outOfSchoolFields.date ? "" : "Select Date",
    };
    setOutOfSchoolFieldsError(isFormError);
    const isFormValid = Object.values(isFormError).every(
      (error) => error === ""
    );
    if (isFormValid) {
      // Loop through bindCSEDatas and check for duplicates
      bindCSEDatas.forEach((data) => {
        const existingYear = new Date(data.Date).getFullYear();

        if (
          data.MstYouthGroupId === mstYouthGroupId &&
          existingYear === newYear
        ) {
          isDuplicate = true; // If match found, set to true
          // setSessionAddDisability(true); // Disable the session addition
        }
      });

      // If no duplicate found after loop, set disability to false
      if (!isDuplicate) {
        setSessionAddDisability(false);
        setVisibleAddPage(true);
        setOutOfSchoolFields({
          ...outOfSchoolFields,
          startTime: getCurrentTime(),
          endTime: getCurrentTime(),
        });
      }
    }
    return isDuplicate; // Return the result of the check
  };

  const handleGo = async () => {
    if (validateSchoolData()) {
      setVisibleAddPage(false);
      alert("A session for this Youth group and year already exists.");
    }
  };
  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Out-of School Session"
          header={cseOutSchoolHeaders}
          records={bindCSEDatas}
          handleEdit={handleEdit}
          handleAction={handleOSSDetails}
          showAdd={showAdd}
          handleSchoolView={handleYouthGroupView}
          tableHeight="h-56"
          onAdd={showAddSession}
          blockView={true}
        />
      )}
      {viewAction && (
        <GridPage
          title={`Out-of School Session > ${outOfSchoolFields.mstYouthGroupId} `}
          header={outOfSchoolYouthHeaders}
          records={youthData}
          tableHeight="h-56"
          addView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Out- of School Session"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <AutoDropDown
                      Class="Custom-class"
                      disabled={sessionAddDisability}
                      title={`Youth Group Name`}
                      options={bindYouthGroupDD}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="mstYouthGroupId"
                      selectedOption={bindYouthGroupDD.find(
                        (x) => x.value == outOfSchoolFields.mstYouthGroupId
                      )}
                      controlFunc={(value, e) =>
                        handleOutSchoolFieldsChange(value.value, e.name)
                      }
                      controlFuncBlur={() => {
                        setOutOfSchoolFieldsError(() => ({
                          ...outOfSchoolFieldsError,
                          mstYouthGroupIdError:
                            outOfSchoolFields.mstYouthGroupId
                              ? ""
                              : "Select Youth Group Name",
                        }));
                      }}
                      errorMessage={outOfSchoolFieldsError.mstYouthGroupIdError}
                    />
                    <InputText
                      inputType="date"
                      title="Date"
                      classStar="text-red-500"
                      name="date"
                      content={outOfSchoolFields.date}
                      controlFunc={(e) =>
                        setOutOfSchoolFields({
                          ...outOfSchoolFields,
                          date: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        setOutOfSchoolFieldsError(() => ({
                          ...outOfSchoolFieldsError,
                          dateError: outOfSchoolFields.date
                            ? ""
                            : "Select Date",
                        }));
                      }}
                      max={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                      errorMessage={outOfSchoolFieldsError.dateError}
                    />
                  </div>
                  {!visibleAddPage && (
                    <div className="flex justify-center">
                      <input
                        type="button"
                        value="GO"
                        className={mainTable.addButton}
                        onClick={(e) => handleGo()}
                      />
                    </div>
                  )}
                  {visibleAddPage && (
                    <div>
                      <div class={mainTable.grid3}>
                        <div>
                          <DropDown
                            title="Type of Resource Person"
                            classStar="text-red-500"
                            options={bindResourcePersonType}
                            placeholder="Select"
                            name="resourcePersonType"
                            selectedOption={
                              outOfSchoolFields.resourcePersonType
                            }
                            controlFunc={(e) =>
                              setOutOfSchoolFields({
                                ...outOfSchoolFields,
                                resourcePersonType: e.target.value,
                              })
                            }
                            controlFuncBlur={(e) => {
                              const selectedValue = e.target.value;
                              setOutOfSchoolFields(() => ({
                                ...outOfSchoolFields,
                                resourcePersonType: selectedValue,
                              }));
                              if (selectedValue.length === 0) {
                                setOutOfSchoolFieldsError(() => ({
                                  ...outOfSchoolFieldsError,
                                  resourcePersonTypeError:
                                    "Select Type Of Resource Person",
                                }));
                              } else {
                                setOutOfSchoolFieldsError(() => ({
                                  ...outOfSchoolFieldsError,
                                  resourcePersonTypeError: "",
                                }));
                              }
                            }}
                          />
                          {outOfSchoolFieldsError.resourcePersonTypeError && (
                            <div className="text-red-500 text-sm font-medium">
                              {outOfSchoolFieldsError.resourcePersonTypeError}
                            </div>
                          )}
                        </div>
                        <AutoDropDown
                          Class="Custom-class"
                          title={`Resource Person Name 1`}
                          options={bindResourcePersonName}
                          classStar="text-red-500"
                          placeholder="Select"
                          name="resourcePersonName1"
                          selectedOption={bindResourcePersonName.find(
                            (x) =>
                              x.value == outOfSchoolFields.resourcePersonName1
                          )}
                          controlFunc={(value, e) =>
                            handleOutSchoolFieldsChange(value.value, e.name)
                          }
                          controlFuncBlur={() => {
                            setOutOfSchoolFieldsError(() => ({
                              ...outOfSchoolFieldsError,
                              resourcePersonNameError:
                                outOfSchoolFields.resourcePersonName1
                                  ? ""
                                  : "Select Resource Person Name 1",
                            }));
                          }}
                          errorMessage={
                            outOfSchoolFieldsError.resourcePersonNameError
                          }
                        />
                        <AutoDropDown
                          Class="Custom-class"
                          title={`Resource Person Name2`}
                          options={bindResourcePersonName}
                          placeholder="Select"
                          name="resourcePersonName2"
                          selectedOption={bindResourcePersonName.find(
                            (x) =>
                              x.value == outOfSchoolFields.resourcePersonName2
                          )}
                          controlFunc={(value, e) =>
                            handleOutSchoolFieldsChange(value.value, e.name)
                          }
                        />
                        {/* <InputText
                          title="Resource Person Name"
                          maxLength={500}
                          inputType="text"
                          classStar="text-red-500"
                          placeholder=""
                          name="resourcePersonName"
                          content={outOfSchoolFields.resourcePersonName}
                          controlFunc={(e) =>
                            setOutOfSchoolFields({
                              ...outOfSchoolFields,
                              resourcePersonName: e.target.value,
                            })
                          }
                          controlFuncBlur={() => {
                            setOutOfSchoolFieldsError(() => ({
                              ...outOfSchoolFieldsError,
                              resourcePersonNameError:
                                outOfSchoolFields.resourcePersonName
                                  ? ""
                                  : "Enter Resource Person Name",
                            }));
                          }}
                          errorMessage={
                            outOfSchoolFieldsError.resourcePersonNameError
                          }
                        /> */}
                        {/* </div>
                  <div class={mainTable.grid3}> */}

                        <InputText
                          inputType="time"
                          title="Start Time"
                          classStar="text-red-500"
                          name="startTime"
                          content={outOfSchoolFields.startTime}
                          controlFunc={(e) =>
                            setOutOfSchoolFields({
                              ...outOfSchoolFields,
                              startTime: e.target.value,
                            })
                          }
                          controlFuncBlur={() => {
                            setOutOfSchoolFieldsError(() => ({
                              ...outOfSchoolFieldsError,
                              startTimeError: outOfSchoolFields.startTime
                                ? ""
                                : "Select Start Time",
                            }));
                          }}
                          errorMessage={outOfSchoolFieldsError.startTimeError}
                        />

                        <InputText
                          inputType="time"
                          title="End Time"
                          classStar="text-red-500"
                          name="endTime"
                          content={outOfSchoolFields.endTime}
                          controlFunc={(e) =>
                            setOutOfSchoolFields({
                              ...outOfSchoolFields,
                              endTime: e.target.value,
                            })
                          }
                          controlFuncBlur={() => {
                            setOutOfSchoolFieldsError(() => ({
                              ...outOfSchoolFieldsError,
                              endTimeError: outOfSchoolFields.endTime
                                ? ""
                                : "Select End Time",
                            }));
                          }}
                          errorMessage={outOfSchoolFieldsError.endTimeError}
                        />
                        {updateScreenTitle && (
                          <div>
                            <DropDown
                              title="Sessions"
                              classStar="text-red-500"
                              options={bindSession}
                              placeholder="Select"
                              name="session"
                              selectedOption={outOfSchoolFields.session}
                              controlFunc={(e) => {
                                handleSession(e.target.value);
                              }}
                            />
                            {outOfSchoolFieldsError.sessionError && (
                              <div className="text-red-500 text-sm font-medium">
                                {outOfSchoolFieldsError.sessionError}
                              </div>
                            )}
                          </div>
                        )}
                        {addScreenTitle && (
                          <InputText
                            title="Sessions"
                            maxLength={1}
                            inputType="number"
                            disabled={true}
                            classStar="text-red-500"
                            placeholder="2023 / 5"
                            name="session"
                            content={outOfSchoolFields.session}
                            controlFunc={(e) =>
                              setOutOfSchoolFields({
                                ...outOfSchoolFields,
                                session: e.target.value,
                              })
                            }
                            controlFuncBlur={() => {
                              setOutOfSchoolFieldsError(() => ({
                                ...outOfSchoolFieldsError,
                                sessionError:
                                  outOfSchoolFields.session &&
                                  outOfSchoolFields.session.length > 0
                                    ? ""
                                    : "Enter Sessions Number",
                              }));
                            }}
                            errorMessage={outOfSchoolFieldsError.sessionError}
                          />
                        )}
                      </div>

                      <Checkbox
                        classDiv="col-span-4 mb-2"
                        title="Modules"
                        classStar="text-red-500"
                        type={"checkbox"}
                        setName={"module"}
                        selectedOptions={selectedModules1}
                        options={bindModules}
                        controlFunc={handleModulesChange}
                        controlFuncBlur={() => {
                          setModulesError(
                            (addScreenTitle && selectedModules.length > 0) ||
                              (updateScreenTitle &&
                                (selectedModules1.length > 0 ||
                                  selectedModules.length > 0)) ||
                              (sessionAddDisability &&
                                selectedModules.length > 0)
                              ? ""
                              : "Select Modules"
                            // addScreenTitle && selectedModules.length > 0
                            //   ? ""
                            //   : "Select Modules",
                            // (updateScreenTitle && selectedModules1.length > 0) ||
                            //   selectedModules.length > 0
                            //   ? ""
                            //   : "Select Modules"
                          );
                        }}
                        disabled={false}
                        tabIndex="0"
                        Class1="w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                        Class="grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg"
                        errorMessage={modulesError}
                      />

                      <FileUpload
                        title="File Upload"
                        // classStar="text-red-500"
                        apiCall="CSEOutSchoolAPI/OutSchoolFileUpload"
                        modalName="CSEOutSchoolFileUpload"
                        showImage={outOfSchoolFields.showImage}
                        showImageData={handleShowImage}
                        disabledBtn={updateScreenTitle}
                        imageUploadedError={
                          outOfSchoolFieldsError.showImageError
                        }
                        limitMassage={
                          "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                        }
                        MAX_FILE_SIZE_MB={2}
                        ALLOWED_FORMATS={[
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                      />
                      <div class="lg:col-span-4 sm:col-span-1">
                        <TextArea
                          inputType="text"
                          maxLength={500}
                          title="Remarks"
                          name="remarks"
                          rows="4"
                          content={outOfSchoolFields.remarks}
                          placeholder="Remarks"
                          controlFunc={(e) =>
                            setOutOfSchoolFields({
                              ...outOfSchoolFields,
                              remarks: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div class="block text-base capitalize font-bold mt-2 text-neutral-700">
                        List Of Participants
                      </div>
                      <div className="hidden md:block overflow-x-auto ">
                        <div className="table-container w-screen md:w-max xl:w-full max-h-96">
                          <table className="w-full border border-gray-300 mt-2">
                            <thead>
                              <tr>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  {`Youth ID.`}
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  {`Youth Name`}
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Gender
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Age
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Referral Service
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Referred To
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 ">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((row, index) => (
                                <tr key={index}>
                                  <td className="border border-gray-300 p-2 w-24">
                                    <InputText
                                      type="text"
                                      content={row.youthId}
                                      disabled={true}
                                      maxLength={50}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "youthId"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2 w-40">
                                    <InputText
                                      type="text"
                                      content={row.youthName}
                                      maxLength={100}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "youthName"
                                        );
                                      }}
                                    />
                                  </td>

                                  <td className="border border-gray-300 p-2 w-16">
                                    <DropDown
                                      options={bindGenderDD}
                                      placeholder="Select"
                                      name="categories"
                                      selectedOption={row.gender}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "gender"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2 w-16">
                                    <DropDown
                                      options={bindCSEAgeDD}
                                      placeholder="Select"
                                      name="age"
                                      selectedOption={row.age}
                                      controlFunc={(e) =>
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "age"
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2 w-32">
                                    <DropDown
                                      options={bindServicesName}
                                      placeholder="Select"
                                      name="referralService"
                                      selectedOption={row.referralService}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "referralService"
                                        );
                                      }}
                                    />
                                  </td>

                                  <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                                    <DropDown
                                      options={bindReferedTo}
                                      placeholder="Select"
                                      name="categories"
                                      selectedOption={row.referredTo}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "referredTo"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 w-16">
                                    <div className="flex gap-4 align-baseline items-center justify-center">
                                      <svg
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        class="w-6 h-6 text-black cursor-pointer"
                                        onClick={() => handleDeleteRow(index)}
                                      >
                                        <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                      </svg>
                                      <svg
                                        viewBox="0 0 1024 1024"
                                        fill="currentColor"
                                        onClick={handleAddRow}
                                        class="w-6 h-6 text-black cursor-pointer"
                                      >
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                      </svg>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className={mainTable.tableMobile} role="table">
                        <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                          {tableData.length > 0 &&
                            tableData.map((row, index) => (
                              <div scope="col" class={mainTable.mobileTable}>
                                {headerData.map((keyData) => (
                                  <div
                                    scope="cell"
                                    class={mainTable.tableData}
                                    role="cell"
                                  >
                                    {keyData.key === "Action" ? (
                                      <div class={mainTable.tableData}>
                                        <div className="hidden md:block ">
                                          <button
                                            id="dropdownMenuIconButton"
                                            data-dropdown-toggle="dropdownDots"
                                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                                            type="button"
                                          >
                                            <svg
                                              class="w-6 h-6"
                                              aria-hidden="true"
                                              fill="currentColor"
                                              viewBox="0 0 20 20"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                        <div className="md:hidden flex justify-center">
                                          <input
                                            type="button"
                                            className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                            onClick={handleAddRow}
                                            value="Add"
                                          />
                                          <input
                                            type="button"
                                            className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                            onClick={() =>
                                              handleDeleteRow(index)
                                            }
                                            value="Delete"
                                          />
                                        </div>
                                      </div>
                                    ) : keyData.key === "YouthName" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full font-bold">
                                          {keyData.title}
                                        </div>
                                        <InputText
                                          type="text"
                                          content={row.youthName}
                                          maxLength={100}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "youthName"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "YouthId" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full font-bold">
                                          {keyData.title}
                                        </div>
                                        <InputText
                                          type="text"
                                          content={row.youthId}
                                          maxLength={50}
                                          disabled={true}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "youthId"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "Gender_en" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindGenderDD}
                                          placeholder="Select"
                                          name="categories"
                                          selectedOption={row.gender}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "gender"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "Age" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full whitespace-nowrap font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindCSEAgeDD}
                                          placeholder="Select"
                                          name="age"
                                          selectedOption={row.age}
                                          controlFunc={(e) =>
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "age"
                                            )
                                          }
                                        />
                                      </div>
                                    ) : keyData.key === "ReferralServices" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full whitespace-nowrap font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindServicesName}
                                          placeholder="Select"
                                          name="referralService"
                                          selectedOption={row.referralService}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "referralService"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "ReferredTo_en" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full whitespace-nowrap font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindReferedTo}
                                          placeholder="Select"
                                          name="categories"
                                          selectedOption={row.referredTo}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "referredTo"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex border p-1">
                                        <div className="flex basis-1/2 font-bold">
                                          {keyData.title}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="hidden md:block">
                        {youthDetailsError && (
                          <div className="text-red-500">
                            {youthDetailsError}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </form>
                {visibleAddPage && (
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    disabledBtn={submitBtnDisabled ? true : false}
                    cancel={cancel}
                    viewSaveButton={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="Out- of School session"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <OutSchoolPopup
            data={currentData1}
            data1={currentData2}
            data2={currentData3}
            data3={currentData4}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          disabledBtn={submitBtnDisabled ? true : false}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
        />
      </MainPopup>
    </MainLayout>
  );
}
export default OutSchoolSession;
