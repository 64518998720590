// Code review - In Progress
// Documentation - In Progress
import "../App.css";
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { useNavigate } from "react-router-dom";
import fpaiLogo from "../image/FPAILogo.jpg";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import ForgetPassword from "../popup-containers/ForgetPassword";
import ResetPassword from "../popup-containers/ResetPassword";
import { ToastContainer, toast } from "react-toastify";
import InputPassword from "../ReusableComponents/InputPassword";
import { useUserCapability } from "../components/ConstantReducerProvider";
import { IUDAPI, authenticationAPI } from "../services/APIConnection";
import DropDown from "../ReusableComponents/DropDown";

function Login() {
  const { state, dispatch } = useUserCapability();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [password, setPassword] = useState("");

  const [currentData, setCurrentData] = useState([]);

  const [popUpDesign, setPopUpDesign] = useState(false);
  const [popUpForgetPwd, setPopUpForgetPwd] = useState(false);
  const [resetPopUp, setResetPopUp] = useState(false);
  const [visibleSdp, setVisibleSdp] = useState(false);
  const [sdp, setSdp] = useState([]);
  const [table, setTableData] = useState({});
  const [tableSdpData, setTableSdpData] = useState({});
  const [sdpValue, setSdpValue] = useState("");
  const [sdpValues, setSdpValues] = useState("");
  const [sdpError, setSDPError] = useState("");

  const [forgotPasswordDesign, setForgotPasswordDesign] = useState(false);
  const [resetPasswordDesign, setResetPasswordDesign] = useState(false);

  const [loginResponse, setLoginResponse] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [disabledGoBtn, setDisabledGoBtn] = useState(false);

  const navigate = useNavigate();
  const getBranch = (tableDatas) => {
    if (tableDatas.Table1 && tableDatas.Table1.length === 1) {
      toast.success("Successful login", {
        position: toast.POSITION.TOP_CENTER,
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 4000);
    } else {
      setDisabledBtn(false);
      setVisibleSdp(true);
      const newSdp = tableDatas.Table1.map((userProfile) => ({
        value: userProfile.MstSDPId,
        label: userProfile.SDP_en,
      }));
      setSdp(newSdp);
      setTableData(tableDatas.Table1);
      const tableData = tableDatas.Table1;
      const sdpValues = tableData.map((item) => item.MstSDPId);
      setSdpValues(sdpValues);
    }
  };
  const handleSubmit = async () => {
    if (email != "" && password != "") {
      setEmailError("");
      setPasswordError("");
      setDisabledBtn(true);
      const loginPayload = {
        userName: email,
        password: password,
      };
      sessionStorage.setItem("userName", email);
      sessionStorage.setItem("password", password);
      const response = await authenticationAPI(
        loginPayload,
        "LoginAPI/LoginValid"
      );
      console.log("loginResponse", response);

      if (response && response.data && response.data.jsonString) {
        localStorage.setItem("jwtToken", response.data.token);
        let tableDatas = JSON.parse(response.data.jsonString);
        console.log("tableDatas", tableDatas.Table[0].Result, tableDatas);
        if (tableDatas.Table[0].Result === "S") {
          getBranch(tableDatas);
          setLoginResponse(tableDatas);
          // setTimeout(() => {
          //   navigate("/dashboard");
          // }, 4000);
        } else if (tableDatas.Table[0].Result === "R") {
          // Handle the case when Result is "U"
          setCurrentData(tableDatas.Table1[0]);
          handleResetPassword(true);
          setDisabledBtn(false);
        } else if (tableDatas.Table[0].Result === "WU") {
          setEmailError("Please enter a valid email  or phone no.");
          setPasswordError("");
          setDisabledBtn(false);
        } else if (tableDatas.Table[0].Result === "WP") {
          setEmailError("");
          setPasswordError("Enter a valid password");
          setDisabledBtn(false);
        } else {
          setEmailError("Please enter a valid email  or phone no.");
          setPasswordError("Enter a valid password");
          setDisabledBtn(false);
        }

        //get Session Values

        if (response && response.data) {
          // Check if Table1 exists and is not empty
          if (tableDatas.Table1 && tableDatas.Table1.length === 1) {
            // Access the desired value from the first item in Table1
            const createdBy = tableDatas.Table1[0].MstUserId;
            sessionStorage.setItem("createdBy", createdBy);

            dispatch({ type: "USER_PROFILE", payload: tableDatas.Table1 });
          }
          // Set user capability data
          if (tableDatas.Table2) {
            dispatch({
              type: "USER_VS_CAPABILITY",
              payload: tableDatas.Table2,
            });
          }
        }
      } else {
        setEmailError("Please enter a valid email  or phone no.");
        setPasswordError("Enter a valid password");
        setDisabledBtn(false);
      }
    } else {
      if (email == "" && password == "") {
        setEmailError("Enter email or phone no");
        setPasswordError("Enter password");
        setDisabledBtn(false);
      } else {
        if (email == "") {
          setEmailError("Enter email or phone no");
          setPasswordError("");
          setDisabledBtn(false);
        }
        if (password == "") {
          setEmailError("");
          setPasswordError("Enter password");
          setDisabledBtn(false);
        }
      }
    }
  };
  const handleClose = () => {
    setPopUpForgetPwd(false);
    setResetPopUp(false);
  };

  const handleOpen = (isOpen) => {
    setPopUpForgetPwd(isOpen);
  };
  const handleOpen1 = (isOpen) => {
    setResetPopUp(isOpen);
  };
  const handleForgetPassword = () => {
    handleOpen(true);
    setPopUpDesign(true);
    setForgotPasswordDesign(true);
  };
  const handleResetPassword = () => {
    handleOpen1(true);
    setResetPasswordDesign(true);
  };
  const handleSdpDropdownChange = (e) => {

    setSdpValue("");
    setSDPError("");
    const selectedValue = e.target.value;
    setSdpValue(selectedValue);

    const user = table.find((item) => item.MstSDPId == selectedValue);

    if (user) {
      const updatedUser = {
        ...user,
        AllSDP: sdpValues, // Assuming sdpValues is defined in your component
      };
      setTableSdpData(updatedUser);
      console.log("updatedUser", updatedUser);
    } else {
      console.log("User not found");
    }
    sessionStorage.setItem("AllSDP", sdpValues);
  };
  const handleGo = () => {

    if (Object.keys(tableSdpData).length === 0 || sdpValue == "") {
      // Check if Table1 exists and is not empty
      if (sdpValue == "") {
        setSDPError("Select S.D.P.");
      }

      // return;
    } else {
      setDisabledGoBtn(true);
      if (tableSdpData) {
        // Access the desired value from the first item in Table1
        const createdBy = tableSdpData.MstUserId;
        sessionStorage.setItem("createdBy", createdBy);

        dispatch({ type: "USER_PROFILE", payload: [tableSdpData] });
      }
      toast.success("Successful login", {
        position: toast.POSITION.TOP_CENTER,
      });
      setTableSdpData({});
      setTimeout(() => {
        navigate("/dashboard");
      }, 4000);
    }
  };

  return (
    <div>
      <ToastContainer />
      <section class={mainTable.signupBackground}>
        <div class={mainTable.signMainContent}>
          <div class={mainTable.signupShadow}>
            <div class={mainTable.signupSpaceing}>
              <div class="flex justify-center items-center mb-1 text-2xl font-semibold text-gray-900 ">
                <img src={fpaiLogo} class=" w-30 h-14" alt="customer Logo" />
              </div>
              <h1 class={mainTable.signupTitle}>Sign in to your account</h1>
              <form class={mainTable.formSpace} action="#">
                <InputText
                  title="Email / Phone No"
                  type="email"
                  name="email"
                  placeholder="Enter Email or Phone"
                  content={email}
                  controlFunc={(e) => setEmail(e.target.value)}
                  controlFuncBlur={(e) => {
                    setEmail(e.target.value);
                    setEmailError(
                      email.length > 0 ? "" : "Enter email or phone no"
                    );
                  }}
                />
                {emailError && <span class="text-red-500">{emailError}</span>}

                <InputPassword
                  title="Password"
                  name="password"
                  placeholder="Enter your password"
                  content={password}
                  controlFunc={(e) => setPassword(e.target.value)}
                  controlFuncBlur={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(
                      password.length > 0 ? "" : "Enter password"
                    );
                  }}
                />
                {passwordError && (
                  <span class="text-red-500">{passwordError}</span>
                )}

                <div class="flex items-center justify-end">
                  <a
                    href="#s"
                    class="text-sm font-medium text-red-600 hover:underline "
                    onClick={() => handleForgetPassword()}
                  >
                    Forgot password?
                  </a>
                </div>
                <button
                  data-modal-hide="bottom-right-modal"
                  type="button"
                  // className={mainTable.submitButton}
                  className={
                    disabledBtn
                      ? mainTable.loginBtnDisabled
                      : mainTable.loginBtn
                  }
                  //class="w-full text-white bg-red-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={() => handleSubmit()}
                  disabled={disabledBtn}
                >
                  Sign in
                </button>
                {visibleSdp && (
                  <div>
                    <DropDown
                      title="S.D.P."
                      options={sdp}
                      classStar={"text-red-500"}
                      placeholder="Select"
                      name="sdp"
                      selectedOption={sdpValue}
                      // errorMessage={sdpError}
                      controlFunc={handleSdpDropdownChange}
                    />
                    {sdpError && (
                      <span class="text-red-500 text-sm  font-medium">
                        {sdpError}
                      </span>
                    )}
                  </div>
                )}
                {visibleSdp && (
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={
                      disabledGoBtn
                        ? mainTable.loginBtnDisabled
                        : mainTable.loginBtn
                    }
                    //class="w-full text-white bg-red-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() => handleGo()}
                    disabled={disabledGoBtn}
                  >
                    Go
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
      <MainPopup
        popUpOpen={popUpForgetPwd}
        setpopUpOpen={setPopUpForgetPwd}
        Open={handleForgetPassword}
        loginPopups={forgotPasswordDesign}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <ForgetPassword
            closePopup={() => setPopUpForgetPwd(!popUpForgetPwd)}
          />
        </div>
      </MainPopup>

      <MainPopup
        popUpOpen={resetPopUp}
        setpopUpOpen={setResetPopUp}
        Open={handleResetPassword}
        loginPopups={resetPasswordDesign}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <ResetPassword
            data={currentData}
            closePopup={() => setResetPopUp(!resetPopUp)}
          />
        </div>
      </MainPopup>
    </div>
  );
}

export default Login;
