// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import fpaiLogo from "../image/FPAILogo.jpg";
import MainPopup from "../popup-containers/MainPopup";
import UserProfile from "../popup-containers/UserProfile";
import { useUserCapability } from "./ConstantReducerProvider";
import PasswordPopup from "../popup-containers/PasswordPopup";
import SystemSettingsPopup from "../popup-containers/SystemSettingsPopup";
import Notifications from "../popup-containers/Notifications";

function TopMenu() {
  const loction = useLocation();
  const [currentMenuId, setCurrentMenuId] = useState(null);
  const { userProfileState, dispatch } = useUserCapability();

  const userProfileDatas = userProfileState.userProfile;
  const userCapabilityTopMenu = userProfileState.userVsCapability;
  const userProfileImg =
    userProfileDatas && userProfileDatas.length > 0
      ? userProfileDatas[0].UserProfileImg
      : null;

  const userName =
    userProfileDatas && userProfileDatas.length > 0
      ? userProfileDatas[0].UserName
      : null;
  const desigantion =
    userProfileDatas && userProfileDatas.length > 0
      ? userProfileDatas[0].Designation
      : null;

  useEffect(() => {
    var createdBy = sessionStorage.getItem("createdBy");
    if (createdBy == null || createdBy == "") {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (loction) {
      const menuId1 = loction.state?.menuId;
      console.log("loction", loction.pathname);
      console.table("datas topmenu", userCapabilityTopMenu);
      const MenuId = userCapabilityTopMenu
        .filter((x) => x.MenuPath == loction.pathname)
        .map((item) => item.MstMenuId)
        .toString();
      const getMstMainMenuHeadingId = userCapabilityTopMenu
        .filter((x) => x.MenuPath == loction.pathname)
        .map((item) => item.MstMenuHeadingId)
        .toString();

      if (
        getMstMainMenuHeadingId == 1 ||
        getMstMainMenuHeadingId == 2 ||
        getMstMainMenuHeadingId == 3 ||
        getMstMainMenuHeadingId == 4
      ) {
        setCurrentMenuId(getMstMainMenuHeadingId); // Set current menu ID based on the retrieved ID
      }
      if (menuId1 == "3") {
        setCurrentMenuId(2);
      } else if (MenuId == "3,27") {
        setCurrentMenuId(3);
      }
      console.log("getMstMainMenuHeadingId", getMstMainMenuHeadingId);
    }
  }, [loction, userProfileState]);
  if (userCapabilityTopMenu.filter((item) => item.MstMenuHeadingId === 1)) {
    var dynamnicTopMenu = userCapabilityTopMenu
      .filter((item) => item.MstMenuHeadingId === 1)
      .map((item) => ({
        title: item.MenuHeading,
        menuHeadingId: item.MstMenuHeadingId,
        menuPath: item.MenuPath,
      }));

    var dynamnicTopMenuMobile = userCapabilityTopMenu
      .filter((item) => item.MstMenuHeadingId === 1)
      .map((item) => ({
        title: item.MenuHeading,
        menuHeadingId: item.MstMenuHeadingId,
        menuPath: item.MenuPath,
        icon: (
          <svg viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
            <path d="M21 16V4H3v12h18m0-14a2 2 0 012 2v12a2 2 0 01-2 2h-7v2h2v2H8v-2h2v-2H3a2 2 0 01-2-2V4c0-1.11.89-2 2-2h18M5 6h9v5H5V6m10 0h4v2h-4V6m4 3v5h-4V9h4M5 12h4v2H5v-2m5 0h4v2h-4v-2z" />
          </svg>
        ),
      }));
  } else {
    console.log("LandingPage permission denied");
  }

  if (userCapabilityTopMenu.filter((item) => item.MstMenuHeadingId === 2)) {
    var dynamnicTopMenu2 = userCapabilityTopMenu
      .filter((item) => item.MstMenuHeadingId === 2)
      .map((item) => ({
        title: item.MenuHeading,
        menuHeadingId: item.MstMenuHeadingId,
        menuPath: item.MenuPath,
        mstMenuId: item.MstMenuId,
      }));
  } else {
    console.log("Administrator permission denied");
  }

  if (dynamnicTopMenu2.length > 0) {
    var TopMenuAdmin = {
      title: dynamnicTopMenu2[0].title,
      menuHeadingId: dynamnicTopMenu2[0].menuHeadingId,
      menuPath: dynamnicTopMenu2[0].menuPath,
      menuId: dynamnicTopMenu2[0].mstMenuId,
    };

    var TopMenuAdminMobile = {
      title: dynamnicTopMenu2[0].title,
      menuHeadingId: dynamnicTopMenu2[0].menuHeadingId,
      menuPath: dynamnicTopMenu2[0].menuPath,
      menuId: dynamnicTopMenu2[0].mstMenuId,
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 14v8H4a8 8 0 018-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm2.595 5.812a3.51 3.51 0 010-1.623l-.992-.573 1-1.732.992.573A3.496 3.496 0 0117 14.645V13.5h2v1.145c.532.158 1.012.44 1.405.812l.992-.573 1 1.732-.992.573a3.51 3.51 0 010 1.622l.992.573-1 1.732-.992-.573a3.496 3.496 0 01-1.405.812V22.5h-2v-1.145a3.496 3.496 0 01-1.405-.812l-.992.573-1-1.732.992-.572zM18 17a1 1 0 100 2 1 1 0 000-2z" />
        </svg>
      ),
    };
  }

  if (userCapabilityTopMenu.filter((item) => item.MstMenuHeadingId === 3)) {
    var dynamnicTopMenu3 = userCapabilityTopMenu
      .filter((item) => item.MstMenuHeadingId === 3)
      .map((item) => ({
        title: item.MenuHeading,
        menuHeadingId: item.MstMenuHeadingId,
        menuPath: item.MenuPath,
        mstMenuId: item.MstMenuId,
        relatedMenu: item.RelatedMenu ? item.RelatedMenu : null,
      }));
  } else {
    console.log("Transaction permission denied");
  }

  if (dynamnicTopMenu3.length > 0) {
    var TopMenuTransaction = {
      title: dynamnicTopMenu3[0].title,
      menuHeadingId: dynamnicTopMenu3[0].menuHeadingId,
      menuPath: dynamnicTopMenu3[0].menuPath,
      menuId: dynamnicTopMenu3[0].mstMenuId,
      relatedMenu: dynamnicTopMenu3[0].relatedMenu
        ? dynamnicTopMenu3[0].relatedMenu
        : null,
    };
    var TopMenuTransactionMobile = {
      title: dynamnicTopMenu3[0].title,
      menuHeadingId: dynamnicTopMenu3[0].menuHeadingId,
      menuPath: dynamnicTopMenu3[0].menuPath,
      menuId: dynamnicTopMenu3[0].mstMenuId,
      relatedMenu: dynamnicTopMenu3[0].relatedMenu
        ? dynamnicTopMenu3[0].relatedMenu
        : null,
      icon: (
        <svg viewBox="0 0 640 512" fill="currentColor" class="w-6 h-6">
          <path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23-174-.2c-13.3 0-24-10.7-24-24s10.7-24 24-24h174.1L535 41zM105 377l-23 23h174c13.3 0 24 10.7 24 24s-10.7 24-24 24H81.9l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64h241.9c-3.7 7.2-5.9 15.3-5.9 24 0 28.7 23.3 52 52 52h117.4c-4 17 .6 35.5 13.8 48.8 20.3 20.3 53.2 20.3 73.5 0l19.3-19.3V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24 0-28.7-23.3-52-52-52H138.6c4-17-.6-35.5-13.8-48.8-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zm384 192c-35.3 0-64 28.7-64 64h64v-64zm-224 32c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96z" />
        </svg>
      ),
    };
  }

  if (userCapabilityTopMenu.filter((item) => item.MstMenuHeadingId === 4)) {
    var dynamnicTopMenu4 = userCapabilityTopMenu
      .filter((item) => item.MstMenuHeadingId === 4)
      .map((item) => ({
        title: item.MenuHeading,
        menuHeadingId: item.MstMenuHeadingId,
        menuPath: item.MenuPath,
        mstMenuId: item.MstMenuId,
      }));
  } else {
    console.log("Reports permission denied");
  }

  if (dynamnicTopMenu4.length > 0) {
    var TopMenuReports = {
      title: dynamnicTopMenu4[0].title,
      menuHeadingId: dynamnicTopMenu4[0].menuHeadingId,
      menuPath: dynamnicTopMenu4[0].menuPath,
      menuId: dynamnicTopMenu4[0].mstMenuId,
    };
    var TopMenuReportsMobile = {
      title: dynamnicTopMenu4[0].title,
      menuHeadingId: dynamnicTopMenu4[0].menuHeadingId,
      menuPath: dynamnicTopMenu4[0].menuPath,
      menuId: dynamnicTopMenu4[0].mstMenuId,
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          viewBox="0 0 24 24"
          class="w-6 h-6"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h5.697M18 14v4h4M18 11V7a2 2 0 00-2-2h-2" />
          <path d="M10 3 H12 A2 2 0 0 1 14 5 V5 A2 2 0 0 1 12 7 H10 A2 2 0 0 1 8 5 V5 A2 2 0 0 1 10 3 z" />
          <path d="M22 18 A4 4 0 0 1 18 22 A4 4 0 0 1 14 18 A4 4 0 0 1 22 18 z" />
          <path d="M8 11h4M8 15h3" />
        </svg>
      ),
    };
  }
  const createDynamicMenus = (menuHeadingId) => {
    return userCapabilityTopMenu
      .filter((item) => item.MstMenuHeadingId === menuHeadingId)
      .map((item) => ({
        title: item.MenuHeading,
        menuHeadingId: item.MstMenuHeadingId,
        menuPath: item.MenuPath,
        menuId: item.MstMenuId,
        relatedMenu: item.RelatedMenu || null,
      }));
  };

  const dynamicMenus = {
    topMenu: createDynamicMenus(1),
    adminMenu: createDynamicMenus(2),
    transactionMenu: createDynamicMenus(3),
    reportsMenu: createDynamicMenus(4),
  };

  const topMenuList = [
    ...dynamicMenus.topMenu,
    dynamicMenus.adminMenu[0],
    dynamicMenus.transactionMenu[0],
    dynamicMenus.reportsMenu[0],
  ].filter(Boolean);
  // const topMenuList = [
  //   ...dynamnicTopMenu,
  //   TopMenuAdmin,
  //   TopMenuTransaction,
  //   TopMenuReports,
  // ];

  const topMenuMobileList = [
    ...dynamnicTopMenuMobile,
    TopMenuAdminMobile,
    TopMenuTransactionMobile,
    TopMenuReportsMobile,
  ];

  const filteredTopMenuList = topMenuList.filter(
    (menuItem) => menuItem !== undefined
  );

  const filteredTopMenuListMobile = topMenuMobileList.filter(
    (menuItem) => menuItem !== undefined
  );

  const navBarMenu = {
    menuWidth: `w-full lg:block px-4 py-2 sm:px-1 lg:px-8`,
    menuSpace: "flex items-center justify-between gap-3 h-fit",
    menuList: `ml-10 items-baseline hidden md:flex lg:flex md:ml-2`,
    logoPosition: "flex items-center w-48",
    menu: "text-white hover:bg-red-400 px-3 py-2 flex flex-col justify-center rounded-md text-xs font-medium",
    menuActive: "bg-red-400",
    hiddenMenuSpace: "px-2 pt-2 pb-3 space-y-1 sm:px-3",
    menuButton:
      "bg-gray-800 inline-flex items-center sm:mx-3 md:hidden justify-center lg:p-2 md:p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white",
    menuButtonHidden:
      "hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium",
    profileImage: "w-12 h-12 rounded-full bg-white",
    profileImageText:
      "w-12 h-12  rounded-full bg-white flex justify-center py-1 align-center text-3xl font-medium text-red-600",
    profileImagePosition:
      "flex justify-end justify-evenly gap-2 items-center md:order-2",
    profileImageButton: "flex justify-center text-sm rounded-full",
  };
  const [topMenuVisiblity, setTopMenuVisibility] = useState(false);
  const [pageName, setPageName] = useState("");
  const [viewsAll, setViewsAll] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [notificationDesign, setNotificationDesign] = useState(false);
  const [userProfilePopUp, setUserProfilePopUp] = useState(false);
  const [passwordPopUp, setPasswordPopUp] = useState(false);
  const [systemSettingPopup, setSystemSettingPopup] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(false);
  const navigate = useNavigate();
  const handlePopup = () => {
    setUserProfilePopUp(true);
  };
  const handlePassword = () => {
    setPasswordPopUp(true);
  };
  const handleSystemSettings = () => {
    setSystemSettingPopup(true);
  };
  const handleNotification = () => {
    setNotifications(true);
    setNotificationDesign(true);
  };
  const TopMenuVisible = () => {
    setTopMenuVisibility(!topMenuVisiblity);
  };
  const LogOut = async () => {
    navigate("/");
    sessionStorage.clear();
    localStorage.clear();
  };
  const handleClose = () => {
    setUserProfilePopUp(false);
    setPasswordPopUp(false);
    setSystemSettingPopup(false);
    setNotifications(false);
  };

  const getUserFirstcharAt = (name) =>
    name ? name.charAt(0).toUpperCase() : "";

  const renderProfileImage = () => {
    if (userProfileImg) {
      return (
        <img
          className={navBarMenu.profileImage}
          src={userProfileImg}
          alt="user"
        />
      );
    } else if (userName) {
      return (
        <div className={navBarMenu.profileImageText}>
          {getUserFirstcharAt(userName)}
        </div>
      );
    }
  };
  // Function to handle menu click and set the currentMenuId
  const handleMenuClick = (menuId) => {
    setCurrentMenuId(menuId);
  };
  return (
    <nav className="bg-[#2d2d6e] p-2">
      {/* */}
      <div className={navBarMenu.menuSpace}>
        <div className="flex items-center">
          <div className="flex-shrink-0  px-1">
            <NavLink to="/">
              <img src={fpaiLogo} class="w-26 h-12" alt="customer Logo" />
            </NavLink>
          </div>
          <div className={navBarMenu.menuList}>
            {topMenuList.map((menu) => (
              <NavLink
                key={menu.menuId}
                to={menu.menuPath}
                // className={({ isActive }) =>
                //   `${navBarMenu.menu} ${isActive ? navBarMenu.menuActive : ""}`
                // }
                className={`${navBarMenu.menu} ${
                  menu.menuHeadingId == currentMenuId
                    ? navBarMenu.menuActive
                    : ""
                }`}
                state={{
                  isAdd: menu.relatedMenu,
                  menuId: menu.menuId,
                  isTransaction: menu.menuHeadingId === 3,
                }}
                onClick={() => {
                  setPageName(menu.menuPath);
                  // handleMenuClick(menu.menuId, menu.menuHeadingId); // Pass menuHeadingId
                }}
              >
                {menu.title}
              </NavLink>
            ))}
          </div>
        </div>
        <div className=" flex justify-end"></div>
        <div className=" flex justify-end items-center">
          {/* <svg
            viewBox="0 0 512 512"
            fill="currentColor"
            onClick={handleSystemSettings}
            className={
              "text-white px-3 py-2 flex flex-col justify-end rounded-md w-12 h-12 font-medium"
            }
          >
            <path d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z" />
          </svg> */}
          <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            onClick={handleNotification}
            className={
              "text-white px-3 py-2 flex flex-col justify-center rounded-md w-12 h-12 font-medium"
            }
          >
            <path d="M10 21h4c0 1.1-.9 2-2 2s-2-.9-2-2m11-2v1H3v-1l2-2v-6c0-3.1 2-5.8 5-6.7V4c0-1.1.9-2 2-2s2 .9 2 2v.3c3 .9 5 3.6 5 6.7v6l2 2m-4-8c0-2.8-2.2-5-5-5s-5 2.2-5 5v7h10v-7z" />
          </svg>
          <div
            class={navBarMenu.profileImagePosition}
            onClick={() => setViewsAll(!viewsAll)}
          >
            <button
              type="button"
              class={navBarMenu.profileImageButton}
              id="user-menu-button"
              aria-expanded="false"
              data-dropdown-toggle="user-dropdown"
              data-dropdown-placement="bottom"
            >
              <p
                className={
                  "text-white px-3 py-2 flex flex-col justify-center rounded-md text-xs font-medium"
                }
              >
                {userName ? userName : null}
                <span className="text-xs text-center">{desigantion}</span>
              </p>
              {/* <img
                class={navBarMenu.profileImage}
                src={userProfileImg}
                alt="user"
              /> */}
              {renderProfileImage()}
            </button>
            {viewsAll && (
              <div
                id="dropdownDots"
                class="z-10 bg-white absolute top-14 right-5 divide-y divide-gray-100 rounded-lg shadow w-44 "
              >
                <ul
                  class="py-2 text-sm text-gray-700 "
                  aria-labelledby="dropdownMenuIconButton"
                >
                  <li>
                    <a
                      class="block px-4 py-2 cursor-pointer hover:bg-gray-100 "
                      onClick={handlePopup}
                    >
                      User Profile
                    </a>
                  </li>
                  <li>
                    <a
                      class="block px-4 py-2 cursor-pointer hover:bg-gray-100 "
                      onClick={handlePassword}
                    >
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      class="block px-4 py-2 cursor-pointer hover:bg-gray-100 "
                      href="https://fpai-outreach.org/mobileapp/fpai-outreach-mobile.zip"
                      target="_blank"
                      rel="noopener noreferrer"
                      download="FPAI-Outreach Mobile APK File"
                    >
                      Download Mobile App
                    </a>
                  </li>
                  <li>
                    <a
                      class="block px-4 py-2 cursor-pointer hover:bg-gray-100 "
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   const link = document.createElement('a');
                      //   link.href = "http://fpai-outreach-dev.xlicon.com/documents/Help%20Document%20for%20FPAI%20Outreach%20Web%20Application%20v1.pdf";
                      //   link.download = "Help Document for FPAI Outreach Web Application v1.pdf";
                      //   document.body.appendChild(link);
                      //   link.click();
                      //   document.body.removeChild(link);
                      // }}
                      //href="http://fpai-outreach-dev.xlicon.com/documents/Help%20Document%20for%20FPAI%20Outreach%20Web%20Application.pdf"
                      //  href="http://fpai-outreach-test.xlicon.com/documents/Help%20Document%20for%20FPAI%20Outreach%20Web%20Application.pdf"
                      // href="http://fpai-outreach-test.xlicon.com/documents/Help%20Document%20for%20FPAI%20Outreach%20Web%20Application.pdf"
                      href="https://fpai-outreach.org/documents/Help%20Document%20for%20FPAI%20Outreach%20Web%20Application.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download="Help Document for FPAI Outreach Web Application"
                    >
                      Help Document for Web App
                    </a>
                  </li>
                  <li>
                    <a
                      class="block px-4 py-2 cursor-pointer hover:bg-gray-100 "
                      //href="http://fpai-outreach-dev.xlicon.com/documents/Help%20Document%20for%20FPAI%20Outreach%20Mobile%20Application.pdf"
                      // href="http://fpai-outreach-test.xlicon.com/documents/Help%20Document%20for%20FPAI%20Outreach%20Mobile%20Application.pdf"
                      href="https://fpai-outreach.org/documents/Help%20Document%20for%20FPAI%20Outreach%20Mobile%20Application.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download="Help Document for FPAI Outreach Mobile Application"
                    >
                      Help Document for Mobile App
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={LogOut}
                      class="block px-4 py-2 cursor-pointer hover:bg-gray-100 "
                    >
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="md:hidden  lg:hidden  block" id="mobile-menu">
        <div
          className={
            "px-2 pt-2 pb-3 flex gap-10 justify-center space-y-1 sm:px-3"
          }
        >
          {filteredTopMenuListMobile.map((menu) => (
            <NavLink
              to={menu.menuPath}
              state={{
                isAdd: menu.relatedMenu ? menu.relatedMenu : undefined,
                menuId: menu.menuId ? menu.menuId : undefined,
                isTransaction: true,
              }}
              className={navBarMenu.menuButtonHidden}
              onClick={() => {
                setPageName(menu.menuPath);
              }}
            >
              {menu.icon}
            </NavLink>
          ))}
        </div>
      </div>

      <MainPopup
        popUpOpen={notifications}
        setpopUpOpen={setNotifications}
        notificationPopups={notificationDesign}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <Notifications closePopup={handleClose} />
        </div>
      </MainPopup>

      <MainPopup
        popUpOpen={userProfilePopUp}
        setpopUpOpen={setUserProfilePopUp}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <UserProfile
            data={userProfileDatas}
            closePopup={() => setUserProfilePopUp(!userProfilePopUp)}
          />
        </div>
      </MainPopup>
      <MainPopup popUpOpen={passwordPopUp} setpopUpOpen={setPasswordPopUp}>
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <PasswordPopup closePopup={handleClose} />
        </div>
      </MainPopup>
      <MainPopup
        popUpOpen={systemSettingPopup}
        setpopUpOpen={setSystemSettingPopup}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <SystemSettingsPopup closePopup={handleClose} />
        </div>
      </MainPopup>
    </nav>
  );
}
export default TopMenu;
