import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { Toaster } from "react-hot-toast";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { mainTable } from "../css/Common";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import { useUserCapability } from "../components/ConstantReducerProvider";
import { KPYheaderData } from "../components/HeaderAttribute";
import TableGrid from "../components/Table";
import { useLocation } from "react-router-dom";
import YearDropdown from "../ReusableComponents/YearDropdown";

const Dashboard = () => {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [FPUsers, setFPUsers] = useState([]);
  const [bindNoOfClientsByGenderDB, setBindNoOfClientsByGenderDB] = useState(
    []
  );
  const [noOfClientsByGenderDBColor, setNoOfClientsByGenderDBColor] = useState(
    []
  );
  const noOfClientsByGenderDBColors = noOfClientsByGenderDBColor.map(
    (item) => item.Color
  );
  const [FPUsersColor, setFPUsersColor] = useState([]);
  const colors = FPUsersColor.map((item) => item.Color);
  const [servicesCategory, setServicesCategory] = useState([]);
  const [serviceKeys, setserviceKeys] = useState([]);
  const [servicesNameList, setServicesNameList] = useState({});
  const [servicesNameListColors, setServicesNameListColors] = useState({});
  const [bindCYP, setBindCYP] = useState([]);
  const [abortionClientsData, setAbortionClientsData] = useState([]);
  const [abortionClientsKeys, setAbortionClientsKeys] = useState([]);
  const [abortionClientNameList, setAbortionClientsNameList] = useState({});
  const [abortionClientListColors, setAbortionClientsListColors] = useState({});
  const [marginalizedClientsNameList, setMarginalizedClientsNameList] =
    useState({});
  const [noOfSRHServicesBySDPData, setNoOfSRHServicesBySDPData] = useState([]);
  const [noOfSRHServicesBySDPKeys, setNoOfSRHServicesBySDPKeys] = useState([]);
  const [noOfSRHServicesBySDPList, setNoOfSRHServicesBySDPList] = useState({});
  const [noOfSRHServicesBySDPColors, setNoOfSRHServicesBySDPColors] = useState(
    {}
  );

  const [cypKeys, setCYPKeys] = useState([]);
  const [cypNameList, setCYPNameList] = useState({});
  const [cypNameListColors, setCYPNameListColors] = useState({});
  const [noOfClients, setNoOfClients] = useState([]);
  const [noOfClientsKeys, setNoOfClientsKeys] = useState([]);
  const [NoOfClientsNameList, setNoOfClientsNameList] = useState({});
  const [NoOfClientsListColor, setNoOfClientsListColor] = useState({});
  const [marginalizedClients, setMarginalizedClients] = useState([]);
  const [marginalizedClientsKeys, setMarginalizedClientsKeys] = useState([]);

  const [
    marginalizedClientsNameListColor,
    setMarginalizedClientsNameListColor,
  ] = useState({});

  const [keyPerformanceIndicatorData, setKeyPerformanceIndicatorData] =
    useState([]);

  const [year, setYear] = useState("");

  // useEffect(() => {
  //   const currentYear = new Date().getFullYear();
  //   console.log("currentYear:",currentYear);
  //   // setYear(currentYear);
  //   getDashboardDataFetch(userProfileDatas,currentYear);
  // }, []);
  useEffect(() => {
    if (location.pathname == "/dashboard") {
      if (userProfileDatas && userProfileDatas[0]) {
        const currentYear = new Date().getFullYear();
        console.log("currentYear:", currentYear);
        getDashboardDataFetch(userProfileDatas, currentYear);
      }
    }
  }, [location]);

  const getDashboardDataFetch = async (userProfileDatas, year) => {
    try {
      await getServicesCategory(userProfileDatas, year);
      await getFPUsers(userProfileDatas, year);
      await noOfClientsData(userProfileDatas, year);
      await cypDBData(userProfileDatas);
      await marginalizedClientsData(userProfileDatas, year);
      await getNoOfClientsByGenderDB(userProfileDatas, year);
      await getNoOfAbortionClients(userProfileDatas);
      await getNoOfSRHServicesBySDPData(userProfileDatas);
      await keyPerformanceIndicatorDB(userProfileDatas, year);
    } catch (e) {}
  };

  const keyPerformanceIndicatorDB = async (userProfileDatas, year) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        year: year.toString(),
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
      };
      const response = await BindPostAPI(
        payload,
        "DashboardAPI/KeyPerformanceIndicatorDB"
      );
      console.log("KeyPerformanceIndicatorDB", response.data.Table);
      setKeyPerformanceIndicatorData(response?.data?.Table);
    }
  };

  const marginalizedClientsData = async (userProfileDatas, year) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        year: year.toString(),
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
      };
      const response = await BindPostAPI(
        payload,
        "DashboardAPI/MarginalizedClientsDB"
      );
      console.log("MarginalizedClientsDB", response.data.Table);
      let formatChange = transformMarginalizedClientsData(response.data.Table);
      console.log("formatChange", formatChange);
      setMarginalizedClients(formatChange);
      const marginalizedKey = Object.keys(
        formatChange[0] ? formatChange[0] : []
      ).filter((key) => key !== "name");
      console.log("marginalizedKey", marginalizedKey);
      setMarginalizedClientsKeys(marginalizedKey);
    }
  };

  const cypDBData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
      };
      const response = await BindPostAPI([data], "DashboardAPI/CYPDB");
      let formatChange = transCYPFormData(response.data.Table);
      console.log("formatChange", formatChange);
      setBindCYP(formatChange);
      const cypKey = Object.keys(
        formatChange[0] ? formatChange[0] : []
      )?.filter((key) => key !== "name");
      setCYPKeys(cypKey);
    }
  };

  const getServicesCategory = async (userProfileDatas, year) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        year: year.toString(),
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
      };
      const response = await BindPostAPI(
        payload,
        "DashboardAPI/ServicesCategoryDB"
      );
      console.log("getServicesCategory", response.data.Table);
      let formatChange = transformData(response.data.Table);
      console.log("formatChange", formatChange);
      setServicesCategory(formatChange);
      const serviceKey = Object.keys(
        formatChange[0] ? formatChange[0] : []
      ).filter((key) => key !== "name");
      console.log("serviceKey", serviceKey);
      setserviceKeys(serviceKey);
    }
  };

  const getFPUsers = async (userProfileDatas, year) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        year: year.toString(),
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
      };
      const response = await BindPostAPI([data], "DashboardAPI/FPUsersDB");
      console.log("FPUsers", response.data.Table1);
      setFPUsers(response.data.Table);
      setFPUsersColor(response.data.Table1);
    }
  };
  const getNoOfClientsByGenderDB = async (userProfileDatas, year) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        year: year.toString(),
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
      };
      const response = await BindPostAPI(
        [data],
        "DashboardAPI/NoOfClientsByGenderDB"
      );

      setBindNoOfClientsByGenderDB(response.data.Table);
      setNoOfClientsByGenderDBColor(response.data.Table1);
    }
  };
  const noOfClientsData = async (userProfileDatas, year) => {

    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        year: year.toString(),
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
      };
      const response = await BindPostAPI([payload], "DashboardAPI/NoOfClients");
      console.log("Noofclients", response.data.Table);
      let formatChange = transformNoofclientssData(response.data.Table);
      console.log("formatChange", formatChange);
      setNoOfClients(formatChange);
      const noOfClientsKey = Object.keys(
        formatChange[0] ? formatChange[0] : []
      ).filter((key) => key !== "name");
      console.log("NoofclientsKey", noOfClientsKey);
      setNoOfClientsKeys(noOfClientsKey);
    }
  };
  const getNoOfAbortionClients = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
      };
      const response = await BindPostAPI(
        [payload],
        "DashboardAPI/AbortionClientsDB"
      );
      console.log("Abortion Data", response.data.Table);
      let formatChange = transformAbortionData(response.data.Table);
      console.log("formatChange", formatChange);
      setAbortionClientsData(formatChange);
      const abortionClientKey = Object.keys(
        formatChange[0] ? formatChange[0] : []
      ).filter((key) => key !== "name");
      console.log("abortionClientKey", abortionClientKey);
      setAbortionClientsKeys(abortionClientKey);
    }
  };
  const getNoOfSRHServicesBySDPData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
      };
      const response = await BindPostAPI(
        [payload],
        "DashboardAPI/NoOfSRHServicesBySDP"
      );
      console.log("responseSRH", response);
      let formatChange = transformNoOfSRHServicesBySDPData(response.data.Table);
      console.log("SRHformatChange", formatChange);
      setNoOfSRHServicesBySDPData(formatChange);
      const noOfSRHServicesBySDPKey = Object.keys(
        formatChange[0] ? formatChange[0] : []
      ).filter((key) => key !== "name");

      setNoOfSRHServicesBySDPKeys(noOfSRHServicesBySDPKey);
    }
  };
  function transformData(data) {
    const result = {};
    const servicesList = {};
    const servicesListColors = {};

    data?.forEach((category) => {
      if (!category.CategoryName) {
        // Skip the category if CategoryName is null or undefined
        return;
      }
      const categoryName = category?.CategoryName?.replace(/ /g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
      servicesList[categoryName] = category?.CategoryName;

      servicesListColors[categoryName] = category?.ServicesCategory_Color;

      for (const [month, value] of Object.entries(category)) {
        if (month !== "CategoryName" && month !== "ServicesCategory_Color") {
          if (!result[month]) {
            result[month] = { name: month };
          }
          result[month][categoryName] = value;
        }
      }
    });
    setServicesNameList(servicesList);
    setServicesNameListColors(servicesListColors);
    console.log("result", result);
    return Object.values(result);
  }
  function transformAbortionData(data) {
    const result = {};
    const servicesList = {};
    const servicesListColors = {};

    data?.forEach((category) => {
      if (!category.MTP) {
        // Skip the category if CategoryName is null or undefined
        return;
      }
      const categoryName = category?.MTP?.replace(/ /g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
      servicesList[categoryName] = category?.MTP;

      servicesListColors[categoryName] = category?.AbortionColor;

      for (const [month, value] of Object.entries(category)) {
        if (month !== "MTP" && month !== "AbortionColor") {
          if (!result[month]) {
            result[month] = { name: month };
          }
          result[month][categoryName] = value;
        }
      }
    });
    setAbortionClientsNameList(servicesList);
    setAbortionClientsListColors(servicesListColors);
    console.log("result", result);
    return Object.values(result);
  }
  function transformNoOfSRHServicesBySDPData(data) {
    const result = {};
    const sdpList = {};
    const sdpListColors = {};

    data?.forEach((category) => {
      if (!category.SDPColor) {
        // Skip the category if CategoryName is null or undefined
        return;
      }
      const categoryName = category?.SDPName?.replace(/ /g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
      sdpList[categoryName] = category?.SDPName;

      sdpListColors[categoryName] = category?.SDPColor;

      for (const [month, value] of Object.entries(category)) {
        if (month !== "SDPColor" && month !== "SDPName") {
          if (!result[month]) {
            result[month] = { name: month };
          }
          result[month][categoryName] = value;
        }
      }
    });
    setNoOfSRHServicesBySDPList(sdpList);
    setNoOfSRHServicesBySDPColors(sdpListColors);
    console.log("resultSDP", result);
    return Object.values(result);
  }
  const transformMarginalizedClientsData = (data) => {
    const result = {};
    const MarginalizedClientsList = {};
    const MarginalizedClientsColors = {};

    data?.forEach((marginalized) => {
      if (!marginalized.MEClients_en) {
        // Skip the category if CategoryName is null or undefined
        return;
      }
      const marginalizedName = marginalized?.MEClients_en?.replace(/ /g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
      MarginalizedClientsList[marginalizedName] = marginalized?.MEClients_en;

      MarginalizedClientsColors[marginalizedName] = marginalized?.ME_Color;

      for (const [month, value] of Object.entries(marginalized)) {
        if (month !== "MEClients_en" && month !== "ME_Color") {
          if (!result[month]) {
            result[month] = { name: month };
          }
          result[month][marginalizedName] = value;
        }
      }
    });
    setMarginalizedClientsNameList(MarginalizedClientsList);
    setMarginalizedClientsNameListColor(MarginalizedClientsColors);

    console.log("MarginalizedClientsresult", result);
    return Object.values(result);
  };

  const transformNoofclientssData = (data) => {
    const result = {};
    const NoofclientsList = {};
    const NoofclientsColors = {};

    data?.forEach((noOfClients) => {
      if (!noOfClients.Total_Clients) {
        // Skip the category if CategoryName is null or undefined
        return;
      }
      const noofclientsName = noOfClients?.Total_Clients?.replace(/ /g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
      NoofclientsList[noofclientsName] = noOfClients?.Total_Clients;

      NoofclientsColors[noofclientsName] = noOfClients?.Color;

      for (const [month, value] of Object.entries(noOfClients)) {
        if (month !== "MEClients_en" && month !== "ME_Color") {
          if (!result[month]) {
            result[month] = { name: month };
          }
          result[month][noofclientsName] = value;
        }
      }
    });
    setNoOfClientsNameList(NoofclientsList);
    setNoOfClientsListColor(NoofclientsColors);

    console.log("Noofclientsresult", result);
    return Object.values(result);
  };

  // const serviceKeys = Object.keys(servicesCategory[0]).filter(
  //   (key) => key !== "name"
  // );

  function transCYPFormData(data) {
    const result = {};
    const cypList = {};
    const cypListColors = {};

    data?.forEach((cypdata) => {
      if (!cypdata.CYP) {
        // Skip the category if CategoryName is null or undefined
        return;
      }
      const cypName = cypdata?.CYP?.replace(/ /g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
      cypList[cypName] = cypdata?.CYP;

      cypListColors[cypName] = cypdata?.Services_Color;

      for (const [month, value] of Object.entries(cypdata)) {
        if (month !== "CYP" && month !== "Services_Color") {
          if (!result[month]) {
            result[month] = { name: month };
          }
          result[month][cypName] = value;
        }
      }
    });
    setCYPNameList(cypList);
    setCYPNameListColors(cypListColors);

    return Object.values(result);
  }

  const renderCustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          transform="rotate(-60)"
          fontSize="12"
        >
          {payload.value.substring(0, 3)}
        </text>
      </g>
    );
  };

  const cypTooltip = (value, name) => {
    return [value, cypNameList[name]];
  };

  const formatTooltip = (value, name) => {
    return [value, servicesNameList[name]];
  };

  const marginalizedTooltip = (value, name) => {
    return [value, marginalizedClientsNameList[name]];
  };

  const abortionTooltip = (value, name) => {
    return [value, abortionClientNameList[name]];
  };
  const noOfSRHServicesBySDPTooltip = (value, name) => {
    return [value, noOfSRHServicesBySDPList[name]];
  };
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  // useEffect(() => {

  //   if (location.pathname == "/dashboard") {
  //     const currentYear = new Date().getFullYear();
  //   console.log("currentYear:",currentYear);
  //     getDashboardDataFetch(userProfileDatas,currentYear);
  //   }
  // }, [location, userProfileDatas]);
  console.log("serviceNames", servicesNameList);

  const handleYearChange = async (year) => {
    // Update your dashboard data based on the selected year
    console.log("Selected year:", year);
    await getDashboardDataFetch(userProfileDatas, year);
    //setYear(year);
  };

  return (
    <MainLayout pageName={"1"}>
      <>
        {userProfileDatas[0]?.MstRoleId != 7 ? (
          <div>
            <div class="flex justify-between w-full">
              <div className="flex justify-center items-center text-slate-500 text-2xl font-bold mb-4">
                Dashboard
              </div>
              <div class="flex justify-end ">
                <YearDropdown onYearChange={handleYearChange} />
              </div>
            </div>

            <div className="w-full">
              <div
                className={
                  "md:grid gap-4 mb-6 lg:grid-cols-2 md:grid-cols-2 sm:col-span-1"
                }
              >
                {servicesCategory?.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      Service categories
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={servicesCategory}
                        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                      >
                        {serviceKeys?.map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={servicesNameListColors[key]}
                            activeDot={{ r: 8 }}
                          />
                        ))}
                        {/* <CartesianGrid  /> */}
                        <XAxis
                          dataKey="name"
                          interval={0}
                          tick={renderCustomizedAxisTick}
                        />
                        <Tooltip formatter={formatTooltip} />
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-4 flex flex-wrap justify-center">
                      {serviceKeys?.map((key) => (
                        <div key={key} className="flex items-center mx-2 my-1">
                          <div
                            className="w-4 h-4 mr-2"
                            style={{
                              backgroundColor: servicesNameListColors[key],
                            }}
                          ></div>
                          <span>{servicesNameList[key]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {bindCYP.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">CYP</div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={bindCYP}
                        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                      >
                        {cypKeys.map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={cypNameListColors[key]}
                            activeDot={{ r: 8 }}
                          />
                        ))}

                        <XAxis
                          dataKey="name"
                          interval={0}
                          tick={renderCustomizedAxisTick}
                        />
                        <Tooltip formatter={cypTooltip} />
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-4 flex flex-wrap justify-center">
                      {cypKeys.map((key) => (
                        <div key={key} className="flex items-center mx-2 my-1">
                          <div
                            className="w-4 h-4 mr-2"
                            style={{ backgroundColor: cypNameListColors[key] }}
                          ></div>
                          <span>{cypNameList[key]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {marginalizedClients?.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      Marginalized clients
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={marginalizedClients}
                        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                      >
                        {marginalizedClientsKeys?.map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={marginalizedClientsNameListColor[key]}
                            activeDot={{ r: 8 }}
                          />
                        ))}
                        {/* <CartesianGrid  /> */}
                        <XAxis
                          dataKey="name"
                          interval={0}
                          tick={renderCustomizedAxisTick}
                        />
                        <Tooltip formatter={marginalizedTooltip} />
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-4 flex flex-wrap justify-center">
                      {marginalizedClientsKeys?.map((key) => (
                        <div key={key} className="flex items-center mx-2 my-1">
                          <div
                            className="w-4 h-4 mr-2"
                            style={{
                              backgroundColor:
                                marginalizedClientsNameListColor[key],
                            }}
                          ></div>
                          <span>{marginalizedClientsNameList[key]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {FPUsers?.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      FP Users
                    </div>
                    <div className="flex">
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          width={500}
                          height={300}
                          data={FPUsers}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 80,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="Contraceptive user by method"
                            angle={-45}
                            textAnchor="end"
                            interval={0}
                            height={100}
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} />
                          <Bar dataKey="First Time User" fill={colors[0]} />
                          <Bar dataKey="Continue user" fill={colors[1]} />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}
                {noOfClients?.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      No. of clients
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={noOfClients.filter((item) => item.name !== "Color")}
                       // data={noOfClients}
                        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                      >
                        {noOfClientsKeys?.map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={NoOfClientsListColor[key]}
                            activeDot={{ r: 8 }}
                          />
                        ))}
                        {/* <CartesianGrid  /> */}
                        <XAxis
                          dataKey="name"
                          interval={0}
                          tick={renderCustomizedAxisTick}
                        />
                        <Tooltip formatter={marginalizedTooltip} />
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-4 flex flex-wrap justify-center">
                      {noOfClientsKeys?.map((key) => (
                        <div key={key} className="flex items-center mx-2 my-1">
                          <div
                            className="w-4 h-4 mr-2"
                            style={{
                              backgroundColor: NoOfClientsListColor[key],
                            }}
                          ></div>
                          <span>{NoOfClientsNameList[key]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {bindNoOfClientsByGenderDB?.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      No. Of Clients By Gender
                    </div>
                    <div className="flex">
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          width={500}
                          height={300}
                          data={bindNoOfClientsByGenderDB}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 80,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="TotalClients"
                            angle={-45}
                            textAnchor="end"
                            interval={0}
                            height={100}
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} />
                          <Bar
                            dataKey="Female"
                            fill={noOfClientsByGenderDBColors[0]}
                          />
                          <Bar
                            dataKey="Male"
                            fill={noOfClientsByGenderDBColors[1]}
                          />
                          <Bar
                            dataKey="Other"
                            fill={noOfClientsByGenderDBColors[2]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  // </div>
                )}
                {/* {abortionClientsData.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      Abortion clients
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={abortionClientsData}
                        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                      >
                        {abortionClientsKeys.map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={abortionClientListColors[key]}
                            activeDot={{ r: 8 }}
                          />
                        ))}
                        <XAxis
                          dataKey="name"
                          interval={0}
                          tick={renderCustomizedAxisTick}
                        />
                        <Tooltip formatter={abortionTooltip} />
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-4 flex flex-wrap justify-center">
                      {abortionClientsKeys.map((key) => (
                        <div key={key} className="flex items-center mx-2 my-1">
                          <div
                            className="w-4 h-4 mr-2"
                            style={{
                              backgroundColor: abortionClientListColors[key],
                            }}
                          ></div>
                          <span>{abortionClientNameList[key]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )} */}
                {noOfSRHServicesBySDPData?.length > 0 && (
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      SRH services by SDP
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={noOfSRHServicesBySDPData}
                        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                      >
                        {noOfSRHServicesBySDPKeys?.map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={noOfSRHServicesBySDPColors[key]}
                            activeDot={{ r: 8 }}
                          />
                        ))}
                        <XAxis
                          dataKey="name"
                          interval={0}
                          //tick={renderCustomizedAxisTick}
                        />
                        <Tooltip formatter={noOfSRHServicesBySDPTooltip} />
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-4 flex flex-wrap justify-center">
                      {noOfSRHServicesBySDPKeys?.map((key) => (
                        <div key={key} className="flex items-center mx-2 my-1">
                          <div
                            className="w-4 h-4 mr-2"
                            style={{
                              backgroundColor: noOfSRHServicesBySDPColors[key],
                            }}
                          ></div>
                          <span>{noOfSRHServicesBySDPList[key]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {keyPerformanceIndicatorData?.length > 0 && (
                  // <div className={mainTable.DashboardShadowBox}>
                  //    <div className="font-bold flex justify-center">
                  //    Key Performance Indicator
                  //   </div>
                  //   <div class={mainTable.table}>
                  //     <div className={mainTable.xScroll}>
                  //       <div className={mainTable.tableHeader} role="rowgroup">
                  //         {KPYheaderData.map((item, itemIndex) => (
                  //           <div className={item.width} role="columnheader">
                  //             <label class="text-sm  font-medium">{item.title}</label>
                  //           </div>
                  //         ))}
                  //       </div>
                  //       <div class={`h-auto`}>
                  //         {keyPerformanceIndicatorData.length > 0 &&
                  //           keyPerformanceIndicatorData.map((data, index) => (
                  //             <div class={mainTable.tableRow}>
                  //               {KPYheaderData.map((keyData) => (
                  //                 <div
                  //                   scope="cell"
                  //                   role="cell"
                  //                   className={keyData.width}
                  //                 >
                  //                   <div>{data[keyData.key]}</div>
                  //                 </div>
                  //               ))}
                  //             </div>
                  //           ))}
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div className={mainTable.DashboardShadowBox}>
                    <div className="font-bold flex justify-center">
                      Key Performance Indicator
                    </div>
                    <div className={mainTable.grid1}>
                      <TableGrid
                        className={mainTable.table}
                        headerData={KPYheaderData}
                        tableData={keyPerformanceIndicatorData}
                        tableHeight="h-auto"
                        itemsPerPage={100}
                        currentPage={1}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center text-slate-500 text-3xl mb-4">
            Welcome
          </div>
        )}
      </>
    </MainLayout>
  );
};

export default Dashboard;
