// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { ResourcesMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import ResourceMasterPopup from "../popup-containers/ResourcesMasterPopup";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, BindPostAPI, IUDAPI, ImageAPI } from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import GridPage from "../components/GridPage";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import FileUpload from "../ReusableComponents/FileUpload";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function ResourcesMaster() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  // All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // Services Category Master insert and edit useState hooks handling .
  const [resourcesCountry, setResourcesCountry] = useState("");
  const [resourcesState, setResourcesState] = useState("");
  const [resourcesLocation, setResourcesLocation] = useState("");
  const [mstResourcesId, setMstResourcesId] = useState("");

  const [resourceFields, setResourceFields] = useState({
    resourcesName: "",
    resourcesPosition: "",
    resourcesState: "",
    resourcesLocation: "",
    showImage: "",
  });

  const [resourceFieldsError, setResourceFieldsError] = useState({
    resourcesNameError: "",
    resourcesPositionError: "",
    resourcesStateError: "",
    resourcesLocationError: "",
    showImageError: "",
  });

  // Bind dropdown value and bind data value useState hooks handling.
  const [bindState, setBindStateDD] = useState([]);
  const [bindLocation, setBindLocationDD] = useState([]);
  const [bindLocation1, setBindLocationDD1] = useState([]);
  const [bindResourcesMasterDatas, setBindResourcesMasterDatas] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [getLocationDatas, setGetLocationDatas] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  // This is Services Resources grid page add button visible handling functionality .
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setResourceFields({
      resourcesState: userProfileDatas[0].MstStateId,
      resourcesLocation: userProfileDatas[0].MstLocationId,
    });
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    resourcesData(userProfileDatas);
    setSubmitBtnDisabled(false);
    setResourceFields({
      resourcesName: "",
      resourcesPosition: "",
      resourcesState: "",
      resourcesLocation: "",
      showImage: "",
    });

    setResourceFieldsError({
      resourcesNameError: "",
      resourcesPositionError: "",
      resourcesStateError: "",
      resourcesLocationError: "",
      showImageError: "",
    });
  };

  const handleEdit = async (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);

    setMstResourcesId(bindResourcesMasterDatas[rowIndex].MstResourcesId);

    setResourceFields({
      ...resourceFields,
      resourcesName: bindResourcesMasterDatas[rowIndex].ResourcesName,
      resourcesPosition: bindResourcesMasterDatas[rowIndex].ResourcesPosition,
      resourcesState: bindResourcesMasterDatas[rowIndex].MstStateId,
      resourcesLocation: bindResourcesMasterDatas[rowIndex].MstLocationId,
      showImage: bindResourcesMasterDatas[rowIndex].ResourcesPhoto,
    });
  };

  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};
  
    if (flag === 1 || flag === 2) {
      // Use fallback values to avoid errors
      if (!resourceFields.resourcesState) {
        errors.resourcesState = "Select State";
      }
      if (!resourceFields.resourcesLocation) {
        errors.resourcesLocation = "Select Branch / Project";
      }
      if ((resourceFields.resourcesName || "").trim() === "") {
        errors.resourcesName = "Enter Resource Person Name";
      } else if (!/^[A-Za-z -]+$/.test(resourceFields.resourcesName)) {
        errors.resourcesName = "Only Alphabet, space and - allowed";
      }
  
      if ((resourceFields.resourcesPosition || "").trim() === "") {
        errors.resourcesPosition = "Enter Position";
      } else if (!/^[A-Za-z -]+$/.test(resourceFields.resourcesPosition)) {
        errors.resourcesPosition = "Only Alphabet, space and - allowed";
      }
  
      // if (!resourceFields.showImage) {
      //   errors.showImage = "Please Upload an Image";
      // }
    }
  
    setResourceFieldsError({
      ...resourceFieldsError,
      resourcesNameError: errors.resourcesName || "",
      resourcesPositionError: errors.resourcesPosition || "",
      resourcesStateError: errors.resourcesState || "",
      resourcesLocationError: errors.resourcesLocation || "",
      // showImageError: errors.showImage || "",
    });
  
    return errors;
  };
  
  // This function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API
  // response for those all and passing the rowindex for Resources.
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    const firstNameValidation = validate(flag);
    if(Object.keys(firstNameValidation).length !== 0){
      setIsOpen(true);
    }

    if (Object.keys(firstNameValidation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1 || flag == 2) {
        const data = {
          mstResourcesId: mstResourcesId ? mstResourcesId : 0,
          resourcesName: resourceFields.resourcesName,
          resourcesPosition: resourceFields.resourcesPosition,
          mstCountryId: 1,
          mstStateId: resourceFields.resourcesState,
          mstLocationId: resourceFields.resourcesLocation,
          resourcesPhoto: resourceFields.showImage,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "ResourcesAPI/IUDResources");
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Resource Person added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Resource Person data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Resource Person updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Resource Person data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        const data = {
          mstResourcesId: bindResourcesMasterDatas[items].MstResourcesId,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "ResourcesAPI/IUDResources");
        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success(
              "Resource Person data deleted successfully 🗑️",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to delete Resource Person data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success(
              "Resource Person data blocked successfully 🚫",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to block Resource Person data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success(
              "Resource Person data unblocked successfully ✅",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to unblock Resource Person data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    }else{
      setSubmitBtnDisabled(false);
    }
  };

  // This function is helped to the viwe, delete, block and unblock
  // input value via rowindex passing for Resources .
  const handleResources = (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const StateRowData = bindResourcesMasterDatas[rowIndex];
    const StateInfo = bindState.find(
      (SD) => SD.value === StateRowData.MstStateId
    );
    const ResultState = {
      value: StateRowData.MstStateId,
      label: StateInfo ? StateInfo.label : "Unknown",
    };
    setResourcesState(ResultState);

    const locationRowData = bindResourcesMasterDatas[rowIndex];
    const LocationInfo = bindLocation.find(
      (L) => L.value === locationRowData.MstLocationId
    );
    const ResultLocation = {
      value: locationRowData.MstLocationId,
      label: LocationInfo ? LocationInfo.label : "Unknown",
    };
    setResourcesLocation(ResultLocation);

    const AutoDropdownPopupdata = [ResultLocation, ResultState];
    setPopupdata(AutoDropdownPopupdata);
    setCurrentData(bindResourcesMasterDatas[rowIndex]);
    setMstResourcesId(bindResourcesMasterDatas[rowIndex].MstResourcesId);
  };

  // This hook function is helped to bind dropdown value and bind all data
  //from Resources master table.
  useEffect(() => {
    const fetchData = async () => {
      const getSelectedLocationData = async () => {
        const locationResponse = await BindAPI("LocationAPI/GetLocation");
        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));
        setBindLocationDD(locationData);
      };

      getSelectedLocationData();
      bindDrpStateData();
      getSelectedLocation();
      resourcesData(userProfileDatas);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (location.pathname == "/resourcesmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // resourcesData();
      cancel();
    }
  }, [location]);
  const resourcesData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
      };
    // const response = await BindAPI("ResourcesAPI/BindResources");
    const response = await BindPostAPI(
      [data],
      "ResourcesAPI/BindResources"
    );
    setBindResourcesMasterDatas(response.data.Table);
  }
  };

  const getSelectedLocation = async () => {
    const locationResponse = await BindAPI("LocationAPI/GetLocation");
    setGetLocationDatas(locationResponse.data.Table);
  };

  //State DropDown Value Start
  const bindDrpStateData = async () => {
    try {
      const bindStatedatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");
      if (bindStatedatadrp.data.Table.length > 0) {
        const stateDD = bindStatedatadrp.data.Table.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));
        setBindStateDD(stateDD);
      } else {
        setBindStateDD([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };
  //State DropDown Value End

  useEffect(() => {
    
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == resourceFields.resourcesState // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDD1(locationSelected);
    } else {
    }
  }, [resourceFields.resourcesState]);

  const handleUserFieldsChange = (value, name) => {
    setResourceFields({
      ...resourceFields,
      [name]: value,
    });
  };

  const handleShowImage = (showImage) => {
    // showImage &&
      setResourceFields({
        ...resourceFields,
        showImage: showImage || "",
      });
    setResourceFieldsError({ ...resourceFieldsError, showImageError: "" });
  };

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Resource Person"
          header={ResourcesMasterHeader}
          records={bindResourcesMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleResources}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Resource Person"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title="Resource Person Name"
                        name="resourcesName"
                        maxLength={200}
                        content={resourceFields.resourcesName}
                        placeholder="Resource Person Name"
                        controlFunc={(e) =>
                          setResourceFields({
                            ...resourceFields,
                            resourcesName: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setResourceFieldsError((resourceFieldsError) => ({
                            ...resourceFieldsError,
                            resourcesNameError:
                            (resourceFields.resourcesName || "").length > 0
                                ? /^[A-Za-z -]+$/.test(
                                    resourceFields.resourcesName
                                  )
                                  ? ""
                                  : "Only Alphabet, space and - allowed"
                                : " Enter Resource Person Name",
                          }));
                        }}
                        errorMessage={resourceFieldsError.resourcesNameError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title="Position"
                        maxLength={200}
                        name="resourcesPosition"
                        content={resourceFields.resourcesPosition}
                        placeholder="Position"
                        controlFunc={(e) =>
                          setResourceFields({
                            ...resourceFields,
                            resourcesPosition: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setResourceFieldsError((resourceFieldsError) => ({
                            ...resourceFieldsError,
                            resourcesPositionError:
                              (resourceFields.resourcesPosition || "").length > 0
                                ? /^[A-Za-z -]+$/.test(
                                    resourceFields.resourcesPosition
                                  )
                                  ? ""
                                  : "Only Alphabet, space and - allowed"
                                : " Enter Position",
                          }));
                        }}
                        errorMessage={
                          resourceFieldsError.resourcesPositionError
                        }
                      />
                    </div>
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title="Country"
                      options={[]}
                      placeholder="Select"
                      name="country"
                      selectedOption={resourcesCountry}
                      controlFunc={(value) => {
                        setResourcesCountry(value);
                      }}
                      Class="custom-class"
                      disabled="true"
                      tabIndex="1"
                    />
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="State"
                        options={bindState}
                        placeholder="Select"
                        name="resourcesState"
                        Class="p-0"
                        selectedOption={bindState.find(
                          (x) => x.value == resourceFields.resourcesState
                        )}
                        controlFunc={(value, e) => {
                          setResourceFields({
                            ...resourceFields,
                            resourcesLocation: "",
                          });

                          setResourceFieldsError({
                            ...resourceFieldsError,
                            resourcesStateError: value ? "" : "Select State",
                          });
                          handleUserFieldsChange(value.value, e.name);
                          setResourceFields({
                            ...resourceFields,
                            resourcesState: value.value,
                            resourcesLocation: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setResourceFieldsError({
                            ...resourceFieldsError,
                            resourcesStateError: resourceFields.resourcesState
                              ? ""
                              : "Select State",
                          });
                        }}
                        tabIndex="1"
                        errorMessage={resourceFieldsError.resourcesStateError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="Branch/Project"
                        options={bindLocation && bindLocation1}
                        placeholder="Select"
                        name="resourcesLocation"
                        Class="p-0"
                        selectedOption={
                          resourceFields.resourcesLocation
                            ? bindLocation.find(
                                (x) =>
                                  x.value == resourceFields.resourcesLocation
                              )
                            : ""
                        }
                        controlFunc={(value, e) => {
                          setResourceFieldsError({
                            ...resourceFieldsError,
                            resourcesLocationError: value
                              ? ""
                              : "Select Branch/Project",
                          });
                          handleUserFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setResourceFieldsError({
                            ...resourceFieldsError,
                            resourcesLocationError:
                              resourceFields.resourcesLocation
                                ? ""
                                : "Select Branch/Project",
                          });
                        }}
                        tabIndex="1"
                        errorMessage={
                          resourceFieldsError.resourcesLocationError
                        }
                      />
                    </div>
                    <div className="col-span-2">
                      <FileUpload
                        title="Photo"
                        classDiv={mainTable.grid2}
                        apiCall="ResourcesAPI/ResourcesImageUpload"
                        modalName="Resources_Image"
                        showImage={resourceFields.showImage}
                        disabledBtn={updateScreenTitle}
                        showImageData={handleShowImage}
                        imageUploadedError={resourceFieldsError.showImageError}
                        limitMassage={
                          "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                        }
                        MAX_FILE_SIZE_MB={2}
                        ALLOWED_FORMATS={[
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                      />
                    </div>
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
         {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleResources}
      >
        <div>
          <PopUpScreenTitle
            title="Resource Person"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <ResourceMasterPopup data={currentData} data1={popupdata} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default ResourcesMaster;
