import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import {
  inventoryReceiptsHeaders,
  inventoryReceiptsViewHeaders,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import InventoryReceiptsPopup from "../popup-containers/InventoryReceiptsPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import Buttons from "../components/Buttons";
import usePrevious from "../Hook/UsePrevious";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const InventoryReceipts = () => {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  //All visible functionality useState hooks handling.
  const [visibleSdp, setVisibleSdp] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  // const [receiptQuantityvisible, setReceiptQuantityvisible] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [viewAction, setViewAction] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // Bind dropdown data value useState hooks handling.
  const [sdp, setSdp] = useState([]);
  const [bindCommodityNameDD, setBindCommodityNameDD] = useState([]);
  const [bindCommodityBrandDD, setBindCommodityBrandDD] = useState([]);
  const [bindSupplier, setBindSupplier] = useState([]);
  const [bindPartnerOrganization, setBindPartnerOrganization] = useState([]);
  const [bindFieldWorkerName, setBindFieldWorkerName] = useState([]);
  const [bindSDP, setBindSdp] = useState([]);
  const [bindLocation, setBindLocation] = useState([]);
  const [bindAreaDD, setBindAreaDD] = useState([]);
  const [bindAreaDD1, setBindAreaDD1] = useState([]);
  const [bindAreaPopUpDD, setBindAreaPopUpDD] = useState([]);
  const [bindAreaDatas, setBindAreaDatas] = useState([]);
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);
  const [bindCommodities, setBindCommodities] = useState([]);
  const [bindBrandDD, setBindBrandDD] = useState([]);
  const [bindInventoryReceiptsDatas, setBindInventoryReceiptsDatas] = useState(
    []
  );
  const [editDate, setEditDate] = useState("");
  const [receiptError, setReceiptError] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [bindReceiptChildDatas, setBindReceiptChildDatas] = useState([]);
  const [bindCommodityDD, setBindCommodityDD] = useState([]);
  const [commoditiesDD, setCommoditiesDD] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [commoditiesDetails, setCommoditiesDetails] = useState("");
  const [bindReceivedFromDD, setBindReceivedFromDD] = useState([
    // { value: 1, label: "Head Quarters" },
    // { value: 2, label: "Branch/Project" },
    // { value: 3, label: "Supplier" },
    // { value: 4, label: "SDP" },
    // { value: 5, label: "Outreach Staff" },
    // { value: 6, label: "Partner Organization" },
    // { value: 7, label: "Govt. Supply" },
  ]);
  // Inventory Receipt insert and edit useState hooks handling .
  const [productReceivedFrom, setProductReceivedFrom] = useState("");
  const [productReceivedFrom1, setProductReceivedFrom1] = useState("");
  const [productReceivedFrom1Error, setProductReceivedFrom1Error] =
    useState("");
  const [receiptsSdp, setReceiptsSdp] = useState("");
  const [receiptsLocation, setReceiptsLocation] = useState("");
  const [currentData3, setCurrentData3] = useState({});
  const [receiptsBrand, setReceiptsBrand] = useState("");
  const [receiptsDate, setReceiptsDate] = useState();
  const [txnReceiptId, setTxnReceiptId] = useState("");
  const [rowIndex, setRowIndex] = useState("");
  const [selectedReceivedFromError, setSelectedReceivedFromError] =
    useState("");
  const [selectedReceivedFrom, setSelectedReceivedFrom] = useState("");
  const [selectedReceivedFrom1, setSelectedReceivedFrom1] = useState(null);
  const [inventoryReceiptFields, setInventoryReceiptFields] = useState({
    receiptNo: "INREC",
    receiptDate: new Date(),
    remarks: "",
    area: "",
    receivedBy: "",
    branch: "",
    sdp: "",
    mstOrwId: "",
    receivedArea: "",
    branchId: "",
    sdpId: "",
  });

  const [tableData, setTableData] = useState([
    {
      receiptsCommodities: "",
      receiptsCategory: "",
      receiptsName: "",
      receiptsBrand: "",
      receivedQuantity: "",
      unitOfMeasure: "",
      batchNo: "",
      dateOfExpiry: "",
      rate: "",
      receiptsCategoryList: [],
      receiptsNameList: [],
      receiptsBrandList: [],
      currentStock: "",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);

  // Inventory Receipt previousTableData and CurrentTableData compare functionality .
  const previousTableData = usePrevious(tableData);
  const handleInventoryReceiptFieldsChange = (value, name) => {
    setInventoryReceiptFields({
      ...inventoryReceiptFields,
      [name]: value,
    });
  };

  // Inventory Receipt Error set useState hooks handling .
  const [inventoryReceiptFieldsError, setInventoryReceiptFieldsError] =
    useState({
      receiptNoError: "",
      receiptDateError: "",
      remarksError: "",
      areaError: "",
      receivedByError: "",
    });

  // Inventory Receipt Child Table Add New Row Functionaity .
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.receiptsCommodities = "";
    newRow.receiptsCategory = "";
    newRow.receiptsName = "";
    newRow.receiptsBrand = "";
    newRow.receivedQuantity = "";
    newRow.unitOfMeasure = "";
    newRow.batchNo = "";
    newRow.dateOfExpiry = "";
    newRow.rate = "";
    newRow.receiptsCategoryList = [];
    newRow.receiptsNameList = [];
    newRow.receiptsBrandList = [];
    newRow.currentStock = "";

    setTableData((prevData) => [...prevData, newRow]);
  };

  // Inventory Receipt Child Table Row Delete Functionaity .
  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          receiptsCommodities: "",
          receiptsCategory: "",
          receiptsName: "",
          receiptsBrand: "",
          receivedQuantity: "",
          unitOfMeasure: "",
          batchNo: "",
          dateOfExpiry: "",
          rate: "",
          receiptsCategoryList: [],
          receiptsNameList: [],
          receiptsBrandList: [],
          currentStock: "",
        };

        // Use spread to create a new array
        return [newRow];
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setCommoditiesDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  // Inventory Receipt Child Table Fields Input Change Handling .
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    // let value = "";
    // if (columnName == "receiptsCommodities") {
    //   value = selectedOption
    //     ? selectedOption.label
    //     : selectedOption.value || "";
    // } else {
    //   value = selectedOption
    //     ? selectedOption.value
    //     : selectedOption.label || "";
    // }
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  const validate = (flag) => {
    const errors = {};
    if (flag === 1 || flag === 2) {
      // if (selectedReceivedFrom.length === 0) {
      //   setSelectedReceivedFromError("Please select Received From");
      // } else {
      //   setSelectedReceivedFromError("");
      // }
      if (!inventoryReceiptFields.receivedBy) {
        errors.receivedBy = "Enter Received By";
      }
  
      if (inventoryReceiptFields.area == "" && selectedReceivedFrom1 == "4") {
        errors.area = "Select Area Name";
      }
      if (
        !productReceivedFrom1 &&
        selectedReceivedFrom1 !== "1" &&
        selectedReceivedFrom1 !== "7"
      ) {
        errors.productReceivedFrom1 =
          selectedReceivedFrom1 === "2"
            ? "Select Received Branch/Project"
            : selectedReceivedFrom1 === "3"
            ? "Select Received Supplier"
            : selectedReceivedFrom1 === "4"
            ? "Select Received S.D.P"
            : selectedReceivedFrom1 === "5"
            ? "Select Received Outreach Staff Name"
            : selectedReceivedFrom1 === "6"
            ? "Select Received Partner Organization"
            : "Please select a valid option";
      }
      if (!selectedReceivedFrom) {
        errors.selectedReceivedFrom = "Please select Received From";
      }
    }
    setSelectedReceivedFromError(errors.selectedReceivedFrom || "");
    setProductReceivedFrom1Error(errors.productReceivedFrom1 || "");
    setInventoryReceiptFieldsError({
      ...inventoryReceiptFieldsError,
      receiptNoError: errors.receiptNo || "",
      receivedByError: errors.receivedBy || "",
      areaError: errors.area || "",
    });
    return errors;
  };
  const headerData = [
    {
      title: "Commodities",
      key: "commoditiesCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Category",
      key: "commoditiesCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "commoditiesName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "commoditiesBrand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Batch No.",
      key: "BatchNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Date Of Expiry",
      key: "DateOfExpiry",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Qty.",
      key: "commoditiesQty",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "commoditiesUOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },

    {
      title: "Rate",
      key: "Rate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const areaResponse = await BindAPI("AreaAPI/GetArea");
  //       setBindAreaDatas(areaResponse.data.Table);

  //       // Call your other functions after fetching area data
  //       await ReceiptDropDownDatas();
  //       await inventoryReceiptsData();
  //     } catch (error) {
  //       // Handle errors if any function fails
  //       console.error("Error in useEffect:", error);
  //     }
  //   };

  //   fetchData(); // Call the async function inside useEffect
  // }, []); // Empty dependency array means this effect runs once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const areaResponse = await BindAPI("AreaAPI/GetArea");
        setBindAreaDatas(areaResponse.data.Table);

        // Fetch remaining dropdown data
        await ReceiptDropDownDatas();

        // Fetch inventory receipts data
        await inventoryReceiptsData();
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };

    fetchData();
  }, []);
  const inventoryReceiptsData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        // setMstBranchId(userProfileDatas[0].MstLocationId);

        console.log("branch", userProfileDatas[0]);
        // setLocation(userProfileDatas[0].MstLocationId);
      } else {
        // handle else case if needed
      }
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "InventoryReceiptAPI/BindInventoryReceipt"
      );
      // const response = await BindAPI("InventoryReceiptAPI/BindInventoryReceipt");
      setBindInventoryReceiptsDatas(response.data.Table);
      setBindReceiptChildDatas(response.data.Table1);
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setInventoryReceiptFields({
        ...inventoryReceiptFields,
        receiptDate: formattedDate,
        mstOrwId:
          userProfileDatas[0].MstRoleId === 7
            ? userProfileDatas[0].MstUserId
            : null,
      });
    }
  }, [addScreenTitle ? addScreenTitle : false]);
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setViewAction(false);
    setInventoryReceiptFields({
      ...inventoryReceiptFields,
      receiptNo: "INREC",
    });
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];

    setInventoryReceiptFields((prevFields) => ({
      ...prevFields,
      receiptDate: formattedDate,
      dateOfExpiry: formattedDate,
    }));
    getBranch(userProfileDatas);
  };
  const cancel = () => {
    setBindAreaDD([]);
    inventoryReceiptsData(userProfileDatas);
    setCommoditiesDetails("");
    setReceiptError("");
    setpopUpOpen(false);
    setRowIndex("");
    setVisibleAdd(false);
    setVisibleTable(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    setSelectedReceivedFrom("");
    setSelectedReceivedFrom1("");
    setProductReceivedFrom1("");
    setSelectedReceivedFromError("");
    inventoryReceiptsData(userProfileDatas);
    setTxnReceiptId("");
    setInventoryReceiptFields({
      receiptNo: "INREC",
      receiptDate: "",
      batchNo: "",
      dateOfExpiry: "",
      remarks: "",
      area: "",
      receivedBy: "",
      branch: "",
      sdp: "",
      receivedArea: "",
    });
    setProductReceivedFrom1Error("");
    setInventoryReceiptFieldsError({
      receiptNoError: "",
      receiptDateError: "",
      batchNoError: "",
      dateOfExpiryError: "",
      remarksError: "",
      areaError: "",
      receivedByError: "",
    });
    setTableData([
      {
        receiptsCommodities: "",
        receiptsCategory: "",
        receiptsName: "",
        receiptsBrand: "",
        receivedQuantity: "",
        unitOfMeasure: "",
        batchNo: "",
        dateOfExpiry: "",
        rate: "",
        currentStock: "",
      },
    ]);
  };

  const appendDataToCommoditiesTable = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    handleDeleteRow(0);
    setTableData([]);
    const formattedDataArray = newDataArray.map((data) => {
      console.log("ReceiptBalance", data.ReceiptBalance);
      console.log("ReceiptQuantity", data.ReceiptQuantity);
      console.log("ReceiptQuantity", data.ReceiptIssueQuantity);
      //   if (data.ReceiptQuantity > data.ReceiptBalance) {
      //     setReceiptQuantityvisible(true);
      // } else {
      //     setReceiptQuantityvisible(false);
      // }
      const receivedQuantityVisible =
        data.ReceiptQuantity > data.ReceiptBalance;
      // Format dateOfExpiry
      const formattedDateOfExpiry = data.ReceiptDateOfExpiry;
      const formattedDate1 = formattedDateOfExpiry.split("T")[0];
      return {
        receiptsCommodities: data.MstCommoditiesId,
        receiptsCategory: data.MstCategoryId,
        receiptsName: data.MstNameId,
        receiptsBrand: data.MstBrandId,
        receivedQuantity: data.ReceiptQuantity,
        unitOfMeasure: data.ReceiptUOM,
        batchNo: data.ReceiptBatchNo,
        dateOfExpiry: formattedDate1,
        rate: data.ReceiptRate,
        receivedQuantityVisible: receivedQuantityVisible,
      };
    });

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };

  // This function is helped to edit the input value via rowindex passing and
  // helping to visible handling for Inventory Receipt .
  const handleEdit = async (rowIndex) => {
    getBranch(userProfileDatas);
    setVisibleAdd(true);
    setVisibleTable(false);
    setViewAction(false);
    setUpdateScreenTitle(true);
    setEditDate(bindInventoryReceiptsDatas[rowIndex].ReceiptDate);
    const currentDate = bindInventoryReceiptsDatas[rowIndex].ReceiptDate;
    const formattedDate = currentDate.split("T")[0];
    setInventoryReceiptFields({
      receiptNo: bindInventoryReceiptsDatas[rowIndex].ReceiptNo,
      receiptDate: formattedDate,
      branch: bindInventoryReceiptsDatas[rowIndex].LocationName_en,
      sdp: visibleSdp
        ? bindInventoryReceiptsDatas[rowIndex].SDP_en
        : bindInventoryReceiptsDatas[rowIndex].MstSdpId,
      branchId: bindInventoryReceiptsDatas[rowIndex].MstBranchId,
      sdpId: bindInventoryReceiptsDatas[rowIndex].MstSdpId,
      // mstOrwId: inventoryReceiptFields[rowIndex].MstOrwId
      //       ? Int16Array.parse(inventoryReceiptFields[rowIndex].MstOrwId)
      //       : null,
      mstOrwId: bindInventoryReceiptsDatas[rowIndex].MstOrwId,
      receivedArea: bindInventoryReceiptsDatas[rowIndex].ReceivedMstAreaId,
      remarks: bindInventoryReceiptsDatas[rowIndex].ReceiptRemarks_en,
      area: bindInventoryReceiptsDatas[rowIndex].MstAreaId,
      receivedBy: bindInventoryReceiptsDatas[rowIndex].ReceiptReceivedBy_en,
    });
    setBindInventoryReceiptsDatas((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        receiptDate: formattedDate,
      };
      return updatedData;
    });
    const TesttxnReceiptId = bindInventoryReceiptsDatas[rowIndex].TxnReceiptId;
    setTxnReceiptId(TesttxnReceiptId);
    const commoditiesDetailData = Object.values(bindReceiptChildDatas).filter(
      (item) => {
        return String(item.TxnReceiptId) === String(TesttxnReceiptId);
      }
    );
    if (commoditiesDetailData.length === 0) {
      setTableData([
        {
          receiptsCommodities: "",
          receiptsCategory: "",
          receiptsName: "",
          receiptsBrand: "",
          receivedQuantity: "",
          unitOfMeasure: "",
          batchNo: "",
          dateOfExpiry: "",
          rate: "",
          receiptsCategoryList: [],
          receiptsNameList: [],
          receiptsBrandList: [],
          currentStock: "",
        },
      ]);
    } else {
      // Append the filtered data to your state using appendDataToState function
      appendDataToCommoditiesTable(JSON.stringify(commoditiesDetailData));
    }

    // appendDataToCommoditiesTable(JSON.stringify(commoditiesDetailData));
    const ReceiptFrom = bindInventoryReceiptsDatas[rowIndex].ReceiptFrom1;
    if (ReceiptFrom === 1) {
      setSelectedReceivedFrom1("1");
      setSelectedReceivedFrom(ReceiptFrom);
    } else if (ReceiptFrom === 2) {
      setSelectedReceivedFrom(ReceiptFrom);
      setSelectedReceivedFrom1("2");
    } else if (ReceiptFrom === 3) {
      setSelectedReceivedFrom(ReceiptFrom);
      setSelectedReceivedFrom1("3");
    } else if (ReceiptFrom === 4) {
      setSelectedReceivedFrom(ReceiptFrom);
      setSelectedReceivedFrom1("4");
    } else if (ReceiptFrom === 5) {
      setSelectedReceivedFrom(ReceiptFrom);
      setSelectedReceivedFrom1("5");
    } else if (ReceiptFrom === 6) {
      setSelectedReceivedFrom(ReceiptFrom);
      setSelectedReceivedFrom1("6");
    } else if (ReceiptFrom === 7) {
      setSelectedReceivedFrom(ReceiptFrom);
      setSelectedReceivedFrom1("7");
    }
    setProductReceivedFrom1(bindInventoryReceiptsDatas[rowIndex].ReceiptFrom2);
  };

  const handleFromDropdownChange = (e) => {
    const selectedValue = e.target.value;
    // setProductReceivedFrom1("");
    // setSelectedReceivedFrom(e.target.value);
    // setSelectedReceivedFrom1(e.target.value);
    // setProductReceivedFrom(e.target.value);
    // setProductReceivedFrom1Error("");
    // setInventoryReceiptFieldsError({
    //   ...inventoryReceiptFieldsError,
    //   areaError: "",
    //   receivedByError: "",
    // });

    // Reset states related to productReceivedFrom1 and clear errors
    setProductReceivedFrom1(""); // Clear productReceivedFrom1
    setInventoryReceiptFields({
      ...inventoryReceiptFields,
      area: "",
      receivedBy: "",
    });
    setSelectedReceivedFrom(selectedValue); // Update selectedReceivedFrom
    setSelectedReceivedFrom1(selectedValue); // Update selectedReceivedFrom1
    setProductReceivedFrom(selectedValue); // Update productReceivedFrom (if needed)
    setProductReceivedFrom1Error(""); // Clear productReceivedFrom1 error
    setInventoryReceiptFieldsError({
      ...inventoryReceiptFieldsError,
      areaError: "",
      receivedByError: "",
    });
  };
  const getDataBasedOnReceivedFrom = () => {
    if (selectedReceivedFrom1 === 1) {
      setSelectedReceivedFrom1(null);
    } else if (selectedReceivedFrom1 === "2") {
      return bindLocation;
    } else if (selectedReceivedFrom1 === "3") {
      return bindSupplier;
    } else if (selectedReceivedFrom1 === "4") {
      return bindSDP;
    } else if (selectedReceivedFrom1 === "5") {
      return bindFieldWorkerName;
    } else if (selectedReceivedFrom1 === "6") {
      return bindPartnerOrganization;
    }
    // Default to an empty array or any other default data source.
    return [];
  };

  const ReceiptDropDownDatas = async () => {
    try {
      // Execute API calls in parallel
      const [
        locationResponse,
        sdpResponse,
        categoryResponse,
        nameResponse,
        brandResponse,
        supplierResponse,
        orsNameResponse,
        partnerOrganizationResponse,
        areaResponse,
        categoryTypeResponse,
        receivedFromResponse,
      ] = await Promise.all([
        BindAPI("LocationAPI/GetLocation"),
        BindAPI("SDPAPI/GetSDP"),
        BindAPI("CommoditiesAPI/GetCommoditiesCategory"),
        BindAPI("CommoditiesAPI/BindCommodities"),
        BindAPI("BrandAPI/BindBrand"),
        BindAPI("SupplierAPI/BindSupplier"),
        BindAPI("InventoryReceiptAPI/BindORSName"),
        BindAPI("PartnerOrganizationAPI/BindPartnerOrganization"),
        BindAPI("AreaAPI/GetArea"),
        BindAPI("InventoryReceiptAPI/BindCommodityType"),
        BindAPI("InventoryReceiptAPI/BindInventoryReceivedFrom"),
      ]);
      setBindCommoditiesCategory(categoryResponse.data.Table);
      setBindCommodities(nameResponse.data.Table);
      setBindBrandDD(brandResponse.data.Table);
      setBindAreaDatas(areaResponse.data.Table);
      // Transform data
      setBindLocation(
        locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }))
      );
      setBindSdp(
        sdpResponse.data.Table.map((item) => ({
          value: item.MstSDPId,
          label: item.SDP_en,
        }))
      );
      setBindCommodityDD(
        categoryResponse.data.Table.map((item) => ({
          value: item.MstCommodityCategoryId,
          label: item.CommodityCategory_en,
        }))
      );
      setBindCommodityNameDD(
        nameResponse.data.Table.map((item) => ({
          value: item.MstCommoditiesId,
          label: item.CommodityName_en,
        }))
      );
      setBindCommodityBrandDD(
        brandResponse.data.Table.map((item) => ({
          value: item.MstBrandId,
          label: item.Brand_en,
        }))
      );
      setBindSupplier(
        supplierResponse.data.Table.map((item) => ({
          value: item.MstSupplierId,
          label: item.SupplierName,
        }))
      );
      setBindFieldWorkerName(
        orsNameResponse.data.Table.filter(
          (record) => record.MstLocationId === 21
        ).map((item) => ({
          value: item.MstUserId,
          label: item.FirstName_en,
        }))
      );
      setBindPartnerOrganization(
        partnerOrganizationResponse.data.Table.map((item) => ({
          value: item.MstPartnerOrganizationId,
          label: item.PartnerOrganizationName,
        }))
      );
      setBindAreaPopUpDD(
        areaResponse.data.Table.map((item) => ({
          value: item.MstAreaId,
          label: item.AreaName_en,
        }))
      );
      setCommoditiesDD(
        categoryTypeResponse.data.Table.map((item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        }))
      );
      setBindReceivedFromDD(
        receivedFromResponse.data.Table.map((item) => ({
          value: item.TxnReceivedFromId,
          label: item.ReceivedFrom_en,
        }))
      );
    } catch (error) {
      console.error("Error fetching receipt dropdown data:", error);
    }
  };

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = bindAreaDatas.find(
      (item) => item.MstSDPId == productReceivedFrom1 // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = bindAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));

      setBindAreaDD(areaSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [productReceivedFrom1 ? productReceivedFrom1 : null]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    if (visibleSdp) {
      const areaSelectedValue = bindAreaDatas.find(
        (item) => item.MstSDPId == parseInt(inventoryReceiptFields.sdpId) // Replace with the desired MstStateId
      );

      if (areaSelectedValue) {
        const areaLabels = bindAreaDatas
          .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
          .map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

        const areaSelected = areaLabels.map((AR) => ({
          value: AR.MstAreaId, // Replace with the appropriate value
          label: AR.AreaName_en,
        }));

        setBindAreaDD1(areaSelected);
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    } else {
      const areaSelectedValue = bindAreaDatas.find(
        (item) => item.MstSDPId == inventoryReceiptFields.sdp // Replace with the desired MstStateId
      );

      if (areaSelectedValue) {
        const areaLabels = bindAreaDatas
          .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
          .map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

        const areaSelected = areaLabels.map((AR) => ({
          value: AR.MstAreaId, // Replace with the appropriate value
          label: AR.AreaName_en,
        }));

        setBindAreaDD1(areaSelected);
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    }
  }, [
    parseInt(inventoryReceiptFields.sdpId)
      ? parseInt(inventoryReceiptFields.sdpId)
      : null,
    inventoryReceiptFields.sdp ? inventoryReceiptFields.sdp : null,
  ]);
  useEffect(() => {
    const updateTableData = async () => {
      if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
        const updatedTableData = [...tableData]; // Clone the current table data

        // Iterate through each row
        for (const row of updatedTableData) {
          if (row?.receiptsCommodities) {
            const getFilteredCategory = bindCommoditiesCategory
              .filter(
                (item) => item.MstCommodityTypeId === row.receiptsCommodities
              )
              .map((item) => ({
                value: item.MstCommodityCategoryId,
                label: item.CommodityCategory_en,
              }));
            row.receiptsCategoryList = getFilteredCategory;
          }

          if (row?.receiptsCategory) {
            const selectedCommoditiesCategory = bindCommodities
              .filter(
                (item) => item.MstCommodityCategoryId === row.receiptsCategory
              )
              .map((item) => ({
                value: item.MstCommoditiesId,
                label: item.CommodityName_en,
                uom: item.CommodityUOM,
              }));
            row.receiptsNameList = selectedCommoditiesCategory;

            // Find the selected commodity for the row and set its unit of measure
            const selectedCommodity = selectedCommoditiesCategory.find(
              (item) => item.value === row.receiptsName
            );
            row.unitOfMeasure = selectedCommodity?.uom || ""; // Reset if no selected commodity
          }

          if (row?.receiptsName) {
            const selectedBrandCategory = bindBrandDD
              .filter((item) => item.MstCommoditiesId === row.receiptsName)
              .map((item) => ({
                value: item.MstBrandId,
                label: item.Brand_en,
              }));
            row.receiptsBrandList = selectedBrandCategory;
          }

          if (row?.receiptsBrand) {
            const data = {
              mstCommodityType: row.receiptsCommodities,
              mstCategoryId: row.receiptsCategory,
              mstCommodityId: row.receiptsName,
              mstBrandId: row.receiptsBrand,
              mstOrganizationStructureId:
                userProfileDatas[0]?.MstOrganizationId || "",
              mstUserId: userProfileDatas[0]?.MstUserId || "",
              mstRoleId: userProfileDatas[0]?.MstRoleId || "",
              mstBranchProjectId: userProfileDatas[0]?.MstLocationId || "",
              mstSDPId: visibleSdp
                ? inventoryReceiptFields.sdpId
                : inventoryReceiptFields.sdp,
              mstAreaId: inventoryReceiptFields.receivedArea
                ? inventoryReceiptFields.receivedArea
                : 0,
              mstORWId:
                userProfileDatas[0]?.MstRoleId === 7
                  ? userProfileDatas[0]?.MstUserId
                  : 0,
              txnTypeId: inventoryReceiptFields.receiptNo,
              flag: 2,
            };

            try {
              const response = await BindPostAPI(
                [data],
                "InventoryReceiptAPI/BindAvailableCurrentStockValidation"
              );

              const balanceQuantity =
                response?.data?.Table?.[0]?.BalanceQuantity || "";
              row.currentStock = balanceQuantity;
              console.log("balanceQuantity", balanceQuantity);
            } catch (error) {
              console.error("Error fetching current stock:", error);
              row.currentStock = ""; // Reset on error
            }
          }
        }

        setTableData(updatedTableData);
      }
    };

    updateTableData();
  }, [tableData]);

  // This function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API
  // response for those all and passing the rowindex for Inventory Receipt .
  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    const createdBy = sessionStorage.getItem("createdBy");

    if (flag == 1 || flag == 2) {
      const inventoryReceiptValidation = validate(flag);

      if (Object.keys(inventoryReceiptValidation).length !== 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }

      if (commoditiesDetails.trim() === "") {
        setReceiptError("Fill the above table");
        setSubmitBtnDisabled(false);
        // setIsOpen(true);
        return; // Stop further execution
      }

      let receiptData;
      try {
        receiptData = JSON.parse(commoditiesDetails);
      } catch (error) {
        console.error("Error parsing receipt data:", error);
        setReceiptError("Error parsing receipt data");
        return; // Stop further execution
      }

      if (!Array.isArray(receiptData)) {
        console.error("Receipt data is not an array");
        setReceiptError("Receipt data is not an array");
        return; // Stop further execution
      }

      const receiptErrors = receiptData.map((receipt, index) => {
        if (!receipt) return `Receipt at index ${index + 1} is missing.`;
        if (!receipt.receiptsCommodities)
          return `Receipt at index ${index + 1} is missing Commodities.`;
        if (!receipt.receiptsCategory)
          return `Receipt at row ${index + 1} is missing Category.`;
        if (!receipt.receiptsName)
          return `Receipt at row ${index + 1} is missing Name.`;
        if (!receipt.receiptsBrand)
          return `Receipt at row ${index + 1} is missing Brand.`;
        if (!receipt.receivedQuantity)
          return `Receipt at row ${index + 1} is missing Quantity.`;
        if (!receipt.unitOfMeasure)
          return `Receipt at row ${index + 1} is missing Unit Of Measure.`;
        if (!receipt.batchNo)
          return `Receipt at row ${index + 1} is missing Batch No.`;
        if (!receipt.dateOfExpiry)
          return `Receipt at row ${index + 1} is missing Date Of Expiry.`;
        return null;
      });

      const filteredErrors = receiptErrors.filter((error) => error !== null);
      let inventoryReceiptChildValidation;
      if (filteredErrors.length > 0) {
        inventoryReceiptChildValidation = 1;
        setReceiptError(filteredErrors.join("; "));
        setSubmitBtnDisabled(false);
        return; // Stop further execution
      } else {
        setReceiptError(""); // No errors found
        inventoryReceiptChildValidation = 0;
      }
      if (Object.keys(inventoryReceiptValidation).length === 0) {
        setSubmitBtnDisabled(true);
      } else {
        setSubmitBtnDisabled(false);
        // setIsOpen(true);
      }
      if (
        Object.keys(inventoryReceiptValidation).length === 0 &&
        inventoryReceiptChildValidation == 0
      ) {
        setIsOpen(false);
        setSubmitBtnDisabled(true);
        const languageKeys = [
          "ta",
          "hi",
          "mr",
          "gu",
          "kn",
          "ml",
          "te",
          "bn",
          "or",
        ];

        const translateTexts = async (text) => {
          return Promise.all(
            languageKeys.map(async (lang) => {
              const response = await LanguageTranslatorAPI(text, lang);
              return response.data.data.translations[0].translatedText;
            })
          );
        };

        const translatedReceivedBy = await translateTexts(
          inventoryReceiptFields.receivedBy
        );
        const translatedRemarks = await translateTexts(
          inventoryReceiptFields.remarks
        );

        const ResultRecipts =
          flag == 1 ? productReceivedFrom : selectedReceivedFrom;
        const data = {
          txnReceiptId: txnReceiptId ? txnReceiptId : 0,
          receiptNo: inventoryReceiptFields.receiptNo,
          receiptDate:
            flag == 1 ? inventoryReceiptFields.receiptDate : editDate,
          mstBranchId: inventoryReceiptFields.branchId,
          mstSdpId: visibleSdp
            ? inventoryReceiptFields.sdpId
            : inventoryReceiptFields.sdp,
          receivedMstAreaId: inventoryReceiptFields.receivedArea
            ? inventoryReceiptFields.receivedArea
            : null,
          mstOrwId: inventoryReceiptFields.mstOrwId
            ? inventoryReceiptFields.mstOrwId
            : null,
          mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          mstRoleId: userProfileDatas[0].MstRoleId,
          receiptFrom1: ResultRecipts,
          receiptFrom2: productReceivedFrom1
            ? productReceivedFrom1
            : selectedReceivedFrom1,
          mstAreaId: inventoryReceiptFields.area
            ? inventoryReceiptFields.area
            : null,
          receiptReceivedByEn: inventoryReceiptFields.receivedBy,
          receiptReceivedByTa: translatedReceivedBy[0],
          receiptReceivedByHi: translatedReceivedBy[1],
          receiptReceivedByMr: translatedReceivedBy[2],
          receiptReceivedByGu: translatedReceivedBy[3],
          receiptReceivedByMl: translatedReceivedBy[4],
          receiptReceivedByTe: translatedReceivedBy[5],
          receiptReceivedByKn: translatedReceivedBy[6],
          receiptReceivedByBn: translatedReceivedBy[7],
          receiptReceivedByOr: translatedReceivedBy[8],
          iReceiptDetailsBulkUpload: commoditiesDetails,
          receiptRemarksEn: inventoryReceiptFields.remarks,
          receiptRemarksTa: translatedRemarks[0],
          receiptRemarksHi: translatedRemarks[1],
          receiptRemarksMr: translatedRemarks[2],
          receiptRemarksGu: translatedRemarks[3],
          receiptRemarksTe: translatedRemarks[4],
          receiptRemarksMl: translatedRemarks[5],
          receiptRemarksKn: translatedRemarks[6],
          receiptRemarksBn: translatedRemarks[7],
          receiptRemarksOr: translatedRemarks[8],
          createdBy: createdBy,
          flag: flag,
        };

        try {
          const response = await IUDAPI(
            [data],
            "InventoryReceiptAPI/IUDInventoryReceipt"
          );

          if (flag == 1 && response.data.Table[0].Result === "S") {
            toast.success("Inventory Receipt added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });

            setSubmitVisibleButton(true);
            setInventoryReceiptFields({
              ...inventoryReceiptFields,
              receiptNo: response.data.Table1[0].ReceiptNo,
            });
            setTimeout(cancel, 10000);
            inventoryReceiptsData();
          } else if (flag == 2 && response.data.Table[0].Result === "M") {
            toast.success("Inventory Receipt updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
            inventoryReceiptsData();
          } else {
            const actionText = flag == 1 ? "add" : "update";
            toast.error(`Failed to ${actionText} Inventory Receipt data 👎`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } catch (error) {
          console.error("Error in IUDAPI request:", error);
          toast.error("An error occurred while processing the request 👎", {
            duration: 2000,
            position: "top-center",
          });
        }
      }
    } else {
      const data = {
        txnReceiptId: bindInventoryReceiptsDatas[items].TxnReceiptId,
        createdBy: createdBy,
        flag: flag,
      };
      try {
        const response = await IUDAPI(
          [data],
          "InventoryReceiptAPI/IUDInventoryReceipt"
        );

        if (flag == 3 && response.data.Table[0].Result === "D") {
          toast.success("Inventory Receipt data deleted successfully 🗑️", {
            duration: 5000,
            position: "top-center",
          });
          cancel();
          inventoryReceiptsData();
        } else if (flag == 4 && response.data.Table[0].Result === "B") {
          toast.success("Inventory Receipt data blocked successfully 🚫", {
            duration: 5000,
            position: "top-center",
          });
          cancel();
          inventoryReceiptsData();
        } else if (flag == 5 && response.data.Table[0].Result === "UB") {
          toast.success("Inventory Receipt data unblocked successfully ✅", {
            duration: 5000,
            position: "top-center",
          });
          cancel();
          inventoryReceiptsData();
        } else {
          const actionText =
            flag == 3 ? "delete" : flag == 4 ? "block" : "unblock";
          toast.error(`Failed to ${actionText} Inventory Receipt data 👎`, {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } catch (error) {
        console.error("Error in IUDAPI request:", error);
        toast.error("An error occurred while processing the request 👎", {
          duration: 2000,
          position: "top-center",
        });
      }
    }
  };

  const handleInventoryReceipts = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const locationRowData = bindInventoryReceiptsDatas[rowIndex];
    const LocationInfo = bindLocation.find(
      (L) => L.value === locationRowData.MstLocationId
    );
    const ResultLocation = {
      value: locationRowData.MstLocationId,
      label: LocationInfo ? LocationInfo.label : "Unknown",
    };
    setReceiptsLocation(ResultLocation);
    const TesttxnReceiptId = bindInventoryReceiptsDatas[rowIndex].TxnReceiptId;

    const commoditiesDetailData = Object.values(bindReceiptChildDatas).filter(
      (item) =>
        item.TxnReceiptId === bindInventoryReceiptsDatas[rowIndex].TxnReceiptId
    );
    setCurrentData3(commoditiesDetailData);
    var DateOpeningPart = bindInventoryReceiptsDatas[rowIndex].ReceiptDate;
    const OpeningdatePart = DateOpeningPart.split("T")[0];
    setReceiptsDate(OpeningdatePart);

    const receiptsCategoryBrandRowData = bindInventoryReceiptsDatas[rowIndex];
    const receiptsCategoryBrandInfo = bindCommodityBrandDD.find(
      (L) => L.value === receiptsCategoryBrandRowData.ReceiptCommodityBrand
    );
    const ResultreceiptsCategoryBrand = {
      value: receiptsCategoryBrandRowData.ReceiptCommodityBrand,
      label: receiptsCategoryBrandInfo
        ? receiptsCategoryBrandInfo.label
        : "Unknown",
    };
    setReceiptsBrand(ResultreceiptsCategoryBrand);

    const areaRowData = bindInventoryReceiptsDatas[rowIndex];
    const areaInfo = bindAreaPopUpDD.find(
      (L) => L.value == areaRowData.MstAreaId
    );
    const ResultArea = {
      value: areaRowData.MstAreaId,
      label: areaInfo ? areaInfo.label : "Unknown",
    };

    const outReachRowData = bindInventoryReceiptsDatas[rowIndex];
    const outReachInfo = bindFieldWorkerName.find(
      (L) => L.value == outReachRowData.MstOrwId
    );
    const ResultoutReach = {
      value: outReachRowData.MstOrwId,
      label: outReachInfo ? outReachInfo.label : "Select",
    };

    const receiptData = bindInventoryReceiptsDatas[rowIndex];
    let editCategoryReceivedItemDropDownValue;
    if (receiptData.ReceiptFrom1 === 4) {
      const SDPValue = bindSDP.find(
        (item) => item.value === receiptData.ReceiptFrom2
      );
      editCategoryReceivedItemDropDownValue = {
        value: receiptData.ReceiptFrom2,
        label: SDPValue ? SDPValue.label : "Unknown",
      };
    } else if (receiptData.ReceiptFrom1 === 2) {
      const eindentCategoryReceivedItemValue = bindLocation.find(
        (item) => item.value === receiptData.ReceiptFrom2
      );
      editCategoryReceivedItemDropDownValue = {
        value: receiptData.ReceiptFrom2,
        label: eindentCategoryReceivedItemValue
          ? eindentCategoryReceivedItemValue.label
          : "Unknown",
      };
    } else if (receiptData.ReceiptFrom1 === 3) {
      const SupplierValues = bindSupplier.find(
        (item) => item.value === receiptData.ReceiptFrom2
      );
      editCategoryReceivedItemDropDownValue = {
        value: receiptData.ReceiptFrom2,
        label: SupplierValues ? SupplierValues.label : "Unknown",
      };
    } else if (receiptData.ReceiptFrom1 === 5) {
      const Fieldvalues = bindFieldWorkerName.find(
        (item) => item.value === receiptData.ReceiptFrom2
      );
      editCategoryReceivedItemDropDownValue = {
        value: receiptData.ReceiptFrom2,
        label: Fieldvalues ? Fieldvalues.label : "Unknown",
      };
    } else if (receiptData.ReceiptFrom1 === 6) {
      const Fieldvalues = bindPartnerOrganization.find(
        (item) => item.value === receiptData.ReceiptFrom2
      );
      editCategoryReceivedItemDropDownValue = {
        value: receiptData.ReceiptFrom2,
        label: Fieldvalues ? Fieldvalues.label : "Unknown",
      };
    }

    setProductReceivedFrom1(editCategoryReceivedItemDropDownValue);

    const AutoDropdownPopupdata = [
      ResultLocation,
      ResultreceiptsCategoryBrand,
      OpeningdatePart,
      editCategoryReceivedItemDropDownValue,
      ResultArea,
      ResultoutReach,
    ];
    setPopupdata(AutoDropdownPopupdata);
    setCurrentData(bindInventoryReceiptsDatas[rowIndex]);
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false);
  };
  const handleReceiptView = (rowIndex) => {
    const filterSessionId = bindReceiptChildDatas.filter(
      (item) =>
        item.TxnReceiptId === bindInventoryReceiptsDatas[rowIndex].TxnReceiptId
    );
    setInventoryReceiptFields({
      ...inventoryReceiptFields,
      receiptNo: bindInventoryReceiptsDatas[rowIndex].ReceiptNo,
      ReceiptFrom1: bindInventoryReceiptsDatas[rowIndex].ReceiptFrom,
    });
    setViewAction(true);
    setStudentData(filterSessionId);
  };
  useEffect(() => {
    if (location.pathname == "/inventoryreceipts") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      inventoryReceiptsData(userProfileDatas);
    }
  }, [location, userProfileDatas]);
  const handleSdpDropdownChange = (e) => {
    setInventoryReceiptFields({
      ...inventoryReceiptFields,
      sdp: e.target.value,
    });
  };

  const getBranch = (userProfileDatas) => {
    console.log("userProfileDatas", userProfileDatas);
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setInventoryReceiptFields({
        ...inventoryReceiptFields,
        branch: userProfileDatas[0].LocationName_en,
        branchId: userProfileDatas[0].MstLocationId,
      });
      if (userProfileDatas.length === 1) {
        setVisibleSdp(true);
        // If userProfileDatas has only one element
        setInventoryReceiptFields({
          ...inventoryReceiptFields,
          branch: userProfileDatas[0].LocationName_en,
          sdp: userProfileDatas[0].SDP_en,
          branchId: userProfileDatas[0].MstLocationId,
          sdpId: userProfileDatas[0].MstSDPId,
        });
      } else {
        const newSdp = userProfileDatas.map((userProfile) => ({
          value: userProfile.MstSDPId,
          label: userProfile.SDP_en,
        }));
        setSdp(newSdp);
        console.log("newSdp", newSdp);
      }
    } else {
      // handle else case if needed
    }
  };
  useEffect(() => {
    if (addScreenTitle || updateScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, []);

  console.log("productReceivedFrom1", productReceivedFrom1);

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Inventory Receipt"
          header={inventoryReceiptsHeaders}
          records={bindInventoryReceiptsDatas}
          handleEdit={handleEdit}
          handleAction={handleInventoryReceipts}
          showAdd={showAdd}
          handleSchoolView={handleReceiptView}
          blockView={true}
        />
      )}
      {viewAction && (
        <GridPage
          title={`Inventory Receipt > ${inventoryReceiptFields.receiptNo} > ${inventoryReceiptFields.ReceiptFrom1}  `}
          header={inventoryReceiptsViewHeaders}
          records={studentData}
          tableHeight="h-56"
          addView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Inventory Receipt"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={""}>
                  <div className={mainTable.grid5}>
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Receipt No."
                        name="receiptNo"
                        placeholder="100"
                        disabled={true}
                        maxLength={30}
                        content={inventoryReceiptFields.receiptNo}
                        controlFunc={(e) =>
                          setInventoryReceiptFields({
                            ...inventoryReceiptFields,
                            receiptNo: e.target.value,
                          })
                        }
                      />
                    </div>
                    <InputText
                      inputType="date"
                      classStar="text-red-500"
                      title="Receipt Date"
                      name="receiptsDate"
                      content={inventoryReceiptFields.receiptDate}
                      controlFunc={(e) =>
                        setInventoryReceiptFields({
                          ...inventoryReceiptFields,
                          receiptDate: e.target.value,
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <InputText
                      inputType="text"
                      title="Branch"
                      name="branch"
                      disabled={true}
                      placeholder="Branch"
                      content={inventoryReceiptFields.branch}
                      controlFunc={(e) =>
                        setInventoryReceiptFields({
                          ...inventoryReceiptFields,
                          branch: e.target.value,
                        })
                      }
                    />
                    {visibleSdp && (
                      <InputText
                        inputType="text"
                        title="S.D.P."
                        name="sdp"
                        content={inventoryReceiptFields.sdp}
                        placeholder=""
                        disabled={true}
                        controlFunc={(e) =>
                          setInventoryReceiptFields({
                            ...inventoryReceiptFields,
                            sdp: e.target.value,
                          })
                        }
                      />
                    )}
                    {!visibleSdp && (
                      <DropDown
                        title="S.D.P."
                        options={sdp}
                        placeholder="Select"
                        name="sdp"
                        selectedOption={inventoryReceiptFields.sdp}
                        controlFunc={handleSdpDropdownChange}
                      />
                    )}
                    <AutoDropDown
                      title="Area"
                      options={bindAreaDD1}
                      name="receivedArea"
                      placeholder="Select"
                      Class="custom-class"
                      selectedOption={bindAreaDD1.find(
                        (x) => x.value == inventoryReceiptFields.receivedArea
                      )}
                      controlFunc={(value, e) => {
                        handleInventoryReceiptFieldsChange(value.value, e.name);
                      }}
                    />
                  </div>
                  <div className={mainTable.gridInventory3}>
                    <AutoDropDown
                      title="Outreach staff"
                      options={bindFieldWorkerName}
                      name="mstOrwId"
                      placeholder="Select"
                      Class="custom-class"
                      selectedOption={bindFieldWorkerName.find(
                        (x) => x.value == inventoryReceiptFields.mstOrwId
                      )}
                      controlFunc={(value, e) => {
                        handleInventoryReceiptFieldsChange(value.value, e.name);
                      }}
                      disabled={true}
                    />
                    <div>
                      <DropDown
                        title="Received From"
                        classStar="text-red-500"
                        options={bindReceivedFromDD}
                        placeholder="Select"
                        name="productReceivedFrom"
                        selectedOption={selectedReceivedFrom}
                        controlFunc={handleFromDropdownChange}
                        controlFuncBlur={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedReceivedFrom(selectedValue);
                          // Validation check
                          if (selectedValue.length === 0) {
                            setSelectedReceivedFromError(
                              "Please select Received From"
                            );
                          } else {
                            setSelectedReceivedFromError("");
                            setProductReceivedFrom1(0);
                          }
                        }}
                      />
                      {selectedReceivedFromError && (
                        <div className="text-red-500">
                          {selectedReceivedFromError}
                        </div>
                      )}
                    </div>
                    {selectedReceivedFrom1 &&
                      selectedReceivedFrom1 !== "1" &&
                      selectedReceivedFrom1 !== "7" && (
                        <>
                          <AutoDropDown
                            Class="p-0"
                            title={
                              selectedReceivedFrom1 == "2"
                                ? "Received Branch/Project"
                                : selectedReceivedFrom1 == "3"
                                ? "Received Supplier"
                                : selectedReceivedFrom1 == "4"
                                ? "Received S.D.P"
                                : selectedReceivedFrom1 == "5"
                                ? "Received Outreach Staff Name"
                                : selectedReceivedFrom1 == "6"
                                ? "Received Partner Organization"
                                : "Add New Field"
                            }
                            options={getDataBasedOnReceivedFrom()}
                            name="branch"
                            classStar="text-red-500"
                            placeholder="Select"
                            selectedOption={productReceivedFrom1 ? getDataBasedOnReceivedFrom(
                              selectedReceivedFrom1
                            ).find((x) => x.value == productReceivedFrom1) : ""}
                            controlFunc={(value, e) => {
                              setProductReceivedFrom1(value.value, e.name);
                            }}
                            controlFuncBlur={() => {
                              const errorMessage =
                                selectedReceivedFrom1 === "2"
                                  ? "Select Received Branch/Project"
                                  : selectedReceivedFrom1 === "3"
                                  ? "Select Received Supplier"
                                  : selectedReceivedFrom1 === "4"
                                  ? "Select Received S.D.P"
                                  : selectedReceivedFrom1 === "5"
                                  ? "Select Received Outreach Staff Name"
                                  : selectedReceivedFrom1 === "6"
                                  ? "Select Received Partner Organization"
                                  : "Please select a valid option";
                              setProductReceivedFrom1Error(
                                productReceivedFrom1 ? "" : errorMessage
                              );
                            }}
                            errorMessage={productReceivedFrom1Error}
                          />
                        </>
                      )}

                    {selectedReceivedFrom1 === "4" && (
                      <AutoDropDown
                        title="Area Name"
                        options={bindAreaDD}
                        name="area"
                        classStar="text-red-500"
                        placeholder="Select"
                        Class="custom-class"
                        selectedOption={bindAreaDD.find(
                          (x) => x.value == inventoryReceiptFields.area
                        )}
                        controlFunc={(value, e) => {
                          handleInventoryReceiptFieldsChange(
                            value.value,
                            e.name
                          );
                        }}
                        controlFuncBlur={() => {
                          setInventoryReceiptFieldsError({
                            ...inventoryReceiptFieldsError,
                            areaError: inventoryReceiptFields.area
                              ? ""
                              : "Select Area Name",
                          });
                        }}
                        errorMessage={inventoryReceiptFieldsError.areaError}
                      />
                    )}
                    {selectedReceivedFrom1 &&
                      selectedReceivedFrom1 == selectedReceivedFrom && (
                        <InputText
                          inputType="text"
                          title="Received By"
                          classStar="text-red-500"
                          name="receivedBy"
                          placeholder="Received By"
                          content={inventoryReceiptFields.receivedBy}
                          controlFunc={(e) =>
                            setInventoryReceiptFields({
                              ...inventoryReceiptFields,
                              receivedBy: e.target.value,
                            })
                          }
                          controlFuncBlur={() => {
                            const trimmedValue =
                              inventoryReceiptFields.receivedBy?.trim(); // Remove leading/trailing spaces
                            setInventoryReceiptFieldsError(
                              (inventoryReceiptFieldsError) => ({
                                ...inventoryReceiptFieldsError,
                                receivedByError:
                                  trimmedValue.length > 0
                                    ? /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(
                                        trimmedValue
                                      )
                                      ? ""
                                      : "Only alphabets allowed"
                                    : "Enter Received By",
                              })
                            );

                            // Update the trimmed value in the state to remove any leading/trailing spaces
                            setInventoryReceiptFields({
                              ...inventoryReceiptFields,
                              receivedBy: trimmedValue,
                            });
                          }}
                          errorMessage={
                            inventoryReceiptFieldsError.receivedByError
                          }
                        />
                      )}
                  </div>
                  {/* <div class="lg:col-span-3 sm:col-span-1 mt-3">
                    <TextArea
                      inputType="text"
                      title="Remarks"
                      name="receiptsRemarks"
                      rows="4"
                      placeholder="Remarks"
                      content={inventoryReceiptFields.remarks}
                      controlFunc={(e) =>
                        setInventoryReceiptFields({
                          ...inventoryReceiptFields,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div> */}

                  <div className="">
                    <div className="overflow-y-none w-screen md:w-max hidden md:block">
                      <table className="w-screen border overflow-x-auto  overflow-y-none border-gray-300 mt-6">
                        <thead>
                          <tr>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40 ">
                              Commodities
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40 ">
                              Category
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40 ">
                              Name
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40 ">
                              Brand
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40">
                              Batch No.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              Date Of Expiry
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              Qty.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              U.O.M.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              Rate
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={commoditiesDD}
                                  placeholder="Select"
                                  name="commodities"
                                  Class="p-0"
                                  selectedOption={commoditiesDD.find(
                                    (x) => x.value == row.receiptsCommodities
                                  )}
                                  controlFunc={(e) => {
                                    row.receiptsCategory = "";
                                    row.receiptsName = "";
                                    row.receiptsBrand = "";
                                    handleInputChange(
                                      e,
                                      index,
                                      "receiptsCommodities"
                                    );
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={row.receiptsCategoryList || []}
                                  placeholder="Select"
                                  name="Category"
                                  Class="p-0"
                                  selectedOption={
                                    row.receiptsCategoryList &&
                                    row.receiptsCategory
                                      ? row.receiptsCategoryList.find(
                                          (x) =>
                                            x.value === row.receiptsCategory
                                        )
                                      : null
                                  }
                                  controlFunc={(e) => {
                                    row.receiptsName = "";
                                    row.receiptsBrand = "";
                                    handleInputChange(
                                      e,
                                      index,
                                      "receiptsCategory"
                                    );
                                  }}
                                />
                              </td>
                              <td
                                key={index}
                                className="border border-gray-300 p-2"
                              >
                                <AutoDropDown
                                  options={row.receiptsNameList || []}
                                  name="name"
                                  placeholder="Select"
                                  selectedOption={
                                    row.receiptsNameList && row.receiptsName
                                      ? row.receiptsNameList.find(
                                          (x) => x.value === row.receiptsName
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(e, index, "receiptsName");
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={row.receiptsBrandList || []}
                                  name="brand"
                                  placeholder="Select"
                                  selectedOption={
                                    row.receiptsBrandList && row.receiptsBrand
                                      ? row.receiptsBrandList.find(
                                          (x) => x.value === row.receiptsBrand
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "receiptsBrand"
                                    );
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.batchNo}
                                  maxLength={30}
                                  controlFunc={(e) => {
                                    if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "batchNo"
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <InputText
                                  inputType="date"
                                  content={row.dateOfExpiry}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "dateOfExpiry"
                                    );
                                  }}
                                  //min={new Date().toISOString().split("T")[0]}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <div
                                  title={`Min Stock: ${row.currentStock || 0}`}
                                >
                                  <InputText
                                    type="text"
                                    placeholder="100"
                                    content={row.receivedQuantity}
                                    maxLength={7}
                                    controlFunc={(e) => {
                                      // const InputText = e.target.value;
                                      if (/^[0-9]*$/.test(e.target.value)) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "receivedQuantity"
                                        );
                                      }
                                    }}
                                    controlFuncBlur={(e) => {
                                      const inputValue = e.target.value;

                                      // Validate the value when the user leaves the field
                                      if (
                                        inputValue !== "" &&
                                        parseFloat(inputValue) <
                                          (row.currentStock || 0)
                                      ) {
                                        handleInputChange(
                                          { value: "" },
                                          index,
                                          "receivedQuantity"
                                        ); // Clear the value if invalid
                                      }
                                    }}
                                    disabled={row.receivedQuantityVisible}
                                  />
                                </div>
                              </td>

                              <td className="border border-gray-300 p-2">
                                <InputText
                                  type="text"
                                  content={row.unitOfMeasure}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "unitOfMeasure"
                                    );
                                  }}
                                  disabled="disabled"
                                />
                              </td>

                              <td className="border border-gray-300 p-2">
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  maxLength={8}
                                  content={row.rate}
                                  controlFunc={(e) => {
                                    if (
                                      /^\d{0,5}(\.\d{0,2})?$/.test(
                                        e.target.value
                                      )
                                    ) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "rate"
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300">
                                <div className="flex gap-4 align-baseline items-center justify-center">
                                  <svg
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    class="w-6 h-6 text-black cursor-pointer"
                                    onClick={() => handleDeleteRow(index)}
                                  >
                                    <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                  </svg>
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="currentColor"
                                    onClick={handleAddRow}
                                    class="w-6 h-6 text-black cursor-pointer"
                                  >
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    {receiptError && (
                      <div className="text-red-500">{receiptError}</div>
                    )}
                  </div>

                  <div className={mainTable.tableMobile} role="table">
                    <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                      {tableData.length > 0 &&
                        tableData.map((row, index) => (
                          <div scope="col" class={mainTable.mobileTable}>
                            {headerData.map((keyData) => (
                              <div
                                scope="cell"
                                class={mainTable.tableData}
                                role="cell"
                              >
                                {keyData.key === "Action" ? (
                                  <div class={mainTable.tableData}>
                                    <div className="md:block hidden">
                                      <button
                                        id="dropdownMenuIconButton"
                                        data-dropdown-toggle="dropdownDots"
                                        class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                        type="button"
                                      >
                                        <svg
                                          class="w-6 h-6"
                                          aria-hidden="true"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                        </svg>
                                      </button>
                                    </div>
                                    <div className="md:hidden flex justify-center">
                                      <input
                                        type="button"
                                        className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                        onClick={handleAddRow}
                                        value="Add"
                                      />
                                      <input
                                        type="button"
                                        className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                        onClick={() => handleDeleteRow(index)}
                                        value="Delete"
                                      />
                                    </div>
                                  </div>
                                ) : keyData.key ===
                                  "commoditiesCategoryType" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={commoditiesDD}
                                      placeholder="Select"
                                      name="Category"
                                      Class="p-0"
                                      selectedOption={commoditiesDD.find(
                                        (x) =>
                                          x.value == row.receiptsCommodities
                                      )} // Use the entire object
                                      controlFunc={(e) => {
                                        row.receiptsCategory = "";
                                        row.receiptsName = "";
                                        row.receiptsBrand = "";
                                        handleInputChange(
                                          e,
                                          index,
                                          "receiptsCommodities"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "commoditiesCategory" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.receiptsCategoryList || []}
                                      placeholder="Select"
                                      name="Category"
                                      Class="p-0"
                                      selectedOption={
                                        row.receiptsCategoryList &&
                                        row.receiptsCategory
                                          ? row.receiptsCategoryList.find(
                                              (x) =>
                                                x.value === row.receiptsCategory
                                            )
                                          : null
                                      }
                                      controlFunc={(e) => {
                                        row.receiptsName = "";
                                        row.receiptsBrand = "";
                                        handleInputChange(
                                          e,
                                          index,
                                          "receiptsCategory"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "commoditiesName" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.receiptsNameList || []}
                                      name="name"
                                      placeholder="Select"
                                      selectedOption={
                                        row.receiptsNameList && row.receiptsName
                                          ? row.receiptsNameList.find(
                                              (x) =>
                                                x.value === row.receiptsName
                                            )
                                          : null
                                      }
                                      Class="p-0"
                                      controlFunc={(e) => {
                                        row.receiptsBrand = "";
                                        handleInputChange(
                                          e,
                                          index,
                                          "receiptsName"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "commoditiesBrand" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.receiptsBrandList || []}
                                      name="brand"
                                      placeholder="Select"
                                      selectedOption={
                                        row.receiptsBrandList &&
                                        row.receiptsBrand
                                          ? row.receiptsBrandList.find(
                                              (x) =>
                                                x.value === row.receiptsBrand
                                            )
                                          : null
                                      }
                                      Class="p-0"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "receiptsBrand"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "commoditiesQty" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <div
                                      title={`Min Stock: ${
                                        row.currentStock || 0
                                      }`}
                                    >
                                      <InputText
                                        type="text"
                                        placeholder="100"
                                        content={row.receivedQuantity}
                                        maxLength={7}
                                        controlFunc={(e) => {
                                          // const InputText = e.target.value;
                                          if (/^[0-9]*$/.test(e.target.value)) {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "receivedQuantity"
                                            );
                                          }
                                        }}
                                        controlFuncBlur={(e) => {
                                          const inputValue = e.target.value;

                                          // Validate the value when the user leaves the field
                                          if (
                                            inputValue !== "" &&
                                            parseFloat(inputValue) <
                                              (row.currentStock || 0)
                                          ) {
                                            handleInputChange(
                                              { value: "" },
                                              index,
                                              "receivedQuantity"
                                            ); // Clear the value if invalid
                                          }
                                        }}
                                        disabled={row.receivedQuantityVisible}
                                      />
                                    </div>
                                  </div>
                                ) : keyData.key === "commoditiesUOM" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.unitOfMeasure}
                                      disabled="disabled"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "unitOfMeasure"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "BatchNumber" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.batchNo}
                                      maxLength={30}
                                      controlFunc={(e) => {
                                        if (
                                          /^[a-zA-Z0-9]*$/.test(e.target.value)
                                        ) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "batchNo"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "DateOfExpiry" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      inputType="date"
                                      content={row.dateOfExpiry}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "dateOfExpiry"
                                        );
                                      }}
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                  </div>
                                ) : keyData.key === "Rate" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.rate}
                                      maxLength={8}
                                      controlFunc={(e) => {
                                        if (
                                          /^\d{0,5}(\.\d{0,2})?$/.test(
                                            e.target.value
                                          )
                                        ) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "rate"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex border p-1">
                                    <div className="flex basis-1/2 font-bold">
                                      {keyData.title}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="md:hidden">
                    {receiptError && (
                      <div className="text-red-500">{receiptError}</div>
                    )}
                  </div>
                  <div class="lg:col-span-3 sm:col-span-1 mt-3">
                    <TextArea
                      inputType="text"
                      title="Remarks"
                      name="receiptsRemarks"
                      rows="4"
                      maxLength={500}
                      placeholder="Remarks"
                      content={inventoryReceiptFields.remarks}
                      controlFunc={(e) =>
                        setInventoryReceiptFields({
                          ...inventoryReceiptFields,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      Receipt No. :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {inventoryReceiptFields.receiptNo}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleInventoryReceipts}
      >
        <div>
          <PopUpScreenTitle
            title="Inventory Receipt"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <InventoryReceiptsPopup
            data={currentData}
            data1={popupdata}
            data2={currentData3}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default InventoryReceipts;
