import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import { ClientProfileMEReportHeader } from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import ReportRowRowPage from "../components/ReportGridRowPage";
import { useUserCapability } from "../components/ConstantReducerProvider";

const ClientProfileMEReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindClientProfileMEData, setBindClientProfileMEData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(
    () => {
      if (branchProject) {
        try {
          const getSelectedSDP = async () => {
            const locationResponse = await BindAPI("SDPAPI/GetSDP");
            setGetSDPDatas(locationResponse.data.Table);

            if (userProfileDatas && userProfileDatas[0]) {
              const sdpLabels = locationResponse.data.Table.filter((item) =>
                branchProject.includes(parseInt(item.MstLocationId))
              ).map((item) => ({
                MstSDPId: item.MstSDPId,
                SDP_en: item.SDP_en,
              }));

              const sdpSelected = sdpLabels.map((SD) => ({
                value: SD.MstSDPId,
                label: SD.SDP_en,
              }));

              if (
                userProfileDatas[0].MstOrganizationId == 1 ||
                userProfileDatas[0].MstOrganizationId == 2
              ) {
                setBindSDP(sdpSelected);
              } else {
                const allSDPIds = userProfileDatas[0]?.AllSDP;
                const sdpId = userProfileDatas[0]?.MstSDPId;

                if (allSDPIds && Array.isArray(allSDPIds)) {
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    allSDPIds.includes(sdp.value)
                  );
                  setBindSDP(filteredSdpSelected);
                }
                if (sdpId) {
                  console.warn("allSDPIds is undefined or not an array");
                  setBindSDP([]);
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    Array.isArray(sdpId)
                      ? sdpId.includes(sdp.value)
                      : sdpId === sdp.value
                  );
                  setBindSDP(filteredSdpSelected);
                }
              }
            }
          };

          getSelectedSDP();
        } catch (e) {
          console.log(`error fetch data ${e}`);
        }
      }
    },
    [branchProject ? branchProject : undefined],
    userProfileDatas ? userProfileDatas : undefined
  );
  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);

    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "ClientAPI/ReportClientProfileME"
      );

      console.log("response", response.data.Table);
      setBindClientProfileMEData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);
  // const handleExport = () => {
  //   // Prepare headers
  //   const headers = ["Marginalized And Excluded Clients", "No."];

  //   // Prepare rows
  //   const transformedData = bindClientProfileMEData.flatMap(
  //     (rowData, index) => [
  //       {
  //         "Marginalized And Excluded Clients": "Person living with disability",
  //         "No.": "",
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Visual",
  //         "No.": rowData.CountMstClientIdDisability1.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Locomotor",
  //         "No.": rowData.CountMstClientIdDisability2.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Hearing",
  //         "No.": rowData.CountMstClientIdDisability3.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Speech",
  //         "No.": rowData.CountMstClientIdDisability4.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Intellectual",
  //         "No.": rowData.CountMstClientIdDisability5.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Multiple disability",
  //         "No.": rowData.CountMstClientIdDisability6.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Not applicable",
  //         "No.": rowData.CountMstClientIdDisability7.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients":
  //           "Person living with disability (Total exclusive)",
  //         "No.": rowData.CountMstClientIdMEClientsId1.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "BPL cardholder (Yes)",
  //         "No.": rowData.TotalClientBPLCardholder.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "SC,ST,DT,NT",
  //         "No.": rowData.CountMstClientIdMEClientsId2.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Adolescent (10-19)",
  //         "No.": rowData.TotalClientAge10to19.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Young population (15-24)",
  //         "No.": rowData.TotalClientAge15to24.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Sex worker",
  //         "No.": rowData.CountMstClientIdMEClientsId3.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "High risk Migrants",
  //         "No.": rowData.CountMstClientIdMEClientsId4.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "IDU's",
  //         "No.": rowData.CountMstClientIdMEClientsId5.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "PLHIV",
  //         "No.": rowData.CountMstClientIdMEClientsId6.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "NTransman",
  //         "No.": rowData.CountMstClientIdMEClientsId7.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Transwoman",
  //         "No.": rowData.CountMstClientIdMEClientsId8.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Transgender",
  //         "No.": rowData.CountMstClientIdMEClientsId9.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Lesbian",
  //         "No.": rowData.CountMstClientIdMEClientsId10.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Gay",
  //         "No.": rowData.CountMstClientIdMEClientsId11.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Bisexual",
  //         "No.": rowData.CountMstClientIdMEClientsId12.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Intersex",
  //         "No.": rowData.CountMstClientIdMEClientsId13.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Closed settings",
  //         "No.": rowData.CountMstClientIdMEClientsId14.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients": "Vulnerable Woman",
  //         "No.": rowData.CountMstClientIdMEClientsId15.toString(),
  //       },
  //       {
  //         "Marginalized And Excluded Clients":
  //           "Total Marginalized and Excluded",
  //         "No.": rowData.TotalClient.toString(),
  //       },
  //     ]
  //   );

  //   // Call export function with headers and rows
  //   exportData(headers, transformedData);
  // };

  const exportData = (headers, rows) => {
    const wsData = [headers, ...rows.map(Object.values)];

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const borderStyle = {
      border: {
        top: { style: "thin", color: { rgb: "00000000" } },
        bottom: { style: "thin", color: { rgb: "00000000" } },
        left: { style: "thin", color: { rgb: "00000000" } },
        right: { style: "thin", color: { rgb: "00000000" } },
      },
    };

    headers.forEach((_, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
      ws[cellAddress].s = {
        ...ws[cellAddress].s,
        ...borderStyle,
        fill: { fgColor: { rgb: "2D2D6E" } }, // Dark blue background color for header
        font: { bold: true, color: { rgb: "FFFFFF" } }, // White font color for header
      };
    });

    rows.forEach((_, rowIndex) => {
      headers.forEach((_, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({
          r: rowIndex + 1,
          c: colIndex,
        });
        ws[cellAddress].s = { ...ws[cellAddress].s, ...borderStyle };
      });
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "Profile_ME.xlsx");
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };

    // Define headers with an empty column for spacing
    const headers = [
      "Branch",
      "SDP",
      "Area",
      "Marginalized and Excluded",
      "Clients No.",
    ];

    // Define the title
    const title = [["Profile ME"]];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedOptionsData.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedOptionsData.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedOptionsData.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    // Prepare rows
    const transformedData = bindClientProfileMEData.flatMap(
      (rowData, index) => {
        const branch = rowData.BranchName; // Assuming each row contains a "BranchName" field
        const SDP = rowData.SDP; // Assuming each row contains a "BranchName" field
        const Area = rowData.Area; // Assuming each row contains a "BranchName" field

        return [
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Person living with disability",
            "Clients No.": "",
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Visual",
            "Clients No.": rowData.CountMstClientIdDisability1.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Locomotor",
            "Clients No.": rowData.CountMstClientIdDisability2.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Hearing",
            "Clients No.": rowData.CountMstClientIdDisability3.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Speech",
            "Clients No.": rowData.CountMstClientIdDisability4.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Intellectual",
            "Clients No.": rowData.CountMstClientIdDisability5.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Multiple disability",
            "Clients No.": rowData.CountMstClientIdDisability6.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Not applicable",
            "Clients No.": rowData.CountMstClientIdDisability7.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded":
              "Person living with disability (Total exclusive)",
            "Clients No.": rowData.CountMstClientIdMEClientsId1.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "BPL cardholder (Yes)",
            "Clients No.": rowData.TotalClientBPLCardholder.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "SC,ST,DT,NT",
            "Clients No.": rowData.CountMstClientIdMEClientsId2.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Adolescent (10-19)",
            "Clients No.": rowData.TotalClientAge10to19.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Young population (15-24)",
            "Clients No.": rowData.TotalClientAge15to24.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Sex worker",
            "Clients No.": rowData.CountMstClientIdMEClientsId3.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "High risk Migrants",
            "Clients No.": rowData.CountMstClientIdMEClientsId4.toString(),
          },
          {
            Branch: branch,
            "Marginalized and Excluded": "IDU's",
            "Clients No.": rowData.CountMstClientIdMEClientsId5.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "PLHIV",
            "Clients No.": rowData.CountMstClientIdMEClientsId6.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "NTransman",
            "Clients No.": rowData.CountMstClientIdMEClientsId7.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Transwoman",
            "Clients No.": rowData.CountMstClientIdMEClientsId8.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Transgender",
            "Clients No.": rowData.CountMstClientIdMEClientsId9.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Lesbian",
            "Clients No.": rowData.CountMstClientIdMEClientsId10.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Gay",
            "Clients No.": rowData.CountMstClientIdMEClientsId11.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Bisexual",
            "Clients No.": rowData.CountMstClientIdMEClientsId12.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Intersex",
            "Clients No.": rowData.CountMstClientIdMEClientsId13.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Closed settings",
            "Clients No.": rowData.CountMstClientIdMEClientsId14.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Vulnerable Woman",
            "Clients No.": rowData.CountMstClientIdMEClientsId15.toString(),
          },
          {
            Branch: branch,
            SDP: SDP,
            Area: Area,
            "Marginalized and Excluded": "Total Marginalized and Excluded",
            "Clients No.": rowData.TotalClient.toString(),
          },
        ];
      }
    );

    // Calculate column totals
    const columnTotal = transformedData.reduce(
      (total, row) => total + parseInt(row["Clients No."] || 0, 10),
      0
    );
    transformedData.push({
      Branch: "Total",
      "Marginalized and Excluded": "Total",
      "Clients No.": columnTotal.toString(),
    });

    // Prepare worksheet data with title, headers, and transformed data
    // const wsData = [
    //   ...title,
    //   [],
    //   headers,
    //   ...transformedData.map(Object.values),
    // ];

    const wsData = [
      ...title,
      dateRangeRow,
      branchRow,
      spacingRow,
      headers,
      ...transformedData.map(Object.values),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    headers.forEach((_, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 4, c: colIndex }); // Assuming row index 4 for headers
      if (!ws[cellAddress]) return;
      ws[cellAddress].s = {
        font: { bold: true, color: { rgb: "FFFFFF" } }, // White font
        fill: { fgColor: { rgb: "2D2D6E" } }, // Dark background color
        alignment: { horizontal: "center", vertical: "center" },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
    });
    // // Styling
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "000000" } },
    //     bottom: { style: "thin", color: { rgb: "000000" } },
    //     left: { style: "thin", color: { rgb: "000000" } },
    //     right: { style: "thin", color: { rgb: "000000" } },
    //   },
    // };

    // // Apply header styling
    // headers.forEach((_, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 2, c: colIndex });
    //   ws[cellAddress].s = {
    //     fill: { fgColor: { rgb: "2D2D6E" } },
    //     font: { bold: true, color: { rgb: "FFFFFF" } },
    //     ...borderStyle,
    //   };
    // });

    // Apply title styling
    // ws["A1"].s = {
    //   font: { bold: true, color: { rgb: "000000" }, sz: 14 },
    //   alignment: { horizontal: "center" },
    // };
    // ws["A1"].v = "Profile ME"; // Set title text
    // ws["A1"].merge = { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length - 1 } };
    // ws["!cols"] = [
    //   { width: 20 }, // Index
    //   { width: 38 }, // Dataname
    //   { width: 10 }, // Constant width
    // ];
    // // Apply data cell styling
    // transformedData.forEach((_, rowIndex) => {
    //   headers.forEach((_, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 3,
    //       c: colIndex,
    //     });
    //     ws[cellAddress].s = { ...borderStyle };
    //   });
    // });

    ws["!rows"] = [
      { hpt: 25 }, // Title
      { hpt: 30 }, // Date range
      { hpt: 50 }, // Branch information
      { hpt: 15 }, // Empty row
      { hpt: 30 }, // Headers
    ];

    ws["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 50 },
      { width: 15 },
    ];

    // Apply borders and styles
    const applyBorders = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyBorders(ws);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Profile ME");

    XLSX.writeFile(wb, "Profile ME.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF("portrait"); // Use landscape orientation for more horizontal space
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Define your headers based on the format you want
    const headers = ["Branch", "Marginalized and Excluded", "Clients No."];

    // Prepare the data in the format compatible with autoTable
    const data = bindClientProfileMEData.flatMap((rowData, index) => {
      // Extracting the branch name (assuming you have it in your data)
      const branchName = rowData.BranchName || "Unknown Branch";
      return [
        ["Person living with disability", ""],
        ["Visual", rowData.CountMstClientIdDisability1.toString()],
        ["Locomotor", rowData.CountMstClientIdDisability2.toString()],
        ["Hearing", rowData.CountMstClientIdDisability3.toString()],
        ["Speech", rowData.CountMstClientIdDisability4.toString()],
        ["Intellectual", rowData.CountMstClientIdDisability5.toString()],
        ["Multiple disability", rowData.CountMstClientIdDisability6.toString()],
        ["Not applicable", rowData.CountMstClientIdDisability7.toString()],
        [
          "Person living with disability (Total exclusive)",
          rowData.CountMstClientIdMEClientsId1.toString(),
        ],
        ["BPL cardholder (Yes)", rowData.TotalClientBPLCardholder.toString()],
        ["SC,ST,DT,NT", rowData.CountMstClientIdMEClientsId2.toString()],
        ["Adolescent (10-19)", rowData.TotalClientAge10to19.toString()],
        ["Young population (15-24)", rowData.TotalClientAge15to24.toString()],
        ["Sex worker", rowData.CountMstClientIdMEClientsId3.toString()],
        ["High risk Migrants", rowData.CountMstClientIdMEClientsId4.toString()],
        ["IDU's", rowData.CountMstClientIdMEClientsId5.toString()],
        ["PLHIV", rowData.CountMstClientIdMEClientsId6.toString()],
        ["NTransman", rowData.CountMstClientIdMEClientsId7.toString()],
        ["Transwoman", rowData.CountMstClientIdMEClientsId8.toString()],
        ["Transgender", rowData.CountMstClientIdMEClientsId9.toString()],
        ["Lesbian", rowData.CountMstClientIdMEClientsId10.toString()],
        ["Gay", rowData.CountMstClientIdMEClientsId11.toString()],
        ["Bisexual", rowData.CountMstClientIdMEClientsId12.toString()],
        ["Intersex", rowData.CountMstClientIdMEClientsId13.toString()],
        ["Closed settings", rowData.CountMstClientIdMEClientsId14.toString()],
        ["Vulnerable Woman", rowData.CountMstClientIdMEClientsId15.toString()],
        ["Total Marginalized and Excluded", rowData.TotalClient.toString()],
      ].map((item) => [branchName, ...item]);
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 10, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "left", // Align text to left
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
    });

    doc.save("Profile ME.pdf");
  };

  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setShowTable(false);
    setSDP(updatedOptions);
    setBindAreaDD([]);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setArea("");
    setShowTable(false);
    setBindSDP([]);
    setBindAreaDD([]);
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    setArea("");
    setShowTable(false);
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Profile ME`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="schoolName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>

              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => {setFromDate(e.target.value);setShowTable(false);}}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => {setToDate(e.target.value);setShowTable(false);}}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindClientProfileMEData.length > 0 ? (
            <div>
              <ReportRowRowPage
                title=""
                header={ClientProfileMEReportHeader}
                records={bindClientProfileMEData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
                isGenderBased={true}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ClientProfileMEReport;
