// Code review - In Progress
// Documentation - In Progress

const countryMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Country",
    key: "CountryName",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Country Code",
    key: "CountryCode",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 m-auto",
  },
];
const stateMasterHeader = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-14 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "State Code",
    key: "StateCode",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];
const areaMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-14 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-24 lg:w-20 xl:w-36 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-32 lg:w-28 xl:w-40 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width: "md:w-28 lg:w-28 xl:w-36 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "S.D.P.",
    key: "SDP_en",
    width: "md:w-24 lg:w-24 xl:w-40 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-28 lg:w-28 xl:w-36 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Area Code",
    key: "AreaCode",
    width: "md:w-20 lg:w-20 xl:w-36 2xl:m-auto px-1 py-1.5 lg:mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];
const sdpMasterHeader = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-14 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-56 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "S.D.P.",
    key: "SDP_en",
    width: "md:w-72 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "S.D.P. Code",
    key: "SDPCode",
    width: "md:w-52 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];
const locationMasterHeader = [
  {
    title: "S.No.",
    key: "LocationSno",
    width: "md:w-14 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-52 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Branch/Project Code",
    key: "LocationCode",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];
const languageMasterHeader = [
  {
    title: "S.No.",
    key: "languageSno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Language",
    key: "Language",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Language Code",
    key: "LanguageKey",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const servicesCategoryHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-14 2xl:w-72 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Category",
    key: "ServicesCategory_en",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const servicesSubCategoryHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Category",
    key: "ServicesCategory_en",
    width: "flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Sub-Category",
    key: "ServicesSubCategory_en",
    width: "flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 m-auto",
  },
];

const servicesMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Category",
    key: "ServicesCategory_en",
    width: "md:w-28 lg:w-48 2xl:w-48 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Sub-Category",
    key: "ServicesSubCategory_en",
    width: "md:w-44 lg:w-56 2xl:w-48 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-44 lg:w-56 2xl:w-48 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
    width1: "text-red-500",
  },
];

const userHeaders = [
  {
    title: "S.No.",
    width: "md:w-10 lg:w-10 xl:w-12 2xl:w-14 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  // {
  //   title: "Photo",
  //   key: "UserProfileImg",
  //   width: "md:w-20 lg:w-20 xl:w-20 2xl:w-36 px-1 py-1.5 ",
  //   width1: "text-red-500",
  // },
  {
    title: "User Name",
    key: "UserName",
    width: "md:w-24 lg:w-24 xl:w-40 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Email",
    key: "UserEmail",
    width: "md:w-32 lg:w-36 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Mobile",
    key: "UserMobile",
    width: "md:w-28 lg:w-24 xl:w-36 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Designation",
    key: "Designation",
    width: "md:w-28 lg:w-24 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Role",
    key: "RoleName",
    width: "md:w-28 lg:w-24 xl:w-36 2xl:w-96 px-1 py-1.5 mr-auto ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5  ",
  },
];

const clientHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12  lg:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Id & (Ex.)",
    key: "ClientId",
    width: "md:w-36 lg:w-36 xl:w-64  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Name",
    key: "ClientName_en",
    width: "md:w-32 lg:w-24 xl:w-40  px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Mother's Name",
    key: "ClientMotherName_en",
    width: "md:w-32 whitespace-normal break-words 2xl:w-72 lg:w-44 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Gender / Age",
    key: "ClientGenderAge_en",
    width: "md:w-32 2xl:w-44 lg:w-32  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Phone",
    key: "GridClientPhoneNo",
    width: "md:w-32 lg:w-36 2xl:w-52  px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action1",
    width: " px-1 py-1.5",
  },
];
const roleMasterHeader = [
  {
    title: "S.No.",
    key: "MstRoleId",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Role / Group",
    key: "RoleName",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Description",
    key: "RoleDiscription",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const campsMeetingsMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Type",
    key: "camps",
    width: "md:w-20 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Title",
    key: "campsTitle",
    width:
      "md:w-60  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "From",
    key: "campsFrom",
    width: "md:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "To",
    key: "campsTo",
    width: "md:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Participant School",
    key: "school",
    width:
      "md:w-72 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Address",
    key: "address",
    width: "md:w-24 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1 px-1 py-1.5 ",
  },
];
const healthCareHeaders = [
  {
    title: "S.No.",
    key: "healthcareAutoUserId",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-24 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Photo",
    key: "HealthCareProviderPhoto",
    width: "md:w-20 lg:w-20  xl:w-28 2xl:28 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "H.C.P. Name",
    key: "HealthcareProviderName_en",
    width: "md:w-28 lg:w-24  xl:w-44 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: " H.C.P. Type",
    key: "Speciality_en",
    width: "md:w-24 lg:w-24  xl:w-36 2xl:m-auto px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: " Email",
    key: "HealthcareProviderEmail",
    width: "md:w-32 lg:w-28  xl:w-52 2xl:m-auto  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: " Mobile",
    key: "HealthcareProviderPhoneNo",
    width: "md:w-28 lg:w-24  xl:w-44 2xl:m-auto px-1 py-1.5  ",
    width1: "text-red-500",
  },

  {
    title: " Address",
    key: "HealthCareProviderAddress_en",
    width: "md:w-24 lg:w-28  xl:w-44 2xl:m-auto px-1 py-1.5  mr-auto",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action",
    width: "   px-1 py-1.5 ",
  },
];
const inventoryProductHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  ",

    width1: "text-red-500",
  },
  // {
  //   title: "Image",
  //   key: "ChooseFile",
  //   width: "md:w-18 2xl:w-12 px-1 py-1.5  ",
  //   width1: "text-red-400 ",
  // },
  {
    title: "Commodity Category",
    key: "GCategoriesType",
    width: "md:w-56 lg:w-60 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Commodity Name",
    key: "CommodityName_en",
    width: "md:w-44 lg:w-60 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Brand",
    key: "CommodityBrand_en",
    width:
      "md:w-32 lg:w-60 whitespace-normal break-words 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },

  {
    title: "Description",
    key: "Description_en",
    width:
      "md:w-44 lg:w-60 whitespace-normal break-words 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "U.O.M",
    key: "GUOM",
    width: "md:w-20 2xl:w-40 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];

const schoolMasterHeaders = [
  {
    title: "S.No.",
    key: "MstSchoolId",
    width: "md:w-10 lg:w-12 xl:w-12 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "School / College Id",
    key: "SchoolId",
    width:
      "md:w-28 md:whitespace-pre-line whitespace-normal break-words lg:w-28 xl:w-40 2xl:m-auto  px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Name Of The School / College",
    key: "SchoolName",
    width:
      "md:w-44 md:whitespace-pre-line lg:w-44 xl:w-56 2xl:m-auto px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Contact Person Name",
    key: "SchoolContactPerson",
    width:
      "md:w-28 md:whitespace-pre-line lg:w-28 xl:w-40 2xl:m-auto  px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Phone Number",
    key: "SchoolPhoneNumber",
    width:
      "md:w-24 md:whitespace-pre-line lg:w-24 xl:w-32 2xl:m-auto  px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Email Id",
    key: "SchoolEmail",
    width: "md:w-32 lg:w-24 xl:w-44 2xl:m-auto  px-1 py-1.5 mr-auto ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];
const youthGroupMasterHeaders = [
  {
    title: "S.No.",
    key: "MstYouthGroupId",
    width: "md:w-10 lg:w-10 xl:w-12 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Youth GroupId",
    key: "YouthGroupId",
    width:
      "md:w-40 lg:w-40 whitespace-pre-line xl:w-40 2xl:m-auto px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Youth Group Name",
    key: "YouthGroupName",
    width:
      "md:w-40 lg:w-40 whitespace-pre-line xl:w-56 2xl:m-auto px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Youth Volunteer Name",
    key: "YouthVolunteerName",
    width:
      "md:w-36 lg:w-36 whitespace-pre-line xl:w-40 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Mobile Number",
    key: "YouthGroupPhoneNumber",
    width: "md:w-28 lg:w-28 xl:w-32 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Email Id",
    key: "YouthGroupEmail",
    width: "md:w-28 lg:w-28 xl:w-44 2xl:m-auto px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5",
  },
];
const studentMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },

  {
    title: "School Name",
    key: "SchoolName",
    width:
      "md:w-60 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Student Name",
    key: "StudentName",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Student ID",
    key: "StudentId",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Class",
    key: "StudentClass",
    width: "md:w-24 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Division",
    key: "StudentDivision",
    width: "md:w-24 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Gender / Age",
    key: "GenderAge",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const appointmentHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Client Name",
    key: "appointmentClient",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Service",
    key: "appointmentService",
    width: "md:w-44 2xl:flex-1 whitespace-normal break-words px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "appointmentDate",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Time",
    key: "appointmentTime",
    width: "md:w-24 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Appointment Type",
    key: "appointmentType",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "HCP Name",
    key: "appointmentHCP",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 ",
  },
];
// const inventoryIssueHeaders = [
//   {
//     title: "S.No.",
//     key: "sno",
//     width: "md:w-10 lg:w-10 xl:w-12 2xl:m-auto px-1 py-1.5  ",
//     width1: " text-red-500  ",
//   },
//   {
//     title: "Category",
//     key: "CommodityCategoryName",
//     width: "md:w-28 lg:w-28 xl:w-44 2xl:m-auto px-1 py-1.5 ",
//     width1: "text-red-500 ",
//   },
//   {
//     title: "Name",
//     key: "CommodityName_en",
//     width: "md:w-40 lg:w-56 xl:w-64 2xl:m-auto px-1 py-1.5 ",
//     width1: "text-red-500 ",
//   },
//   // {
//   //   title: "Brand",
//   //   key: "Brand",
//   //   width: "md:w-24 lg:w-24  xl:w-40 2xl:m-auto px-1 py-1.5 ",
//   //   width1: "text-red-500 ",
//   // },
//   {
//     title: "Issue To",
//     key: "IssueTo",
//     width: "md:w-20 lg:w-20 xl:w-40 2xl:m-auto px-1 py-1.5 ",
//     width1: "text-red-500 ",
//   },

//   {
//     title: "Receiver",
//     key: "Receiver",
//     width: "md:w-20 lg:w-20 xl:w-40 2xl:m-auto px-1 py-1.5 ",
//     width1: "text-red-500 ",
//   },
//   {
//     title: "Quantity",
//     key: "IndentPendingQuantity",
//     width: "md:w-20 lg:w-20 xl:w-32 2xl:m-auto px-1 py-1.5 ",
//     width1: "text-red-500 ",
//   },
//   {
//     title: "UOM",
//     key: "IndentUOM",
//     width: "md:w-20 lg:w-20 xl:w-20 2xl:m-auto px-1 py-1.5 lg:mr-auto",
//     width1: "text-red-500 ",
//   },

//   {
//     title: "Action",
//     key: "action",
//     width: " 2xl:m-auto  px-1 py-1.5 ",
//   },
// ];
const eventMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Event Number",
    key: "EventNumber",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-400",
    width1: "text-red-500",
  },
  // {
  //   title: "Resource Name",
  //   key: "EventResourcePersonName",
  //   width: "md:w-44 whitespace-pre-line 2xl:flex-1 px-1 py-1.5 m-auto",
  //   //width1: "md:w-44 text-red-600  2xl:flex-1 px-1 py-1.5  ",
  //   filterInputBox: "block text-sm text-red-400 mt-2 w-28 h-6",
  //   width1: "text-red-400",
  // },
  // {
  //   title: "Event Topics / Subjects",
  //   key: "EventTopic",
  //   width: "md:w-48 2xl:flex-1 px-1 py-1.5 m-auto",
  //   //width1: "md:w-44 text-red-600 2xl:flex-1 px-1 py-1.5  ",
  //   filterInputBox: "block text-sm text-red-400 mt-2 w-28 h-6",
  //   width1: "text-red-400",
  // },
  {
    title: "Session Type",
    key: "GEventType",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto ",
    //width1: "md:w-32 text-red-600 2xl:flex-1 px-1 py-1.5  ",
    filterInputBox: "block text-sm text-red-400 mt-2 w-28 h-6",
    width1: "text-red-500",
  },
  {
    title: "School ID",
    key: "GEventSchoolId",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto",
    //width1: "md:w-32 text-red-600 2xl:flex-1 px-1 py-1.5  ",
    filterInputBox: "block text-sm text-red-400 mt-2 w-28 h-6",
    width1: "text-red-500",
  },
  {
    title: "Session Date",
    key: "EventDate",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto",
    //width1: "md:w-32 text-red-600 2xl:flex-1 px-1 py-1.5  ",
    filterInputBox: "block text-sm text-red-400 mt-2 w-28 h-6",
    width1: "text-red-500",
  },
  {
    title: "From",
    key: "EventFrom",
    width: "md:w-28 2xl:flex-1 px-1 py-1.5 m-auto",
    //width1: "md:w-28 text-red-400 2xl:flex-1 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "To",
    key: "EventTo",
    width: "md:w-28 2xl:flex-1 px-1 py-1.5 m-auto",
    // width1: "md:w-28 text-red-400 2xl:flex-1 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Address",
    key: "EventAddress",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const EducationMaterialMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Material Title",
    key: "materialTitle",
    width:
      "md:w-44 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Material Subject",
    key: "materialSubject",
    width:
      "md:w-48 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Material Type",
    key: "materialType",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Remarks",
    key: "remarks",
    width: "md:w-40 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const cseInSchoolHeaders = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-10 xl:w-10 2xl:w-10 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "OldDate",
    width: "sm:w-20 md:w-20 lg:w-20 xl:20 2xl:w-20 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "School Name",
    key: "SchoolName",
    width: "sm:w-20 md:w-52 lg:w-52 xl:w-80 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Class",
    key: "GridTableClass",
    width: "md:w-12 xl:w-32 2xl:w-16 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Division",
    key: "Division",
    width: "md:w-16 xl:w-16 2xl:w-16 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Sessions",
    key: "Sessions",
    width: "md:w-16 lg:w-16 xl:w-16 2xl:w-16 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  // {
  //   title: "Remarks",
  //   key: "remarks",
  //   width: "md:w-36 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 mr-auto",
  //   width1:
  //     "md:w-32 whitespace-normal break-words text-red-600  px-1 py-1.5  ",
  // },
  {
    title: "Action",
    key: "schoolAction",
    width: " px-1 py-1.5",
  },
];
// const InSchoolStudentHeaders = [
//   {
//     title: "S.No.",
//     key: "sno",
//     width: "md:w-12 px-1 py-1.5",
//   },
//   {
//     title: "Student Name",
//     key: "StudentName",
//     width: "md:w-32 xl:w-44 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
//   },
//   {
//     title: "Student Id",
//     key: "StudentId",
//     width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
//   },
//   {
//     title: "Gender / Age",
//     key: "Gender_en",
//     width: "md:w-32 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
//   },
//   {
//     title: "Referral Service",
//     key: "ReferralServices",
//     width: "md:w-32 xl:w-48 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
//   },
//   {
//     title: "Referred To",
//     key: "ReferredTo_en",
//     width: "md:w-32 xl:w-44 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
//   },
//   {
//     title: "Modules",
//     key: "Topics",
//     width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
//   },
// ];
const inschoolsessionStudentHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 px-1 py-1.5",
  },
  {
    title: "Roll No.",
    key: "RollId",
    width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Student Name",
    key: "StudentName",
    width:
      "md:w-32 xl:w-44 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Last Session Date",
    key: "LastDate",
    width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Gender / Age",
    key: "GenderAge",
    width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Referral Service",
    key: "ReferralServices",
    width:
      "md:w-32 xl:w-48 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Referred To",
    key: "ReferredTo_en",
    width:
      "md:w-32 xl:w-44 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Modules",
    key: "Modules",
    width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
];
const outOfSchoolYouthHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 px-1 py-1.5",
  },
  {
    title: "Youth Name",
    key: "YouthName",
    width:
      "md:w-32 xl:w-36 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Youth Id",
    key: "YouthId",
    width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Last Session Date",
    key: "LastDate",
    width: "md:w-28  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Gender / Age",
    key: "GenderAge",
    width: "md:w-28 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Referral Service",
    key: "ReferralServices",
    width:
      "md:w-32 xl:w-40 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Referred To",
    key: "ReferredTo_en",
    width:
      "md:w-32 xl:w-40 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
  {
    title: "Modules",
    key: "Modules",
    width: "md:w-32  2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
  },
];
const cseOutSchoolHeaders = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-10 2xl:w-10 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "OldDate",
    width: "md:w-20 xl:20 2xl:w-20 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Youth Group Name",
    key: "YouthGroupName",
    width: "md:w-72 xl:w-96 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Sessions",
    key: "Sessions",
    width: "md:w-20 2xl:w-20 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "schoolAction",
    width: "px-1 py-1.5",
  },
];
const cseHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Type",
    key: "type",
    width: "md:w-20 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "School / Youth Group Name",
    key: "schoolName",
    width:
      "md:w-52  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Student / Youth Name",
    key: "studentName",
    width:
      "md:w-48 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Event Number",
    key: "eventNumber",
    width:
      "md:w-32 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Module",
    key: "module",
    width:
      "md:w-36 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Remarks",
    key: "remarks",
    width:
      "md:w-36 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const inventoryReceiptsHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-12  px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Receipt No.",
    key: "ReceiptNo",
    width: "md:w-28 lg:w-28 xl:w-44 2xl:flex-1 px-1 py-1.5 ",
  },
  {
    title: "Date",
    key: "FormattedReceiptDate",
    width: "md:w-28 lg:w-28 xl:w-40 2xl:flex-1 px-1 py-1.5 ",
  },
  {
    title: "From",
    key: "ReceivedFrom_en",
    width:
      "md:w-32 lg:w-32 xl:w-44 whitespace-pre-line 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Name",
    key: "FromName",
    width:
      "md:w-32 lg:w-32 xl:w-44 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Received By",
    key: "ReceiptReceivedBy_en",
    width: "md:w-28 lg:w-28 xl:w-40 2xl:flex-1 2xl:flex-1 px-1 py-1.5  mr-auto",
  },
  {
    title: "Action",
    key: "receiptAction",
    width: "md:w-28    px-1 py-1.5 ",
  },
];
const inventoryReceiptsViewHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-12 px-1 py-1.5 ",
  },
  {
    title: "Receipt No.",
    key: "ReceiptNo",
    width:
      "md:w-20 lg:w-20 xl:w-24 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Date",
    key: "FormattedReceiptDate",
    width:
      "md:w-20 lg:w-20 xl:w-24 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "BrandName",
    width:
      "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line  px-1 py-1.5 ",
  },
  {
    title: "Qty.",
    key: "ReceiptQuantity",
    width:
      "md:w-12 lg:w-12 xl:w-20 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "U.O.M.",
    key: "ReceiptUOM",
    width:
      "md:w-12 lg:w-12 xl:w-20 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Batch No.",
    key: "ReceiptBatchNo",
    width:
      "md:w-20 lg:w-20 xl:w-20 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Date Of Expiry",
    key: "FormattedReceiptDateOfExpiry1",
    width:
      "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5 mr-auto",
  },
  {
    title: "Rate",
    key: "ReceiptRate",
    width: "md:w-14 px-1 py-1.5 ",
  },
];
const SRHCommoditiesMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Category",
    key: "category",
    width: "md:w-40 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Service List",
    key: "serviceList",
    width:
      "md:w-44 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Commodities",
    key: "commodities",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Description",
    key: "description",
    width:
      "md:w-40 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Unit Of Measure",
    key: "unitOfMeasure",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const NonSRHProductsMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Commodities",
    key: "commodities",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Description",
    key: "description",
    width:
      "md:w-60 whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Unit Of Measure",
    key: "unitOfMeasure",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const spServiceSessionMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-32 lg:w-32 xl:w-52 2xl:w-52 whitespace-pre-line px-1 py-1.5 ",
    width1: "text-red-500",
  },

  {
    title: "Special Service Session Type",
    key: "SPServicesName_en",
    width: "md:w-48 lg:w-52 xl:w-80 whitespace-pre-line 2xl:w-80 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Client Type",
    key: "ClientType_en",
    width: "md:w-40 lg:w-52 xl:w-80 2xl:w-80 whitespace-pre-line px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Healthcare Provider Name",
    key: "HealthcareProviderName_en",
    width:
      "md:w-44 lg:w-52 xl:w-80 2xl:w-80 whitespace-pre-line px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];
const ServicesClientHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Reg. Dt.",
    key: "FormattedDate",
    width: "md:w-24 lg:w-24 whitespace-normal break-words px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Id & (Ex.)",
    key: "ClientId",
    width: "md:w-60 lg:w-60 whitespace-normal break-words 2xl:w-72 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Name",
    key: "ClientName_en",
    width: "md:w-36 lg:w-36 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Mother's Name",
    key: "ClientMotherName_en",
    width: "md:w-32 lg:w-36 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Gender / Age",
    key: "ClientGenderAge_en",
    width: "md:w-24 2xl:w-44 lg:w-24 whitespace-pre-line  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Phone",
    key: "GridClientPhoneNo",
    width: "md:w-28 lg:w-28 2xl:w-52  px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "ServicesClientAction",
    width: "w-20 px-1 py-1.5",
  },
];
const ServicesHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Service Prv. Dt.",
    key: "FormattedDate",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Id & Services Id",
    key: "ServicesId",
    width: "md:w-60 lg:w-60 xl:w-72 2xl:w-60 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Name",
    key: "ClientName_en",
    width: "md:w-52 lg:w-52 xl:w-72 2xl:w-96 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-500",
  },

  {
    title: "C.R.Supply Follow Up",
    key: "CRSFollowUp1",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Referral Follow Up",
    key: "ReferralFollowUp1",
    width:
      "md:w-24 lg:w-24 xl:w-24 2xl:w-24 whitespace-pre-line px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action1",
    width: " px-1 py-1.5 ",
  },
];
const ServicesAddHeader = [
  {
    title: "Services",
    key: "value",
    width: "md:w-60 2xl:w-96 truncate  px-1 py-1.5 m-auto",
  },

  {
    title: "Test Result",
    key: "test",
    width: "md:w-28 px-1 py-1.5 m-auto text-center",
  },
  {
    title: "PAC Services",
    key: "pacservices",
    width: "md:w-32 px-2 py-3 text-center m-auto",
  },
  {
    title: "Brand",
    key: "brand",
    width: "md:w-32 px-2 py-3 text-center m-auto",
  },
  {
    title: "Item",
    key: "qty",
    width: "md:w-20 px-1 py-1.5 text-center m-auto",
  },
  {
    title: "UOM",
    key: "uom",
    width: "md:w-20 px-2 py-3 m-auto text-center",
  },
  {
    title: "Charges",
    key: "servicesCharge",
    width: "md:w-24 px-2 py-3 text-center m-auto",
  },
  {
    title: "F.P. User",
    key: "FPUser",
    width: "md:w-32 px-2 py-3 text-center m-auto",
  },

  {
    title: "Referred To",
    key: "referral",
    width: "md:w-36 px-1 py-1.5 m-auto text-center",
  },
  {
    title: "Action",
    key: "ServicesAction",
    width: "px-1 py-1.5 text-center m-auto",
  },
];
const ServicesAddViewHeader = [
  {
    title: "Services",
    key: "value",
    width: "md:w-60 2xl:w-96 truncate  px-1 py-1.5 m-auto",
  },

  {
    title: "Test Result",
    key: "test",
    width: "md:w-28 px-1 py-1.5 m-auto text-center",
  },
  {
    title: "UOM",
    key: "uom",
    width: "md:w-16 px-1 py-1.5 m-auto text-center",
  },
  {
    title: "Item",
    key: "qty",
    width: "md:w-20 px-1 py-1.5 text-center m-auto",
  },
  {
    title: "PAC Services",
    key: "pacservices",
    width: "md:w-32 px-2 py-3 text-center m-auto",
  },
  {
    title: "Brand",
    key: "brand",
    width: "md:w-36 px-1 py-1.5 text-center m-auto",
  },
  {
    title: "Charges",
    key: "servicesCharge",
    width: "md:w-20 px-1 py-1.5 text-center m-auto",
  },
  {
    title: "F.P. User",
    key: "FPUser",
    width: "md:w-36 px-1 py-1.5 text-center m-auto",
  },
  {
    title: "Referred To",
    key: "referral",
    width: "md:w-36 px-1 py-1.5 m-auto text-center",
  },
];
const ServicesHeadersData = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 lg:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "FormattedDate",
    width: "md:w-24 2xl:w-24 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "S.D.P",
    key: "SDP_en",
    width: "md:w-40 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-96 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "detailsAction",
    width: "md:w-40 text-center 2xl:flex-1 px-1 py-1.5 ",
  },
];

const inventoryIndentHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "FormattedIndentDate",
    width:
      "md:w-32  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "From",
    key: "IndentFrom1_en",
    width:
      "md:w-32  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Name",
    key: "IndentFrom2_en",
    width:
      "md:w-40  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-1 py-1.5 m-auto",
  },
];
const ResourcesMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-20  px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Photo",
    key: "ResourcesPhoto",
    width: "md:w-44  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Resource Person Name",
    key: "ResourcesName",
    width: "md:w-72  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Position",
    key: "ResourcesPosition",
    width: "md:w-60  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width:
      "md:w-52  whitespace-normal break-words 2xl:flex-1 px-1 py-1.5 mr-auto ",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];

const studentDetailsHeaders = [
  {
    title: "S.No.",
    key: "no",
    width: "md:w-12 2xl:flex-1 px-1 py-1.5  m-auto",
  },
  {
    title: "Student Name",
    key: "StudentName",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Student ID",
    key: "StudentId",
    width: "md:w-24 2xl:flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Gender / Age",
    key: "StudentAge",
    width: "md:w-28 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Class",
    key: "StudentClass",
    width: "md:w-20 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Division",
    key: "StudentDivision",
    width: "md:w-20 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Referel Service",
    key: "ReferalService",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Refered to",
    key: "ReferedTo",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 m-auto",
  },
];
const BrochureMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 2xl:w-12 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Image",
    key: "NewVariable",
    key1: "BrochureType",
    width:
      "md:w-32 lg:w-28 xl:w-32 2xl:w-28 whitespace-normal break-words px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Brochure Name",
    key: "BrochureName",
    width: "whitespace-normal break-words flex-1 px-1 py-1.5 m-auto ",

    width1: "text-red-500",
  },
  {
    title: "Brochure Type",
    key: "BrochureTypes",
    width: "whitespace-normal break-words flex-1 px-1 py-1.5 m-auto ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "  px-1 py-1.5 m-auto",
  },
];
const AwarenessHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-20 2xl:w-12 px-1 py-1.5  ",
  },
  {
    title: "Date",
    key: "AwarenessDate",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Awareness Type",
    key: "AwarenessType",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  // {
  //   title: "Category",
  //   key: "AwarenessCategory",
  //   width: "md:w-40 2xl:flex-1 px-1 py-1.5 ",
  // },
  {
    title: "Topics",
    key: "AwarenessTopics",
    width: "md:w-52 whitespace-pre-line 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Remarks",
    key: "AwarenessRemarks",
    width: "md:w-96 whitespace-pre-line 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];
const YouthHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Youth Group Name",
    key: "YouthGroupName",
    width: "md:w-64 whitespace-pre-line 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Name",
    key: "YouthName",
    width: "md:w-36 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Youth ID",
    key: "YouthId",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Gender / Age",
    key: "GenderAge",
    width: "md:w-32 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Remarks",
    key: "YouthRemarks",
    width: "md:w-64  whitespace-pre-line 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];
const roleVsCapabilityHeader = [
  // {
  //   title: "S.No.",
  //   key: "sno",
  //   width: "md:w-20 2xl:w-12 px-1 py-1.5  ",
  // },
  {
    title: "Select All Menus",
    key: "MenuName",
    width: "md:w-48 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Rights",
    key: "Rights",
    width: "md:w-96 whitespace-pre-line 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-500",
  },
];

const eventRegisterHeaders = [
  {
    title: "S.No.",
    key: "TxnEventRegisterId",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-14 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Month",
    key: "MonthName",
    width: "md:w-32 2xl:m-auto  px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Year",
    key: "YearId",
    width: "md:w-28 lg:w-28 whitespace-break-spaces 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Teacher Training",
    key: "TeacherTraining",
    width:
      "md:w-40 2xl:w-40 lg:w-44  whitespace-break-spaces 2xl:m-auto px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "NGO/CBO Staff Training",
    key: "NGOCBOStaffTraining",
    width: "md:w-52 lg:w-60 whitespace-break-spaces 2xl:m-auto px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "PE Training",
    key: "PeerEducatorTrained",
    width:
      "md:w-40 lg:w-40 whitespace-break-spaces lg:w-60 2xl:w-96 2xl:m-auto  px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Peer-To-Peer Training",
    key: "PeerToPeerTraining",
    width:
      "md:w-52 lg:w-60 2xl:w-96 whitespace-break-spaces px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5",
  },
];

const InterPersonalContactHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-10 px-1 py-1.5  ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Locality / Street",
    key: "LocalityStreet_en",
    width: "md:w-40 lg:w-40 xl:w-44 2xl:w-52 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Topic",
    key: "IECTopic_en",
    width: "md:w-48 lg:w-48 xl:w-72 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Staff Name",
    key: "StaffName_en",
    width: "md:w-32 lg:w-32 xl:w-52 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];
const GroupDiscussionHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-10 px-1 py-1.5  ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-36 lg:w-40 xl:w-44 2xl:w-52 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Topic",
    key: "IECTopic_en",
    width: "md:w-44 lg:w-48 xl:w-72 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Staff Name",
    key: "StaffName_en",
    width: "md:w-32 lg:w-32 xl:w-52 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];

const GeneralMassMeetingHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-10 px-1 py-1.5  ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-36 lg:w-40 xl:w-44 2xl:w-52 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Topic",
    key: "IECTopic_en",
    width: "md:w-44 lg:w-48 xl:w-72 2xl:w-96 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Staff Name",
    key: "StaffName_en",
    width: "md:w-32 lg:w-32 xl:w-52 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];

const MassMediaHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-10 px-1 py-1.5  ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-40 lg:w-40 xl:w-52 2xl:w-52 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Topic",
    key: "IECTopic_en",
    width: "md:w-44 lg:w-60 xl:w-80 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];

const SpecialServiceSessionHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-14 lg:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Id",
    key: "clientId",
    width: "md:w-32 lg:w-32 xl:w-60 2xl:m-auto  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Client Name",
    key: "clientName",
    width: "md:w-32 lg:w-36 xl:w-60 2xl:m-auto  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Mother's Name",
    key: "clientMotherName",
    width: "md:w-32 lg:w-32 xl:w-60 2xl:m-auto  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Gender / Age",
    key: "clientGender",
    width: "md:w-32 lg:w-28 xl:w-32 2xl:m-auto  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Phone",
    key: "clientPhone",
    width: "md:w-32 lg:w-28 xl:w-48 2xl:m-auto  px-1 py-1.5",
    width1: "text-red-500",
  },
  // {
  //   title: "Address",
  //   key: "address",
  //   width: "md:w-20 whitespace-normal break-words lg:w-32 2xl:w-40 px-1 py-1.5",
  // },
  {
    title: "Action",
    key: "SplServiceAction",
    width: " px-1 py-1.5  ",
  },
];
const ConsumptionViewHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-12 px-1 py-1.5 ",
  },
  {
    title: "Consumption No.",
    key: "ConsumptionNo",
    width:
      "md:w-24 lg:w-24 xl:w-24 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Date",
    key: "FormattedConsumptionDate",
    width:
      "md:w-20 lg:w-20 xl:w-20 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-20 lg:w-24 xl:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width:
      "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line  px-1 py-1.5 ",
  },
  {
    title: "Demo.",
    key: "Demonstration",
    width:
      "md:w-12 lg:w-12 xl:w-20 2xl:flex-1 whitespace-pre-line px-1 py-1.5 ",
  },
  {
    title: "Damaged/Adjustments",
    key: "DamagedAdjustments",
    width:
      "md:w-12 lg:w-12 xl:w-24 2xl:flex-1  whitespace-pre-line break-words px-1 py-1.5 ",
  },
  {
    title: "Exp.",
    key: "Expired",
    width:
      "md:w-12 lg:w-12 xl:w-20 2xl:flex-1 whitespace-pre-line px-1 py-1.5 mr-auto",
  },
  // {
  //   title: "Assoc. Clinic",
  //   key: "GivenToAssociatedClinics",
  //   width:
  //     "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 whitespace-pre-line px-1 py-1.5 mr-auto",
  // },
  {
    title: "Total",
    key: "Total",
    width: "md:w-14 px-1 py-1.5 ",
  },
];
const ServicesName = [
  { value: "1", label: "Family Planning" },
  { value: "2", label: "Abortion" },
  { value: "3", label: "HIV/AIDS" },
  { value: "4", label: "STI/RTI" },
  { value: "5", label: "Gynaecology" },
  { value: "6", label: "ANC/PNC" },
  { value: "7", label: "Urology" },
  { value: "8", label: "Subfertility" },
  { value: "9", label: "Specialized SRH" },
  { value: "10", label: "Paediatrics" },
  { value: "11", label: "Other SRH" },
  { value: "12", label: "Non-SRH" },
];
const ReferedTo = [
  { value: "1", label: "Static Clinic" },
  { value: "2", label: "Satelite Clinic" },
  { value: "3", label: "Mobile clinic" },
  { value: "3", label: "Associated clinic" },
  { value: "4", label: "Private Facility" },
  { value: "5", label: "Govt. Facility" },
];
const referralService = [
  { value: "1", label: "Menstrual Health" },
  { value: "2", label: "Anaemia" },
  { value: "3", label: "Family Planning" },
  { value: "4", label: "Sexual orientation counseling" },
  { value: "5", label: "Relationship counseling" },
  { value: "6", label: "Gender Based Violence" },
  { value: "7", label: "Urology" },
  { value: "8", label: "STI/RTI" },
  { value: "9", label: "Other" },
];
const supplierMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-12 xl:w-12 2xl:w-24 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Logo",
    key: "SupplierLogo",
    width: "md:w-16 lg:w-16 xl:w-24 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Supplier Id",
    key: "SupplierId",
    width: "md:w-28 lg:w-24 xl:w-36 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Supplier Name",
    key: "SupplierName",
    width: "md:w-32 lg:w-32 xl:w-44 2xl:m-auto 2xl:w-60 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "City",
    key: "LocationName_en",
    width: "md:w-24 lg:w-24 xl:w-36 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Mobile",
    key: "SupplierMobileNumber",
    width: "md:w-24 lg:w-24 xl:w-36 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },

  {
    title: "Email",
    key: "SupplierEmailId",
    width: "md:w-32 lg:w-28 xl:w-60 2xl:m-auto 2xl:w-48 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];

const OutSchoolHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 px-1 py-1.5  ",
    width1: "text-red-500",
  },

  {
    title: "Youth Group Name",
    key: "schoolName",
    width:
      "md:w-52 xl:w-96 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Youth Name",
    key: "studentName",
    width: "md:w-60 whitespace-normal break-words  px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Session Number",
    key: "eventNumber",
    width: "md:w-44 2xl:w-22 whitespace-normal break-words  px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Module",
    key: "module",
    width: "md:w-36 2xl:w-64 whitespace-normal break-words  px-1 py-1.5 ",
    width1: "text-red-500",
  },
  // {
  //   title: "Remarks",
  //   key: "remarks",
  //   width: "md:w-36 2xl:w-72 whitespace-normal break-words  px-1 py-1.5 mr-auto",
  //   width1:
  //     "md:w-32 whitespace-normal break-words text-red-600  px-1 py-1.5  ",
  // },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5",
  },
];
const SingleSessionOnlineHeader = [
  {
    title: "S.No.",
    key: "txn_singlesessiononlineId",
    width: "md:w-12 2xl:w-12 px-1 py-1.5  ",
  },
  {
    title: "Date",
    key: "SEODate",
    width: "md:w-28 2xl:w-36 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Start Time",
    key: "SEOStartTime",
    width: "md:w-28  2xl:w-28 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "End Time",
    key: "SEOEndTime",
    width: "md:w-28 2xl:w-28 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Venue",
    key: "SEOVenue",
    width: "md:w-28 2xl:w-28 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];

const SingleSessionHeader = [
  {
    title: "S.No.",
    key: "txn_singlesessionId",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-10 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "FormatSEODate",
    width: "md:w-24 lg:w-24 xl:w-24  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Time",
    key: "SEOStartEndTimes",
    width: "md:w-40 lg:w-40 xl:w-40  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Mode",
    key: "RadioSessionTypes",
    width:
      "md:w-30 whitespace-normal break-words xl:w-30 2xl:w-30 lg:w-30 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Resource Person",
    key: "ResourcesName1",
    width: "md:w-56 2xl:w-96 lg:w-44 xl:w-80 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Total No.Of Participants",
    key: "SEONoOfParticipants",
    width:
      "md:w-24 lg:w-24 2xl:w-24 xl:w-24 whitespace-pre-line px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 ",
  },
];
const AwarenessGender = [
  { value: "1", label: "Male" },
  { value: "2", label: "Female" },
  { value: "3", label: "Other" },
];
const AwarenessAge = [
  { value: "1", label: "10-14" },
  { value: "2", label: "15-19" },
  { value: "3", label: "20-24" },
];

const ReferralServiceCSE = [
  { value: "1", label: "Menstrual Health" },
  { value: "2", label: "Anaemia" },
  { value: "3", label: "Family Planning" },
  { value: "4", label: "Sexual orientation counseling " },
  { value: "5", label: "Relationship counseling" },
  { value: "6", label: "Gender Based Violence" },
  { value: "7", label: "Urology" },
  { value: "8", label: "STI/RTI" },
  { value: "9", label: "Other" },
];
const contraceptiveHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5  ",
    width1: "text-red-500",
  },
  {
    title: "Contraceptive Categories",
    key: "CommodityCategory_en",
    width: "md:w-44 lg:w-48 2xl:w-48 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Contraceptive Name",
    key: "CommodityName_en",
    width: "md:w-36 lg:w-36 xl:w-52 2xl:mw-52 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  // {
  //   title: "U.O.M",
  //   key: "CommodityUOM",
  //   width: "md:w-20 lg:w-20 xl:w-28 2xl:m-auto px-1 py-1.5",
  //   width1: "text-red-500",
  // },
  {
    title: "Description",
    key: "Description_en",
    width: "md:w-20 lg:w-72  xl:w-96 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 2xl:m-auto",
  },
];
const socialMarketingHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5  ",
  },
  {
    title: "Social Marketing Categories",
    key: "CommodityCategory_en",
    width: "md:w-48 lg:w-48 xl:w-52 2xl:w-52 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Social Marketing Name",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-48 2xl:w-48 px-1 py-1.5 ",
    width1: "text-red-500",
  },

  // {
  //   title: "U.O.M",
  //   key: "CommodityUOM",
  //   width: "md:w-20 lg:w-20 xl:w-28 2xl:m-auto px-1 py-1.5 ",
  //   width1: "text-red-500",
  // },
  {
    title: "Description",
    key: "Description_en",
    width: "md:w-20 lg:w-80  xl:w-96 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 2xl:m-auto",
  },
];

const medicinesDrugsHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5  ",
  },
  {
    title: "Medicines Category",
    key: "CommodityCategory_en",
    width: "md:w-36 lg:w-36 xl:w-48 2xl:w-48 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Medicines / Drugs Name",
    key: "CommodityName_en",
    width: "md:w-44 lg:w-44 xl:w-52 2xl:w-52 px-1 py-1.5 ",
    width1: "text-red-500",
  },

  {
    title: "Route",
    key: "ModeName",
    width: "md:w-24 lg:w-20 xl:w-32 2xl:w-32 px-1 py-1.5 ",
    width1: "text-red-500",
  },
  // {
  //   title: "U.O.M",
  //   key: "CommodityUOM",
  //   width: "md:w-16 lg:w-18 xl:w-24 2xl:m-auto px-1 py-1.5 ",
  //   width1: "text-red-500",
  // },
  {
    title: "Description",
    key: "Description_en",
    width: "md:w-20 lg:w-60  xl:w-72 2xl:w-72 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },

  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 2xl:m-auto",
  },
];
const consumablesHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5  ",
  },
  {
    title: "Consumables Categories",
    key: "CommodityCategory_en",
    width: "md:w-48 lg:w-48 xl:w-48 2xl:w-48 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Consumables Name",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-48 2xl:w-48 px-1 py-1.5",
    width1: "text-red-500",
  },

  // {
  //   title: "U.O.M",
  //   key: "CommodityUOM",
  //   width: "md:w-20 lg:w-20 xl:w-28 2xl:m-auto px-1 py-1.5 ",
  //   width1: "text-red-500",
  // },

  {
    title: "Description",
    key: "Description_en",
    width: "md:w-20 lg:w-72  xl:w-96 2xl:w-96 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 2xl:m-auto",
  },
];
const inventoryIssueDetailsHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 px-1 py-1.5 ",
    width1: "md:w-12 2xl:flex-1 text-gray-900 px-1 py-1.5  ",
  },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-28 px-1 py-1.5 ",
  },

  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-56  whitespace-normal break-words  px-1 py-1.5",
    width1:
      "md:w-44  whitespace-normal break-words text-red-600 2xl:flex-1 px-1 py-1.5  ",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-28 xl:w-56 whitespace-normal break-words  px-1 py-1.5 ",
    width1:
      "md:w-44  whitespace-normal break-words text-red-600 2xl:flex-1 px-1 py-1.5  ",
  },
  {
    title: "Batch",
    key: "IssuesBatch",
    width: "md:w-32  whitespace-normal break-words  px-1 py-1.5",
    width1:
      "md:w-44  whitespace-normal break-words text-red-600 2xl:flex-1 px-1 py-1.5  ",
  },
  {
    title: "Exp. Date",
    key: "FormattedExpiryDate",
    width: "md:w-28  whitespace-normal break-words  px-1 py-1.5 ",
    width1:
      "md:w-32  whitespace-normal break-words text-red-600 2xl:flex-1 px-1 py-1.5  ",
  },
  {
    title: "Issued Qty.",
    key: "IssuesQuantity",
    width: "md:w-28  whitespace-normal break-words  px-1 py-1.5 ",
    width1:
      "md:w-32  whitespace-normal break-words text-red-600 2xl:flex-1 px-1 py-1.5  ",
  },
  {
    title: "UOM",
    key: "IssuesUOM",
    width: "md:w-20  px-1 py-1.5 ",
    width1: "md:w-32  whitespace-normal break-words text-red-600 px-1 py-1.5  ",
  },
];
const inventoryIssueHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-14 2xl:w-12 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Indent No.",
    key: "IndentNo",
    width: "md:w-20 lg:w-20 xl:w-20 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Indent Date",
    key: "IndentDate",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Issued No.",
    key: "IssueNo",
    width: "md:w-20 lg:w-20 xl:w-20 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Issued Date",
    key: "IssueDate",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Issued To",
    key: "ReceivedFrom_en",
    width: "md:w-20 lg:w-20 xl:w-20 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receiver",
    key: "LocationName_en",
    width: "md:w-20 lg:w-16 xl:w-40 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-16 xl:w-40 2xl:flex-1 px-1 py-1.5 mr-auto",
    width1: "text-red-600",
  },
  {
    title: "Action",
    key: "IssueAction",
    width: "w-24 px-1 py-1.5",
  },
];
const inventoryDirectIssueHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-14 2xl:w-12 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  // {
  //   title: "Indent No",
  //   key: "IndentNo",
  //   width: "md:w-20 lg:w-20 xl:w-28 2xl:flex-1 px-1 py-1.5 ",
  //   width1: "text-red-600",
  // },
  // {
  //   title: "Indent Date",
  //   key: "IndentDate",
  //   width: "md:w-24 lg:w-24 xl:w-28 2xl:flex-1 px-1 py-1.5 ",
  //   width1: "text-red-600",
  // },
  {
    title: "Issued No.",
    key: "IssueNo",
    width: "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Issued Date",
    key: "IssueDate",
    width: "md:w-24 lg:w-24 xl:w-36 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  // {
  //   title: "Issue To",
  //   key: "IssueTo",
  //   width: "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 px-1 py-1.5",
  //   width1: "text-red-600",
  // },

  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-16 xl:w-60 2xl:flex-1 px-1 py-1.5 ",
    width1: "text-red-600",
  },
  {
    title: "Issued Through",
    key: "IssuedThrough",
    width: "md:w-28 lg:w-28 xl:w-52 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Remarks",
    key: "IssueRemarks_en",
    width: "md:w-20 lg:w-16 xl:w-36 2xl:flex-1 px-1 py-1.5 mr-auto",
    width1: "text-red-600",
  },
  {
    title: "Action",
    key: "IssueAction",
    width: "w-24 px-1 py-1.5",
  },
];
const pendingIndentHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
  },

  {
    title: "Indent No.",
    key: "IndentNo",
    width: "md:w-28 lg:w-28 xl:w-40 2xl:flex-1 px-1 py-1.5 ",
  },
  {
    title: "Indent Date",
    key: "IndentDate",
    width: "md:w-28 lg:w-28 xl:w-40 2xl:flex-1 px-1 py-1.5 ",
  },
  {
    title: "Requested By",
    key: "IndentFrom1_en",
    width: "md:w-28 lg:w-28 xl:w-44 2xl:flex-1 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "IndentFrom2_en",
    width: "md:w-28 lg:w-28 xl:w-44 2xl:flex-1 px-1 py-1.5",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-28 lg:w-28 xl:w-44 2xl:flex-1 px-1 py-1.5 mr-auto ",
  },
  {
    title: "Action",
    key: "PendingIndentAction",
    width: "md:w-28 px-1 py-1.5 text-center",
  },
];
const indentDetailsHeader = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  {
    title: "Commodity",
    key: "CommodityCategory_en",
    width: "md:w-52 px-1 py-1.5 ",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Indent Quantity",
    key: "IndentQuantity",
    width: "md:w-32 2xl:w-32 px-1 py-1.5 mr-auto ",
  },
  {
    title: "Issued Before",
    key: "IssuedBefore",
    width: "md:w-28 2xl:w-32 px-1 py-1.5 mr-auto ",
  },
  {
    title: "Pending Quantity",
    key: "IndentPendingQuantity",
    width: "md:w-32 2xl:w-32 px-1 py-1.5 mr-auto ",
  },
];
const issueDetailsHeader = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  // {
  //   title: "CommodityType",
  //   key: "CommodityType_en",
  //   width: "md:w-28 lg:48 2xl:w-48 px-1 py-1.5",
  // },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-28 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-32 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "issueBrand",
    width: "md:w-32 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Batch",
    key: "BatchNo",
    width: "md:w-24 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Exp.Dt",
    key: "ExpiryDate",
    width: "md:w-36 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Available Stock",
    key: "Available",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issued Now",
    key: "IssueQuantity",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Balance Stock",
    key: "Balance",
    width: "md:w-28 px-1 py-1.5 2xl:w-32 text-center",
  },
  {
    title: "Action",
    key: "issueAction",
    width: "w-16 px-1 py-1.5",
  },
];
const issueDetailsEditHeader = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  // {
  //   title: "CommodityType",
  //   key: "CommodityType_en",
  //   width: "md:w-28 lg:48 2xl:w-48 px-1 py-1.5",
  // },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-28 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-32 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "issueBrand",
    width: "md:w-32 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Batch",
    key: "BatchNo",
    width: "md:w-24 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Exp.Dt",
    key: "ExpiryDate",
    width: "md:w-36 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Available Stock",
    key: "Available",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issued Now",
    key: "IssueQuantity",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Balance Stock",
    key: "Balance",
    width: "md:w-28 px-1 py-1.5 2xl:w-32 text-center",
  },
  // {
  //   title: "Action",
  //   key: "issueAction",
  //   width: "w-16 px-1 py-1.5",
  // },
];
const issueIndentDetailsHeader = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  // {
  //   title: "CommodityType",
  //   key: "CommodityType_en",
  //   width: "md:w-28 lg:48 2xl:w-48 px-1 py-1.5",
  // },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-24 lg:48 text-center 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-28 lg:48 text-center 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "issueBrand",
    width: "md:w-32 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Batch",
    key: "BatchNo",
    width: "md:w-24 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Exp.Dt",
    key: "ExpiryDate",
    width: "md:w-36 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Available Stock",
    key: "Available",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issued Before",
    key: "IssuedBefore",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issued Now",
    key: "IssueQuantity",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Balance Stock",
    key: "Balance",
    width: "md:w-28 px-1 py-1.5 2xl:w-32 text-center",
  },
  {
    title: "Action",
    key: "issueAction",
    width: "w-16 px-1 py-1.5",
  },
];
const issueIndentDetailsEditHeader = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  // {
  //   title: "CommodityType",
  //   key: "CommodityType_en",
  //   width: "md:w-28 lg:48 2xl:w-48 px-1 py-1.5",
  // },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-24 lg:48 text-center 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-28 lg:48 text-center 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "issueBrand",
    width: "md:w-32 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Batch",
    key: "BatchNo",
    width: "md:w-24 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Exp.Dt",
    key: "ExpiryDate",
    width: "md:w-36 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Available Stock",
    key: "Available",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issued Before",
    key: "IssuedBefore",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issued Now",
    key: "IssueQuantity",
    width: "md:w-28 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Balance Stock",
    key: "Balance",
    width: "md:w-28 px-1 py-1.5 2xl:w-32 text-center",
  },
  // {
  //   title: "Action",
  //   key: "issueAction",
  //   width: "w-16 px-1 py-1.5",
  // },
];
const issueDetailsHeaderPopup = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  {
    title: "CommodityType",
    key: "CommodityType_en",
    width: "md:w-28 lg:48 2xl:w-48 px-1 py-1.5",
  },
  {
    title: "Category",
    key: "CommodityCategory",
    width: "md:w-28 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-28 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-28 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Batch",
    key: "IssuesBatch",
    width: "md:w-16 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Exp. Date",
    key: "ExpiryDate",
    width: "md:w-24 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Available",
    key: "ReceiptBalance",
    width: "md:w-20 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issue Qty.",
    key: "IssueQuantity",
    width: "md:w-16 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Balance",
    key: "Balance",
    width: "md:w-20 px-1 py-1.5 2xl:w-32 text-center",
  },
];
const issueDetailsPopUpHeader = [
  {
    title: "S.No.",
    key: "SNo",
    width: "md:w-12 px-1 py-1.5",
  },
  {
    title: "CommodityType",
    key: "CommodityType_en",
    width: "md:w-28 lg:48 2xl:w-48 px-1 py-1.5",
  },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-24 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-24 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-24 lg:48 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Batch",
    key: "IssuesBatch",
    width: "md:w-16 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Exp. Date",
    key: "ReceiptDateOfExpiry",
    width: "md:w-24 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Available",
    key: "Available1",
    width: "md:w-20 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Issue Qty.",
    key: "IssuesQuantity1",
    width: "md:w-20 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Balance",
    key: "Balance1",
    width: "md:w-20 px-1 py-1.5 2xl:w-32 text-center",
  },
];
// const pendingIndentHeader = [
//   {
//     title: "S.No.",
//     key: "sno",
//     width: "md:w-10 lg:w-12 xl:w-12 2xl:w-20 px-1 py-1.5",
//   },
//   {
//     title: "Category",
//     key: "CommodityCategoryName",
//     width: "md:w-36 lg:w-40 xl:w-44 2xl:w-96  px-1 py-1.5 ",
//   },
//   {
//     title: "Name",
//     key: "CommodityName_en",
//     width: "md:w-36 lg:w-40 xl:w-48 2xl:w-96  px-1 py-1.5",
//   },
//   // {
//   //   title: "Brand",
//   //   key: "CommodityBrand_en",
//   //   width: "md:w-24 lg:w-24 xl:w-32 2xl:w-52   px-1 py-1.5 ",
//   // },
//   {
//     title: "From",
//     key: "IndentFrom1",
//     width: "md:w-20 lg:w-16 xl:w-40 2xl:w-52 px-1 py-1.5 ",
//   },
//   {
//     title: "Name",
//     key: "IndentFrom2",
//     width: "md:w-20 lg:w-20 xl:w-44 2xl:w-52   px-1 py-1.5  ",
//   },
//   {
//     title: "Qty.",
//     key: "IndentQuantity",
//     width: "md:w-14 lg:w-12 xl:w-24 2xl:w-52   px-1 py-1.5 ",
//   },
//   {
//     title: "Pending Qty.",
//     key: "IndentPendingQuantity",
//     width: "md:w-24 lg:w-24 xl:w-28 2xl:w-52  px-1 py-1.5 ",
//   },
//   // {
//   //   title: "UOM",
//   //   key: "IndentUOM",
//   //   width: "md:w-16 lg:w-14 xl:w-32 2xl:w-52 px-1 py-1.5 ",
//   // },
//   {
//     title: "Action",
//     key: "pendingIndentAction",
//     width: " px-1 py-1.5  2xl:m-auto",
//   },
// ];
const PartnerOrganizationHeader = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-40 lg:w-40 xl:w-40 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-40 lg:w-40 xl:w-40 2xl:m-auto px-1 py-1.5",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width: "md:w-40 lg:w-40 xl:w-40 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "Partner Organization",
    key: "PartnerOrganizationName",
    width: "md:w-40 lg:w-40 xl:w-72 2xl:m-auto px-1 py-1.5 mr-auto",
  },

  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];
const CBPHeader = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-36 lg:w-36 xl:w-40 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-40 lg:w-36 xl:w-48 2xl:m-auto px-1 py-1.5",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width: "md:w-40 lg:w-36 xl:w-48 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "CBP",
    key: "CBPName",
    width: "md:w-40 lg:w-40 xl:w-40 2xl:m-auto px-1 py-1.5 mr-auto",
  },

  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];
const brandHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Commodities",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-60 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-44 lg:w-44 xl:w-60 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-44 lg:w-60 xl:w-72 2xl:flex-1 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];

const consumptionHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-12  2xl:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Consumption No.",
    key: "ConsumptionNo",
    width: "md:w-32 lg:w-32 xl:w-36  2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Date",
    key: "ConsumptionDateFormat",
    width: "md:w-24 lg:w-24 xl:w-28   2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Commodities",
    key: "CommodityType_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Branch",
    key: "LocationName_en",
    width: "md:w-24 lg:w-20 xl:w-40 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "S.D.P.",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  // {
  //   title: "Qty",
  //   key: "Total",
  //   width: "md:w-16 lg:w-16 xl:w-24 2xl:flex-1 px-1 py-1.5",
  //   width1: "text-red-500",
  // },
  {
    title: "Reason",
    key: "Reason_en",
    width: "md:w-20 lg:w-20 xl:w-32 2xl:flex-1 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "receiptAction",
    width: " px-1 py-1.5 md:w-32  ",
  },
];

const ReportInschoolSession = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 2xl:w-14 lg:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "School ID No.",
    key: "SchoolId",
    width: "md:w-24 lg:w-20 xl:w-28 2xl:m-auto px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Student Roll/ ID No.",
    key: "SessionNumber",
    width: "md:w-32 lg:w-24 xl:w-60 2xl:m-auto px-1 py-1.5 ",
    width1: "text-red-500",
  },
  {
    title: "Class",
    key: "Class",
    width: "md:w-32 whitespace-normal break-words 2xl:w-72 lg:w-44 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Division",
    key: "Division",
    width: "md:w-32 2xl:w-44 lg:w-32  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Age (In Years)",
    key: "Age",
    width: "md:w-32 lg:w-36 2xl:w-52  px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Gender (M/ F/ O)",
    key: "Gender",
    width: "md:w-20 whitespace-normal break-words lg:w-32 2xl:w-40 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Division",
    key: "Division",
    width: "md:w-32 2xl:w-44 lg:w-32  px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "1: Sexual health and well being, human rights",
    key: "Age",
    width: "md:w-32 lg:w-36 2xl:w-52  px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "2: Gender, gender based violence",
    key: "Gender",
    width: "md:w-20 whitespace-normal break-words lg:w-32 2xl:w-40 px-1 py-1.5",
    width1: "text-red-500",
  },
];

// const notificationsHeader = [
//   {
//     title: "S.No.",
//     key: "sno",
//     width: "md:w-10 lg:w-10 xl:w-10 2xl:w-12  px-1 py-1.5",
//   },
//   {
//     title: "Date",
//     key: "Date",
//     width: "md:w-28 lg:w-28 xl:w-28 2xl:w-28   px-1 py-1.5",
//   },
//   {
//     title: "Client Name",
//     key: "FollowUpWith",
//     width: "md:w-48 lg:w-48 xl:w-36 2xl:w-48  px-1 py-1.5",
//   },
//   {
//     title: "Action",
//     key: "NotificationAction",
//     width: "md:w-32 lg:w-32 xl:w-24 2xl:w-32 px-1 py-1.5",
//   },
//   {
//     title: "Follow-up By",
//     key: "FirstName_en",
//     width: "md:w-32 lg:w-32 xl:w-36 2xl:w-32 px-1 py-1.5",
//   },
//   {
//     title: "Remarks",
//     key: "NotificationRemarks",
//     width: "md:w-32 lg:w-32 xl:w-48 2xl:w-32 px-1 py-1.5",
//   },
//   {
//     title: "Next Follow-up",
//     key: "NotificationDate",
//     width: "md:w-32 lg:w-32 xl:w-36 2xl:w-32 px-1 py-1.5",
//   },
//   {
//     title: "Save",
//     key: "NotificationSave",
//     width: "md:w-20 lg:w-20 xl:w-16 2xl:w-20 px-1 py-1.5",
//   },
// ];

const ReferralTrackingHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "FormattedDate",
    width: "md:w-20 lg:w-20 xl:w-202xl:w-20 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Beneficiary name",
    key: "ParticipantName_en",
    width:
      "md:w-32 lg:w-32 xl:w-32 2xl:w-60 whitespace-pre-line whitespace-normal break-words px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Mobile no.",
    key: "MobileNo",
    width: "md:w-28 lg:w-28 xl:w-28 2xl:w-28 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Referral service",
    key: "ReferredBy_en",
    width: "md:w-32 lg:w-32 xl:w-32 2xl:w-52 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Referred to",
    key: "ReferredTo_en",
    width: "md:w-32 lg:w-32 xl:w-32 2xl:w-52 whitespace-pre-line px-1 py-1.5  ",
  },
  {
    title: "Service received",
    key: "ServiceReceived",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Follow-Up By",
    key: "FormattedFollowUpBy",
    width: "md:w-20 lg:w-20 xl:w-20 2xl:w-20 whitespace-pre-line px-1 py-1.5  ",
  },
  {
    title: "Date followed up",
    key: "DateFollowedUp",
    width:
      "md:w-40 lg:w-36 xl:w-40 2xl:w-40 whitespace-pre-line px-1 py-1.5 mr-auto ",
  },
  {
    title: "Action",
    key: "ReferralTrackingSave",
    width: "w-24 px-1 py-1.5 2xl:text-center",
  },
];

const clientChildrenDetailsHeaders = [
  {
    title: "Order of children",
    key: "childrenOrder",
    width: "w-40 whitespace-normal break-normal px-1 py-1.5",
  },
  {
    title: "Years",
    key: "clientYears",
    width: "w-20  break-normal  px-1 py-1.5",
  },
  {
    title: "Months",
    key: "clientMonths",
    width: "w-16  break-normal  px-1 py-1.5",
  },
  {
    title: "Days",
    key: "clientDays",
    width: "w-16  break-normal  px-1 py-1.5",
  },
  {
    title: "Gender (M /F /O)",
    key: "clientGender",
    width: "w-24  break-normal  px-1 py-1.5",
  },
];

const contraceptiveRSFHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-12 xl:w-12 2xl:w-12  px-1 py-1.5",
  },
  {
    title: "Date",
    key: "FormattedDate",
    width: "md:w-20 lg:w-20 xl:w-18 2xl:w-28    px-1 py-1.5",
  },
  {
    title: "Service Dt.",
    key: "FormattedServiceDate",
    width: "md:w-20 lg:w-20 xl:w-20 2xl:w-28    px-1 py-1.5",
  },

  {
    title: "Client Name",
    key: "ClientFirstName_en",
    width: "md:w-28 lg:w-28 xl:w-28 2xl:w-48 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Client ID",
    key: "ClientID",
    width: "md:w-48 lg:w-48 xl:w-24 2xl:w-48  px-1 py-1.5",
  },
  {
    title: "Mobile No.",
    key: "GridClientPhoneNo",
    width: "md:w-32 lg:w-32 xl:w-32 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "FP Method",
    key: "Services_en",
    width: "md:w-32 lg:w-20 xl:w-28 2xl:w-32 px-1 py-1.5",
  },

  {
    title: "Remarks",
    key: "ContraceptiveRemarks",
    width: "md:w-32 lg:w-32 xl:w-60 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Next Follow-up",
    key: "ContraceptiveNextFollowUpDate",
    width: "md:w-36 lg:w-36 xl:w-36 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Save",
    key: "NotificationSave",
    width: "md:w-16 lg:w-16 xl:w-14 2xl:w-32 px-1 py-1.5",
  },
];

const ReferralFollowUpNotificationsHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12  px-1 py-1.5",
  },
  {
    title: "Date",
    key: "FormattedDate",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-28    px-1 py-1.5",
  },
  {
    title: "Client Name",
    key: "ClientFirstName_en",
    width: "md:w-24 lg:w-24 xl:w-52 2xl:w-28    px-1 py-1.5",
  },

  {
    title: "Client ID",
    key: "ClientID",
    width: "md:w-28 lg:w-28 xl:w-52 2xl:w-48 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Mobile No.",
    key: "GridClientPhoneNo",
    width: "md:w-48 lg:w-48 xl:w-40 2xl:w-48  px-1 py-1.5",
  },
  {
    title: "Referral Service",
    key: "ReferredTo_en",
    width: "md:w-32 lg:w-32 xl:w-44 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Remarks",
    key: "ReferralFollowUpRemarks",
    width: "md:w-32 lg:w-20 xl:w-52 2xl:w-32 px-1 py-1.5",
  },
  {
    title: "Save",
    key: "NotificationSave",
    width: "  px-1 py-1.5",
  },
];

const openingStockHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-24 px-1 py-1.5  ",
  },
  {
    title: "Commodities",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-36 xl:w-48 2xl:m-auto px-1 py-1.5 ",
  },
  {
    title: "Category",
    key: "CommodityCategory_en",
    width: "md:w-44 lg:w-44 xl:w-52 2xl:m-auto px-1 py-1.5 mr-auto",
  },
  {
    title: "Name",
    key: "CommodityName_en",
    width: "md:w-44 lg:w-44 xl:w-52 2xl:m-auto px-1 py-1.5 mr-auto",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-28 lg:w-28 xl:w-48 2xl:m-auto px-1 py-1.5 ",
  },

  {
    title: "U.O.M",
    key: "CommodityUOM",
    width: "md:w-18 lg:w-18 xl:w-24 2xl:m-auto px-1 py-1.5 mr-auto",
  },
  {
    title: "Action",
    key: "action",
    width: "px-1 py-1.5 2xl:m-auto",
  },
];

const districtMasterHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-14 2xl:w-12 px-1 py-1.5  m-auto",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-52 2xl:flex-1 px-1 py-1.5 ",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "District",
    key: "District_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
  },

  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const blockMasterHeader = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-14 2xl:w-12 px-1 py-1.5  m-auto",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "District",
    key: "District_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "md:w-60 2xl:flex-1 px-1 py-1.5 m-auto",
  },

  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const cityvillageMasterHeader = [
  {
    title: "S.No.",
    key: "Sno",
    width: "md:w-14 2xl:w-12 px-1 py-1.5  m-auto",
  },
  {
    title: "Country",
    key: "CountryName_en",
    width: "flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "District",
    key: "District_en",
    width: "flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "flex-1 px-1 py-1.5 m-auto",
  },
  {
    title: "City / village",
    key: "City_en",
    width: "flex-1 px-1 py-1.5 m-auto",
  },

  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const doctorAppoinmentsHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-10 2xl:w-10 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Client Id",
    key: "ClientId",
    width: "md:w-60 lg:w-60 xl:w-60 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Client Name",
    key: "ClientName_en",
    width: "md:w-40 lg:w-40 xl:w-40 whitespace-pre-line  2xl:w-72 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Gender / Age",
    key: "ClientGenderAge_en",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Phone",
    key: "ClientPhoneNo",
    width: "md:w-28 lg:w-28 xl:w-28 2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Appointment",
    key: "LocationName_en",
    width: "md:w-60 lg:w-60 xl:w-56 2xl:w-60 whitespace-pre-line px-1 py-1.5",
  },
  {
    title: "Services",
    key: "DoctorConsultationAction",
    width: "px-1 py-1.5 md:w-16 2xl:mr-auto mr-auto",
  },
  {
    title: "Action",
    key: "appointmentsAction",
    width: "px-1 py-1.5 md:w-32",
  },
];
const CSEInSchoolReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "School Name",
    key: "SchoolName",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Name of the Student",
    key: "StudentName",
    width: "md:w-24 lg:w-24 xl:w-40 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Student Roll No.",
    key: "RollId",
    width: "md:w-24 lg:w-24 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Class",
    key: "MstCSEClass",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Division",
    key: "Division",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Age group",
    key: "Age",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Gender (M/ F/ O)",
    key: "Gender",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 1: Gender",
    key: "1)Gender",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 2: Sexual and Reproductive Health",
    key: "2)Sexual and Reproductive Health",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 3: Sexual Rights and Sexual Citizenship",
    key: "3)Sexual Rights and Sexual Citizenship",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 4: Pleasure",
    key: "4)Pleasure",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 5: Violence",
    key: "5)Violence",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 6: Diversity",
    key: "6)Diversity",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 7: Relationships",
    key: "7)Relationships",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of modules completed",
    key: "No. of modules completed",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of modules",
    key: "Modules",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of sessions",
    key: "Sessions",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Date of first session (S1)",
    key: "FirstDate",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Duration (days)  to complete",
    key: "DateDifference",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (1)",
    key: "ResourcepersonType1",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (2)",
    key: "ResourcepersonType2",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (3)",
    key: "ResourcepersonType3",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (4)",
    key: "ResourcepersonType4",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (1)",
    key: "ResourcePersonName1",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (2)",
    key: "ResourcePersonName2",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (3)",
    key: "ResourcePersonName3",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (4)",
    key: "ResourcePersonName4",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (5)",
    key: "ResourcePersonName5",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (6)",
    key: "ResourcePersonName6",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (7)",
    key: "ResourcePersonName7",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (8)",
    key: "ResourcePersonName8",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];

const CSEOutSchoolReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Youth Group Name",
    key: "YouthGroupName",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Name of the Youth Member",
    key: "YouthName",
    width: "md:w-24 lg:w-24 xl:w-40 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Youth ID",
    key: "YouthId",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Age group",
    key: "Age",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Gender (M/ F/ O)",
    key: "Gender",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 1: Gender",
    key: "Module1",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 2: Sexual and Reproductive Health",
    key: "Module2",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 3: Sexual Rights and Sexual Citizenship",
    key: "Module3",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 4: Pleasure",
    key: "Module4",
    width: "md:w-24 lg:w-24 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 5: Violence",
    key: "Module5",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 6: Diversity",
    key: "Module6",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Module 7: Relationships",
    key: "Module7",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of modules completed",
    key: "Noofmodulescompleted",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of modules",
    key: "Modules",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of sessions",
    key: "Sessions",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Date of first session (S1)",
    key: "FirstDate",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Duration (days)  to complete",
    key: "DateDifference",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (1)",
    key: "ResourcepersonType1",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (2)",
    key: "ResourcepersonType2",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (3)",
    key: "ResourcepersonType3",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Type (4)",
    key: "ResourcepersonType4",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (1)",
    key: "ResourcePersonName1",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (2)",
    key: "ResourcePersonName2",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (3)",
    key: "ResourcePersonName3",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (4)",
    key: "ResourcePersonName4",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (5)",
    key: "ResourcePersonName5",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (6)",
    key: "ResourcePersonName6",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (7)",
    key: "ResourcePersonName7",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Resource person Name (8)",
    key: "ResourcePersonName8",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];
const CSEReferralsReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-10 lg:w-10 xl:w-12 2xl:w-20 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Name",
    key: "name",
    width: "md:w-28 lg:w-28 xl:w-28 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Place / Location",
    key: "SchoolName",
    width:
      "md:w-36 lg:w-44 xl:w-52 lg:whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Name",
    key: "StudentName",
    width: "md:w-28 lg:w-32 xl:w-32 2xl:w-72 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Age group",
    key: "Age",
    width: "md:w-20 lg:w-20 xl:w-24 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Gender",
    key: "Gender",
    width: "md:w-16 lg:w-16 xl:w-24 2xl:w-44 px-1 py-1.5",
  },
  {
    title: "Referral Service",
    key: "ReferralServices",
    width: "md:w-28 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-52 px-1 py-1.5",
  },
  {
    title: "Referred to",
    key: "ReferredTo_en",
    width: "md:w-24 lg:w-24 xl:w-36 whitespace-pre-line 2xl:w-52 px-1 py-1.5",
  },
];

const DropoutsReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Contraception Methods",
    key: "Method",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Complication / Side-effects",
    key: "RemarksDiscontinued1",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "Reasons for Drop Outs",
  },
  {
    title: "Failure (Method /User)/ Confirmed Pregnancy",
    key: "RemarksDiscontinued2",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "Reasons for Drop Outs",
  },
  {
    title: "Personal Reasons-Fears, Difficult to Use, Objection",
    key: "RemarksDiscontinued3",
    width: "md:w-24 lg:w-28 xl:w-56 2xl:w-96 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
    group: "Reasons for Drop Outs",
  },
  {
    title: "Supplies related Reason-Difficult to Get Another Source ",
    key: "RemarksDiscontinued4",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    group: "Reasons for Drop Outs",
  },
  {
    title: "Planned Pregnancy / Want child",
    key: "RemarksDiscontinued5",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    group: "Reasons for Drop Outs",
  },
  {
    title: "Lost to Follow-up",
    key: "RemarksDiscontinued6",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    group: "Reasons for Drop Outs",
  },
  {
    title: "Sterilisation (M+F)",
    key: "RemarksDiscontinued7",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    group: "Switched Method",
  },
  {
    title: "IUD",
    key: "RemarksDiscontinued8",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
    group: "Switched Method",
  },
  {
    title: "LNG-IUS",
    key: "RemarksDiscontinued9",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "Switched Method",
  },
  {
    title: "Implants",
    key: "RemarksDiscontinued10",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
    group: "Switched Method",
  },
  {
    title: "Oral Pill",
    key: "RemarksDiscontinued11",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
    group: "Switched Method",
  },
  {
    title: "Condom (M+F)",
    key: "RemarksDiscontinued12",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Switched Method",
  },
  {
    title: "Injectables",
    key: "RemarksDiscontinued13",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Switched Method",
  },
  {
    title: "Total",
    key: "Total",
    width: "md:w-24 lg:w-24 xl:w-24 2xl:w-96 px-1 py-1.5",
  },
];
const SingleSessionReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Event ID.",
    key: "EventNo",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Event Date",
    key: "SEODate",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Name of the Resource Person",
    key: "SEOResourcesPerson",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Mode of session (Online / Offline)",
    key: "SessionType",
    width: "md:w-24 lg:w-28 xl:w-56 2xl:w-96 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Venue",
    key: "SEOVenue",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Duration of the session",
    key: "TimeDifference",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10-14",
    key: "SEOMaleChild",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-19",
    key: "SEOFemaleChild",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24",
    key: "SEOOtherChild",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "No. of participants (estimated)",
    key: "SEONoOfParticipants",
    width: "md:w-24 lg:w-28 xl:w-36  whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Remarks",
    key: "SEORemarks",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
  },
];
const MonthlyEventsReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Month",
    key: "MonthName",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Year",
    key: "YearId",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Teacher",
    key: "TeacherTraining",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "Training by FPAI/Volunteers(No. of participants)",
  },
  {
    title: "NGO/CBO staff",
    key: "NGOCBOStaffTraining",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "Training by FPAI/Volunteers(No. of participants)",
  },
  {
    title: "Peer Educator (direct/ToT)",
    key: "PeerEducatorTrained",
    width: "md:w-24 lg:w-28 xl:w-56 2xl:w-96 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
    group: "Training by FPAI/Volunteers(No. of participants)",
  },
  {
    title: "Peer-to-Peer",
    key: "PeerToPeerTraining",
    group: "Training by FPAI/Volunteers(No. of participants)",
    width: "md:w-24 lg:w-28 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Parents",
    key: "Parents",
    group: "Sensitization meeting (No. of participants)",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Teachers",
    key: "Teachers",
    group: "Sensitization meeting (No. of participants)",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Name",
    key: "NameOfTheNGOCBO",
    group: "NGO/CBOs partner",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No.",
    key: "NoOfNGOCBOsPartnered",
    group: "NGO/CBOs partner",
    width: "md:w-24 lg:w-24 xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Male",
    group: "Type of youth groups",
    key: "YouthGroupsFormedBoys",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Female",
    group: "Type of youth groups",
    key: "YouthGroupsFormedGirls",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Other",
    group: "Type of youth groups",
    key: "YouthGroupsFormedOthers",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Mixed",
    group: "Type of youth groups",
    key: "YouthGroupsFormedMixed",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. of schools enrolled (year)",
    key: "SchoolsEnrolled",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "No. of schools completed",
    key: "SchoolsCompleted",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "No. Of Youth Groups Formed",
    key: "YouthGroupsFormedTotal",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Remarks",
    key: "Remarks",
    width: "md:w-24 lg:w-28 xl:w-24 2xl:w-96 px-1 py-1.5",
  },
];

const ProfileCATClientsReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "Area",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Category-Wise Clients",
    key: "ServicesCategory_en",
    width: "w-56",
  },
  {
    title: "Gender",
    key: "Gender",
    width: "w-36",
  },
  {
    title: "0-9 Yrs",
    key: "Age0to9",
    width: "w-24",
  },
  {
    title: "10-14 Yrs",
    key: "Age10to14",
    width: "w-24",
  },
  {
    title: "15-19 Yrs",
    key: "Age15to19",
    width: "w-24",
  },
  {
    title: "20-24 Yrs",
    key: "Age20to24",
    width: "w-24",
  },
  {
    title: "25-29 Yrs",
    key: "Age25to29",
    width: "w-24",
  },
  {
    title: "30-34 Yrs",
    key: "Age30to34",
    width: "w-24",
  },
  {
    title: "35-39 Yrs",
    key: "Age35to39",
    width: "w-24",
  },
  {
    title: "40-44 Yrs",
    key: "Age40to44",
    width: "w-24",
  },
  {
    title: "45-49 Yrs",
    key: "Age45to49",
    width: "w-24",
  },
  {
    title: "50 Yrs & Above",
    key: "Age50andAbove",
    width: "w-28",
  },
];
const ProfilePACReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "PACServices",
    width: "w-56 px-1 py-1.5",
  },
  {
    title: "Medical Abortion(MA)",
    key: "Medical Abortion(MA)",
    width: "w-60 px-1 py-1.5",
  },
  {
    title: "Surgical Abortion",
    key: "Surgical Abortion",
    width: "w-60 px-1 py-1.5",
  },
  {
    title: "Incomplete Abortion (TIA)",
    key: "Incomplete Abortion (TIA)",
    width: "w-60 px-1 py-1.5",
  },
];
const ClientProfileMEReportHeader = [
 
  {
    title: "Person living with disability ",
    key: "no",
    width: "w-96",
  },
  {
    title: "Visual ",
    key: "CountMstClientIdDisability1",
    width: "w-96",
  },
  {
    title: "Locomotor",
    key: "CountMstClientIdDisability2",
    width: "w-96",
  },
  {
    title: "Hearing",
    key: "CountMstClientIdDisability3",
    width: "w-96",
  },
  {
    title: "Speech",
    key: "CountMstClientIdDisability4",
    width: "w-96",
  },
  {
    title: "Intellectual",
    key: "CountMstClientIdDisability5",
    width: "w-96",
  },
  {
    title: "Multiple disability",
    key: "CountMstClientIdDisability6",
    width: "w-96",
  },
  {
    title: "Not applicable",
    key: "CountMstClientIdDisability7",
    width: "w-96",
  },
  {
    title: "Person living with disability (Total exclusive)",
    key: "CountMstClientIdMEClientsId1",
    width: "w-44 ",
  },
  {
    title: "BPL cardholder (Yes)",
    key: "TotalClientBPLCardholder",
    width: "w-96",
  },
  {
    title: "SC,ST,DT,NT",
    key: "CountMstClientIdMEClientsId2",
    width: "w-96",
  },
  {
    title: "Adolescent (10-19)",
    key: "TotalClientAge10to19",
    width: "w-96",
  },
  {
    title: "Young population (15-24)",
    key: "TotalClientAge15to24",
    width: "w-96",
  },
  {
    title: "Sex worker",
    key: "CountMstClientIdMEClientsId3",
    width: "w-96",
  },
  {
    title: "High risk Migrants",
    key: "CountMstClientIdMEClientsId4",
    width: "w-96",
  },
  {
    title: "IDU's",
    key: "CountMstClientIdMEClientsId5",
    width: "w-96",
  },
  {
    title: "PLHIV",
    key: "CountMstClientIdMEClientsId6",
    width: "w-96",
  },
  {
    title: "NTransman",
    key: "CountMstClientIdMEClientsId7",
    width: "w-96",
  },
  {
    title: "Transwoman",
    key: "CountMstClientIdMEClientsId8",
    width: "w-96",
  },
  {
    title: "Transgender",
    key: "CountMstClientIdMEClientsId9",
    width: "w-96",
  },
  {
    title: "Lesbian",
    key: "CountMstClientIdMEClientsId10",
    width: "w-96",
  },
  {
    title: "Gay",
    key: "CountMstClientIdMEClientsId11",
    width: "w-96",
  },
  {
    title: "Bisexual",
    key: "CountMstClientIdMEClientsId12",
    width: "w-96",
  },
  {
    title: "Intersex",
    key: "CountMstClientIdMEClientsId13",
    width: "w-96",
  },
  {
    title: "Closed settings",
    key: "CountMstClientIdMEClientsId14",
    width: "w-96",
  },
  {
    title: "Vulnerable Woman ",
    key: "CountMstClientIdMEClientsId15",
    width: "w-96",
  },
  {
    title: "Total Marginalized and Excluded",
    key: "TotalClient",
    width: "w-44",
  },
  {
    title: "Branch",
    key: "BranchName",
    width: "w-1/6",
  },
  {
    title: "SDP",
    key: "SDP",
    width: "w-44",
  },
  {
    title: "Area",
    key: "Area",
    width: "w-44",
  },
];
const iecReferralsReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-24 lg:w-24 xl:w-28 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "No.(IPC/GD/ GMM/MED)",
    key: "IECNo",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  }, 
 
  {
    title: "District",
    key: "District_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "City/Village",
    key: "City_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Venue",
    key: "Venue",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Name of the participant",
    key: "BeneficiaryNameEn",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Age",
    key: "MstAgeId",
    width: "md:w-24 lg:w-24 xl:w-14 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Gender",
    key: "Gender_en",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Referral Service",
    key: "ReferredBy",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Referred to",
    key: "ReferredTo_en",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Received service (Y/N)",
    key: "ServiceReceived",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Followed-up date",
    key: "DateFollowedUp",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
];

const iecIPCReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "OpeningDate",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-24 lg:w-24 xl:w-28 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "IPC no.",
    key: "IPCno",
    width: "md:w-20 lg:w-20 xl:w-56 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  
  
  {
    title: "District",
    key: "District_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "City/Village",
    key: "City_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Channel",
    key: "Channel",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Staff Name",
    key: "staffname",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Participants name",
    key: "ParticipantName_en",
    width: "md:w-24 lg:w-24 xl:w-40 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Age",
    key: "MstAgeId",
    width: "md:w-24 lg:w-24 xl:w-14 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Gender",
    key: "Gender_en",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T1: FP/MCH",
    key: "T1: FP/MCH",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T2: Abortion",
    key: "T2: Abortion",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T3: STI & HIV",
    key: "T3: STI & HIV",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T4: Adolescent Health",
    key: "T4: Adolescent Health",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T5: Infertility",
    key: "T5: Infertility",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T6: SRHR",
    key: "T6: SRHR",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T7: GBV/RC",
    key: "T7: GBV/RC",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T8: Oral Cancer",
    key: "T8: Oral Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T9: Breast Cancer",
    key: "T9: Breast Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T10: Cervical cancer",
    key: "T10: Cervical cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T11: HPV vaccination",
    key: "T11: HPV vaccination",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T12: MISP",
    key: "T12: MISP",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T13: Non-Communicable Disease (NCD)",
    key: "T13: Non-Communicable Disease (NCD)",
    width: "md:w-24 lg:w-28 whitespace-pre-line  xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T14: Gender Identity & Sexual Orientation",
    key: "T14: Gender Identity & Sexual Orientation",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T15: Climate change & environment",
    key: "T15: Climate change & environment",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T16: Other",
    key: "T16: Other",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
];

const iecMMReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "OpeningDate",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-24 lg:w-24 xl:w-28 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "MED no.",
    key: "MMno",
    width: "md:w-20 lg:w-20 xl:w-56 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  
 
  {
    title: "District",
    key: "District_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "City/Village",
    key: "City_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Channel",
    key: "Channel",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T1: FP/MCH",
    key: "T1: FP/MCH",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T2: Abortion",
    key: "T2: Abortion",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T3: STI & HIV",
    key: "T3: STI & HIV",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T4: Adolescent Health",
    key: "T4: Adolescent Health",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T5: Infertility",
    key: "T5: Infertility",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T6: SRHR",
    key: "T6: SRHR",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T7: GBV/RC",
    key: "T7: GBV/RC",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T8: Oral Cancer",
    key: "T8: Oral Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T9: Breast Cancer",
    key: "T9: Breast Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T10: Cervical cancer",
    key: "T10: Cervical cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T11: HPV vaccination",
    key: "T11: HPV vaccination",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T12: MISP",
    key: "T12: MISP",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T13: Non-Communicable Disease (NCD)",
    key: "T13: Non-Communicable Disease (NCD)",
    width: "md:w-24 lg:w-28 whitespace-pre-line  xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T14: Gender Identity & Sexual Orientation",
    key: "T14: Gender Identity & Sexual Orientation",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T15: Climate change & environment",
    key: "T15: Climate change & environment",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T16: Other",
    key: "T16: Other",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  // {
  //   title: "Total no. of participants (estimated)",
  //   key: "ParticipantsEstimated",
  //   width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  // },
  {
    title: "Mode",
    key: "Mode",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Type of media",
    key: "Type of media",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];

const iecGDReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "OpeningDate",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-24 lg:w-24 xl:w-28 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "GD no.",
    key: "GDno",
    width: "md:w-20 lg:w-20 xl:w-56 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  
 
  {
    title: "District",
    key: "District_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "City/Village",
    key: "City_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Channel",
    key: "Channel",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Staff Name",
    key: "staffname",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T1: FP/MCH",
    key: "T1: FP/MCH",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T2: Abortion",
    key: "T2: Abortion",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T3: STI & HIV",
    key: "T3: STI & HIV",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T4: Adolescent Health",
    key: "T4: Adolescent Health",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T5: Infertility",
    key: "T5: Infertility",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T6: SRHR",
    key: "T6: SRHR",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T7: GBV/RC",
    key: "T7: GBV/RC",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T8: Oral Cancer",
    key: "T8: Oral Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T9: Breast Cancer",
    key: "T9: Breast Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T10: Cervical cancer",
    key: "T10: Cervical cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T11: HPV vaccination",
    key: "T11: HPV vaccination",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T12: MISP",
    key: "T12: MISP",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T13: Non-Communicable Disease (NCD)",
    key: "T13: Non-Communicable Disease (NCD)",
    width: "md:w-24 lg:w-28 whitespace-pre-line  xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T14: Gender Identity & Sexual Orientation",
    key: "T14: Gender Identity & Sexual Orientation",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T15: Climate change & environment",
    key: "T15: Climate change & environment",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T16: Other",
    key: "T16: Other",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10-14yrs",
    key: "10-14yrs",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Age",
  },
  {
    title: "15-24yrs",
    key: "15-24yrs",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Age",
  },
  {
    title: "25+yrs",
    key: "25+yrs",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Age",
  },
  {
    title: "Male",
    key: "male",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Gender",
  },
  {
    title: "Female",
    key: "Female",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Gender",
  },
  {
    title: "Other",
    key: "Others",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Gender",
  },
  // {
  //   title: "Total no. of Participants",
  //   key: "NoOfPaticipantsTotal",
  //   width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  // },
];

const iecGMMReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "OpeningDate",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-24 lg:w-24 xl:w-28 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-24 lg:w-24 xl:w-32 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "GMM no.",
    key: "GMMno",
    width: "md:w-20 lg:w-20 xl:w-60 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  }, 
  
  {
    title: "District",
    key: "District_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Block",
    key: "Block_en",
    width: "md:w-24 lg:w-28 xl:w-28 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "City/Village",
    key: "City_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Venue",
    key: "Venue_en",
    width: "md:w-24 lg:w-28 xl:w-32 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Channel",
    key: "Channel",
    width: "md:w-24 lg:w-28 xl:w-40 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Staff Name",
    key: "staffname",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T1: FP/MCH",
    key: "T1: FP/MCH",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T2: Abortion",
    key: "T2: Abortion",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T3: STI & HIV",
    key: "T3: STI & HIV",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T4: Adolescent Health",
    key: "T4: Adolescent Health",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T5: Infertility",
    key: "T5: Infertility",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T6: SRHR",
    key: "T6: SRHR",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T7: GBV/RC",
    key: "T7: GBV/RC",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T8: Oral Cancer",
    key: "T8: Oral Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T9: Breast Cancer",
    key: "T9: Breast Cancer",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T10: Cervical cancer",
    key: "T10: Cervical cancer",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T11: HPV vaccination",
    key: "T11: HPV vaccination",
    width: "md:w-24 lg:w-28 xl:w-32 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T12: MISP",
    key: "T12: MISP",
    width: "md:w-24 lg:w-28 xl:w-48 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T13: Non-Communicable Disease (NCD)",
    key: "T13: Non-Communicable Disease (NCD)",
    width: "md:w-24 lg:w-28 whitespace-pre-line  xl:w-36 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T14: Gender Identity & Sexual Orientation",
    key: "T14: Gender Identity & Sexual Orientation",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T15: Climate change & environment",
    key: "T15: Climate change & environment",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "T16: Other",
    key: "T16: Other",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10-14yrs",
    key: "10-14yrs",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Age",
  },
  {
    title: "15-24yrs",
    key: "15-24yrs",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Age",
  },
  {
    title: "25+yrs",
    key: "25+yrs",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Age",
  },
  {
    title: "Male",
    key: "male",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Gender",
  },
  {
    title: "Female",
    key: "Female",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Gender",
  },
  {
    title: "Other",
    key: "Others",
    width: "md:w-24 lg:w-28 xl:w-36 2xl:w-96 px-1 py-1.5",
    group: "Gender",
  },
  // {
  //   title: "Total no. of Participants",
  //   key: "NoOfPaticipantsTotal",
  //   width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  // },
];

const ageGenderDHIS2SRHReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Female",
    key: "FemaleAge0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Male",
    key: "MaleAge0to9",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Others",
    key: "OtherAge0to9",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Female",
    key: "FemaleAge10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Male",
    key: "MaleAge10to14",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Others",
    key: "OtherAge10to14",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-19 Yrs Female",
    key: "FemaleAge15to19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-19 Yrs Male",
    key: "MaleAge15to19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-19 Yrs Others",
    key: "OtherAge15to19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Female",
    key: "FemaleAge20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Male",
    key: "MaleAge20to24",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Others",
    key: "OtherAge20to24",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Female",
    key: "FemaleAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Male",
    key: "MaleAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Others",
    key: "OtherAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Female",
    key: "FemaleAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Male",
    key: "MaleAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Others",
    key: "OtherAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Female",
    key: "FemaleAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Male",
    key: "MaleAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Others",
    key: "OtherAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Female",
    key: "FemaleAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Male",
    key: "MaleAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Others",
    key: "OtherAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Female",
    key: "FemaleAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Male",
    key: "MaleAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Others",
    key: "OtherAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50 Yrs and Above Female",
    key: "FemaleAge50andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50 Yrs and Above Male",
    key: "MaleAge50andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50 Yrs and Above Others",
    key: "OtherAge50andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];
const ageGenderDonorSRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Female",
    key: "FemaleAge0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Male",
    key: "MaleAge0to9",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Others",
    key: "OtherAge0to9",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Female",
    key: "FemaleAge10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Male",
    key: "MaleAge10to14",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Others",
    key: "OtherAge10to14",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-18 Yrs Female",
    key: "FemaleAge15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-18 Yrs Male",
    key: "MaleAge15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-18 Yrs Others",
    key: "OtherAge15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "19 Yrs Female",
    key: "FemaleAge19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "19 Yrs Male",
    key: "MaleAge19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "19 Yrs Others",
    key: "OtherAge19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Female",
    key: "FemaleAge20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Male",
    key: "MaleAge20to24",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Others",
    key: "OtherAge20to24",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Female",
    key: "FemaleAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Male",
    key: "MaleAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Others",
    key: "OtherAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Female",
    key: "FemaleAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Male",
    key: "MaleAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Others",
    key: "OtherAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Female",
    key: "FemaleAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Male",
    key: "MaleAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Others",
    key: "OtherAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Female",
    key: "FemaleAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Male",
    key: "MaleAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Others",
    key: "OtherAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Female",
    key: "FemaleAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Male",
    key: "MaleAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Others",
    key: "OtherAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50-59 Yrs Female",
    key: "FemaleAge50to59",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50-59 Yrs Male",
    key: "MaleAge50to59",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50-59 Yrs Others",
    key: "OtherAge50to59",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "60 Yrs and Above Female",
    key: "FemaleAge60AndAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "60 Yrs and Above Male",
    key: "MaleAge60AndAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "60 Yrs and Above Others",
    key: "OtherAge60AndAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];
const StockSDPHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Type",
    key: "TxnType",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Id",
    key: "TxnTypeId",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Batch No.",
    key: "BatchNo",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expiry Date",
    key: "ExpiryDate",
    width: "md:w-24 lg:w-40 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Recived By",
    key: "ReceivedBy",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const StockReportBranchHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Type",
    key: "TxnType",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Id",
    key: "TxnTypeId",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Batch No.",
    key: "BatchNo",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expiry Date",
    key: "ExpiryDate",
    width: "md:w-24 lg:w-40 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Recived By",
    key: "ReceivedBy",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const StockReportORSHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "ORS",
    key: "ORS",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Type",
    key: "TxnType",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Id",
    key: "TxnTypeId",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Batch No.",
    key: "BatchNo",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expiry Date",
    key: "ExpiryDate",
    width: "md:w-24 lg:w-40 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Recived By",
    key: "ReceivedBy",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const StockReportBranchLevelHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const StockReportSDPLevelHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const StockReportORSLevelHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "ORS",
    key: "ORS",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const StockReportSDPHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Date",
    key: "formatted_date",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Type",
    key: "CommodityType_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity Category",
    key: "CommodityCategory_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Commodity",
    key: "CommodityName_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Brand",
    key: "Brand_en",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Type",
    key: "TxnType",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line  2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Id",
    key: "TxnTypeId",
    width: "md:w-40 lg:w-40 xl:w-44 whitespace-pre-line  2xl:w-44 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Opening Stock",
    key: "OpeningQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Receipts",
    key: "ReceivedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Issues",
    key: "IssuedQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Expired",
    key: "Expired",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Damaged",
    key: "Damaged",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Demo",
    key: "Demo",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Total Consumption",
    key: "TotalConsumption",
    width: "md:w-32 lg:w-32 xl:w-32 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Closing Stock",
    key: "BalanceQuantity",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line 2xl:w-24 px-1 py-1.5",
    width1: "text-red-600",
  },
];
const ageGenderChildrenReportHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr Female",
    key: "FemaleLessthan1",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr Male",
    key: "MaleLessthan1",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr Others",
    key: "OtherLessthan1",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "01-02 Yrs Female",
    key: "FemaleAge01to02",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "01-02 Yrs Male",
    key: "MaleAge01to02",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "01-02 Yrs Others",
    key: "OtherAge01to02",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "03-05 Yrs Female",
    key: "FemaleAge03to05",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "03-05 Yrs Male",
    key: "MaleAge03to05",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "03-05 Yrs Others",
    key: "OtherAge03to05",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "06 - 09 Yrs Female",
    key: "FemaleAge06to09",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "06 - 09 Yrs Male",
    key: "MaleAge06to09",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "06 - 09 Yrs Others",
    key: "OtherAge06to09",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10 Yrs & Above Female",
    key: "FemaleAge10andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10 Yrs & Above Male",
    key: "MaleAge10andAbove",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10 Yrs & Above Others",
    key: "OtherAge10andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];
const clientsByAgeGenderDHIS2SRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "Area",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "dataname",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Female",
    key: "Female_10_14_Yrs",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Male",
    key: "Male_10_14_Yrs",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Others",
    key: "Others_10_14_Yrs",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "15-19 Yrs Female",
    key: "Female_15_19_Yrs",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "15-19 Yrs Male",
    key: "Male_15_19_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "15-19 Yrs Others",
    key: "Others_15_19_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Female",
    key: "Female_20_24_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Male",
    key: "Male_20_24_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Others",
    key: "Others_20_24_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Female",
    key: "Female_25_29_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25_29_Yrs_Male",
    key: "Male_25_29_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Others",
    key: "Others_25_29_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Female",
    key: "Female_30_34_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Male",
    key: "Male_30_34_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Others",
    key: "Others_30_34_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Female",
    key: "Female_35_39_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Male",
    key: "Male_35_39_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs others",
    key: "Others_35_39_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Female",
    key: "Female_40_44_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Male",
    key: "Male_40_44_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Others",
    key: "Others_40_44_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Female",
    key: "Female_45_49_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Male",
    key: "Female_45_49_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Others",
    key: "Others_45_49_Yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50 Yrs and Above Female",
    key: "Female_50_Yrs_and_Above",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50 Yrs and Above Male",
    key: "Male_50_Yrs_and_Above",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50 Yrs and Above Others",
    key: "Others_50_Yrs_and_Above",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Less than 10 Yrs Female",
    key: "Less_than_10_Yrs_Female",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Less than 10 Yrs Male",
    key: "Less_than_10_Yrs_Male",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Less than 10 Yrs Others",
    key: "Less_than_10_Yrs_Others",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];

const clientsByAgeGenderDHIS2NonSRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "Services_en",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-24 yrs Female",
    key: "FemaleAge0to24",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-24 yrs Male",
    key: "MaleAge0to24",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-24 yrs Others",
    key: "OtherAge0to24",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "25 Yrs and above Female",
    key: "FemaleAge24Above",
    width: "md:w-24 lg:w-24 xl:w-32 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "25 Yrs and above Male",
    key: "MaleAge24Above",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "25 Yrs and above Others",
    key: "OtherAge24Above",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
  },
];

const clientsByAgeGenderDonorSRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "Area",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "Dataname",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Female",
    key: "FemaleAge0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Male",
    key: "MaleAge0to9",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs Others",
    key: "OtherAge0to9",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Female",
    key: "FemaleAge10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Male",
    key: "MaleAge10to14",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14 Yrs Others",
    key: "OtherAge10to14",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-18 Yrs Female",
    key: "FemaleAge15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-18 Yrs Male",
    key: "MaleAge15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "19 Yrs Others",
    key: "OtherAge19yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "19 Yrs Female",
    key: "FemaleAge19yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "19 Yrs Male",
    key: "MaleAge19yrs",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "15-18 Yrs Others",
    key: "OtherAge15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Female",
    key: "FemaleAge20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Male",
    key: "MaleAge20to24",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "20-24 Yrs Others",
    key: "OtherAge20to24",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Female",
    key: "FemaleAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Male",
    key: "MaleAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "25-29 Yrs Others",
    key: "OtherAge25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Female",
    key: "FemaleAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Male",
    key: "MaleAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "30-34 Yrs Others",
    key: "OtherAge30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Female",
    key: "FemaleAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Male",
    key: "MaleAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "35-39 Yrs Others",
    key: "OtherAge35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Female",
    key: "FemaleAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Male",
    key: "MaleAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "40-44 Yrs Others",
    key: "OtherAge40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Female",
    key: "FemaleAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Male",
    key: "MaleAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "45-49 Yrs Others",
    key: "OtherAge45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50-59 Yrs Female",
    key: "FemaleAge50to59",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50-59 Yrs Male",
    key: "MaleAge50to59",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "50-59 Yrs Others",
    key: "OtherAge50to59",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "60 Yrs and Above Female",
    key: "FemaleAge60andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "60 Yrs and Above Male",
    key: "MaleAge60andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "60 Yrs and Above Others",
    key: "OtherAge60andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];
const fpItemProvidedDHIS2SRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "PACServices_en",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-72 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs",
    key: "Age0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "10-14 Yrs",
    key: "Age10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "15-19 Yrs",
    key: "Age15to19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "20-24 Yrs",
    key: "Age20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "25-29 Yrs",
    key: "Age25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "30-34 Yrs",
    key: "Age30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "35-39 Yrs",
    key: "Age35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "40-44 Yrs",
    key: "Age40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "45-49 Yrs",
    key: "Age45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "50 Yrs and Above",
    key: "Age50andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },
];

const clientsByAgeGenderChildrenHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "Area",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "Dataname",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr Female",
    key: "FemaleLessthan1",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr Male",
    key: "MaleLessthan1",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr Others",
    key: "OtherLessthan1",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "01-02 Yrs Female",
    key: "FemaleAge01to02",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "01-02 Yrs Male",
    key: "MaleAge01to02",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "01-02 Yrs Others",
    key: "OtherAge01to02",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "03-05 Yrs Female",
    key: "FemaleAge03to05",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "03-05 Yrs Male",
    key: "MaleAge03to05",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "03-05 Yrs Others",
    key: "OtherAge03to05",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "06 - 09 Yrs Female",
    key: "FemaleAge06to09",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "06 - 09 Yrs Male",
    key: "MaleAge06to09",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "06 - 09 Yrs Others",
    key: "OtherAge06to09",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10 Yrs & Above Female",
    key: "FemaleAge10andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10 Yrs & Above Male",
    key: "MaleAge10andAbove",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "10 Yrs & Above Others",
    key: "OtherAge10andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];

const fpUserDHIS2SRHByAgeHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "PACServicesGrouped",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-56 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs",
    key: "Age0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "10-14 Yrs",
    key: "Age10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "15-19 Yrs",
    key: "Age15to19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "20-24 Yrs",
    key: "Age20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "25-29 Yrs",
    key: "Age25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "30-34 Yrs",
    key: "Age30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "35-39 Yrs",
    key: "Age35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "40-44 Yrs",
    key: "Age40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "45-49 Yrs",
    key: "Age45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "50 Yrs and Above",
    key: "Age50andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },
];

const cypDHIS2SRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Dataname",
    key: "CYP",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-72 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs",
    key: "Age0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "10-14 Yrs",
    key: "Age10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "15-19 Yrs",
    key: "Age15to19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "20-24 Yrs",
    key: "Age20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-28 px-1 py-1.5",
  },

  {
    title: "25-29 Yrs",
    key: "Age25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-32 px-1 py-1.5",
  },

  {
    title: "30-34 Yrs",
    key: "Age30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-32 px-1 py-1.5",
  },

  {
    title: "35-39 Yrs",
    key: "Age35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-32 px-1 py-1.5",
  },

  {
    title: "40-44 Yrs",
    key: "Age40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-32 px-1 py-1.5",
  },

  {
    title: "45-49 Yrs",
    key: "Age45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-32 px-1 py-1.5",
  },

  {
    title: "50 Yrs and Above",
    key: "Age50andAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-36 px-1 py-1.5",
  },
];

const KPYheaderData = [
  {
    title: "Performance",
    key: "Performance",
    width: "md:w-32 lg:w-28 xl:w-60 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Prev. year",
    key: "previous_year_count",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
  {
    title: "Current year",
    key: "current_year_count",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];

const projectMasterHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-14 2xl:w-72 px-1 py-1.5  m-auto",
    width1: "text-red-500",
  },
  {
    title: "Branch/Project",
    key: "LocationName_en",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Project",
    key: "ProjectName_en",
    width: "flex-1 px-1 py-1.5 m-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 m-auto",
  },
];

const ageDonorSRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs",
    key: "Age0to9",
    width: "md:w-20 lg:w-20 xl:w-20 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "10-14 Yrs",
    key: "Age10to14",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "15-18 Yrs",
    key: "Age15to18",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "19 Yrs",
    key: "Age19",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "20-24 Yrs",
    key: "Age20to24",
    width: "md:w-24 lg:w-24 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "25-29 Yrs",
    key: "Age25to29",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "30-34 Yrs",
    key: "Age30to34",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "35-39 Yrs",
    key: "Age35to39",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "40-44 Yrs",
    key: "Age40to44",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "45-49 Yrs",
    key: "Age45to49",
    width: "md:w-24 lg:w-28 xl:w-20 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "50-59 Yrs",
    key: "Age50to59",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "60 Yrs and Above",
    key: "Age60AndAbove",
    width: "md:w-24 lg:w-28 xl:w-28 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];
const ageChildrenHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-44 lg:w-64 xl:w-72 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Less than 1 yr",
    key: "LessThan1Yr",
    width: "md:w-28 lg:w-20 xl:w-40 whitespace-pre-line  2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "01-02 Yrs",
    key: "Age1to2",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "03-05 Yrs",
    key: "Age3to5",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
  },

  {
    title: "06 - 09 Yrs",
    key: "Age6to9",
    width: "md:w-24 lg:w-28 xl:w-36 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
  },

  {
    title: "10 Yrs & Above",
    key: "Age10AndAbove",
    width: "md:w-28 lg:w-24 xl:w-36 whitespace-pre-line 2xl:w-40 px-1 py-1.5",
  },
];

const ageDHIS2SRHHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-14 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "0-9 yrs",
    key: "Age0to9",
    width: "md:w-20 lg:w-20 xl:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "10-14 Yrs",
    key: "Age10to14",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "15-19 Yrs",
    key: "Age15to19",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "20-24 Yrs",
    key: "Age20to24",
    width: "md:w-24 lg:w-24 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "25-29 Yrs",
    key: "Age25to29",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "30-34 Yrs",
    key: "Age30to34",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "35-39 Yrs",
    key: "Age35to39",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "40-44 Yrs",
    key: "Age40to44",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "45-49 Yrs",
    key: "Age45to49",
    width: "md:w-24 lg:w-28 xl:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },

  {
    title: "50 Yrs and Above",
    key: "Age50AndAbove",
    width: "md:w-24 lg:w-28 xl:w-44 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
  },
];

const reportGenderHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-14 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-44 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Services",
    key: "Services_en",
    width: "md:w-72 lg:w-72 xl:w-96 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Female",
    key: "Female",
    width: "md:w-32 lg:w-32 xl:w-32 2xl:w-32 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Male",
    key: "Male",
    width: "md:w-32 lg:w-32 xl:w-32  2xl:w-32 px-1 py-1.5",
    width1: "text-red-600",
  },

  {
    title: "Others",
    key: "Others",
    width: "md:w-32 lg:w-32 xl:w-32 2xl:w-32 px-1 py-1.5",
  },
];

const IECSummaryHeader = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "SDP",
    key: "SDP_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Area",
    key: "AreaName_en",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Type of topic/ subject",
    key: "Typeoftopicsubject",
    width: "md:w-32 lg:w-32 xl:w-44 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Gender/Age",
    key: "GenderAge",
    width: "md:w-32 lg:w-32 xl:w-56 whitespace-pre-line  2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
    group: "Interpersonal contact (1-4 persons)",
  },
  {
    title: "10-14 Years",
    key: "tentoYears",
    width: "md:w-32 lg:w-32 xl:w-56 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
    group: "Interpersonal contact (1-4 persons)",
  },
  {
    title: "15-24 Years",
    key: "fifteentoYears",
    width: "md:w-32 lg:w-32 xl:w-56 2xl:w-60 whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
    group: "Interpersonal contact (1-4 persons)",
  },
  {
    title: " 25+ Years",
    key: "twentyfivetoYears",

    width: "md:w-32 lg:w-32 xl:w-56 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
    group: "Interpersonal contact (1-4 persons)",
  },
  {
    title: "Events",
    key: "GroupDiscussionsEvents",

    width: "md:w-32 lg:w-32 xl:w-40 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
    group: "Group discussions (5-20 persons)",
  },
  {
    title: "Participants",
    key: "GDParticipants",

    width: "md:w-32 lg:w-32 xl:w-36 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
    group: "Group discussions (5-20 persons)",
  },
  {
    title: "Events",
    key: "GeneralMassEvents",
    group: "General / Mass meetings (21+persons)",
    width: "md:w-32 lg:w-32 xl:w-36 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
  },
  {
    title: "Participants",
    key: "GMMParticipants",
    group: "General / Mass meetings (21+persons)",
    width: "md:w-32 lg:w-32 xl:w-56 whitespace-pre-line 2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Events",
    key: "MassMediaEvents",
    width: "md:w-32 lg:w-32 xl:w-24 2xl:w-60 px-1 py-1.5",
    width1: "text-red-600",
    group: "Mass media/Supporting activities",
  },
  {
    title: "Participants",
    key: "MMParticipants",
    width: "md:w-32 lg:w-32 xl:w-24 2xl:w-60 px-1 py-1.5",
    group: "Mass media/Supporting activities",
  },
];

const CSESummaryHeader = [
  {
    title: "S.No. ",
    key: "RowNumber",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-18 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Branch",
    key: "Branch",
    width: "md:w-20 lg:w-20 xl:w-36 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Module Name",
    key: "ModuleName",
    width: "md:w-20 lg:w-20 xl:w-72 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "Gender",
    key: "Gender",
    width: "md:w-24 whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
  {
    title: "10-14",
    key: "InSchool_10_14",
    width: "md:w-24  whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "A. In School/College",
  },
  {
    title: "15-19",
    key: "InSchool_15_19",
    width: "md:w-24  whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
    group: "A. In School/College",
  },
  {
    title: "20-24",
    key: "InSchool_20_24",
    width: "md:w-24  xl:w-56 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    group: "A. In School/College",
  },
  {
    title: "10-14",
    key: "OutOfSchool_10_14",
    width: "md:w-24  whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
    group: "B. Out of School youth",
  },
  {
    title: "15-19",
    key: "OutOfSchool_15_19",
    width: "md:w-24  whitespace-pre-line px-1 py-1.5",
    width1: "text-red-600",
    group: "B. Out of School youth",
  },
  {
    title: "20-24",
    key: "OutOfSchool_20_24",
    width: "md:w-24 whitespace-pre-line 2xl:w-96 px-1 py-1.5",
    group: "B. Out of School youth",
  },

  {
    title: "Cumulative",
    key: "Cumulative",
    width: "md:w-20  whitespace-pre-line  2xl:w-96 px-1 py-1.5",
    width1: "text-red-600",
  },
];

const staffNameHeaders = [
  {
    title: "S.No.",
    key: "sno",
    width: "md:w-12 lg:w-12 xl:w-12 2xl:w-12 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "State",
    key: "StateName_en",
    width: "md:w-40 lg:w-40 xl:w-60 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Branch / Project",
    key: "LocationName_en",
    width: "md:w-44 lg:w-44 xl:w-60 2xl:flex-1 px-1 py-1.5",
    width1: "text-red-500",
  },
  {
    title: "Staff Name",
    key: "StaffName_en",
    width: "md:w-44 lg:w-60 xl:w-72 2xl:flex-1 px-1 py-1.5 mr-auto",
    width1: "text-red-500",
  },
  {
    title: "Action",
    key: "action",
    width: " px-1 py-1.5 ",
  },
];

export {
  referralService,
  ReferedTo,
  AwarenessGender,
  ReferralServiceCSE,
  AwarenessAge,
  ServicesName,
  countryMasterHeader,
  stateMasterHeader,
  areaMasterHeader,
  locationMasterHeader,
  languageMasterHeader,
  userHeaders,
  clientHeaders,
  roleMasterHeader,
  campsMeetingsMasterHeader,
  healthCareHeaders,
  inventoryProductHeader,
  schoolMasterHeaders,
  studentMasterHeaders,
  appointmentHeaders,
  inventoryIssueHeaders,
  eventMasterHeaders,
  EducationMaterialMasterHeaders,
  cseInSchoolHeaders,
  cseOutSchoolHeaders,
  outOfSchoolYouthHeaders,
  inventoryReceiptsHeaders,
  NonSRHProductsMasterHeaders,
  SRHCommoditiesMasterHeaders,
  ServicesClientHeaders,
  inventoryReceiptsViewHeaders,
  inventoryIndentHeaders,
  ResourcesMasterHeader,
  servicesCategoryHeaders,
  servicesSubCategoryHeaders,
  servicesMasterHeaders,
  sdpMasterHeader,
  studentDetailsHeaders,
  BrochureMasterHeader,
  ServicesHeadersData,
  AwarenessHeader,
  YouthHeader,
  ServicesAddHeader,
  youthGroupMasterHeaders,
  roleVsCapabilityHeader,
  doctorAppoinmentsHeaders,
  supplierMasterHeader,
  spServiceSessionMasterHeaders,
  eventRegisterHeaders,
  InterPersonalContactHeader,
  GroupDiscussionHeaders,
  GeneralMassMeetingHeaders,
  MassMediaHeaders,
  OutSchoolHeaders,
  SingleSessionOnlineHeader,
  SingleSessionHeader,
  SpecialServiceSessionHeader,
  contraceptiveHeader,
  socialMarketingHeader,
  medicinesDrugsHeader,
  pendingIndentHeader,
  cseHeaders,
  PartnerOrganizationHeader,
  inschoolsessionStudentHeader,
  CBPHeader,
  brandHeaders,
  ReportInschoolSession,
  inventoryIssueDetailsHeaders,
  indentDetailsHeader,
  issueDetailsHeader,
  issueDetailsEditHeader,
  issueIndentDetailsHeader,
  issueIndentDetailsEditHeader,
  issueDetailsHeaderPopup,
  ServicesHeaders,
  consumptionHeaders,
  consumablesHeader,
  ReferralFollowUpNotificationsHeader,
  issueDetailsPopUpHeader,
  ReferralTrackingHeaders,
  clientChildrenDetailsHeaders,
  contraceptiveRSFHeader,
  openingStockHeader,
  ConsumptionViewHeaders,
  districtMasterHeader,
  blockMasterHeader,
  cityvillageMasterHeader,
  inventoryDirectIssueHeaders,
  CSEInSchoolReportHeader,
  projectMasterHeaders,
  CSEOutSchoolReportHeader,
  CSEReferralsReportHeader,
  DropoutsReportHeader,
  iecReferralsReportHeader,
  iecIPCReportHeader,
  iecMMReportHeader,
  iecGDReportHeader,
  iecGMMReportHeader,
  ProfileCATClientsReportHeader,
  ProfilePACReportHeader,
  ClientProfileMEReportHeader,
  ageGenderDHIS2SRHReportHeader,
  ageGenderChildrenReportHeader,
  clientsByAgeGenderDHIS2SRHHeader,
  clientsByAgeGenderDonorSRHHeader,
  fpItemProvidedDHIS2SRHHeader,
  clientsByAgeGenderChildrenHeader,
  fpUserDHIS2SRHByAgeHeader,
  cypDHIS2SRHHeader,
  KPYheaderData,
  ageGenderDonorSRHHeader,
  ageDonorSRHHeader,
  SingleSessionReportHeader,
  ageChildrenHeader,
  ageDHIS2SRHHeader,
  MonthlyEventsReportHeader,
  reportGenderHeader,
  clientsByAgeGenderDHIS2NonSRHHeader,
  StockReportBranchHeader,
  IECSummaryHeader,
  StockReportSDPHeader,
  CSESummaryHeader,
  staffNameHeaders,
  ServicesAddViewHeader,
  StockReportBranchLevelHeader,
  StockReportORSLevelHeader,
  StockReportORSHeader,
  StockReportSDPLevelHeader,
  StockSDPHeader,
};
