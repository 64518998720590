import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { clientsByAgeGenderDHIS2SRHHeader } from "../components/HeaderAttribute";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import * as XLSX from "sheetjs-style";
import { useUserCapability } from "../components/ConstantReducerProvider";
import InputText from "../ReusableComponents/InputText";

const ClientsByAgeGenderDHIS2SRHReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchName, setBranchName] = useState("");
  const [branchProject, setBranchProject] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindClientsByAgeGenderDHIS2SRH, setBindClientsByAgeGenderDHIS2SRH] =
    useState([]);
  const [showTable, setShowTable] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);
    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchProjectArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchProjectResult = branchProjectArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchProjectResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      console.log("ReportData", ReportData);
      const response = await BindPostAPI(
        ReportData,
        "ClientAPI/ReportClientsByAgeGenderDHIS2SRH"
      );

      console.log("response", response.data.Table);
      setBindClientsByAgeGenderDHIS2SRH(response.data.Table);
      setShowTable(true);
    } else {
    }
  };

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };

    exportData(
      clientsByAgeGenderDHIS2SRHHeader.map((column) => column.title),
      bindClientsByAgeGenderDHIS2SRH,
      dateRange,
      selectedOptionsData
    );
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    // Title and date range rows configuration
    const titleRow = [
      {
        v: "Clients by age_gender - SRH",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    const formatDate = (dateStr) => {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      return `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`;
    };

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    // Modified header row with wrapped text
    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true, // Enable text wrapping for headers
          },
        },
      })),
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];

    // Rest of your existing code for columnTotals, dataRows, etc...
    // ... (keep existing code)

    const columnTotals = {
      Female_10_14_Yrs: 0,
      Male_10_14_Yrs: 0,
      Others_10_14_Yrs: 0,
      Female_15_19_Yrs: 0,
      Male_15_19_Yrs: 0,
      Others_15_19_Yrs: 0,
      Female_20_24_Yrs: 0,
      Male_20_24_Yrs: 0,
      Others_20_24_Yrs: 0,
      Female_25_29_Yrs: 0,
      Male_25_29_Yrs: 0,
      Others_25_29_Yrs: 0,
      Female_30_34_Yrs: 0,
      Male_30_34_Yrs: 0,
      Others_30_34_Yrs: 0,
      Female_35_39_Yrs: 0,
      Male_35_39_Yrs: 0,
      Others_35_39_Yrs: 0,
      Female_40_44_Yrs: 0,
      Male_40_44_Yrs: 0,
      Others_40_44_Yrs: 0,
      Female_45_49_Yrs: 0,
      Male_45_49_Yrs: 0,
      Others_45_49_Yrs: 0,
      Female_50_Yrs_and_Above: 0,
      Male_50_Yrs_and_Above: 0,
      Others_50_Yrs_and_Above: 0,
      Less_than_10_Yrs_Female: 0,
      Less_than_10_Yrs_Male: 0,
      Less_than_10_Yrs_Others: 0,
    };

    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.Female_10_14_Yrs || 0) +
        Number(rowData.Male_10_14_Yrs || 0) +
        Number(rowData.Others_10_14_Yrs || 0) +
        Number(rowData.Female_15_19_Yrs || 0) +
        Number(rowData.Male_15_19_Yrs || 0) +
        Number(rowData.Others_15_19_Yrs || 0) +
        Number(rowData.Female_20_24_Yrs || 0) +
        Number(rowData.Male_20_24_Yrs || 0) +
        Number(rowData.Others_20_24_Yrs || 0) +
        Number(rowData.Female_25_29_Yrs || 0) +
        Number(rowData.Male_25_29_Yrs || 0) +
        Number(rowData.Others_25_29_Yrs || 0) +
        Number(rowData.Female_30_34_Yrs || 0) +
        Number(rowData.Male_30_34_Yrs || 0) +
        Number(rowData.Others_30_34_Yrs || 0) +
        Number(rowData.Female_35_39_Yrs || 0) +
        Number(rowData.Male_35_39_Yrs || 0) +
        Number(rowData.Others_35_39_Yrs || 0) +
        Number(rowData.Female_40_44_Yrs || 0) +
        Number(rowData.Male_40_44_Yrs || 0) +
        Number(rowData.Others_40_44_Yrs || 0) +
        Number(rowData.Female_45_49_Yrs || 0) +
        Number(rowData.Male_45_49_Yrs || 0) +
        Number(rowData.Others_45_49_Yrs || 0) +
        Number(rowData.Female_50_Yrs_and_Above || 0) +
        Number(rowData.Male_50_Yrs_and_Above || 0) +
        Number(rowData.Others_50_Yrs_and_Above || 0) +
        Number(rowData.Less_than_10_Yrs_Female || 0) +
        Number(rowData.Less_than_10_Yrs_Male || 0) +
        Number(rowData.Less_than_10_Yrs_Others || 0);

      columnTotals.Female_10_14_Yrs += Number(rowData.Female_10_14_Yrs || 0);
      columnTotals.Male_10_14_Yrs += Number(rowData.Male_10_14_Yrs || 0);
      columnTotals.Others_10_14_Yrs += Number(rowData.Others_10_14_Yrs || 0);
      columnTotals.Female_15_19_Yrs += Number(rowData.Female_15_19_Yrs || 0);
      columnTotals.Male_15_19_Yrs += Number(rowData.Male_15_19_Yrs || 0);
      columnTotals.Others_15_19_Yrs += Number(rowData.Others_15_19_Yrs || 0);
      columnTotals.Female_20_24_Yrs += Number(rowData.Female_20_24_Yrs || 0);
      columnTotals.Male_20_24_Yrs += Number(rowData.Male_20_24_Yrs || 0);
      columnTotals.Others_20_24_Yrs += Number(rowData.Others_20_24_Yrs || 0);
      columnTotals.Female_25_29_Yrs += Number(rowData.Female_25_29_Yrs || 0);
      columnTotals.Male_25_29_Yrs += Number(rowData.Male_25_29_Yrs || 0);
      columnTotals.Others_25_29_Yrs += Number(rowData.Others_25_29_Yrs || 0);
      columnTotals.Female_30_34_Yrs += Number(rowData.Female_30_34_Yrs || 0);
      columnTotals.Male_30_34_Yrs += Number(rowData.Male_30_34_Yrs || 0);
      columnTotals.Others_30_34_Yrs += Number(rowData.Others_30_34_Yrs || 0);
      columnTotals.Female_35_39_Yrs += Number(rowData.Female_35_39_Yrs || 0);
      columnTotals.Male_35_39_Yrs += Number(rowData.Male_35_39_Yrs || 0);
      columnTotals.Others_35_39_Yrs += Number(rowData.Others_35_39_Yrs || 0);
      columnTotals.Female_40_44_Yrs += Number(rowData.Female_40_44_Yrs || 0);
      columnTotals.Male_40_44_Yrs += Number(rowData.Male_40_44_Yrs || 0);
      columnTotals.Others_40_44_Yrs += Number(rowData.Others_40_44_Yrs || 0);
      columnTotals.Female_45_49_Yrs += Number(rowData.Female_45_49_Yrs || 0);
      columnTotals.Male_45_49_Yrs += Number(rowData.Male_45_49_Yrs || 0);
      columnTotals.Others_45_49_Yrs += Number(rowData.Others_45_49_Yrs || 0);
      columnTotals.Female_50_Yrs_and_Above += Number(
        rowData.Female_50_Yrs_and_Above || 0
      );
      columnTotals.Male_50_Yrs_and_Above += Number(
        rowData.Male_50_Yrs_and_Above || 0
      );
      columnTotals.Others_50_Yrs_and_Above += Number(
        rowData.Others_50_Yrs_and_Above || 0
      );
      columnTotals.Less_than_10_Yrs_Female += Number(
        rowData.Less_than_10_Yrs_Female || 0
      );
      columnTotals.Less_than_10_Yrs_Male += Number(
        rowData.Less_than_10_Yrs_Male || 0
      );
      columnTotals.Less_than_10_Yrs_Others += Number(
        rowData.Less_than_10_Yrs_Others || 0
      );

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Area,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.dataname,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.Female_10_14_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_10_14_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_10_14_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_15_19_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_15_19_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_15_19_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_20_24_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_20_24_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_20_24_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_25_29_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_25_29_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_25_29_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_30_34_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_30_34_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_30_34_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_35_39_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_35_39_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_35_39_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_40_44_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_40_44_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_40_44_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_45_49_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_45_49_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_45_49_Yrs || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female_50_Yrs_and_Above || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Male_50_Yrs_and_Above || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others_50_Yrs_and_Above || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Less_than_10_Yrs_Female || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Less_than_10_Yrs_Male || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Less_than_10_Yrs_Others || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      {
        v: "Totals",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      {
        v: columnTotals.Female_10_14_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_10_14_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_10_14_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_15_19_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_15_19_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_15_19_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_20_24_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_20_24_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_20_24_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_25_29_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_25_29_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_25_29_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_30_34_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_30_34_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_30_34_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_35_39_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_35_39_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_35_39_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_40_44_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_40_44_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_40_44_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_45_49_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_45_49_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_45_49_Yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Female_50_Yrs_and_Above,
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Male_50_Yrs_and_Above,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Others_50_Yrs_and_Above,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Less_than_10_Yrs_Female,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Less_than_10_Yrs_Male,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.Less_than_10_Yrs_Others,
        t: "n",
        s: totalsRowStyle,
      },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    // Combine all rows with new headers
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow,
      ...dataRows,
      totalsRow,
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // // Set merged cells for date range and branch information
    // ws["!merges"] = [
    //   // Merge title cell across columns
    //   { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },

    //   // //Merge from and To date
    //   // { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } },

    //   // Merge branch name across columns
    //   { s: { r: 2, c: 2 }, e: { r: 2, c: 4 } },
    //   // Merge SDP name across columns
    //   { s: { r: 2, c: 6 }, e: { r: 2, c: 8 } },

    //   // // Merge Area name across columns
    //   { s: { r: 2, c: 10 }, e: { r: 2, c: 12 } },
    // ];

    // Modified column widths
    ws["!cols"] = [
      { width: 5 }, // Index
      { width: 38 }, // Dataname
      ...columns.slice(2).map(() => ({ width: 15 })), // Data columns
      { width: 15 }, // Total column
    ];

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create and save workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Clients by age_gender - SRH.xlsx");
  };

  // const exportData = (columns, rows) => {
  //   // Title row configuration
  //   const titleRow = [
  //     {
  //       v: "Clients by age_gender - SRH",
  //       s: {
  //         font: { bold: true, sz: 16 },
  //         alignment: { horizontal: "left" },
  //       },
  //     },
  //   ];

  //   // const headerRow = columns.map((column) => ({
  //   //   v: column,
  //   //   s: {
  //   //     fill: {
  //   //       fgColor: { rgb: "2D2D6E" }, // Background color (dark blue)
  //   //     },
  //   //     font: {
  //   //       bold: true,
  //   //       color: { rgb: "FFFFFF" }, // Font color (white)
  //   //     },
  //   //   },
  //   // }));

  //   // Add "Row Total" to headers
  //   const headerRow = [
  //     ...columns.map((column) => ({
  //       v: column,
  //       s: {
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         alignment: { horizontal: "left" },
  //       },
  //     })),
  //     {
  //       v: "Total",
  //       s: {
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         alignment: { horizontal: "right" },
  //       },
  //     },
  //   ];

  //   const columnTotals = {
  //     Female_10_14_Yrs: 0,
  //     Male_10_14_Yrs: 0,
  //     Others_10_14_Yrs: 0,
  //     Female_15_19_Yrs: 0,
  //     Male_15_19_Yrs: 0,
  //     Others_15_19_Yrs: 0,
  //     Female_20_24_Yrs: 0,
  //     Male_20_24_Yrs: 0,
  //     Others_20_24_Yrs: 0,
  //     Female_25_29_Yrs: 0,
  //     Male_25_29_Yrs: 0,
  //     Others_25_29_Yrs: 0,
  //     Female_30_34_Yrs: 0,
  //     Male_30_34_Yrs: 0,
  //     Others_30_34_Yrs: 0,
  //     Female_35_39_Yrs: 0,
  //     Male_35_39_Yrs: 0,
  //     Others_35_39_Yrs: 0,
  //     Female_40_44_Yrs: 0,
  //     Male_40_44_Yrs: 0,
  //     Others_40_44_Yrs: 0,
  //     Female_45_49_Yrs: 0,
  //     Male_45_49_Yrs: 0,
  //     Others_45_49_Yrs: 0,
  //     Female_50_Yrs_and_Above: 0,
  //     Male_50_Yrs_and_Above: 0,
  //     Others_50_Yrs_and_Above: 0,
  //     Less_than_10_Yrs_Female: 0,
  //     Less_than_10_Yrs_Male: 0,
  //     Less_than_10_Yrs_Others: 0,
  //   };

  //   const dataRows = rows.map((rowData, index) => {
  //     const isAlternateRow = index % 2 === 1;
  //     const baseStyle = isAlternateRow
  //       ? { fill: { fgColor: { rgb: "F0F0F0" } } }
  //       : {};

  //     // Calculate row total for numeric fields
  //     const rowTotal =
  //       Number(rowData.Female_10_14_Yrs || 0) +
  //       Number(rowData.Male_10_14_Yrs || 0) +
  //       Number(rowData.Others_10_14_Yrs || 0) +
  //       Number(rowData.Female_15_19_Yrs || 0) +
  //       Number(rowData.Male_15_19_Yrs || 0) +
  //       Number(rowData.Others_15_19_Yrs || 0) +
  //       Number(rowData.Female_20_24_Yrs || 0) +
  //       Number(rowData.Male_20_24_Yrs || 0) +
  //       Number(rowData.Others_20_24_Yrs || 0) +
  //       Number(rowData.Female_25_29_Yrs || 0) +
  //       Number(rowData.Male_25_29_Yrs || 0) +
  //       Number(rowData.Others_25_29_Yrs || 0) +
  //       Number(rowData.Female_30_34_Yrs || 0) +
  //       Number(rowData.Male_30_34_Yrs || 0) +
  //       Number(rowData.Others_30_34_Yrs || 0) +
  //       Number(rowData.Female_35_39_Yrs || 0) +
  //       Number(rowData.Male_35_39_Yrs || 0) +
  //       Number(rowData.Others_35_39_Yrs || 0) +
  //       Number(rowData.Female_40_44_Yrs || 0) +
  //       Number(rowData.Male_40_44_Yrs || 0) +
  //       Number(rowData.Others_40_44_Yrs || 0) +
  //       Number(rowData.Female_45_49_Yrs || 0) +
  //       Number(rowData.Male_45_49_Yrs || 0) +
  //       Number(rowData.Others_45_49_Yrs || 0) +
  //       Number(rowData.Female_50_Yrs_and_Above || 0) +
  //       Number(rowData.Male_50_Yrs_and_Above || 0) +
  //       Number(rowData.Others_50_Yrs_and_Above || 0) +
  //       Number(rowData.Less_than_10_Yrs_Female || 0) +
  //       Number(rowData.Less_than_10_Yrs_Male || 0) +
  //       Number(rowData.Less_than_10_Yrs_Others || 0);

  //     columnTotals.Female_10_14_Yrs += Number(rowData.Female_10_14_Yrs || 0);
  //     columnTotals.Male_10_14_Yrs += Number(rowData.Male_10_14_Yrs || 0);
  //     columnTotals.Others_10_14_Yrs += Number(rowData.Others_10_14_Yrs || 0);
  //     columnTotals.Female_15_19_Yrs += Number(rowData.Female_15_19_Yrs || 0);
  //     columnTotals.Male_15_19_Yrs += Number(rowData.Male_15_19_Yrs || 0);
  //     columnTotals.Others_15_19_Yrs += Number(rowData.Others_15_19_Yrs || 0);
  //     columnTotals.Female_20_24_Yrs += Number(rowData.Female_20_24_Yrs || 0);
  //     columnTotals.Male_20_24_Yrs += Number(rowData.Male_20_24_Yrs || 0);
  //     columnTotals.Others_20_24_Yrs += Number(rowData.Others_20_24_Yrs || 0);
  //     columnTotals.Female_25_29_Yrs += Number(rowData.Female_25_29_Yrs || 0);
  //     columnTotals.Male_25_29_Yrs += Number(rowData.Male_25_29_Yrs || 0);
  //     columnTotals.Others_25_29_Yrs += Number(rowData.Others_25_29_Yrs || 0);
  //     columnTotals.Female_30_34_Yrs += Number(rowData.Female_30_34_Yrs || 0);
  //     columnTotals.Male_30_34_Yrs += Number(rowData.Male_30_34_Yrs || 0);
  //     columnTotals.Others_30_34_Yrs += Number(rowData.Others_30_34_Yrs || 0);
  //     columnTotals.Female_35_39_Yrs += Number(rowData.Female_35_39_Yrs || 0);
  //     columnTotals.Male_35_39_Yrs += Number(rowData.Male_35_39_Yrs || 0);
  //     columnTotals.Others_35_39_Yrs += Number(rowData.Others_35_39_Yrs || 0);
  //     columnTotals.Female_40_44_Yrs += Number(rowData.Female_40_44_Yrs || 0);
  //     columnTotals.Male_40_44_Yrs += Number(rowData.Male_40_44_Yrs || 0);
  //     columnTotals.Others_40_44_Yrs += Number(rowData.Others_40_44_Yrs || 0);
  //     columnTotals.Female_45_49_Yrs += Number(rowData.Female_45_49_Yrs || 0);
  //     columnTotals.Male_45_49_Yrs += Number(rowData.Male_45_49_Yrs || 0);
  //     columnTotals.Others_45_49_Yrs += Number(rowData.Others_45_49_Yrs || 0);
  //     columnTotals.Female_50_Yrs_and_Above += Number(rowData.Female_50_Yrs_and_Above || 0);
  //     columnTotals.Male_50_Yrs_and_Above += Number(rowData.Male_50_Yrs_and_Above || 0);
  //     columnTotals.Others_50_Yrs_and_Above += Number(rowData.Others_50_Yrs_and_Above || 0);
  //     columnTotals.Less_than_10_Yrs_Female += Number(
  //       rowData.Less_than_10_Yrs_Female || 0
  //     );
  //     columnTotals.Less_than_10_Yrs_Male += Number(rowData.Less_than_10_Yrs_Male || 0);
  //     columnTotals.Less_than_10_Yrs_Others += Number(
  //       rowData.Less_than_10_Yrs_Others || 0
  //     );

  //     return [
  //       {
  //         v: index + 1,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.Dataname,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: Number(rowData.Female_10_14_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_10_14_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_10_14_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_15_19_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_15_19_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_15_19_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_20_24_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_20_24_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_20_24_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_25_29_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_25_29_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_25_29_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_30_34_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_30_34_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_30_34_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_35_39_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_35_39_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_35_39_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_40_44_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_40_44_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_40_44_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_45_49_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_45_49_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_45_49_Yrs || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Female_50_Yrs_and_Above || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Male_50_Yrs_and_Above || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Others_50_Yrs_and_Above || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Less_than_10_Yrs_Female || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Less_than_10_Yrs_Male || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.Less_than_10_Yrs_Others || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: rowTotal,
  //         t: "n",
  //         s: {
  //           ...baseStyle,
  //           font: { bold: true },
  //           alignment: { horizontal: "right" },
  //           numFmt: "0",
  //         },
  //       },
  //     ];
  //   });

  //   // Create totals row
  //   const totalsRowStyle = {
  //     font: { bold: true },
  //     fill: { fgColor: { rgb: "E0E0E0" } },
  //     alignment: { horizontal: "right" },
  //     numFmt: "0",
  //   };

  //   const grandTotal = Object.values(columnTotals).reduce(
  //     (sum, val) => sum + val,
  //     0
  //   );

  //   const totalsRow = [
  //     { v: "", s: totalsRowStyle },
  //     {
  //       v: "Totals",
  //       s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
  //     },
  //     {
  //       v: columnTotals.Female_10_14_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_10_14_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_10_14_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_15_19_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_15_19_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_15_19_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_20_24_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_20_24_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_20_24_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_25_29_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_25_29_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_25_29_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_30_34_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_30_34_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_30_34_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_35_39_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_35_39_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_35_39_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_40_44_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_40_44_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_40_44_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_45_49_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_45_49_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_45_49_Yrs,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Female_50_Yrs_and_Above,
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Male_50_Yrs_and_Above,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Others_50_Yrs_and_Above,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Less_than_10_Yrs_Female,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Less_than_10_Yrs_Male,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     {
  //       v: columnTotals.Less_than_10_Yrs_Others,
  //       t: "n",
  //       s: totalsRowStyle,
  //     },
  //     // Grand total
  //     {
  //       v: grandTotal,
  //       t: "n",
  //       s: {
  //         ...totalsRowStyle,
  //         font: { bold: true, color: { rgb: "2D2D6E" } },
  //       },
  //     },
  //   ];

  //   const wsData = [titleRow, headerRow, ...dataRows, totalsRow];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);
  //   // const wsData = [headerRow, ...dataRows];
  //   // const ws = XLSX.utils.aoa_to_sheet(wsData);

  //   // Set custom column widths
  //   ws["!cols"] = [
  //     { width: 5 }, // Index
  //     { width: 38 }, // Dataname
  //     { width: 14 }, // Constant width
  //     { width: 14 }, // Constant width
  //     { width: 14 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 },
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 15 }, // Constant width
  //     { width: 24 }, // Constant width
  //     { width: 24 }, // Constant width
  //     { width: 24 }, // Constant width
  //     { width: 15 }, // Row Total
  //   ];

  //   // Border style for all cells
  //   const borderStyle = {
  //     border: {
  //       top: { style: "thin", color: { rgb: "00000000" } },
  //       bottom: { style: "thin", color: { rgb: "00000000" } },
  //       left: { style: "thin", color: { rgb: "00000000" } },
  //       right: { style: "thin", color: { rgb: "00000000" } },
  //     },
  //   };

  //   const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
  //   ws[titleCell].s = titleRow[0].s;

  //   // Header row
  //   headerRow.forEach((cell, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
  //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
  //   });

  //   // Data rows
  //   dataRows.forEach((row, rowIndex) => {
  //     row.forEach((cell, colIndex) => {
  //       const cellAddress = XLSX.utils.encode_cell({
  //         r: rowIndex + 2,
  //         c: colIndex,
  //       });
  //       ws[cellAddress] = {
  //         v: cell.v,
  //         t: cell.t,
  //         s: { ...cell.s, ...borderStyle },
  //       };
  //     });
  //   });

  //   // Totals row
  //   totalsRow.forEach((cell, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({
  //       r: dataRows.length + 2,
  //       c: colIndex,
  //     });
  //     ws[cellAddress] = {
  //       v: cell.v,
  //       t: cell.t,
  //       s: { ...cell.s, ...borderStyle },
  //     };
  //   });

  //   // dataRows.forEach((row, rowIndex) => {
  //   //   row.forEach((cell, colIndex) => {
  //   //     const cellAddress = XLSX.utils.encode_cell({
  //   //       r: rowIndex + 1,
  //   //       c: colIndex,
  //   //     });
  //   //     ws[cellAddress] = { v: cell.v, s: cell.s };
  //   //   });
  //   // });

  //   // Create and save workbook
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   XLSX.writeFile(wb, "Clients by age_gender - SRH.xlsx");
  //   // const wb = XLSX.utils.book_new();
  //   // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // XLSX.writeFile(wb, "Clients by age_gender - SRH.xlsx");
  // };

  // const exportData = (columns, rows) => {

  //   const headerRow = columns.map((column) => ({
  //     v: column,
  //     s: {
  //       fill: {
  //         fgColor: { rgb: "2D2D6E" }, // Background color (dark blue)
  //       },
  //       font: {
  //         bold: true,
  //         color: { rgb: "FFFFFF" }, // Font color (white)
  //       },
  //     },
  //   }));

  //   const dataRows = rows.map((rowData, index) => {
  //     const isAlternateRow = index % 2 === 1;
  //     const rowStyle = isAlternateRow
  //       ? { fill: { fgColor: { rgb: "F0F0F0" } } }
  //       : {};

  //     return [
  //       { v: index + 1, s: rowStyle },
  //       { v: rowData.Dataname, s: rowStyle },
  //       { v: rowData.Female_10_14_Yrs, s: rowStyle },
  //       { v: rowData.Male_10_14_Yrs, s: rowStyle },
  //       { v: rowData.Others_10_14_Yrs, s: rowStyle },
  //       { v: rowData.Female_15_19_Yrs, s: rowStyle },
  //       { v: rowData.Male_15_19_Yrs, s: rowStyle },
  //       { v: rowData.Others_15_19_Yrs, s: rowStyle },
  //       { v: rowData.Female_20_24_Yrs, s: rowStyle },
  //       { v: rowData.Male_20_24_Yrs, s: rowStyle },
  //       { v: rowData.Others_20_24_Yrs, s: rowStyle },
  //       { v: rowData.Female_25_29_Yrs, s: rowStyle },
  //       { v: rowData.Male_25_29_Yrs, s: rowStyle },
  //       { v: rowData.Others_25_29_Yrs, s: rowStyle },
  //       { v: rowData.Female_30_34_Yrs, s: rowStyle },
  //       { v: rowData.Male_30_34_Yrs, s: rowStyle },
  //       { v: rowData.Others_30_34_Yrs, s: rowStyle },
  //       { v: rowData.Female_35_39_Yrs, s: rowStyle },
  //       { v: rowData.Male_35_39_Yrs, s: rowStyle },
  //       { v: rowData.Others_35_39_Yrs, s: rowStyle },
  //       { v: rowData.Female_40_44_Yrs, s: rowStyle },
  //       { v: rowData.Male_40_44_Yrs, s: rowStyle },
  //       { v: rowData.Others_40_44_Yrs, s: rowStyle },
  //       { v: rowData.Female_45_49_Yrs, s: rowStyle },
  //       { v: rowData.Male_45_49_Yrs, s: rowStyle },
  //       { v: rowData.Others_45_49_Yrs, s: rowStyle },
  //       { v: rowData.Female_50_Yrs_and_Above, s: rowStyle },
  //       { v: rowData.Male_50_Yrs_and_Above, s: rowStyle },
  //       { v: rowData.Others_50_Yrs_and_Above, s: rowStyle },
  //       { v: rowData.Less_than_10_Yrs_Female, s: rowStyle },
  //       { v: rowData.Less_than_10_Yrs_Male, s: rowStyle },
  //       { v: rowData.Less_than_10_Yrs_Others, s: rowStyle },
  //     ];
  //   });

  //   const wsData = [headerRow, ...dataRows];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);

  //   headerRow.forEach((cell, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
  //     ws[cellAddress].s = cell.s;
  //   });

  //   dataRows.forEach((row, rowIndex) => {
  //     row.forEach((cell, colIndex) => {
  //       const cellAddress = XLSX.utils.encode_cell({
  //         r: rowIndex + 1,
  //         c: colIndex,
  //       });
  //       ws[cellAddress] = { v: cell.v, s: cell.s };
  //     });
  //   });

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   XLSX.writeFile(wb, "Clients by age_gender - SRH.xlsx");
  // };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    const headers = clientsByAgeGenderDHIS2SRHHeader.map(
      (column) => column.title
    );

    const data = bindClientsByAgeGenderDHIS2SRH.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.SDP,
        rowData.Area,
        rowData.dataname,
        rowData.Female_10_14_Yrs,
        rowData.Male_10_14_Yrs,
        rowData.Others_10_14_Yrs,
        rowData.Female_15_19_Yrs,
        rowData.Male_15_19_Yrs,
        rowData.Others_15_19_Yrs,
        rowData.Female_20_24_Yrs,
        rowData.Male_20_24_Yrs,
        rowData.Others_20_24_Yrs,
        rowData.Female_25_29_Yrs,
        rowData.Male_25_29_Yrs,
        rowData.Others_25_29_Yrs,
        rowData.Female_30_34_Yrs,
        rowData.Male_30_34_Yrs,
        rowData.Others_30_34_Yrs,
        rowData.Female_35_39_Yrs,
        rowData.Male_35_39_Yrs,
        rowData.Others_35_39_Yrs,
        rowData.Female_40_44_Yrs,
        rowData.Male_40_44_Yrs,
        rowData.Others_40_44_Yrs,
        rowData.Female_45_49_Yrs,
        rowData.Male_45_49_Yrs,
        rowData.Others_45_49_Yrs,
        rowData.Female_50_Yrs_and_Above,
        rowData.Male_50_Yrs_and_Above,
        rowData.Others_50_Yrs_and_Above,
        rowData.Less_than_10_Yrs_Female,
        rowData.Less_than_10_Yrs_Male,
        rowData.Less_than_10_Yrs_Others,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Auto adjust width
        7: { cellWidth: "auto" }, // Auto adjust width
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Age
        11: { cellWidth: "auto" }, // Gender
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Index column
        17: { cellWidth: "auto" }, // Auto adjust width
        18: { cellWidth: "auto" }, // Auto adjust width
        19: { cellWidth: "auto" }, // Auto adjust width
        20: { cellWidth: "auto" }, // Auto adjust width
        21: { cellWidth: "auto" }, // Auto adjust width
        22: { cellWidth: "auto" }, // Auto adjust width
        23: { cellWidth: "auto" }, // Auto adjust width
        24: { cellWidth: "auto" }, // Auto adjust width
        25: { cellWidth: "auto" }, // Auto adjust width
        26: { cellWidth: "auto" }, // Age
        27: { cellWidth: "auto" }, // Gender
        28: { cellWidth: "auto" }, // Auto adjust width
        29: { cellWidth: "auto" }, // Auto adjust width
        30: { cellWidth: "auto" }, // Auto adjust width
        31: { cellWidth: "auto" }, // Auto adjust width
        32: { cellWidth: "auto" }, // Auto adjust width
        33: { cellWidth: "auto" }, // Auto adjust width
        34: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("Clients by age_gender - SRH.pdf");
  };
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setShowTable(false);
    setBranchProject(updatedOptions);
    console.log("updatedOptions", updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setShowTable(false);
    setSDP(updatedOptions);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
    console.log("sdpNamesList", sdpNamesList);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    setArea(updatedOptions);
    setShowTable(false);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  useEffect(() => {
    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    getSelectedSDP();
  }, []);

  useEffect(() => {
    if (branchProject) {
      try {
        const getSelectedSDP = async () => {
          const locationResponse = await BindAPI("SDPAPI/GetSDP");
          setGetSDPDatas(locationResponse.data.Table);

          if (userProfileDatas && userProfileDatas[0]) {
            const sdpLabels = locationResponse.data.Table.filter((item) =>
              branchProject.includes(parseInt(item.MstLocationId))
            ).map((item) => ({
              MstSDPId: item.MstSDPId,
              SDP_en: item.SDP_en,
            }));

            const sdpSelected = sdpLabels.map((SD) => ({
              value: SD.MstSDPId,
              label: SD.SDP_en,
            }));

            if (
              userProfileDatas[0].MstOrganizationId == 1 ||
              userProfileDatas[0].MstOrganizationId == 2
            ) {
              setBindSDP(sdpSelected);
            } else {
              const allSDPIds = userProfileDatas[0]?.AllSDP;

              if (allSDPIds && Array.isArray(allSDPIds)) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  allSDPIds.includes(sdp.value)
                );
                setBindSDP(filteredSdpSelected);
              }
              const sdpId = userProfileDatas[0]?.MstSDPId;
              if (sdpId) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  Array.isArray(sdpId)
                    ? sdpId.includes(sdp.value)
                    : sdpId === sdp.value
                );

                setBindSDP(filteredSdpSelected);
              }
            }
          }
        };

        getSelectedSDP();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [
    branchProject ? branchProject : undefined,
    userProfileDatas ? userProfileDatas : undefined,
  ]);

  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);
  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Clients by age_gender - SRH`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => {setFromDate(e.target.value);setShowTable(false);}}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => {setToDate(e.target.value);setShowTable(false);}}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindClientsByAgeGenderDHIS2SRH.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={clientsByAgeGenderDHIS2SRHHeader}
                records={bindClientsByAgeGenderDHIS2SRH}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ClientsByAgeGenderDHIS2SRHReport;
