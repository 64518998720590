// Code review - Completed
// Documentation - Completed
import React, { useState, useEffect } from "react";
import { sdpMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import SDP from "../popup-containers/SDP";
import MainPopup from "../popup-containers/MainPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const AreaMaster = () => {
  const location1 = useLocation();
  //All bind dropdown value and bind data value useState hooks handling.
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [bindCountriesDD, setBindCountriesDD] = useState([]);
  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindLocationDropDown, setBindLocationDropDown] = useState([]);
  const [bindSdpMasterDatas, setBindSdpMasterDatas] = useState([]);

  //All visible functionality useState hooks handling.
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // SDP Master insert and edit useState hooks handling .
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const [sdp, setSdp] = useState("");
  const [sdpError, setSdpError] = useState("");
  const [sdpCode, setSdpCode] = useState("");
  const [sdpCodeError, setSdpCodeError] = useState("");
  const [mstSDPId, setMstSDPId] = useState("");
  const [sdpData, setSDPData] = useState("");

  const createdBy = sessionStorage.getItem("createdBy");

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  // This is grid page add button visible handling functionality .
  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    SDPData();
    setLocation("");
    setState("");
    setSdp("");
    setSdpCode("");
    setLocationError("");
    setStateError("");
    setSdpError("");
    setSdpCodeError("");
    SDPData();
  };

  //This is SDP Master grid data bind functionality and SDP Master dropdown value bind functionality .
  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));

        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));

        setBindStateDropDown(statesData);
        setBindLocationDropDown(locationData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    SDPData();
    dropDownDatas();
  }, []);

  const SDPData = async () => {
    const response = await BindAPI("SDPAPI/BindSDP");
    setBindSdpMasterDatas(response.data.Table);
  };
  // this function is bind the dropdown value for location what we select in state dropdown that selected related only show the location dropdown .
  useEffect(() => {
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state
    );
    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId,
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown(locationSelected);
    } else {
    }
  }, [state]);

  // this function is helped to edit the input value via rowindex passing and helping to visible handling.
  const handleEdit = (rowIndex) => {
    // setEditMode(true);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setMstSDPId(bindSdpMasterDatas[rowIndex].MstSDPId);
    setSdp(bindSdpMasterDatas[rowIndex].SDP_en);
    setSdpCode(bindSdpMasterDatas[rowIndex].SDPCode);
    setLocation(bindSdpMasterDatas[rowIndex].MstLocationId);
    setState(bindSdpMasterDatas[rowIndex].MstStateId);
  };

  // this function is helped to the viwe, delete, block and unblock input value via rowindex passing.
  const HandleSDP = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setSDPData(bindSdpMasterDatas[rowIndex]);

    const SDPData = bindSdpMasterDatas[rowIndex];
    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === SDPData.MstStateId
    );
    const editStateDropDownValue = {
      value: SDPData.MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };
    //State DropDown Value End

    //Location DropDown Value Start
    const locationDropDownValue = bindLocationDropDown.find(
      (item) => item.value === SDPData.MstLocationId
    );
    const editLocationDropDownValue = {
      value: SDPData.MstLocationId,
      label: locationDropDownValue ? locationDropDownValue.label : "Unknown",
    };
    //Location DropDown Value End

    const autoDropDownValues = [
      editStateDropDownValue,
      editLocationDropDownValue,
    ];
    setPopUpDropDownData(autoDropDownValues);
  };

  // this function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API response for those all and passing the rowindex.
  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // validation start
      let stateValidate = "";
      let locationValidate = "";
      let sdpValidate = "";
      let sdpCodeValidate = "";

      stateValidate = state ? "" : "Select a State";
      setStateError(stateValidate);

      locationValidate = location ? "" : "Select a Branch/Project";
      setLocationError(locationValidate);

      sdpValidate = sdp.length > 0 ? "" : "Enter S.D.P";
      setSdpError(sdpValidate);
      sdpCodeValidate = sdpCode.length > 0
      ? /^[A-Z]{3}$/.test(sdpCode)
        ? ""
        : "Enter minimum 3 charactor and should be uppercase"
      : "Enter S.D.P. code";
      setSdpCodeError(sdpCodeValidate);
      if (stateValidate || locationValidate || sdpValidate || sdpCodeValidate) {
        setSubmitBtnDisabled(false);
        setIsOpen(true);
        return;
      }
      // Validation end
      const languageKeys = [
        "ta", // Tamil
        "hi", // Hindi
        "mr", // Marathi
        "gu", // Gujarati
        "kn", // Kannada
        "ml", // Malayalam
        "te", // Telugu
        "bn", // Bengali
        "or", // Oriya
      ];
      const translatedTexts = [];
      for (let i of languageKeys) {
        const translateText = await LanguageTranslatorAPI(sdp, i);
        translatedTexts.push(
          translateText.data.data.translations[0].translatedText
        );
      }
      if (
        !stateValidate &&
        !locationValidate &&
        !sdpValidate &&
        !sdpCodeValidate
      ) {
        setIsOpen(false);
        const data = [
          {
            mstSDPId: mstSDPId ? mstSDPId : 0,
            mstCountryId: 1,
            mstStateId: state,
            mstLocationId: location,
            sdP_en: sdp,
            sdP_ta: translatedTexts[0],
            sdP_hi: translatedTexts[1],
            sdP_mr: translatedTexts[2],
            sdP_gu: translatedTexts[3],
            sdP_kn: translatedTexts[4],
            sdP_ml: translatedTexts[5],
            sdP_te: translatedTexts[6],
            sdP_bn: translatedTexts[7],
            sdP_or: translatedTexts[8],
            sdpCode: sdpCode,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(data, "SDPAPI/IUDSDP");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].result === "S") {
            toast.success("SDP data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add SDP data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].result === "M") {
            toast.success("SDP data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update SDP data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      const data = [
        {
          mstSDPId: bindSdpMasterDatas[items].MstSDPId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(data, "SDPAPI/IUDSDP");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].result === "D") {
          toast.success("SDP data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete SDP data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].result === "B") {
          toast.success("SDP data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block SDP data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("SDP data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock SDP data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location1.pathname == "/sdpsmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      SDPData();
    }
  }, [location1]);

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={"S.D.P Master"}
          header={sdpMasterHeader}
          records={bindSdpMasterDatas}
          handleEdit={handleEdit}
          handleAction={HandleSDP}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class="border-black">
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="S.D.P Master"
            />
          </div>
          <div class="mx-auto md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title={"Country"}
                      options={bindCountriesDD}
                      selectedOption={country}
                      placeholder={"Select"}
                      name={"country"}
                      controlFunc={(value) => {
                        setCountry(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="State"
                      options={bindStateDropDown}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindStateDropDown.find(
                        (x) => x.value == state
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setLocation("");
                        setState(value.value);
                        setStateError(value ? "" : "Select a State");
                      }}
                      controlFuncBlur={() => {
                        setLocation("");
                        setStateError(state ? "" : "Select a State");
                      }}
                      errorMessage={stateError}
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="Branch/Project"
                      options={bindLocationDropDown}
                      placeholder="Select"
                      name="location"
                      // selectedOption={location}
                      selectedOption={
                        location
                          ? bindLocationDropDown.find(
                              (x) => x.value == location
                            )
                          : ""
                      }
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setLocation(value.value);
                        setLocationError(
                          value ? "" : "Select a Branch/Project"
                        );
                      }}
                      controlFuncBlur={() => {
                        setLocationError(
                          location ? "" : "Select a Branch/Project"
                        );
                      }}
                      errorMessage={locationError}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"S.D.P."}
                      name={"sdp"}
                      content={sdp}
                      controlFunc={(e) =>{ 
                        const value = e.target.value;
                        setSdp(value)
                        setSdpError(
                          value.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(value)
                              ? ""  // No error if the input is valid
                              : ""
                            : "Enter S.D.P"  // Error message if the input is empty
                        );
                      }}
                      controlFuncBlur={(e) => {
                        // setSdp(e.target.value);
                        // setSdpError(sdp.length > 0 ? "" : " Enter S.D.P");
                        const trimmedValue = e.target.value.trim();
                        setSdp(trimmedValue);
                        setSdpError(
                          trimmedValue.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(trimmedValue)
                              ? ""  // No error if the input is valid
                              : "Only single spaces between words allowed"
                            : "Enter S.D.P"  // Error message if the input is empty
                        );
                      }}
                      errorMessage={sdpError}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"S.D.P. Code"}
                      name={"sdp"}
                      content={sdpCode}
                      disabled={updateScreenTitle ? updateScreenTitle : false}
                      controlFunc={(e) => setSdpCode(e.target.value)}
                      controlFuncBlur={(e) => {
                        setSdpCode(e.target.value);
                        setSdpCodeError(
                          sdpCode.length > 0
                            ? /^[A-Z]{3}$/.test(sdpCode)
                              ? ""
                              : "Enter minimum 3 charactor and should be uppercase"
                            : "Enter S.D.P. code"
                        );
                      }}
                      errorMessage={sdpCodeError}
                    />
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
       {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleSDP}
      >
        <div>
          <PopUpScreenTitle
            title="S.D.P Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <SDP data={sdpData} data2={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default AreaMaster;
