import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import {
  StockReportORSHeader,
  StockReportORSLevelHeader,
  StockReportSDPHeader,
} from "../components/HeaderAttribute";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import * as XLSX from "sheetjs-style";
import { useUserCapability } from "../components/ConstantReducerProvider";
import RadioGroup from "../ReusableComponents/RadioButton";

const ReportStockORSLevel = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [branchName, setBranchName] = useState("");

  const [branchStockFields, setBranchStockFields] = useState({
    branchProject: "",
    sdp: "",
    area: "",
    orw: "",
    commodityType: "",
    commodityCategory: "",
    CommodityId: "",
  });
  const [branchStockFieldsError, setBranchStockFieldsError] = useState({
    sdpError: "",
    areaError: "",
    orwError: "",
    branchProjectError: "",
    commodityTypeError: "",
    commodityCategoryError: "",
    commodityError: "",
  });

  const [showTable, setShowTable] = useState(false);

  const [stockReportType, setStockReportType] = useState(1);
  const [stockReportTypeDD, setStockReportTypeDD] = useState([
    { value: 1, label: "Stock Report" },
    { value: 2, label: "Stock Ledger" },
  ]);
  const handlestockReportTypeChange = (selectedOptions) => {
    setStockReportType(selectedOptions);
    setBindCommodityData([]);
  };
  const [getSDPDatas, setGetSDPDatas] = useState([]);

  const [bindSDP, setBindSDP] = useState([]);
  const [getCommoditiesData, setGetCommoditiesData] = useState([]);
  const [getCommodityCategoryData, setGetCommodityCategoryData] = useState([]);
  const [bindCommodityId, setBindCommodityId] = useState([]);
  const [bindCommodityCategory, setBindCommodityCategory] = useState([]);
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindCommodityData, setBindCommodityData] = useState([]);
  const [bindCommodityType, setBindCommodityType] = useState([]);
  const [bindUser, setBindUser] = useState([]);
  const [bindORWDD, setBindORWDD] = useState([]);
  const [bindArea, setBindArea] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [ORSNamesList, setORSNamesList] = useState([]);
  const [commodityTypeNamesList, setCommodityTypeNamesList] = useState([]);
  const [commoditySubCategoryNamesList, setCommoditySubCategoryNamesList] =
    useState([]);
  const [commodityNamesList, setCommodityNamesList] = useState([]);

  const [areaNamesList, setAreaNamesList] = useState([]);

  const [commodityTypeNameList, setCommodityTypeNameList] = useState([]);
  const [commoditySubCategoryNameList, setcommoditySubCategoryNameList] =
    useState([]);
  const [commodityNameList, setCommodityNameList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      setBranchName(userProfileDatas[0]?.LocationName_en);
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchStockFields({
          ...branchStockFields,
          branchProject: [userProfileDatas[0]?.MstLocationId],
          sdp: "",
        });
      } else {
        setBranchStockFields({
          ...branchStockFields,
          branchProject: [userProfileDatas[0]?.MstLocationId],
          sdp: [userProfileDatas[0]?.MstSDPId],
        });
        console.log("SDP", branchStockFields.sdp);
      }
    }
    BranchProjectFetchData();
    commodityTypeDD();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  useEffect(() => {
    if (branchStockFields.sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            branchStockFields.sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindArea(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [branchStockFields.sdp ? branchStockFields.sdp : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = branchStockFields.branchProject
      ? ""
      : "Select Branch/Project";
    const sdpValidate = branchStockFields.sdp ? "" : "Select SDP";
    const areaValidate = branchStockFields.area ? "" : "Select Area";

    const orwValidate = branchStockFields.orw ? "" : "Select Outreach Worker";

    const commodityTypeValidate = branchStockFields.commodityType
      ? ""
      : "Select commodity type";
    const commodityCategoryValidate = branchStockFields.commodityCategory
      ? ""
      : "Select commodity category";
    const CommodityIdValidate = branchStockFields.CommodityId
      ? ""
      : "Select commodity";
    setBranchStockFieldsError({
      ...branchStockFieldsError,
      branchProjectError: branchProjectValidate,
      sdpError: sdpValidate,
      areaError: areaValidate,
      orwError: orwValidate,
      commodityTypeError: commodityTypeValidate,
      commodityCategoryError: commodityCategoryValidate,
      commodityError: CommodityIdValidate,
    });

    let branchProjectArray = Array.from(
      { length: branchStockFields.branchProject.length },
      (v, i) => branchStockFields.branchProject[i]
    );
    let branchProjectResult = branchProjectArray.join(",");

    let sdpArray = Array.from(
      { length: branchStockFields.sdp.length },
      (v, i) => branchStockFields.sdp[i]
    );
    let areaArray = Array.from(
      { length: branchStockFields.area.length },
      (v, i) => branchStockFields.area[i]
    );
    let sdpResult = sdpArray.join(",");
    let areaResult = areaArray.join(",");

    let orwArray = Array.from(
      { length: branchStockFields.orw.length },
      (v, i) => branchStockFields.orw[i]
    );
    let orwResult = orwArray.join(",");

    let commodityTypeArray = Array.from(
      { length: branchStockFields.commodityType.length },
      (v, i) => branchStockFields.commodityType[i]
    );
    let commodityTypeResult = commodityTypeArray.join(",");

    let commodityCategoryArray = Array.from(
      { length: branchStockFields.commodityCategory.length },
      (v, i) => branchStockFields.commodityCategory[i]
    );
    let commodityCategoryResult = commodityCategoryArray.join(",");

    let commodityArray = Array.from(
      { length: branchStockFields.CommodityId.length },
      (v, i) => branchStockFields.CommodityId[i]
    );
    let commodityResult = commodityArray.join(",");

    if (
      !branchProjectValidate &&
      !sdpValidate &&
      !areaValidate &&
      !orwValidate &&
      !commodityTypeValidate &&
      !commodityCategoryValidate &&
      !CommodityIdValidate
    ) {
      const ReportData = [
        {
          mstBranchId: branchProjectResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          mstORWId: orwResult,
          commodityTypeId: commodityTypeResult,
          commodityCategoryId: commodityCategoryResult,
          commodityId: commodityResult,
          flag: stockReportType,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "InventoryIssuesAPI/StockReportORWLevel"
      );

      console.log("response", response.data.Table);
      setBindCommodityData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };

  useEffect(() => {
    if (branchStockFields.branchProject) {
      const NameList = branchStockFields.branchProject
        .map(
          (option) =>
            bindBranchProjectDD?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setBranchProjectNamesList(NameList);
    }

    if (branchStockFields.sdp) {
      const NameList = branchStockFields.sdp
        .map((option) => bindSDP?.find((item) => item.value === option)?.label)
        .filter(Boolean); // Remove undefined values if any
      setSDPNamesList(NameList);
    }

    if (branchStockFields.area) {
      const NameList = branchStockFields.area
        .map((option) => bindArea?.find((item) => item.value === option)?.label)
        .filter(Boolean); // Remove undefined values if any
      setAreaNamesList(NameList);
    }

    if (branchStockFields.orw) {
      const NameList = branchStockFields.orw
        .map(
          (option) => bindORWDD?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setORSNamesList(NameList);
    }

    if (branchStockFields.commodityType) {
      const NameList = branchStockFields.commodityType
        .map(
          (option) =>
            bindCommodityType?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setCommodityTypeNameList(NameList);
    }

    if (branchStockFields.commodityCategory) {
      const NameList = branchStockFields.commodityCategory
        .map(
          (option) =>
            bindCommodityCategory?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setcommoditySubCategoryNameList(NameList);
    }

    if (branchStockFields.CommodityId) {
      const NameList = branchStockFields.CommodityId.map(
        (option) =>
          bindCommodityId?.find((item) => item.value === option)?.label
      ).filter(Boolean); // Remove undefined values if any
      setCommodityNameList(NameList);
    }
  }, [branchStockFields]);

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };
  const handleExport = () => {
    const dateRange1 = {
      tillDate: toDate,
    };

    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
      orsData: ORSNamesList.toString(),
      commodityTypeData: commodityTypeNameList.toString(),
      commodityCategoryData: commoditySubCategoryNameList.toString(),
      commodityIdData: commodityNameList.toString(),
    };

    if (stockReportType == 1) {
      exportDataStock(
        StockReportORSLevelHeader.map((column) => column.title),
        bindCommodityData,
        dateRange1,
        selectedOptionsData
      );
    } else {
      exportDataLedger(
        StockReportORSHeader.map((column) => column.title),
        bindCommodityData,
        dateRange,
        selectedOptionsData
      );
    }
  };
  const exportDataStock = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "Stock report - ORS level",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "Till Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.tillDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
      {
        v: "ORS:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails?.orsData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Branch information row with merged cells
    const commoditiesRow = [
      { v: "" },
      {
        v: "Commodity Type:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.commodityTypeData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Commodity Sub-Category:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.commodityCategoryData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Commodity:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.commodityIdData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "left" },
        },
      })),
    ];

    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: rowData.formatted_date,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ORS,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.CommodityType_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.CommodityCategory_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.CommodityName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Brand_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: Number(rowData.OpeningQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.ReceivedQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.IssuedQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Expired || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Damaged || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Demo || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.TotalConsumption || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.BalanceQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Combine all rows
    // const wsData = [titleRow, headerRow, ...dataRows];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      commoditiesRow,
      spacingRow,
      headerRow,
      ...dataRows,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Set custom column widths
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 20 }, // IPC  No
      { width: 30 }, // Gender
      { width: 30 }, // Topics1
      { width: 30 }, // Topics2
      { width: 30 }, // Topics3
      { width: 30 }, // Gender
      { width: 30 }, // Gender
      { width: 30 }, // Gender
      { width: 15 }, // Topics6
      { width: 15 }, // Topics7
      { width: 15 }, // Topics7
      { width: 15 }, // Topics8
      { width: 15 }, // Topics9
      { width: 15 }, // Topics10
      { width: 30 }, // Topics10
      { width: 15 }, // Row Total
      { width: 15 }, // Row Total
    ];

    // // Border style for all cells
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };
    // // Title row
    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 25 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create the workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the Excel file
    XLSX.writeFile(wb, "StockReport - ORS.xlsx");
  };
  const exportDataLedger = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "Stock Ledger report - ORS level",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "Till Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.tillDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
      {
        v: "ORS:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails?.orsData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Branch information row with merged cells
    const commoditiesRow = [
      { v: "" },
      {
        v: "Commodity Type:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.commodityTypeData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Commodity Sub-Category:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.commodityCategoryData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Commodity:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.commodityIdData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "left" },
        },
      })),
    ];

    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: rowData.formatted_date,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ORS,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.CommodityType_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.CommodityCategory_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.CommodityName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Brand_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.TxnType,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.TxnTypeId,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.BatchNo,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ExpiryDate,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ReceivedBy,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.OpeningQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.ReceivedQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.IssuedQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Expired || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Damaged || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Demo || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.TotalConsumption || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.BalanceQuantity || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Combine all rows
    // const wsData = [titleRow, headerRow, ...dataRows];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      commoditiesRow,
      spacingRow,
      headerRow,
      ...dataRows,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Set custom column widths
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 20 }, // IPC  No
      { width: 30 }, // Gender
      { width: 30 }, // Gender
      { width: 30 }, // Gender
      { width: 30 }, // Gender
      { width: 30 }, // Topics1
      { width: 30 }, // Topics2
      { width: 30 }, // Topics3
      { width: 15 }, // Topics4
      { width: 30 }, // Topics5
      { width: 15 }, // Topics6
      { width: 15 }, // Topics7
      { width: 15 }, // Topics7
      { width: 15 }, // Topics8
      { width: 15 }, // Topics9
      { width: 15 }, // Topics10
      { width: 30 }, // Topics10
      { width: 15 }, // Row Total
      { width: 15 }, // Row Total
    ];

    // // Border style for all cells
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };
    // // Title row
    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 25 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create the workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the Excel file
    XLSX.writeFile(wb, "StockLedgerReport - ORS.xlsx");
  };
  const downloadPDF = () => {
    if (stockReportType == 1) {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a3",
      });
      const logoWidth = 30;
      const logoHeight = 14;
      doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

      const headers = StockReportORSLevelHeader.map((column) => column.title);

      const data = bindCommodityData.map((rowData, index) => {
        return [
          index + 1,
          rowData.Branch,
          rowData.formatted_date,
          rowData.CommodityType_en,
          rowData.CommodityCategory_en,
          rowData.CommodityName_en,
          rowData.Brand_en,
          rowData.OpeningQuantity,
          rowData.ReceivedQuantity,
          rowData.IssuedQuantity,
          rowData.Expired,
          rowData.Damaged,
          rowData.Demo,
          rowData.TotalConsumption,
          rowData.BalanceQuantity,
        ];
      });

      // Create the table with headers and data
      doc.autoTable({
        head: [headers],
        body: data,
        startY: 30,
        styles: {
          fontSize: 7, // Reduced font size
          lineColor: "black",
          lineWidth: 0.2,
          halign: "center", // Align text to center
          valign: "middle", // Align text vertically in the middle
          cellPadding: 2, // Add padding to cells
          overflow: "linebreak", // Allow text to wrap within cells
        },
        headStyles: {
          fillColor: "#2d2d6e",
          textColor: [255, 255, 255],
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Light gray color for alternate rows
        },
        columnStyles: {
          0: { cellWidth: 10 }, // Index column
          1: { cellWidth: "auto" }, // Auto adjust width
          2: { cellWidth: "auto" }, // Auto adjust width
          3: { cellWidth: "auto" }, // Auto adjust width
          4: { cellWidth: "auto" }, // Auto adjust width
          5: { cellWidth: "auto" }, // Auto adjust width
          6: { cellWidth: "auto" }, // Auto adjust width
          7: { cellWidth: "auto" }, // Auto adjust width
          8: { cellWidth: "auto" }, // Auto adjust width
          9: { cellWidth: "auto" }, // Auto adjust width
          10: { cellWidth: "auto" }, // Age
          11: { cellWidth: "auto" }, // Gender
          12: { cellWidth: "auto" }, // Auto adjust width
          13: { cellWidth: "auto" }, // Auto adjust width
          14: { cellWidth: "auto" }, // Age
          15: { cellWidth: "auto" }, // Age
        },
      });

      doc.save("Stock Report - ORS.pdf");
    } else {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a3",
      });
      const logoWidth = 30;
      const logoHeight = 14;
      doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

      const headers = StockReportORSHeader.map((column) => column.title);

      const data = bindCommodityData.map((rowData, index) => {
        return [
          index + 1,
          rowData.Branch,
          rowData.formatted_date,
          rowData.CommodityType_en,
          rowData.CommodityCategory_en,
          rowData.CommodityName_en,
          rowData.Brand_en,
          rowData.TxnType,
          rowData.TxnTypeId,
          rowData.BatchNo,
          rowData.ExpiryDate,
          rowData.ReceivedBy,
          rowData.OpeningQuantity,
          rowData.ReceivedQuantity,
          rowData.IssuedQuantity,
          rowData.Expired,
          rowData.Damaged,
          rowData.Demo,
          rowData.TotalConsumption,
          rowData.BalanceQuantity,
        ];
      });

      // Create the table with headers and data
      doc.autoTable({
        head: [headers],
        body: data,
        startY: 30,
        styles: {
          fontSize: 7, // Reduced font size
          lineColor: "black",
          lineWidth: 0.2,
          halign: "center", // Align text to center
          valign: "middle", // Align text vertically in the middle
          cellPadding: 2, // Add padding to cells
          overflow: "linebreak", // Allow text to wrap within cells
        },
        headStyles: {
          fillColor: "#2d2d6e",
          textColor: [255, 255, 255],
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Light gray color for alternate rows
        },
        columnStyles: {
          0: { cellWidth: 10 }, // Index column
          1: { cellWidth: "auto" }, // Auto adjust width
          2: { cellWidth: "auto" }, // Auto adjust width
          3: { cellWidth: "auto" }, // Auto adjust width
          4: { cellWidth: "auto" }, // Auto adjust width
          5: { cellWidth: "auto" }, // Auto adjust width
          6: { cellWidth: "auto" }, // Auto adjust width
          7: { cellWidth: "auto" }, // Auto adjust width
          8: { cellWidth: "auto" }, // Auto adjust width
          9: { cellWidth: "auto" }, // Auto adjust width
          10: { cellWidth: "auto" }, // Age
          11: { cellWidth: "auto" }, // Gender
          12: { cellWidth: "auto" }, // Auto adjust width
          13: { cellWidth: "auto" }, // Auto adjust width
          14: { cellWidth: "auto" }, // Age
          15: { cellWidth: "auto" }, // Gender
          16: { cellWidth: "auto" },
          17: { cellWidth: "auto" },
          18: { cellWidth: "auto" },
          19: { cellWidth: "auto" },
          20: { cellWidth: "auto" },
        },
      });

      doc.save("StockLedgerReport - ORS.pdf");
    }
  };

  useEffect(() => {
    const dropDownDatas = async () => {
      //GetState
      //GetLocation
      try {
        const data = {
          mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
          mstBranchId: userProfileDatas[0]?.MstLocationId,
          mstSdpId: userProfileDatas[0].MstSDPId
            ? userProfileDatas[0].MstSDPId
            : 0,
          mstUserId: userProfileDatas[0]?.MstUserId,
          mstRoleId: userProfileDatas[0]?.MstRoleId,
        };
        const outReachWorkerResponse = await BindPostAPI(
          data,
          "UserAPI/BindUser"
        );

        const filteredWithData = outReachWorkerResponse.data.Table2.filter(
          (item) => [7].includes(item.MstRoleId)
        );
        setBindUser(filteredWithData);
      } catch (error) {}
    };

    dropDownDatas();
  }, []);
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  useEffect(() => {
    const getSelectedcommodityCategory = async () => {
      const commodityCategoryResponse = await BindAPI(
        "CommoditiesAPI/GetCommoditiesCategory"
      );
      setGetCommodityCategoryData(commodityCategoryResponse.data.Table);
    };
    // Bind service Dropdown datas
    const getSelectedServices = async () => {
      const commodityCategoryResponse = await BindAPI(
        "CommoditiesAPI/BindCommodities"
      );
      setGetCommoditiesData(commodityCategoryResponse.data.Table);
    };

    getSelectedServices();

    getSelectedcommodityCategory();
  }, []);

  useEffect(() => {
    BranchProjectFetchData();
    commodityTypeDD();
  }, []);

  const commodityTypeDD = async () => {
    try {
      const commodityCategoryResponse = await BindAPI(
        "InventoryReceiptAPI/BindCommodityType"
      );

      const commodityCategoryData = commodityCategoryResponse.data.Table.map(
        (item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        })
      );

      setBindCommodityType(commodityCategoryData);
    } catch (error) {
      // Handle API call errors (e.g., show an error message)
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);
  const sdpChange = (updatedOptions) => {
    setBranchStockFields({
      sdp: updatedOptions,
      area: "",
    });
  };

  const branchStockFieldsChange = (value, name) => {
    setBranchStockFields({
      ...branchStockFields,
      [name]: value,
    });
  };

  useEffect(() => {
    if (branchStockFields.branchProject) {
      try {
        const getSelectedSDP = async () => {
          const locationResponse = await BindAPI("SDPAPI/GetSDP");
          setGetSDPDatas(locationResponse.data.Table);

          if (userProfileDatas && userProfileDatas[0]) {
            const sdpLabels = locationResponse.data.Table.filter((item) =>
              branchStockFields.branchProject.includes(
                parseInt(item.MstLocationId)
              )
            ).map((item) => ({
              MstSDPId: item.MstSDPId,
              SDP_en: item.SDP_en,
            }));

            const sdpSelected = sdpLabels.map((SD) => ({
              value: SD.MstSDPId,
              label: SD.SDP_en,
            }));

            if (
              userProfileDatas[0].MstOrganizationId == 1 ||
              userProfileDatas[0].MstOrganizationId == 2
            ) {
              setBindSDP(sdpSelected);
            } else {
              const allSDPIds = userProfileDatas[0]?.AllSDP;
              const sdpId = userProfileDatas[0]?.MstSDPId;
              if (allSDPIds && Array.isArray(allSDPIds)) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  allSDPIds.includes(sdp.value)
                );
                setBindSDP(filteredSdpSelected);
              }
              if (sdpId) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  Array.isArray(sdpId)
                    ? sdpId.includes(sdp.value)
                    : sdpId === sdp.value
                );

                setBindSDP(filteredSdpSelected);
              }
            }
          }
        };

        getSelectedSDP();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [
    branchStockFields.branchProject
      ? branchStockFields.branchProject
      : undefined,
    userProfileDatas ? userProfileDatas : undefined,
  ]);

  useEffect(() => {
    // Bind Services Sub Category Dropdown datas

    const commoditySubCategorySelectedValue = branchStockFields.commodityType;
    setBindCommodityCategory([]);
    if (commoditySubCategorySelectedValue) {
      const commoditySubCategoryLabels = getCommodityCategoryData
        .filter((item) =>
          commoditySubCategorySelectedValue.includes(item.MstCommodityTypeId)
        )
        .map((item) => ({
          MstCommodityCategoryId: item.MstCommodityCategoryId,
          CommodityCategory_en: item.CommodityCategory_en,
        }));

      const servicesSubCategorySelected = commoditySubCategoryLabels.map(
        (AR) => ({
          value: AR.MstCommodityCategoryId,
          label: AR.CommodityCategory_en,
        })
      );
      setBindCommodityCategory(servicesSubCategorySelected);
    } else {
    }
  }, [branchStockFields.commodityType]);

  useEffect(() => {
    // Bind Services Dropdown datas

    const selectedSubCategories = branchStockFields.commodityCategory;
    setBindCommodityId([]);
    if (selectedSubCategories.length > 0) {
      const servicesLabels = getCommoditiesData
        .filter((item) =>
          selectedSubCategories.includes(item.MstCommodityCategoryId)
        )
        .map((item) => ({
          MstCommoditiesId: item.MstCommoditiesId,
          CommodityName_en: item.CommodityName_en,
        }));

      const servicessSelected = servicesLabels.map((AR) => ({
        value: AR.MstCommoditiesId,
        label: AR.CommodityName_en,
      }));
      setBindCommodityId(servicessSelected);
    } else {
      // Handle the case when no sub-categories are selected
      setBindCommodityId([]);
    }
  }, [branchStockFields.commodityCategory]);

  useEffect(() => {
    // Bind Services Dropdown datas

    const selectedSDP = branchStockFields.sdp;
    setBindORWDD([]);
    if (selectedSDP.length > 0) {
      const orwLabels = bindUser
        .filter((item) => selectedSDP.includes(item.MstSDPId))
        .map((item) => ({
          MstUserId: item.MstUserId,
          userName: item.FirstName_en + " " + item.LastName_en,
        }));

      const sdpSelected = orwLabels.map((AR) => ({
        value: AR.MstUserId,
        label: AR.userName,
      }));
      setBindORWDD(sdpSelected);
    } else {
      // Handle the case when no sub-categories are selected
      setBindORWDD([]);
    }
  }, [branchStockFields.sdp]);

  /*get full details to the excel report details.*/

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  console.log("branchStockFields", branchStockFields);
  console.log("branchProjectNamesList", branchProjectNamesList);
  console.log("sdpNamesList", sdpNamesList);
  console.log("ORSNamesList", ORSNamesList);
  console.log("commodityTypeNamesList", commodityTypeNamesList);
  console.log("commoditySubCategoryNamesList", commoditySubCategoryNamesList);
  console.log("commodityNamesList", commodityNamesList);

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.grid3}>
              <div></div>
              <div>
                <RadioGroup
                  type="radio"
                  selectedOptions={stockReportType}
                  options={stockReportTypeDD}
                  controlFunc={handlestockReportTypeChange}
                />
              </div>
              <div></div>
            </div>
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">
                {stockReportType == 1
                  ? `Stock report - ORS level`
                  : `Stock ledger report - ORS level`}
              </div>
            </div>
            <div className={mainTable.grid4}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchStockFields.branchProject?.length > 0
                        ? "More Selected"
                        : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchStockFields.branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={(e) => {
                      setShowTable(false);
                      branchStockFieldsChange(e, "branchProject");
                    }}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchStockFieldsError.branchProjectError && (
                    <span className="text-red-500">
                      {branchStockFieldsError.branchProjectError}
                    </span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    branchStockFields.sdp?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="sdp"
                  selectedOptions={branchStockFields.sdp}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    setShowTable(false);
                    branchStockFieldsChange(e, "sdp");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {branchStockFieldsError.sdpError && (
                  <span className="text-red-500">
                    {branchStockFieldsError.sdpError}
                  </span>
                )}
              </div>

              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    branchStockFields.area?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="area"
                  selectedOptions={branchStockFields.area}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    setShowTable(false);
                    branchStockFieldsChange(e, "area");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {branchStockFieldsError.areaError && (
                  <span className="text-red-500">
                    {branchStockFieldsError.areaError}
                  </span>
                )}
              </div>

              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Outreach Worker"
                  filteredOptions={bindORWDD}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    branchStockFields.orw?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="area"
                  selectedOptions={branchStockFields.orw}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    setShowTable(false);
                    branchStockFieldsChange(e, "orw");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {branchStockFieldsError.orwError && (
                  <span className="text-red-500">
                    {branchStockFieldsError.orwError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Commodity Type"
                  filteredOptions={bindCommodityType}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    branchStockFields.commodityType?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="commodityType"
                  selectedOptions={branchStockFields.commodityType}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    setShowTable(false);
                    branchStockFieldsChange(e, "commodityType");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {branchStockFieldsError.commodityTypeError && (
                  <span className="text-red-500">
                    {branchStockFieldsError.commodityTypeError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Commodity Sub-Category"
                  filteredOptions={bindCommodityCategory}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    branchStockFields.commodityCategory?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="commodityCategory"
                  selectedOptions={branchStockFields.commodityCategory}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    setShowTable(false);
                    branchStockFieldsChange(e, "commodityCategory");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {branchStockFieldsError.commodityCategoryError && (
                  <span className="text-red-500">
                    {branchStockFieldsError.commodityCategoryError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Commodity"
                  filteredOptions={bindCommodityId}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    branchStockFields.CommodityId?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="CommodityId"
                  selectedOptions={branchStockFields.CommodityId}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    setShowTable(false);
                    branchStockFieldsChange(e, "CommodityId");
                  }}
                />
                {branchStockFieldsError.commodityError && (
                  <span className="text-red-500">
                    {branchStockFieldsError.commodityError}
                  </span>
                )}
              </div>
            </div>
            <div class={mainTable.grid3}>
              {stockReportType == 2 && (
                <InputText
                  inputType="date"
                  title="From"
                  name="fromDate"
                  content={fromDate}
                  controlFunc={(e) => {
                    setShowTable(false);
                    setFromDate(e.target.value);
                  }}
                />
              )}
              <InputText
                inputType="date"
                title={stockReportType == 1 ? "Till Date" : "To"}
                name="toDate"
                content={toDate}
                controlFunc={(e) => {
                  setShowTable(false);
                  setToDate(e.target.value);
                }}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindCommodityData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={
                  stockReportType == 1
                    ? StockReportORSLevelHeader
                    : StockReportORSHeader
                }
                records={bindCommodityData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ReportStockORSLevel;
