// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import UsePrevious from "../Hook/UsePrevious";
import { useUserCapability } from "../components/ConstantReducerProvider";

const IssueOfCommodity = ({
  isOpen,
  data1,
  currentData1,
  currentData2,
  issuePopupCancel,
}) => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [bindCommodityDD, setBindCommodityDD] = useState([]);
  const [bindCommodityTypeDD, setBindCommodityTypeDD] = useState([]);
  const [bindCommodityNameDD, setBindCommodityNameDD] = useState([]);
  const [bindBrandDD, setBindBrandDD] = useState([]);

  const [medicineCommodityError, setMedicineCommodityError] = useState("");
  const [servicesIssueDetails, setServicesIssueDetails] = useState([]);

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };
  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setServicesIssueDetails(jsonString);
      console.log("JsonString", jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const headerData = [
    {
      title: "Commodity",
      key: "commoditiesCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Commodity Category",
      key: "commoditiesCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Commodity Name",
      key: "commoditiesName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "commoditiesBrand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Qty.",
      key: "commoditiesQty",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "commoditiesUOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Charges",
      key: "charges",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  const [tableData, setTableData] = useState([
    {
      commodity: "",
      commodityCategory: "",
      commodityName: "",
      brand: "",
      quantity: "",
      uom: "",
      charges: "",
      commodityCategoryList: [],
      commodityNameList: [],
      brandList: [],
      currentStock: "",
    },
  ]);
  const handleDeleteRow = (index) => {
    // const updatedTableData = [...tableData];
    // updatedTableData.splice(index, 1);
    // setTableData(updatedTableData);
    // convertArrayToJSONString(updatedTableData);
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          commodity: "",
          commodityCategory: "",
          commodityName: "",
          brand: "",
          quantity: "",
          uom: "",
          charges: "",
          commodityCategoryList: [],
          commodityNameList: [],
          brandList: [],
          currentStock: "",
        };

        // Use spread to create a new array
        return [newRow];
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.commodity = "";
    newRow.commodityCategory = ""; // Clear the values of the new row
    newRow.commodityName = "";
    newRow.quantity = "";
    newRow.uom = "";
    newRow.charges = "";
    newRow.commodityCategoryList = [];
    newRow.commodityNameList = [];
    newRow.brandList = [];
    newRow.currentStock = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };

  useEffect(() => {
    const commodityTypeDD = async () => {
      try {
        const commodityTypeResponse = await BindAPI(
          "CommoditiesAPI/GetCommoditiesCategory"
        );
        const commodityResponse = await BindAPI(
          "InventoryReceiptAPI/BindCommodityType"
        );

        const brandResponse = await BindAPI("BrandAPI/BindBrand");

        const commodityResponseData = commodityResponse.data.Table.filter(
          (item) =>
            item.MstCommodityTypeId === 2 || item.MstCommodityTypeId === 3
        ).map((item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        }));

        setBindBrandDD(brandResponse.data.Table);

        setBindCommodityDD(commodityResponseData);
        setBindCommodityTypeDD(commodityTypeResponse.data.Table);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    const commodityNameDD = async () => {
      try {
        const commodityNameResponse = await BindAPI(
          "CommoditiesAPI/BindCommodities"
        );
        // setBindCommodityDD(commodityNameResponse.data.Table);

        setBindCommodityNameDD(commodityNameResponse.data.Table);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    commodityTypeDD();
    commodityNameDD();
  }, []);

  const handleSubmit = () => {
    if (servicesIssueDetails.length === 0) {
      setMedicineCommodityError("Fill the above table");
    } else {
      let medicineCommodityData = [];
      try {
        medicineCommodityData = JSON.parse(servicesIssueDetails);
      } catch (error) {
        console.error("Error parsing Medicine Commodity data:", error);
        setMedicineCommodityError("Error parsing Medicine Commodity data");
        return; // Exit the function early if there's an error
      }

      if (!Array.isArray(medicineCommodityData)) {
        console.error("Medicine Commodity data is not an array");
        setMedicineCommodityError("Medicine Commodity data is not an array");
        return; // Exit the function early if data is not an array
      }

      const medicineCommodityErrors = medicineCommodityData.map(
        (medicineCommodity) => {
          if (
            !medicineCommodity ||
            !medicineCommodity.commodity ||
            !medicineCommodity.commodityCategory ||
            !medicineCommodity.commodityName ||
            !medicineCommodity.brand ||
            !medicineCommodity.quantity ||
            !medicineCommodity.uom ||
            !medicineCommodity.charges
          ) {
            return "Medicine Commodity information is incomplete";
          }
          return null;
        }
      );

      const hasMedicineCommodityErrors = medicineCommodityErrors.some(
        (error) => error !== null
      );

      if (!hasMedicineCommodityErrors) {
        setMedicineCommodityError("");
        const data2 = servicesIssueDetails;
        data1(data2);
        console.log("servicesIssueDetails", servicesIssueDetails);
        isOpen(false);
      } else {
        setMedicineCommodityError(
          "Please provide complete Medicine Commodity information"
        );
      }
    }
  };

  const previousTableData = UsePrevious(tableData);
  useEffect(() => {
    const updateTableData = async () => {
      if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
        const updatedTableData = await Promise.all(
          tableData.map(async (row, rowIndex) => {
            const updatedRow = { ...row }; // Avoid direct mutation

            if (row?.commodity) {
              const getFilteredCategory = bindCommodityTypeDD
                .filter((item) => item.MstCommodityTypeId == row.commodity)
                .map((items) => ({
                  value: items.MstCommodityCategoryId,
                  label: items.CommodityCategory_en,
                }));
              updatedRow.commodityCategoryList = getFilteredCategory;
            }

            if (row?.commodityCategory) {
              const selectedCommoditiesCategory = bindCommodityNameDD
                .filter(
                  (item) => item.MstCommodityCategoryId == row.commodityCategory
                )
                .map((item) => ({
                  value: item.MstCommoditiesId,
                  label: item.CommodityName_en,
                  uom: item.CommodityUOM,
                }));
              updatedRow.commodityNameList = selectedCommoditiesCategory;

              const selectedCommodity = selectedCommoditiesCategory.find(
                (item) => item.value === row.commodityName
              );

              updatedRow.uom = selectedCommodity ? selectedCommodity.uom : "";
            }

            if (row?.commodityName) {
              const selectedBrandCategory = bindBrandDD
                .filter((item) => item.MstCommoditiesId == row.commodityName)
                .map((items) => ({
                  value: items.MstBrandId,
                  label: items.Brand_en,
                }));
              updatedRow.brandList = selectedBrandCategory;
            }

            if (row?.brand || row?.quantity == "" || row?.charges == "") {
          
              const data = {
                mstCommodityType: row.commodity,
                mstCategoryId: row.commodityCategory,
                mstCommodityId: row.commodityName,
                mstBrandId: row.brand,
                mstOrganizationStructureId:
                  userProfileDatas[0].MstOrganizationId,
                mstUserId: userProfileDatas[0].MstUserId,
                mstRoleId: userProfileDatas[0].MstRoleId,
                mstBranchProjectId: userProfileDatas[0].MstLocationId,
                mstSDPId: currentData2[0],
                mstAreaId: currentData2[1],
                mstORWId:
                  userProfileDatas[0].MstRoleId == 7
                    ? userProfileDatas[0].MstUserId
                    : 0,
                servicesId: currentData2[2] ? currentData2[2] : "",
                flag: 1,
              };

              try {
                const response = await BindPostAPI(
                  [data],
                  "TXNServicesAPI/BindAvailableCurrentStock"
                );

                if (response?.data?.Table?.length > 0) {
                  const balanceQuantity =
                    response.data.Table[0].BalanceQuantity ?? "";
                  updatedRow.currentStock = balanceQuantity;
                  console.log("balanceQuantity", balanceQuantity);
                } else {
                  console.log("No data available for current stock.");
                  updatedRow.currentStock = "";
                }
              } catch (error) {
                console.error("Error fetching current stock:", error);
                updatedRow.currentStock = "";
              }
            } else if (row?.brand) {
          
              const data = {
                mstCommodityType: row.commodity,
                mstCategoryId: row.commodityCategory,
                mstCommodityId: row.commodityName,
                mstBrandId: row.brand,
                mstOrganizationStructureId:
                  userProfileDatas[0].MstOrganizationId,
                mstUserId: userProfileDatas[0].MstUserId,
                mstRoleId: userProfileDatas[0].MstRoleId,
                mstBranchProjectId: userProfileDatas[0].MstLocationId,
                mstSDPId: currentData2[0],
                mstAreaId: currentData2[1],
                mstORWId:
                  userProfileDatas[0].MstRoleId == 7
                    ? userProfileDatas[0].MstUserId
                    : 0,
                servicesId: currentData2[2] ? currentData2[2] : "",
                flag: currentData2[3] ? currentData2[3] : 1,
              };

              try {
                const response = await BindPostAPI(
                  [data],
                  "TXNServicesAPI/BindAvailableCurrentStock"
                );

                if (response?.data?.Table?.length > 0) {
                  const balanceQuantity =
                    response.data.Table[0].BalanceQuantity ?? "";
                  updatedRow.currentStock = balanceQuantity;
                  console.log("balanceQuantity", balanceQuantity);
                } else {
                  console.log("No data available for current stock.");
                  updatedRow.currentStock = "";
                }
              } catch (error) {
                console.error("Error fetching current stock:", error);
                updatedRow.currentStock = "";
              }
            }

            return updatedRow;
          })
        );

        setTableData(updatedTableData);
      }
    };

    updateTableData();
  }, [tableData, currentData2]);

  useEffect(() => {
    console.log("useEffectcall", currentData1);
    if (currentData1.length > 0) {
      console.log("useEffectcall2", currentData1);
      const clearValues = () => {
        setTableData([]);
      };
      clearValues();
      const ParticipantDatas = JSON.stringify(currentData1);
      try {
        const newDataArray = JSON.parse(ParticipantDatas);
        const formattedDataArray = newDataArray.map((data) => ({
          commodity: data.MstCommodityTypeId,
          commodityCategory: data.MstCommodityCategoryId,
          commodityName: data.MstCommodityId,
          brand: data.MstBrandId,
          quantity: data.Quantity,
          uom: data.UOM,
          charges: data.Charges,
        }));

        setTableData((prevData) => [...prevData, ...formattedDataArray]);
        convertArrayToJSONString(formattedDataArray);
        // Call clearValues only when currentData1 has a value
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else if (currentData1.length < 0) {
      // Set default values for tableData
      setTableData([
        {
          commodity: "",
          commodityCategory: "",
          commodityName: "",
          brand: "",
          quantity: "",
          uom: "",
          charges: "",
          commodityCategoryList: [],
          commodityNameList: [],
          brandList: [],
          currentStock: "",
        },
      ]);
    } else {
      console.log("Table Data is Not Set");
    }
  }, [currentData1]);

  useEffect(() => {

    if (issuePopupCancel) {
      const clearValues = () => {
        setTableData([]);
      };
      clearValues();
      console.log("issuePopupCancel", issuePopupCancel);
      setTableData([
        {
          commodity: "",
          commodityCategory: "",
          commodityName: "",
          brand: "",
          quantity: "",
          uom: "",
          charges: "",
          commodityCategoryList: [],
          commodityNameList: [],
          brandList: [],
          currentStock: "",
        },
      ]);
    }
  }, [issuePopupCancel ? issuePopupCancel : false]);
  console.log("issuePopupCancel", issuePopupCancel);
  return (
    <div class="w-full">
      <div class={mainTable.mainContentScreen}>
        <form class={mainTable.formForPopup}>
          <div class={mainTable.labelBold}>Medicine / Commodities</div>
          <hr class={mainTable.servicesHorizontalLine}></hr>
          <div className="hidden md:block">
            <table className="w-full border border-gray-300 mt-6">
              <thead>
                <tr>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Commodity
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Commodity Category
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Commodity Name
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Brand
                  </th>

                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Qty.
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    U.O.M.
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Charges
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 ">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2 w-48">
                      <AutoDropDown
                        Class="Custom-class"
                        options={bindCommodityDD}
                        placeholder="Select"
                        name="commodity"
                        selectedOption={
                          row.commodity
                            ? bindCommodityDD.find(
                                (x) => x.value == row.commodity
                              )
                            : ""
                        }
                        controlFunc={(e) => {
                          handleInputChange(e, index, "commodity");
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-2 w-52">
                      <AutoDropDown
                        Class="Custom-class"
                        options={row.commodityCategoryList || []}
                        placeholder="Select"
                        name="commodityCategory"
                        selectedOption={
                          row.commodityCategory
                            ? row.commodityCategoryList
                              ? row.commodityCategoryList.find(
                                  (x) => x.value === row.commodityCategory
                                )
                              : null
                            : ""
                        }
                        controlFunc={(selectedOption) => {
                          handleInputChange(
                            selectedOption,
                            index,
                            "commodityCategory"
                          );
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-2 w-44">
                      <AutoDropDown
                        Class="Custom-class"
                        options={row.commodityNameList || []}
                        placeholder="Select"
                        name="productName"
                        selectedOption={
                          row.commodityName
                            ? row.commodityNameList
                              ? row.commodityNameList.find(
                                  (x) => x.value === row.commodityName
                                )
                              : null
                            : ""
                        }
                        controlFunc={(selectedOption) => {
                          handleInputChange(
                            selectedOption,
                            index,
                            "commodityName"
                          );
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-2 w-40">
                      <AutoDropDown
                        Class="Custom-class"
                        options={row.brandList || []}
                        placeholder="Select"
                        name="brand"
                        selectedOption={
                          row.brand
                            ? row.brandList
                              ? row.brandList.find((x) => x.value === row.brand)
                              : null
                            : ""
                        }
                        controlFunc={(selectedOption) => {
                          handleInputChange(selectedOption, index, "brand");
                        }}
                      />
                    </td>

                    <td className="border border-gray-300 p-2 w-20">
                      <div title={`Available Stock: ${row.currentStock || 0}`}>
                        <InputText
                          type="text"
                          content={row.quantity}
                          maxLength={5}
                          controlFunc={(e) => {
                            const InputText = e.target.value;

                            if (
                              /^[0-9]*$/.test(InputText) &&
                              (InputText === "" ||
                                parseFloat(InputText) <=
                                  (row.currentStock || 0))
                            ) {
                              handleInputChange(e.target, index, "quantity");
                            }
                          }}
                        />
                      </div>
                    </td>

                    <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                      <InputText
                        type="text"
                        content={row.uom}
                        maxLength={10}
                        controlFunc={(e) => {
                          const InputText = e.target.value;

                          if (/^[A-Za-z]*$/.test(InputText)) {
                            handleInputChange(e.target, index, "uom");
                          }
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-1 w-20 font-normal">
                      <InputText
                        type="text"
                        content={row.charges}
                        maxLength={7}
                        controlFunc={(e) => {
                          const InputText = e.target.value;

                          if (/^\d*\.?\d*$/.test(InputText)) {
                            handleInputChange(e.target, index, "charges");
                          }
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 w-16">
                      <div className="flex gap-4 align-baseline items-center justify-center">
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-6 h-6 text-black cursor-pointer"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                        </svg>
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          onClick={handleAddRow}
                          class="w-6 h-6 text-black cursor-pointer"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="hidden md:block">
            {medicineCommodityError && (
              <div className="text-red-500">{medicineCommodityError}</div>
            )}
          </div>
          <div className={mainTable.tableMobile} role="table">
            <div className="w-full md:overflow-y-auto lg:h-96 h-full">
              {tableData.length > 0 &&
                tableData.map((row, index) => (
                  <div scope="col" class={mainTable.mobileTable}>
                    {headerData.map((keyData) => (
                      <div scope="cell" class={mainTable.tableData} role="cell">
                        {keyData.key === "Action" ? (
                          <div class={mainTable.tableData}>
                            <div className="md:block hidden">
                              <button
                                id="dropdownMenuIconButton"
                                data-dropdown-toggle="dropdownDots"
                                class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                type="button"
                              >
                                <svg
                                  class="w-6 h-6"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                </svg>
                              </button>
                            </div>
                            <div className="md:hidden flex justify-center">
                              <input
                                type="button"
                                className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                onClick={handleAddRow}
                                value="Add"
                              />
                              <input
                                type="button"
                                className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                onClick={() => handleDeleteRow(index)}
                                value="Delete"
                              />
                            </div>
                          </div>
                        ) : keyData.key === "commoditiesCategoryType" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              options={bindCommodityDD}
                              placeholder="Select"
                              name="commodity"
                              selectedOption={bindCommodityDD.find(
                                (x) => x.value == row.commodity
                              )}
                              controlFunc={(e) => {
                                handleInputChange(e, index, "commodity");
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesCategory" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              options={row.commodityCategoryList || []}
                              placeholder="Select"
                              name="commodityCategory"
                              selectedOption={
                                row.commodityCategoryList
                                  ? row.commodityCategoryList.find(
                                      (x) => x.value === row.commodityCategory
                                    )
                                  : null
                              }
                              controlFunc={(selectedOption) => {
                                handleInputChange(
                                  selectedOption,
                                  index,
                                  "commodityCategory"
                                );
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesName" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              options={row.commodityNameList || []}
                              placeholder="Select"
                              name="productName"
                              selectedOption={
                                row.commodityNameList
                                  ? row.commodityNameList.find(
                                      (x) => x.value === row.commodityName
                                    )
                                  : null
                              }
                              controlFunc={(selectedOption) => {
                                handleInputChange(
                                  selectedOption,
                                  index,
                                  "commodityName"
                                );
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesBrand" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              options={row.brandList || []}
                              placeholder="Select"
                              name="brand"
                              selectedOption={
                                row.brandList
                                  ? row.brandList.find(
                                      (x) => x.value === row.brand
                                    )
                                  : null
                              }
                              controlFunc={(selectedOption) => {
                                handleInputChange(
                                  selectedOption,
                                  index,
                                  "brand"
                                );
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesQty" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full whitespace-nowrap font-bold">
                              {keyData.title}
                            </div>
                            <div
                              title={`Available Stock: ${
                                row.currentStock || 0
                              }`}
                            >
                              <InputText
                                type="text"
                                content={row.quantity}
                                controlFunc={(e) => {
                                  const InputText = e.target.value;

                                  if (
                                    /^[0-9]*$/.test(InputText) &&
                                    (InputText === "" ||
                                      parseFloat(InputText) <=
                                        (row.currentStock || 0))
                                  ) {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "quantity"
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ) : keyData.key === "commoditiesUOM" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full whitespace-nowrap font-bold">
                              {keyData.title}
                            </div>
                            <InputText
                              type="text"
                              content={row.uom}
                              maxLength={10}
                              controlFunc={(e) => {
                                const InputText = e.target.value;

                                if (/^[A-Za-z/s]*$/.test(InputText)) {
                                  handleInputChange(e.target, index, "uom");
                                }
                              }}
                            />
                          </div>
                        ) : keyData.key === "charges" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full whitespace-nowrap font-bold">
                              {keyData.title}
                            </div>
                            <InputText
                              type="text"
                              content={row.charges}
                              maxLength={7}
                              controlFunc={(e) => {
                                const InputText = e.target.value;

                                if (/^\d*\.?\d*$/.test(InputText)) {
                                  handleInputChange(e.target, index, "charges");
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div className="flex border p-1">
                            <div className="flex basis-1/2 font-bold">
                              {keyData.title}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          <div className="md:hidden">
            {medicineCommodityError && (
              <div className="text-red-500">{medicineCommodityError}</div>
            )}
          </div>
          <div className={mainTable.submitCancelMainDiv}>
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IssueOfCommodity;
