// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import { staffNameHeaders } from "../components/HeaderAttribute";
import toast from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import { useLocation } from "react-router-dom";

import GridPage from "../components/GridPage";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import StaffName from "../popup-containers/StaffName";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
const StaffNameMaster = () => {
  const location = useLocation();
  // All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  //Staff name insert and edit useState hooks handling .

  const [staffNames, setStaffNames] = useState([""]);
  const [staffValidationErrors, setStaffValidationErrors] = useState([""]);

  //Staff name Error handle useStates
  const [staffNameFiledsError, setstaffNameFiledsError] = useState({
    staffNameError: "",
    stateError: "",
    branchProjectError: "",
  });

  const [staffNameFileds, setStaffNameFileds] = useState({
    state: "",
    staffName: "",
    branchProject: "",
  });

  const [mstStaffNameId, setMstStaffNameId] = useState("");

  // All bind data value useState hooks handling.
  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindLocationDropDown, setBindLocationDropDown] = useState([]);
  const [bindLocationDropDown2, setBindLocationDropDown2] = useState([]);
  const [getLocationDatas1, setGetLocationDatas1] = useState([]);
  const [bindStaffNameData, setBindStaffNameData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [staffNamePopupData, setStaffNamePopupData] = useState("");
  const [staffPopupDropDownData, setStaffPopupDropDownData] = useState({});

  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  useEffect(() => {
    const staffDropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));
        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));

        setBindStateDropDown(statesData);
        setBindLocationDropDown(locationData);
      } catch (error) {}
    };

    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas1(locationResponse.data.Table);
    };

    getSelectedLocation();
    callBindStaffNameData();
    staffDropDownDatas();
  }, []);

  useEffect(() => {
    const stateSelectedValue = getLocationDatas1.find(
      (item) => item.MstStateId == staffNameFileds.state
    );
    console.log("stateSelectedValue", stateSelectedValue);
    if (stateSelectedValue) {
      const locationLabels = getLocationDatas1
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId,
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown2(locationSelected);
    } else {
    }
  }, [staffNameFileds.state]);

  const handleStaffNameFieldsChange = (value, name) => {
    setStaffNameFileds({
      ...staffNameFileds,
      [name]: value,
    });
  };

  const cancel = async () => {
    callBindStaffNameData();
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);

    setStaffNames([""]);
    setRowIndex("");
    setMstStaffNameId("");
    setstaffNameFiledsError({
      nameError: "",
      stateError: "",
      branchProjectError: "",
    });

    setStaffNameFileds({
      state: "",
      brandName: "",
      branchProject: "",
    });

    setStaffValidationErrors([""]);
  };

  const callBindStaffNameData = async () => {
    const response = await BindAPI("StaffNameAPI/BindStaffName");
    setBindStaffNameData(response.data.Table);
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const handleBrandDetails = (rowIndex, flag) => {

    handleOpen(true);

    setViewDetails(false);
    setDeleteDetails(false);
    setBlockDetails(false);
    setUnBlockDetails(false);

    if (flag === 1) {
      setViewDetails(true);
    } else if (flag === 3) {
      setDeleteDetails(true);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setBlockDetails(true);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }

    setStaffNamePopupData(bindStaffNameData[rowIndex]);

    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === bindStaffNameData[rowIndex].MstStateId
    );
    const editStateDropDownValue = {
      value: bindStaffNameData[rowIndex].MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };

    //State DropDown Value End

    //Location DropDown Value Start
    const locationDropDownValue = bindLocationDropDown.find(
      (item) => item.value === bindStaffNameData[rowIndex].MstBranchProjecctId
    );
    const editLocationDropDownValue = {
      value: bindStaffNameData[rowIndex].MstBranchProjecctId,
      label: locationDropDownValue ? locationDropDownValue.label : "Unknown",
    };

    //Location DropDown Value End

    const sendPopupDropDownDatas = [
      editStateDropDownValue,
      editLocationDropDownValue,
    ];
    setStaffPopupDropDownData(sendPopupDropDownDatas);
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setMstStaffNameId(bindStaffNameData[rowIndex].MstStaffNameId);

    setStaffNames([bindStaffNameData[rowIndex].StaffName_en]);

    setStaffNameFileds({
      ...staffNameFileds,
      state: bindStaffNameData[rowIndex].MstStateId,
      branchProject: bindStaffNameData[rowIndex].MstBranchProjecctId,
    });
  };

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      if (staffNameFileds.state === "") {
        errors.state = "Select State";
      }

      if (staffNameFileds.branchProject === "") {
        errors.branchProject = "Select Branch / Project";
      }

      const updatedValidationErrors = [];

      staffNames.forEach((staff, index) => {
        updatedValidationErrors[index] = isStaffValid(staff)
          ? ""
          : "Enter Staff Name";
      });

      if (staffNames.some((staff) => staff.trim() !== "")) {
        // Clear the errors if brands have data
        setStaffValidationErrors([]);
        delete errors.updatedValidationErrors;
      } else {
        setStaffValidationErrors(updatedValidationErrors);
        errors.updatedValidationErrors = updatedValidationErrors;
      }
    }

    const staffMasterErrorObject = {
      stateError: errors.state || "",
      branchProjectError: errors.branchProject || "",
    };
    setstaffNameFiledsError(staffMasterErrorObject);
    console.log("Errors", errors);
    return errors;
  };

  const createdBy = sessionStorage.getItem("createdBy");

  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    const validation = validate(flag);
    if (Object.keys(validation).length !== 0) {
      setIsOpen(true);
    }
    if (Object.keys(validation).length == 0) {
      setIsOpen(false);
      if (flag == 1 || flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedTextsArray = [];
        const translatedTextsArray2 = [];

        let i = 0;
        for (let staff of staffNames) {
          i = i + 1;

          for (let language of languageKeys) {
            const translateText = await LanguageTranslatorAPI(staff, language);
            translatedTextsArray2.push(
              translateText.data.data.translations[0].translatedText
            );
          }
          if (flag == 1) {
            const Payload = [
              {
                mstStaffNameId: mstStaffNameId ? mstStaffNameId : 0,
                mstStateId: staffNameFileds.state,
                mstBranchProjecctId: staffNameFileds.branchProject,

                staffName_en: staff,
                staffName_ta: translatedTextsArray2[0],
                staffName_hi: translatedTextsArray2[1],
                staffName_mr: translatedTextsArray2[2],
                staffName_gu: translatedTextsArray2[3],
                staffName_kn: translatedTextsArray2[4],
                staffName_ml: translatedTextsArray2[5],
                staffName_te: translatedTextsArray2[6],
                staffName_bn: translatedTextsArray2[7],
                staffName_or: translatedTextsArray2[8],
                createdBy: createdBy,
                flag: flag,
              },
            ];

            const response = await IUDAPI(Payload, "StaffNameAPI/IUDStaffName");
            if (i == staffNames.length) {
              if (response.data.Table[0].Result === "S") {
                toast.success("Staff Name added successfully 👍", {
                  duration: 3000,
                  position: "top-center",
                });
                setTimeout(cancel, 3000);
              } else {
                toast.error("Failed to add Staff Name data 👎", {
                  duration: 2000,
                  position: "top-center",
                });
                // setTimeout(cancel, 2000);
              }
            }
          } else if (flag == 2) {
            const Payload = [
              {
                mstStaffNameId: mstStaffNameId ? mstStaffNameId : 0,
                mstStateId: staffNameFileds.state,
                mstBranchProjecctId: staffNameFileds.branchProject,

                staffName_en: staff,
                staffName_ta: translatedTextsArray2[0],
                staffName_hi: translatedTextsArray2[1],
                staffName_mr: translatedTextsArray2[2],
                staffName_gu: translatedTextsArray2[3],
                staffName_kn: translatedTextsArray2[4],
                staffName_ml: translatedTextsArray2[5],
                staffName_te: translatedTextsArray2[6],
                staffName_bn: translatedTextsArray2[7],
                staffName_or: translatedTextsArray2[8],
                createdBy: createdBy,
                flag: flag,
              },
            ];

            const response = await IUDAPI(Payload, "StaffNameAPI/IUDStaffName");

            if (i == staffNames.length) {
              if (response.data.Table[0].Result === "M") {
                toast.success("Staff Name updated successfully 👍", {
                  duration: 3000,
                  position: "top-center",
                });
                setTimeout(cancel, 3000);
              } else {
                toast.error("Failed to update Staff Name data 👎", {
                  duration: 2000,
                  position: "top-center",
                });
                setTimeout(cancel, 2000);
              }
            }
          }
          translatedTextsArray2.length = 0;
        }
      } else {
        const data = {
          mstStaffNameId: bindStaffNameData[items].MstStaffNameId,
          createdBy: createdBy,
          flag: flag,
        };

        const response = await IUDAPI([data], "StaffNameAPI/IUDStaffName");

        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success(
              "Staff Name data deleted successfully 🗑️",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to delete Staff Name data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success(
              "Staff Name data blocked successfully 🚫",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to block Staff Name data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success(
              "Staff Name data unblocked successfully ✅",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to unblock Staff Name data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const addStaffInput = () => {
    setStaffNames([...staffNames, ""]);
  };

  const removeStaffInput = (index) => {
    const newStaffs = [...staffNames];
    newStaffs.splice(index, 1);
    setStaffNames(newStaffs);
  };

  const handleStaffChange = (index, value) => {
    const newStaff = [...staffNames];
    newStaff[index] = value;
    setStaffNames(newStaff);
  };

  const isStaffValid = (staff) => {
    // You can add your validation logic here
    return staff.length > 0; // Example: Check if the brand is not empty
  };
  const handleStaffBlur = (index) => {
    const updatedValidationErrors = [...staffValidationErrors];
    updatedValidationErrors[index] = isStaffValid(staffNames[index])
      ? ""
      : "Enter Staff Name";
    setStaffValidationErrors(updatedValidationErrors);
  };

  useEffect(() => {
    if (location.pathname == "/staffname") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);

      cancel();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={`Staff Name`}
          header={staffNameHeaders}
          records={bindStaffNameData}
          handleEdit={handleEdit}
          handleAction={handleBrandDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full">
          <div class=" border-black  ">
            <div class="text-black text-2xl font-bold my-2">
              {addScreenTitle && (
                <div class={mainTable.addScreenTitle}>{"Staff Name - Add"}</div>
              )}

              {updateScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Staff Name - Edit"}
                </div>
              )}
            </div>
          </div>

          <div class="mx-auto flex  border-black">
            <div class="relative w-full h-full  md:h-auto">
              <div class="relative bg-white rounded-lg  ">
                <div class="px-6 py-6 lg:px-8">
                  <form className={mainTable.grid3}>
                    <AutoDropDown
                      title="State"
                      classStar={"text-red-500"}
                      options={bindStateDropDown}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindStateDropDown.find(
                        (x) => x.value == staffNameFileds.state
                      )}
                      controlFunc={(value, e) => {
                        handleStaffNameFieldsChange(value.value, e.name);
                        setStaffNameFileds({
                          ...staffNameFileds,
                          state: value.value,
                          branchProject: "",
                        });
                      }}
                      controlFuncBlur={() => {
                        setstaffNameFiledsError({
                          ...staffNameFiledsError,
                          stateError: staffNameFileds.state
                            ? ""
                            : "Select State",
                        });
                      }}
                      Class="custom-class"
                      //disabled="false"
                      tabIndex="1"
                      errorMessage={staffNameFiledsError.stateError}
                    />

                    <AutoDropDown
                      classStar="text-red-500"
                      title="Branch/Project"
                      options={bindLocationDropDown2}
                      placeholder="Select"
                      name="branchProject"
                      selectedOption={
                        staffNameFileds.branchProject
                          ? bindLocationDropDown.find(
                              (x) => x.value == staffNameFileds.branchProject
                            )
                          : ""
                      }
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value, e) => {
                        handleStaffNameFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setstaffNameFiledsError(() => ({
                          ...staffNameFiledsError,
                          branchProjectError: staffNameFileds.branchProject
                            ? ""
                            : "Select Branch/Project",
                        }));
                      }}
                      errorMessage={staffNameFiledsError.branchProjectError}
                    />

                    {staffNames.map((staff, index) => (
                      <div key={index} className="flex items-center">
                        <InputText
                          inputType={"text"}
                          title={`Staff Name ${index + 1}`}
                          name={`StaffName${index}`}
                          content={staff}
                          maxLength={100}
                          classDiv={"w-full"}
                          classStar="text-red-500"
                          controlFunc={(e) =>
                            handleStaffChange(index, e.target.value)
                          }
                          controlFuncBlur={() => handleStaffBlur(index)}
                          errorMessage={
                            staffValidationErrors &&
                            staffValidationErrors[index]
                          }
                        />
                        {addScreenTitle && (
                          <div className="ml-2 flex flex-col align-baseline gap-1 mt-3">
                            <svg
                              viewBox="0 0 1024 1024"
                              fill="currentColor"
                              onClick={addStaffInput}
                              className="w-5 h-5 text-black cursor-pointer"
                              role="button"
                              aria-label="Add Brand"
                            >
                              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                            </svg>
                            <svg
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5 text-black cursor-pointer"
                              role="button"
                              aria-label="Remove Brand"
                              onClick={() => removeStaffInput(index)}
                            >
                              <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                            </svg>
                          </div>
                        )}
                      </div>
                    ))}
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    disabledBtn={submitBtnDisabled ? true : false}
                    cancel={cancel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <PopUpScreenTitle
            title="Staff Name"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <StaffName data={staffNamePopupData} data2={staffPopupDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          disabledBtn={submitBtnDisabled ? true : false}
          cancel={cancel}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default StaffNameMaster;
