import React, { useEffect, useRef, useState } from "react";
import { supplierMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
// import TableGrid from "../components/Table";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import SupplierPopup from "../popup-containers/SupplierPopup";
import MainPopup from "../popup-containers/MainPopup";
// import download from "../image/download.png";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, IUDAPI, ImageAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import FileUpload from "../ReusableComponents/FileUpload";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const SupplierMaster = () => {
  const location = useLocation();
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const supplierNameRef = useRef(null);
  const addressRef = useRef(null);
  const countryRef = useRef(null);
  const stateRef = useRef(null);
  const branchProjectRef = useRef(null);
  const pinCodeRef = useRef(null);
  const contactPersonRef = useRef(null);
  const contactNumberRef = useRef(null);
  const contactPersonDesignationRef = useRef(null);
  const [supplierFields, setSupplierFields] = useState({
    supplierId: "SUP",
    supplierName: "",
    address: "",
    country: "1",
    state: "",
    branchProject: "",
    pinCode: "",
    date: new Date(),
    commodities: null,
    showImage: "",
    contactPerson: "",
    contactPersonDesignation: "",
    contactNumber: "",
    contactPersonEmail: "",
  });

  const [supplierFieldsError, setSupplierFieldsError] = useState({
    supplierIdError: "",
    supplierNameError: "",
    addressError: "",
    countryError: "",
    stateError: "",
    branchProjectError: "",
    pinCodeError: "",
    dateError: "",
    branchProjectError: "",
    // sdpError: "",
    // userIdError: "",
    showImageError: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setSupplierFields({ ...supplierFields, date: formattedDate });
    };

    fetchData();
  }, []);
  const handleSupplierFieldsChange = (value, name) => {
    //
    console.log("Values:", value, name);
    setSupplierFields({
      ...supplierFields,
      [name]: value,
    });
  };
  // const [supplierId, setSupplierId] = useState("");
  // const [supplierIdError, setSupplierIdError] = useState("");
  // const [supplierName, setSupplierName] = useState("");
  // const [supplierNameError, setSupplierNameError] = useState("");
  // const [address, setAddress] = useState("");
  // const [addressError, setAddressError] = useState("");
  // const [location, setLocation] = useState("");
  // const [locationError, setLocationError] = useState("");
  // const [state, setState] = useState("");
  // const [stateError, setStateError] = useState("");
  // const [country, setCountry] = useState("");
  // const [countryError, setCountryError] = useState("");
  // const [pinCode, setPinCode] = useState("");
  // const [pinCodeError, setPinCodeError] = useState("");
  // const [logo, setLogo] = useState("");

  // const [supplierDate, setSupplierDate] = useState("");

  // const [medicinesDrugs, setMedicinesDrugs] = useState("");
  // const [medicinesDrugsError, setMedicinesDrugsError] = useState("");
  // const [contactPerson, setContactPerson] = useState("");
  // const [contactPersonError, setContactPersonError] = useState("");
  // const [contactPersonDesignation, setContactPersonDesignation] = useState("");
  // const [contactPersonDesignationError, setContactPersonDesignationError] =
  //   useState("");
  // const [contactNumber, setContactNumber] = useState("");
  // const [contactNumberError, setContactNumberError] = useState("");
  // const [contactPersonEmail, setContactPersonEmail] = useState("");
  // const [contactPersonEmailError, setContactPersonEmailError] = useState("");
  const [mstSupplierId, setMstSupplierId] = useState("");

  // const [imageUploaded, setimageUploaded] = useState("");
  // const [imageUploadedError, setImageUploadedError] = useState("");

  // const [showImage, setShowImage] = useState("");

  const [supplierData, setSupplierData] = useState({});

  const [addScreenTitle, setAddScreenTitle] = useState(false);

  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  // View details start

  const [rowIndex, setRowIndex] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [popViewdata, setPopViewdata] = useState({});
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [supplierdata2, setSupplierdata2] = useState({});

  // view details end

  const [bindSupplierMasterDatas, setBindSupplierMasterDatas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [bindCommoditiesDD, setBindCommoditiesDD] = useState([]);

  // const handleDropDown = (event) => {
  //   // To set Number of records per page
  //   const selectedValue = parseInt(event.target.value); // Convert to integer
  //   setItemsPerPage(selectedValue);
  //   setCurrentPage(1); // Reset to first page when items per page changes
  // };

  useEffect(() => {
    BindSupplierGridData();
  }, []);
  const BindSupplierGridData = async () => {
    const response = await BindAPI("SupplierAPI/BindSupplier");
    setBindSupplierMasterDatas(response.data.Table);
  };
  useEffect(() => {
    const fetchData = async () => {
      binddrpCoutrydata();
      binddrpstatedata();
      binddrpMedicinesdrugsdata();
    };
    fetchData();
  }, []);

  const binddrpCoutrydata = async () => {
    try {
      const bindCountrydatadrp = await BindAPI(
        "MasterDataAPI/BindAllMasterData"
      );
      if (bindCountrydatadrp.data.Table9.length > 0) {
        const countryDD = bindCountrydatadrp.data.Table9.map((m) => ({
          value: m.MstCountryId,
          label: m.CountryName_en,
        }));
        setCountryBindDD(countryDD);
      } else {
        setCountryBindDD([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpstatedata = async () => {
    try {
      const bindStatedatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");
      if (bindStatedatadrp.data.Table.length > 0) {
        const stateDD = bindStatedatadrp.data.Table.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));

        setStateBindDD(stateDD);
      } else {
        setStateBindDD([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpMedicinesdrugsdata = async () => {
    try {
      const bindMedicinesdrugsdatadrp = await BindAPI(
        "MasterDataAPI/BindAllMasterData"
      );

      console.log("Medicines:", bindMedicinesdrugsdatadrp.data);
      if (bindMedicinesdrugsdatadrp.data.Table10.length > 0) {
        const medicinesdrugsDD = bindMedicinesdrugsdatadrp.data.Table10.map(
          (m) => ({
            value: m.MstCommoditiesId,
            label: m.CommodityName_en,
          })
        );
        setBindCommoditiesDD(medicinesdrugsDD);
      } else {
        setBindCommoditiesDD([]);
      }
    } catch (error) {
      console.error("Error fetching Medicines Drugs data:", error);
    }
  };

  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
      console.log("locationResponse", locationResponse);
    };
    getSelectedLocation();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == supplierFields.state // Replace with the desired MstStateId
    );
    console.log("stateSelectedValue", getLocationDatas, supplierFields);
    if (stateSelectedValue) {
      const branchProjectLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));
      const branchProjectSelected = branchProjectLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBranchProjectBindDD(branchProjectSelected);
    } else {
    }
  }, [supplierFields.state]);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true); // Add Screen title & button
    setUpdateScreenTitle(false); // Update Screen title & button
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setSupplierFields({ ...supplierFields, date: formattedDate });
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    clear();
  };
  const clear = () => {
    //Clear Supplier data
    BindSupplierGridData();
    setSupplierFields({
      supplierId: "SUP",
      supplierName: "",
      address: "",
      country: "1",
      state: "",
      branchProject: "",
      pinCode: "",
      date: "",
      commodities: null,
      imageUploaded: "",
      contactPerson: "",
      contactPersonDesignation: "",
      contactNumber: "",
      contactPersonEmail: "",
    });
    setSupplierFieldsError({
      supplierIdError: "",
      supplierNameError: "",
      addressError: "",
      countryError: "",
      stateError: "",
      branchProjectError: "",
      pinCodeError: "",
      dateError: "",
      branchProjectError: "",
      sdpError: "",
      userIdError: "",
      showImageError: "",
    });
    // setBindCommoditiesDD([]);
    // setSupplierId("");
    // setSupplierIdError("");
    // setSupplierName("");
    // setSupplierNameError("");
    // setAddress("");
    // setAddressError("");
    // setCountry("");
    // setCountryError("");
    // setState("");
    // setStateError("");
    // setLocation("");
    // setLocationError("");
    // setPinCode("");
    // setPinCodeError("");
    // setMedicinesDrugs("");
    // setMedicinesDrugsError("");
    // setContactPerson("");
    // setContactPersonError("");
    // setContactNumber("");
    // setContactNumberError("");
    // setContactPersonDesignation("");
    // setContactPersonDesignationError("");
    // setContactPersonEmail("");
    // setContactPersonEmailError("");
    // setShowImage("");
    // setImageUploadedError("");
  };

  // const handleShowImage = async () => {
  //   const formData = new FormData();
  //   formData.append("SupplierLogo", imageUploaded);
  //   const response = await ImageAPI(formData, "SupplierImageUpload");
  //   setShowImage(response.data);
  // };
  const handleShowImage = (showImage) => {

    console.log("image", showImage);
    
    // Set showImage to an empty string if showImage is falsy
    setSupplierFields({
      ...supplierFields,
      showImage: showImage || "",  // This will set to showImage if it's truthy, else ""
    });
  
    console.log(supplierFields);
  
    // Reset any error related to showImage
    setSupplierFieldsError({ ...supplierFieldsError, showImageError: "" });
  };
  

  // const dropdownValuesEffect = () => {
  //   if (editMode && selectedRowIndex !== null) {
  //     dropdownstate(selectedRowIndex);
  //     dropdownlocation(selectedRowIndex);
  //   }
  // };
  // useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);

  // const dropdownstate = (rowIndex) => {
  //   try {
  //     const StaterowData = bindSupplierMasterDatas[rowIndex];
  //     const StateInfo = stateBindDD.find(
  //       (ST) => ST.value === StaterowData.MstStateId
  //     );
  //     const resultState = {
  //       value: StaterowData.MstStateId,
  //       label: StateInfo ? StateInfo.label : "DefaultStateLabel",
  //     };
  //     setState(resultState);
  //   } catch (error) {
  //     console.error("Error setting state:", error);
  //   }
  // };

  // const dropdownlocation = (rowIndex) => {
  //   try {
  //     const locationrowData = bindSupplierMasterDatas[rowIndex];
  //     const LocationInfo = getLocationDatas.find(
  //       (L) => L.MstLocationId === locationrowData.MstLocationId
  //     );
  //     const ResultLocation = {
  //       value: locationrowData.MstLocationId,
  //       label: LocationInfo
  //         ? LocationInfo.LocationName_en
  //         : "DefaultStateLabel",
  //     };
  //     setLocation(ResultLocation);
  //   } catch (error) {
  //     console.error("Error setting state:", error);
  //   }
  // };

  const handleEdit = async (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    var supplierDate = bindSupplierMasterDatas[rowIndex].SupplierDate;
    const supplierDatePart = supplierDate.split("T")[0];
    setSupplierFields({
      ...supplierFields,
      supplierId: bindSupplierMasterDatas[rowIndex].SupplierId,
      supplierName: bindSupplierMasterDatas[rowIndex].SupplierName,
      address: bindSupplierMasterDatas[rowIndex].SupplierAddress,
      country: bindSupplierMasterDatas[rowIndex].MstCountryId,
      state: bindSupplierMasterDatas[rowIndex].MstStateId,
      branchProject: bindSupplierMasterDatas[rowIndex].MstLocationId,
      pinCode: bindSupplierMasterDatas[rowIndex].SupplierPincode,

      date: supplierDatePart,
      commodities: bindSupplierMasterDatas[rowIndex].SupplierCommodities,
      showImage: bindSupplierMasterDatas[rowIndex].SupplierLogo,
      contactPerson:
        bindSupplierMasterDatas[rowIndex].SupplierContactPersonName,
      contactPersonDesignation:
        bindSupplierMasterDatas[rowIndex].SupplierContactPersonDesignation,
      contactNumber: bindSupplierMasterDatas[rowIndex].SupplierMobileNumber,
      contactPersonEmail: bindSupplierMasterDatas[rowIndex].SupplierEmailId,
    });

    setMstSupplierId(bindSupplierMasterDatas[rowIndex].MstSupplierId);
    // setSupplierId(bindSupplierMasterDatas[rowIndex].SupplierId);
    // setSupplierName(bindSupplierMasterDatas[rowIndex].SupplierName);
    // setAddress(bindSupplierMasterDatas[rowIndex].SupplierAddress);
    // setShowImage(bindSupplierMasterDatas[rowIndex].SupplierLogo);
    // const CountryrowData = bindSupplierMasterDatas[rowIndex];
    // const CountryInfo = countryBindDD.find(
    //   (ST) => ST.value === CountryrowData.MstCountryId
    // );
    // const resultCountry = {
    //   value: CountryrowData.MstCountryId,
    //   label: CountryInfo ? CountryInfo.label : "",
    // };
    // setCountry(resultCountry);
    // setPinCode(bindSupplierMasterDatas[rowIndex].SupplierPincode);
    // setLogo(bindSupplierMasterDatas[rowIndex].SupplierLogo);
    // setContactPerson(
    //   bindSupplierMasterDatas[rowIndex].SupplierContactPersonName
    // );
    // setContactPersonDesignation(
    //   bindSupplierMasterDatas[rowIndex].SupplierContactPersonDesignation
    // );
    // setContactNumber(bindSupplierMasterDatas[rowIndex].SupplierMobileNumber);
    // setContactPersonEmail(bindSupplierMasterDatas[rowIndex].SupplierEmailId);
    // setMedicinesDrugs(bindSupplierMasterDatas[rowIndex].SupplierCommodities);
    // const medicinesDrugsData = bindSupplierMasterDatas[rowIndex];
    // const medicinesDrugsInfo = bindCommoditiesDD.find(
    //   (ST) => ST.value == medicinesDrugsData.SupplierCommodities
    // );
    // const resultmedicinesDrugs = {
    //   value: medicinesDrugsData.SupplierCommodities,
    //   label: medicinesDrugsInfo ? medicinesDrugsInfo.label : "",
    // };
    // setMedicinesDrugs(resultmedicinesDrugs);
    // setSupplierDate(bindSupplierMasterDatas[rowIndex].SupplierDate);
    // var supplierDate = bindSupplierMasterDatas[rowIndex].SupplierDate;
    // const supplierDatePart = supplierDate.split("T")[0];
    // setSupplierDate(supplierDatePart);
  };

  const handleSupplier = (rowIndex, flag) => {
    // Setting visibility in popup container

    setpopUpOpen(true);

    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }

    setSupplierData(bindSupplierMasterDatas[rowIndex]);
    const CountryrowData = bindSupplierMasterDatas[rowIndex];
    const CountryInfo = countryBindDD.find(
      (ST) => ST.value === CountryrowData.MstCountryId
    );
    const resultCountry = {
      value: CountryrowData.MstCountryId,
      label: CountryInfo ? CountryInfo.label : "",
    };
    const StaterowData = bindSupplierMasterDatas[rowIndex];
    const StateInfo = stateBindDD.find(
      (ST) => ST.value === StaterowData.MstStateId
    );
    const resultState = {
      value: StaterowData.MstStateId,
      label: StateInfo ? StateInfo.label : "DefaultStateLabel",
    };
    const locationrowData = bindSupplierMasterDatas[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstLocationId
    );
    const ResultLocation = {
      value: locationrowData.MstLocationId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };
    const medicinesDrugsData = bindSupplierMasterDatas[rowIndex];
    const medicinesDrugsInfo = bindCommoditiesDD.find(
      (ST) => ST.value === medicinesDrugsData.SupplierCommodities
    );
    const resultmedicinesDrugs = {
      value: medicinesDrugsData.SupplierCommodities,
      label: medicinesDrugsInfo ? medicinesDrugsInfo.label : "",
    };
    var supplierDate = bindSupplierMasterDatas[rowIndex].SupplierDate;
    const supplierDatePart = supplierDate.split("T")[0];

    const Supplierdata2 = [
      resultCountry,
      resultState,
      ResultLocation,
      resultmedicinesDrugs,
      supplierDatePart,
    ];

    setSupplierdata2(Supplierdata2);
  };

  const [branchProjectBindDD, setBranchProjectBindDD] = useState([]);
  const [stateBindDD, setStateBindDD] = useState([]);
  const [countryBindDD, setCountryBindDD] = useState([
    { value: "1", label: "India" },
  ]);
  const createdBy = sessionStorage.getItem("createdBy");
  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};
  };
  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // const supplierIdValidate =
      const isFormError = {
        ...supplierFieldsError,
        // supplierIdError:
        //   supplierFields.supplierId.length > 0
        //     ? /^[A-Za-z0-9]+$/.test(supplierFields.supplierId)
        //       ? ""
        //       : "Only Alphabet and Numbers allowed"
        //     : "Enter Supplier Id.",
        supplierNameError:
          supplierFields.supplierName.length > 0
            ? /^[A-Za-z.' ]+$/.test(supplierFields.supplierName)
              ? ""
              : "Only Alphabet and space allowed"
            : "Enter Supplier Name",
        addressError: supplierFields.address.length > 0 ? "" : "Enter Address",
        countryError: supplierFields.country ? "" : "Select Country",
        stateError: supplierFields.state ? "" : "Select State",
        branchProjectError: supplierFields.branchProject ? "" : "Select City",
        pinCodeError: supplierFields.pinCode ? "" : "Enter Pin Code",
        // commoditiesError: supplierFields.commodities
        //   ? ""
        //   : "Select Commodities",
        // showImageError: supplierFields.showImage
        //   ? ""
        //   : "Please Upload an Image",
        contactPersonError:
          supplierFields.contactPerson.length > 0
            ? /^[A-Za-z ]+$/.test(supplierFields.contactPerson)
              ? ""
              : "Only Alphabet and space allowed"
            : "Enter Contact Person",

        contactNumberError:
          supplierFields.contactNumber.length > 0
            ? /^[\d\s]+$/.test(supplierFields.contactNumber)
              ? ""
              : "Only numbers allowed"
            : "Enter Contact Number",
        // contactPersonEmailError:
        //   supplierFields.contactPersonEmail.length > 0
        //     ? /^[A-Za-z@.]+$/.test(supplierFields.contactPersonEmail)
        //       ? ""
        //       : "Enter Valid Email Id"
        //     : "Enter Email",

        contactPersonDesignationError:
          supplierFields.contactPersonDesignation.length > 0
            ? /^[A-Za-z.' ]+$/.test(supplierFields.contactPersonDesignation)
              ? ""
              : "Only Alphabet and space allowed"
            : "Enter Designation",
      };
      setSupplierFieldsError(isFormError);
      const isFormValid = Object.values(isFormError).every(
        (error) => error === ""
      );
      if (isFormValid) {
        setSubmitBtnDisabled(true);
        // if ({...supplierFieldsError,
        //         supplierIdError: "",
        //         supplierNameError: "",
        //         addressError: "",
        //         countryError: "",
        //         stateError: "",
        //         branchProjectError: "",
        //         pinCodeError: "",
        //         dateError: "",
        //         branchProjectError: "",
        //         showImageError: "",})
        // !supplierIdValidate &&
        // !supplierNameValidate &&
        // !addressValidate &&
        // !countryValidate &&
        // !stateValidate &&
        // !locationValidate &&
        // !pinCodeValidate &&
        // !medicinesDrugsValidate &&
        // !supplierLogoValidate &&
        // !contactPersonValidate &&
        // !contactPersonDesignationValidate &&
        // !contactNumberValidate &&
        // !contactPersonEmailValidate
        // )

        const supplierPayload = [
          {
            mstSupplierId: mstSupplierId ? mstSupplierId : 0,
            supplierName: supplierFields.supplierName,
            supplierId: supplierFields.supplierId,
            mstCountryId: supplierFields.country,
            mstStateId: supplierFields.state,
            mstLocationId: supplierFields.branchProject,
            supplierAddress: supplierFields.address,
            supplierPincode: supplierFields.pinCode,
            supplierLogo: supplierFields.showImage,
            supplierContactPersonName: supplierFields.contactPerson,
            supplierContactPersonDesignation:
              supplierFields.contactPersonDesignation,
            supplierMobileNumber: supplierFields.contactNumber,
            supplierEmailId: supplierFields.contactPersonEmail,
            supplierDate: supplierFields.date,
            supplierCommodities: supplierFields.commodities,
            createdby: createdBy,
            flag: flag,
          },
        ];

        const response = await IUDAPI(
          supplierPayload,
          "SupplierAPI/IUDSupplier"
        );

        if (flag == 1) {
          // Submit

          if (response.data.Table[0].Result === "S") {
            toast.success("Supplier added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setSubmitVisibleButton(true);
            setSupplierFields({
              ...supplierFields,
              supplierId: response.data.Table1[0].SupplierId,
            });
            setTimeout(cancel, 10000);
          } else {
            toast.error("Failed to add Supplier data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("Supplier updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Supplier data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
        setIsOpen(true);
        if (isFormError.supplierNameError && supplierNameRef.current) {
          supplierNameRef.current.focus();
        } else if (isFormError.addressError && addressRef.current) {
          addressRef.current.focus();
        } else if (isFormError.countryError && countryRef.current) {
          countryRef.current.focus();
        } else if (isFormError.stateError && stateRef.current) {
          stateRef.current.focus();
        } else if (isFormError.branchProjectError && branchProjectRef.current) {
          branchProjectRef.current.focus();
        } else if (isFormError.pinCodeError && pinCodeRef.current) {
          pinCodeRef.current.focus();
        } else if (isFormError.contactPersonError && contactPersonRef.current) {
          contactPersonRef.current.focus();
        } else if (
          isFormError.contactPersonDesignationError &&
          contactPersonDesignationRef.current
        ) {
          contactPersonDesignationRef.current.focus();
        } else if (isFormError.contactNumberError && contactNumberRef.current) {
          contactNumberRef.current.focus();
        }
        // toast.error("Please fill in all the mandatory fields.", {
        //   duration: 2000,
        //   position: "top-center",
        // });
      }
    } else {
      const data = {
        mstSupplierId: bindSupplierMasterDatas[items].MstSupplierId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "SupplierAPI/IUDSupplier");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Supplier data deleted successfully 🗑️",
            5000,
            cancel()
          );
        } else {
          toast.error("Failed to delete Supplier data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            "Supplier data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Supplier data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Supplier data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Supplier data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location.pathname == "/suppliermaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // BindSupplierGridData();
      cancel();
    }
  }, [location]);
  // const [searchInput, setSearchInput] = useState("");

  // const [searchData, setFilteredData] = useState(bindSupplierMasterDatas);

  // const handleSearchInputChange = (e) => {
  //   // Search in Grid

  //   const inputValue = e.target.value;

  //   setSearchInput(inputValue);

  //   handleSearch(inputValue);
  // };

  // const handleSearch = (input) => {
  //   const filtered = bindSupplierMasterDatas.filter((item) => {
  //     const providerData = `${item.SupplierId} ${item.SupplierName} ${item.SupplierMobileNumber} ${item.SupplierEmailId} ${item.LocationName_en}`;

  //     return providerData.toLowerCase().includes(input.toLowerCase());
  //   });

  //   setFilteredData(filtered);
  // };

  return (
    <MainLayout pageName={"2"}>
      {visibleTable && (
        <GridPage
          title="Supplier Master"
          header={supplierMasterHeader}
          records={bindSupplierMasterDatas}
          // currentPage={currentPage}
          blockView={true}
          handleEdit={handleEdit}
          handleAction={handleSupplier}
          showAdd={showAdd}
        />
        // <div className="">
        //   <div class={mainTable.headerTitle}>
        //     <div className={mainTable.gridTableHeader}>{`Supplier Master`}</div>
        //   </div>

        //   <div className="relative mt-1 md:hidden">
        //     <div className={mainTable.searchIconPosition}>
        //       <svg
        //         className={mainTable.searchIconSvg}
        //         aria-hidden="true"
        //         fill="currentColor"
        //         viewBox="0 0 20 20"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           fill-rule="evenodd"
        //           d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //           clip-rule="evenodd"
        //         ></path>
        //       </svg>
        //     </div>

        //     <input
        //       type="text"
        //       className={mainTable.searchInputBox}
        //       placeholder="Search for items"
        //       value={searchInput}
        //       onChange={handleSearchInputChange}
        //     />
        //   </div>

        //   <div className="justify-between flex items-center">
        //     <div className={mainTable.searchAdd}>
        //       <div className="mt-1 gap-2 flex justify-between items-baseline">
        //         <label for="pageSize" className="hidden md:block">
        //           Rows Per Page:
        //         </label>

        //         <select
        //           onChange={handleDropDown}
        //           className={mainTable.dropDownRecords}
        //           name="pageSize"
        //         >
        //           <option value="2">2</option>
        //           <option value="5">5</option>
        //           <option value="10">10</option>
        //           <option value="20">20</option>
        //           <option value="50" selected>
        //             50
        //           </option>
        //           <option value="100">100</option>
        //         </select>
        //       </div>

        //       <div>
        //         <label for="table-search" className="sr-only">
        //           Search
        //         </label>
        //       </div>

        //       <div className="hidden md:block relative mt-1">
        //         <div className={mainTable.searchIconPosition}>
        //           <svg
        //             className={mainTable.searchIconSvg}
        //             aria-hidden="true"
        //             fill="currentColor"
        //             viewBox="0 0 20 20"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               fill-rule="evenodd"
        //               d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //               clip-rule="evenodd"
        //             ></path>
        //           </svg>
        //         </div>

        //         <input
        //           type="text"
        //           className={mainTable.searchInputBox}
        //           placeholder="Search for items"
        //           value={searchInput}
        //           onChange={handleSearchInputChange}
        //         />
        //       </div>
        //     </div>

        //     <div className="flex self-end">
        //       <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
        //         <span className="mb-12 text-red-600 text-7xl">.</span>

        //         <span>Block</span>
        //       </div>

        //       <button
        //         type="button"
        //         className={mainTable.addButton}
        //         onClick={showAdd}
        //       >
        //         <svg
        //           width="25"
        //           height="20"
        //           viewBox="0 0 50 50"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
        //             fill="white"
        //           />
        //         </svg>
        //         ADD
        //       </button>
        //     </div>
        //   </div>

        //   <div class={mainTable.tableShadowWidth}>
        //     <TableGrid
        //       className={mainTable.table}
        //       headerData={supplierMasterHeader}
        //       tableData={
        //         searchData.length > 0 ? searchData : bindSupplierMasterDatas
        //       }
        //       onEditGrid={handleEdit}
        //       onDeleteGrid={handleSupplier}
        //       onBlockGrid={handleSupplier}
        //       onAddressGrid={handleSupplier}
        //       onViewGrid={handleSupplier}
        //       currentPage={currentPage}
        //       itemsPerPage={itemsPerPage}
        //     />
        //   </div>

        //   <div className={mainTable.submitCancelMainDiv}>
        //     <button
        //       onClick={() => setCurrentPage(currentPage - 1)}
        //       disabled={currentPage === 1}
        //       className={mainTable.submitButton}
        //     >
        //       Previous Page
        //     </button>

        //     <label>{currentPage}</label>

        //     <button
        //       onClick={() => setCurrentPage(currentPage + 1)}
        //       disabled={
        //         currentPage === bindSupplierMasterDatas.length / itemsPerPage
        //       }
        //       className={mainTable.cancelButton}
        //     >
        //       Next Page
        //     </button>
        //   </div>
        // </div>
      )}

      {visibleAdd && (
        <div class=" flex flex-col w-full">
          <div class={mainTable.clientMasterMainAdd}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Supplier Master"
            />
            {/* <div class={mainTable.addScreenTitle}>
              {addScreenTitle && (
                <div className={mainTable.addScreenTitle}>
                  {"Supplier Master - Add"}
                </div>
              )}

              {updateScreenTitle && (
                <div className={mainTable.addScreenTitle}>
                  {" Supplier Master - Edit"}
                </div>
              )}
            </div> */}

            <div class={mainTable.addShadowScreen}>
              <div class={mainTable.mainContentScreen}>
                <form class={mainTable.formForClientMaster}>
                  <div class={mainTable.labelBold}>Supplier Details</div>

                  <hr class={mainTable.horizontalLine}></hr>

                  <div class="grid gap-4 mb-6 md:grid-cols-3">
                    <InputText
                      inputType={"text"}
                      title={" Supplier Id"}
                      name={" supplierId"}
                      maxLength={20}
                      classStar={"text-red-500"}
                      content={supplierFields.supplierId}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          supplierId: e.target.value,
                        })
                      }
                      disabled={true}
                    />

                    <InputText
                      ref={supplierNameRef}
                      inputType={"text"}
                      title={"Supplier Name"}
                      name={"suppliername"}
                      classStar={"text-red-500"}
                      maxLength={100}
                      content={supplierFields.supplierName}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          supplierName: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        // setSupplierName(e.target.value);
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          supplierNameError:
                            supplierFields.supplierName &&
                            supplierFields.supplierName.length > 0
                              ? /^[A-Za-z.' ]+$/.test(
                                  supplierFields.supplierName
                                )
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Supplier Name",
                        }));
                      }}
                      errorMessage={supplierFieldsError.supplierNameError}
                    />
                    {/* {supplierNameError && (
                        <span class="text-red-500">{supplierNameError}</span>
                      )} */}

                    <InputText
                      ref={addressRef}
                      inputType={"text"}
                      title={"Address"}
                      maxLength={500}
                      name={"address"}
                      classStar={"text-red-500"}
                      content={supplierFields.address}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          address: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        // setAddress(e.target.value);
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          addressError:
                            supplierFields.address &&
                            supplierFields.address.length > 0
                              ? /^[A-Za-z0-9, -/]+$/.test(
                                  supplierFields.address
                                )
                                ? ""
                                : "Only Alphabets, numbers, commas, hyphens, and spaces allowed"
                              : "Enter Address",
                        }));
                      }}
                      errorMessage={supplierFieldsError.addressError}
                    />
                    {/* {addressError && (
                        <span class="text-red-500">{addressError}</span>
                      )} */}

                    <AutoDropDown
                      title="Country"
                      ref={countryRef}
                      options={countryBindDD}
                      placeholder="Select"
                      name="country"
                      selectedOption={countryBindDD.find(
                        (x) => x.value == supplierFields.country
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      classStar="text-red-500"
                      controlFunc={(value, e) => {
                        handleSupplierFieldsChange(value.value, e.name);
                        // setSupplierFields({
                        //   ...supplierFields,
                        //   country: value,
                        // });
                        // setCountryError(value ? "" : "Select a Country");
                      }}
                      controlFuncBlur={() => {
                        setSupplierFieldsError(() => ({
                          ...supplierFieldsError,
                          countryError: supplierFields.country
                            ? ""
                            : "Select Country",
                        }));
                      }}
                      errorMessage={supplierFieldsError.countryError}
                    />
                    {/* {countryError && (
                        <span className="text-red-500">{countryError}</span>
                      )} */}

                    <AutoDropDown
                      title="State"
                      ref={stateRef}
                      options={stateBindDD}
                      placeholder="Select"
                      name="state"
                      selectedOption={stateBindDD.find(
                        (x) => x.value == supplierFields.state
                      )}
                      Class="p-0"
                      tabIndex="1"
                      classStar="text-red-500"
                      controlFunc={(value, e) => {
                        handleSupplierFieldsChange(value.value, e.name);
                        setSupplierFields({
                          ...supplierFields,
                          state: value.value,
                          branchProject: "",
                        });
                        setSupplierFieldsError({
                          ...supplierFieldsError,
                          stateError: value ? "" : "Select State",
                        });

                        // controlFunc={(value) => {
                        //   setSupplierFields({ ...supplierFields, state: value });
                      }}
                      controlFuncBlur={() => {
                        setSupplierFieldsError(() => ({
                          ...supplierFieldsError,
                          stateError: supplierFields.state
                            ? ""
                            : "Select State",
                        }));
                      }}
                      errorMessage={supplierFieldsError.stateError}
                      // setStateError(state ? "" : "Select a State");
                      // }}
                    />
                    {/* {stateError && (
                        <span className="text-red-500">{stateError}</span>
                      )} */}

                    <AutoDropDown
                      title="City"
                      ref={branchProjectRef}
                      options={branchProjectBindDD}
                      placeholder="Select"
                      name="branchProject"
                      selectedOption={
                        supplierFields.branchProject
                          ? branchProjectBindDD.find(
                              (x) => x.value == supplierFields.branchProject
                            )
                          : ""
                      }
                      Class="custom-class"
                      tabIndex="1"
                      classStar="text-red-500"
                      controlFunc={(value, e) => {
                        handleSupplierFieldsChange(value.value, e.name);
                        // controlFunc={(value) => {
                        //   setSupplierFields({
                        //     ...supplierFields,
                        //     branchProject: value,
                        //   });
                        // setLocation(value);
                      }}
                      controlFuncBlur={() => {
                        setSupplierFieldsError(() => ({
                          ...supplierFieldsError,
                          branchProjectError: supplierFields.branchProject
                            ? ""
                            : "Select City",
                        }));
                      }}
                      errorMessage={supplierFieldsError.branchProjectError}
                      //   setLocationError(location ? "" : "Select a Location");
                      // }}
                    />
                    {/* {locationError && (
                        <span className="text-red-500">{locationError}</span>
                      )} */}

                    <InputText
                      ref={pinCodeRef}
                      inputType={"text"}
                      title={"Pin Code"}
                      name={"pinCode"}
                      classStar={"text-red-500"}
                      maxLength={6}
                      content={supplierFields.pinCode}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          pinCode: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          pinCodeError:
                            supplierFields.pinCode &&
                            supplierFields.pinCode.length > 0
                              ? /^[\d\s]+$/.test(supplierFields.pinCode)
                                ? ""
                                : "Only numbers allowed"
                              : "Enter Pin Code",
                        }));
                      }}
                      errorMessage={supplierFieldsError.pinCodeError}
                      //   setPinCodeError(
                      //     pinCode.length > 0
                      //       ? /^[0-9]+$/.test(pinCode)
                      //         ? ""
                      //         : "Only numbers allowed"
                      //       : "Enter Supplier PinCode"
                      //   );
                      // }}
                    />
                    {/* {pinCodeError && (
                        <span class="text-red-500">{pinCodeError}</span>
                      )} */}

                    <InputText
                      inputType="date"
                      title="Date"
                      classStar="text-red-500"
                      name="date"
                      content={supplierFields.date}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          date: e.target.value,
                        })
                      }
                    />
                    <div></div>
                    {/* <AutoDropDown
                      title="Commodities"
                      options={bindCommoditiesDD}
                      placeholder="Select"
                      name="commodities"
                      selectedOption={bindCommoditiesDD.find(
                        (x) => x.value == supplierFields.commodities
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      classStar="text-red-500"
                      controlFunc={
                        (value, e) => {
                          handleSupplierFieldsChange(value.value, e.name);
                          // controlFunc={
                          //   (value) => {
                          //     setSupplierFields({
                          //       ...supplierFields,
                          //       commodities: value,
                          //     });
                        }
                        // setMedicinesDrugs(value);
                      }
                      controlFuncBlur={() => {
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          commoditiesError: supplierFields.commodities
                            ? ""
                            : "Select Commodities",
                          // );
                        }));
                      }}
                      errorMessage={supplierFieldsError.commoditiesError}
                    /> */}
                    {/* {medicinesDrugsError && (
                        <span className="text-red-500">
                          {medicinesDrugsError}
                        </span>
                      )} */}

                    {/* <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="file"
                        title="Logo"
                        name="image"
                        // content={image}
                        // controlFunc={(e) => setImage(e.target.value)}
                        handleImageUpload={(e) =>
                          setimageUploaded(e.target.files[0])
                        }
                      />
                      <span class="text-neutral-700 text-xs break-all">
                        Image size limit: 2MB. Accepted Image types: JPEG, PNG.
                      </span>
                      {imageUploadedError && (
                        <span className="text-red-500">
                          {imageUploadedError}
                        </span>
                      )}
                    </div>

                    <div className="flex">
                      <div className="mt-7 mr-7">
                        <button
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>

                      <div>
                        <img
                          src={showImage ? showImage : download}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div> */}
                  </div>
                  <FileUpload
                    title="Logo"
                    // classDiv="col-span-2"
                    // classStar="text-red-500"
                    apiCall="SupplierAPI/SupplierImageUpload"
                    modalName="SupplierLogo"
                    name="showImage"
                    disabledBtn={updateScreenTitle}
                    showImage={supplierFields.showImage}
                    showImageData={handleShowImage}
                    imageUploadedError={supplierFieldsError.showImageError}
                    limitMassage={
                      "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                    }
                    MAX_FILE_SIZE_MB={2}
                    ALLOWED_FORMATS={["image/jpeg", "image/jpg", "image/png"]}
                  />
                  <div class={mainTable.labelBold}>Contact Info</div>

                  <hr class={mainTable.horizontalLine}></hr>

                  <div class="grid gap-4 mb-6 md:grid-cols-3">
                    <InputText
                      ref={contactPersonRef}
                      inputType={"text"}
                      title={"Contact Person"}
                      name={"contactPerson"}
                      classStar={"text-red-500"}
                      maxLength={100}
                      content={supplierFields.contactPerson}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          contactPerson: e.target.value,
                        })
                      }
                      controlFuncBlur={(e) => {
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          contactPersonError:
                            supplierFields.contactPerson &&
                            supplierFields.contactPerson.length > 0
                              ? /^[A-Za-z ]+$/.test(
                                  supplierFields.contactPerson
                                )
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Contact Person",
                        }));
                      }}
                      errorMessage={supplierFieldsError.contactPersonError}
                    />
                    {/* {contactPersonError && (
                        <span class="text-red-500">{contactPersonError}</span>
                      )} */}

                    <InputText
                      ref={contactPersonDesignationRef}
                      inputType={"text"}
                      title={"Designation"}
                      name={"contactPersonDesignation"}
                      classStar={"text-red-500"}
                      maxLength={50}
                      content={supplierFields.contactPersonDesignation}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          contactPersonDesignation: e.target.value,
                        })
                      }
                      controlFuncBlur={(e) => {
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          contactPersonDesignationError:
                            supplierFields.contactPersonDesignation &&
                            supplierFields.contactPersonDesignation.length > 0
                              ? /^[A-Za-z ]+$/.test(
                                  supplierFields.contactPersonDesignation
                                )
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Designation",
                        }));
                      }}
                      errorMessage={
                        supplierFieldsError.contactPersonDesignationError
                      }
                    />
                    {/* {contactPersonDesignationError && (
                        <span class="text-red-500">
                          {contactPersonDesignationError}
                        </span>
                      )} */}

                    <InputText
                      ref={contactNumberRef}
                      inputType={"text"}
                      title={"Contact Number"}
                      name={"contactNumber"}
                      classStar={"text-red-500"}
                      maxLength={11}
                      content={supplierFields.contactNumber}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          contactNumber: e.target.value,
                        })
                      }
                      controlFuncBlur={(e) => {
                        setSupplierFieldsError((supplierFieldsError) => ({
                          ...supplierFieldsError,
                          contactNumberError:
                            supplierFields.contactNumber &&
                            supplierFields.contactNumber.length > 0
                              ? /^[\d\s]+$/.test(supplierFields.contactNumber)
                                ? ""
                                : "Enter a valid Contact Number"
                              : "Enter Contact Number",
                        }));
                      }}
                      errorMessage={supplierFieldsError.contactNumberError}
                    />
                    {/* {contactNumberError && (
                        <span class="text-red-500">{contactNumberError}</span>
                      )} */}

                    <InputText
                      inputType={"text"}
                      title={"Email"}
                      name={"contactPersonEmail"}
                      // classStar={"text-red-500"}
                      content={supplierFields.contactPersonEmail}
                      controlFunc={(e) =>
                        setSupplierFields({
                          ...supplierFields,
                          contactPersonEmail: e.target.value,
                        })
                      }
                      // controlFuncBlur={(e) => {
                      //   setSupplierFieldsError((supplierFieldsError) => ({
                      //     ...supplierFieldsError,
                      //     contactPersonEmailError:
                      //       supplierFields.contactPersonEmail &&
                      //       supplierFields.contactPersonEmail.length > 0
                      //         ? /^[a-z0-9._%-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
                      //             supplierFields.contactPersonEmail
                      //           )
                      //           ? ""
                      //           : "Enter Valid Email Id"
                      //         : "Enter Email",
                      //   }));
                      // }}
                      // errorMessage={supplierFieldsError.contactPersonEmailError}
                    />
                    {/* {contactPersonEmailError && (
                        <span class="text-red-500">
                          {contactPersonEmailError}
                        </span>
                      )} */}
                  </div>
                </form>

                {/* <div class={mainTable.submitCancelMainDiv}>
                  {addScreenTitle && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}

                  {updateScreenTitle && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}

                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    onClick={cancel}
                    class={mainTable.cancelButton}
                  >
                    CANCEL
                  </button>
                </div> */}
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      Supplier Id :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {supplierFields.supplierId}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <PopUpScreenTitle
            title="Supplier Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          {/* <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <div class="text-xl font-medium text-neutral-700 mx-5 ">
            {viewDetails && `Supplier Master - View`}
            {deleteDetails && `Supplier Master - Delete`}
            {blockDetails && `Supplier Master - Block`}
            {unBlockDetails && `Supplier Master - Unblock`}
          </div> */}
          <SupplierPopup data={supplierData} data1={supplierdata2} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
        {/* <div className={mainTable.submitCancelMainDiv}>
          {deleteDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}

          {unBlockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div> */}
      </MainPopup>
    </MainLayout>
  );
};

export default SupplierMaster;
