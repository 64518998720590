// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import { BindAPI, IUDAPI, parameterPostAPI } from "../services/APIConnection";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import axios from "axios";
import { useUserCapability } from "../components/ConstantReducerProvider";

const DoctorAppoinment = ({
  isOpen,
  clientData,
  data1,
  currentData2,
  doctorPopupCancel,
}) => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  const [apptWith, setApptWith] = useState("");
  const [bindAreaDD, setBindAreaDD] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [meetingLinkError, setMeetingLinkError] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editModeFlag, setEditModeFlag] = useState(1);

  const [apptWithError, setApptWithError] = useState("");

  const [bindAppoinmentWithDD, setBindAppoinmentWithDD] = useState([]);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const [appointmentDate, setappointmentDate] = useState(getCurrentDate);
  const [appointmentEndDateTime, setAppointmentEndDateTime] = useState("");
  const [appointmentStartDateTime, setAppointmentStartDateTime] = useState("");

  const [apptBranchMailId, setApptBranchMailId] = useState("");

  useEffect(() => {

    const appoinmentWithDD = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      if (userProfileDatas[0]?.MstLocationId) {
        const filteredData = locationResponse.data.Table.filter(
          (item) => item.MstLocationId === userProfileDatas[0].MstLocationId
        );

        setApptBranchMailId(filteredData[0].Email);
        setApptWith(userProfileDatas[0].MstLocationId);

        //   const sdpData = appoinmentWithResponse.data.Table.filter(
        //     (item) => item.MstSDPId === userProfileDatas[0].MstSDPId
        //   ).map((item) => ({
        //     value: item.MstSDPId,
        //     label: item.SDP_en,
        //   }));

        //   setBindAppoinmentWithDD(sdpData);
        // } else {
        //   const sdpLabels = appoinmentWithResponse.data.Table.filter(
        //     (item) => item.MstLocationId === userProfileDatas[0]?.MstLocationId
        //   ).map((item) => ({
        //     MstSDPId: item.MstSDPId,
        //     SDP_en: item.SDP_en,
        //   }));

        //   const sdpSelected = sdpLabels.map((AR) => ({
        //     value: AR.MstSDPId,
        //     label: AR.SDP_en,
        //   }));

        //   setBindAppoinmentWithDD(sdpSelected);
      }

      if (currentData2 && currentData2[0]) {
        setappointmentDate(currentData2[0].AppoinmentDate);
        console.log("sssss", currentData2[0].AppoinmentDate);
        setApptWith(userProfileDatas[0].MstLocationId);
        setArea(currentData2[0].MstAreaId);
        setMeetingLink(currentData2[0].MeetingLink);
        setEditMode(true);
      } else {
    
        setappointmentDate(getCurrentDate);
        handleEndTime(appointmentDate);
        console.log("userProfileDatas", userProfileDatas[0]);
        if (userProfileDatas && userProfileDatas[0]) {
          if (userProfileDatas[0].MstLocationId != null) {
            setApptWith(userProfileDatas[0].MstLocationId);
          } else {
            setApptWith("");
          }

          console.log("apptWith", apptWith);
        }
        setArea("");
        setMeetingLink("");
      }
    };
    appoinmentWithDD();
  }, [currentData2 ? currentData2 : undefined]);

  // useEffect(() => {
  //   const getSelectedArea = async () => {
  //     const locationResponse = await BindAPI("AreaAPI/GetArea");
  //     setGetAreaDatas(locationResponse.data.Table);
  //   };

  //   getSelectedArea();
  // }, []);

  // useEffect(() => {
  //   if (apptWith && getAreaDatas.length > 0) {
  //     const areaSelectedValue = getAreaDatas.find(
  //       (item) => item.MstSDPId === apptWith
  //     );

  //     if (areaSelectedValue) {
  //       const areaLabels = getAreaDatas
  //         .filter((item) => item.MstSDPId === apptWith)
  //         .map((item) => ({
  //           MstAreaId: item.MstAreaId,
  //           AreaName_en: item.AreaName_en,
  //           AreaEmail: item.AreaEmail,
  //         }));

  //       const areaSelected = areaLabels.map((AR) => ({
  //         value: AR.MstAreaId,
  //         label: AR.AreaName_en,
  //         AreaEmail: AR.AreaEmail,
  //       }));
  //       console.log("areaSelected", areaSelected);
  //       setBindAreaDD(areaSelected);
  //     } else {
  //       setBindAreaDD([]);
  //     }
  //   }
  // }, [apptWith, getAreaDatas]);

  const validate = () => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    // Validation logic for the Appoinment With input
    if (apptWith == "") {
      errors.apptWith = "Select Appoinment With";
    }

    // Validation logic for the Area Name input
    // if (area == "") {
    //   errors.area = "Select area";
    // }
    // // Validation logic for the Meeting Link input
    // if (meetingLink == "") {
    //   errors.meetingLink = "Click Schedule Appoinment";
    // }

    setApptWithError(errors.apptWith || "");
    // setAreaError(errors.area || "");
    // setMeetingLinkError(errors.meetingLink || "");

    return errors;
  };

  const handleSubmit = () => {
    // const doctorAppoinmentValidation = validate();

    if (meetingLink != "") {
      const appoinmentPayload = {
        appointmentDate: appointmentDate,
        AppointmentWith: apptWith,
        MstAreaId: area,
        MeetingLink: meetingLink,
        Status: editModeFlag ? editModeFlag : "",
      };
      data1(appoinmentPayload);
      console.log("data1", data1);
      isOpen(false);
    }
  };

  useEffect(() => {
    if (doctorPopupCancel) {
      console.log("doctorPopupCancel", doctorPopupCancel);
      setappointmentDate(getCurrentDate);
      setApptWith("");
      setApptWithError("");
      setArea("");
      setAreaError("");
      setMeetingLink("");
      setMeetingLinkError("");
      setEditMode(false);
    }
  }, [doctorPopupCancel ? doctorPopupCancel : false]);
  const handleValueChange = (value) => {
    setEditModeFlag(value);
  };

  // const getBranchEmail = (value) => {
  //   if (value) {
  //     const filteredOptions = bindAreaDD
  //       .filter((e) => e.value == value)
  //       .map((i) => i.AreaEmail)
  //       .toString();
  //     console.log("filteredOptions", filteredOptions);
  //     // setApptBranchMailId(filteredOptions);
  //   } else {
  //     return;
  //   }
  // };

  const handleEndTime = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);
    const endTime = new Date(
      new Date(selectedDate).getTime() + 2 * 60 * 60 * 1000
    ); // Add 2 hours
    setAppointmentEndDateTime(endTime.toISOString().slice(0, 16)); // Convert to ISO string and remove seconds
    setAppointmentStartDateTime(selectedDateObj.toISOString().slice(0, 16));
  };

  const ScheduleAppointment = async () => {

    let validation = validate();
    if (Object.keys(validation).length === 0) {
      let payload = {
        subject: `Doctor Appointment - ${clientData?.ClientName_en}`,
        body: { contentType: "HTML", content: "" },
        start: { dateTime: appointmentStartDateTime, timeZone: "UTC" },
        end: { dateTime: appointmentEndDateTime, timeZone: "UTC" },
        location: { displayName: "Online" },
        attendees: [
          {
            emailAddress: {
              address: userProfileDatas[0]?.UserEmail,
              name: userProfileDatas[0]?.UserName,
            },
            type: "required",
          },
          {
            emailAddress: { address: apptBranchMailId, name: "" },
            type: "required",
          },
        ],
        isOnlineMeeting: true,
        onlineMeetingProvider: "teamsForBusiness",
      };

      const response = await IUDAPI(
        payload,
        "api/MicrosoftTeamsAPI/CreateMicrosoftTeamsMeeting"
      );

      if (response.status === 200) {
        //const responseBody = await response.text();
        console.log("response data the Meeting", response);
        const regExp = /Join URL:\s*(https:\/\/teams\.microsoft\.com\/[^\s]+)/;
        const match = response?.data?.match(regExp);

        if (match && match[1]) {
          const joinUrl = match[1];
          console.log(joinUrl);
          setMeetingLink(joinUrl);
      

          const appoinmentPayload = {
            appointmentDate: appointmentDate,
            AppointmentWith: apptWith,
            MstAreaId: area,
            MeetingLink: joinUrl,
            Status: editModeFlag ? editModeFlag : "",
          };
          data1(appoinmentPayload);
          console.log("data1", data1);
          // isOpen(false);

        } else {
          console.log("Join URL not found in the response");
        }
      } else {
        console.log(`Request failed with status ${response.status}`);
      }
    }
  };

  console.log("EndTime Data:", appointmentEndDateTime);
  console.log("Start Time:", appointmentStartDateTime);
  console.log("clientData", clientData);
  return (
    <div class="w-full">
      <div>
        <form class={"p-5"}>
          <div class={mainTable.labelBold}>Doctor Appointment</div>
          <hr class={mainTable.servicesHorizontalLine}></hr>
          <div className="md:grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-4 sm:col-span-1 w-full">
            <InputText
              inputType="datetime-local"
              title="Appoinment Date"
              name="date"
              min={new Date().toISOString().slice(0, 16)}
              content={appointmentDate}
              controlFunc={(e) => {
                setappointmentDate(e.target.value);
                if (
                  editMode &&
                  currentData2[0].AppoinmentDate != e.target.value
                ) {
                  handleValueChange(2);
                }
              }}
            />
            {/* <div>
              <AutoDropDown
                classStar="text-red-500"
                title="Appointment with"
                options={bindAppoinmentWithDD}
                placeholder="Select"
                name="state"
                selectedOption={bindAppoinmentWithDD.find(
                  (x) => x.value == apptWith
                )}
                controlFunc={(value) => {
                  setArea("");
                  setApptWith(value.value);
                  setApptWithError(value ? "" : "Select Appoinment With");
                  setApptBranchMailId("");
                  if (
                    editMode &&
                    currentData2[0].AppointmentWith != value.value
                  ) {
                    console.log(
                      "currentData2[0].AppointmentWith",
                      currentData2[0].AppointmentWith
                    );
                    console.log("value", value.value);
                    handleValueChange(2);
                  }
                }}
                controlFuncBlur={() => {
                  setArea("");
                  setApptWithError(apptWith ? "" : "Select Appoinment With");
                }}
                Class="custom-class"
                tabIndex="1"
              />
              {apptWithError && (
                <span class="text-red-500">{apptWithError}</span>
              )}
            </div> */}
            {/* <AutoDropDown
              classStar="text-red-500"
              title="Area"
              options={bindAreaDD}
              placeholder="Select"
              name="area"
              selectedOption={
                area ? bindAreaDD.find((x) => x.value == area) : ""
              }
              controlFunc={(value) => {
                setArea(value.value);
                setAreaError(value ? "" : "Select Area");
                if (editMode && currentData2[0].MstAreaId != value.value) {
                  handleValueChange(2);
                }
                getBranchEmail(value.value);
              }}
              controlFuncBlur={() => {
                setAreaError(area ? "" : "Select Area");
              }}
              Class="custom-class"
              tabIndex="1"
              errorMessage={areaError}
            /> */}

            <InputText
              inputType="text"
              title={"Appointment with"}
              name={"CYPConversionFactor"}
              content={userProfileDatas[0]?.LocationName_en}
              disabled="true"
            />
            <InputText
              inputType="text"
              disabled="true"
              title={"Branch / Project Email"}
              content={apptBranchMailId}
              name={"CYPConversionFactor"}
            />
            <div className="mt-7 mr-7">
              <button
                type="button"
                className={
                  meetingLink === ""
                    ? mainTable.submitButton
                    : mainTable.disabledSubmitButton
                }
                onClick={() => {
                  ScheduleAppointment();
                }}
                disabled={meetingLink === "" ? false : true}
                title={meetingLink === "" ? "" : "Button is disabled"}
              >
                Schedule Appoinment
              </button>
            </div>
          </div>
          {meetingLink != "" && (
            <div class="lg:col-span-4 sm:col-span-1">
              <TextArea
                inputType="text"
                title="Your Meeting Link"
                name="meetingLink"
                rows="4"
                placeholder="Meeting Link"
                content={meetingLink}
                disabled="true"
              />
            </div>
          )}
        </form>
        {/* {meetingLink != "" && (
          <div className={mainTable.submitCancelMainDiv}>
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default DoctorAppoinment;
