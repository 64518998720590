import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { inventoryIndentHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { Indent } from "../popup-containers/IndentPopup";
import moment from "moment/moment";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import usePrevious from "../Hook/UsePrevious";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function InventoryIndent() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [bindInventoryIndentsData, setBindInventoryIndentsData] = useState([]);
  const [bindOrganizationStructure, setOrganizationStructure] = useState([
    // { value: "1", label: "Head Quarters" },
    // { value: "2", label: "Branch/Project" },
    // { value: "3", label: "SDP" },
    // { value: "4", label: "Outreach Staff" },
    // { value: "5", label: "CBP" },
  ]);
  const [bindAreaDD1, setBindAreaDD1] = useState([]);
  const [sdpDD, setSdpDD] = useState([]);
  const [commodityType, setCommodityType] = useState([]);
  const [bindSdpDropDown, setBindSdpDropDown] = useState([]);
  const [bindFieldWorkerName, setBindFieldWorkerName] = useState([]);
  const [bindCBP, setBindCBP] = useState([]);
  const [bindLocationDropDown, setBindLocationDropDown] = useState([]);
  const [bindAreaDropDown, setBindAreaDropDown] = useState([]);
  const [bindAreaDatas, setBindAreaDatas] = useState([]);
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);
  const [bindCommodities, setBindCommodities] = useState([]);

  const [bindIndentChildDatas, setBindIndentChildDatas] = useState([]);
  const [iCParticipantDetails, setICParticipantDetails] = useState("");
  const [bindCategoryNameDropDown, setBindCategoryNameDropDown] = useState([]);
  const [bindCategoryDropDown, setBindCategoryDropDown] = useState([]);
  const [bindCommodityType, setBindCommodityType] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [getCategoryNameDropDown, setGetCategoryNameDropDown] = useState([]);
  const [selectedReceivedFromError, setSelectedReceivedFromError] =
    useState("");

  const [indentChildTableError, setIndentChildTableErrorError] = useState("");
  const [txnIndentId, setTxnIndentId] = useState("");
  const [indentNumber, setIndentNumber] = useState("ININD");
  const [indentDate, setIndentDate] = useState("");
  const [indentAreaName, setIndentAreaName] = useState("");
  const [indentAreaNameError, setIndentAreaNameError] = useState("");
  const [indentFrom1, setIndentFrom1] = useState("");
  const [indentFrom2, setIndentFrom2] = useState("");
  const [indentFrom2Error, setIndentFrom2Error] = useState("");
  const [description, setDescription] = useState("");
  const [indentNumberError, setIndentNumberError] = useState("");
  const [sdp, setSdp] = useState("");
  const [branch, setBranch] = useState("");
  const [area, setArea] = useState("");
  const [sdpId, setSdpId] = useState("");
  const [branchId, setBranchId] = useState("");

  const [indentData, setIndentData] = useState({});
  const [currentData2, setCurrentData2] = useState({});
  const [currentData3, setCurrentData3] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);
  const [updateButton, setUpdateButton] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [editMode, setEditMode] = useState([]);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [visibleSdp, setVisibleSdp] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [indentFrom1Label, setIndentFrom1Label] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  // Indent Table start
  const [tableData, setTableData] = useState([
    {
      indentCategoryType: "",
      indentCategory: "",
      indentName: "",
      indentQuantity: "",
      UOM: "",
      indentCategoryList: [],
      indentNameList: [],
    },
  ]);

  const previousTableData = usePrevious(tableData);

  const headerData = [
    {
      title: "Category Type",
      key: "indentCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Category",
      key: "indentCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "indentName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Qty.",
      key: "indentQuantity",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "UOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  // const inventoryIndentData = async () => {
  //   const response = await BindAPI("InventoryIndentAPI/BindInventoryIndent");
  //   setBindInventoryIndentsData(response.data.Table);
  //   setBindIndentChildDatas(response.data.Table1);
  // };
  const inventoryIndentData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "InventoryIndentAPI/BindInventoryIndent"
      );
      setBindInventoryIndentsData(response.data.Table);
      setBindIndentChildDatas(response.data.Table1);
    }
  };
  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setIndentDate(formattedDate);
    getBranch(userProfileDatas);
    indentFrom();
  };
  const cancel = () => {
    setIndentData({});
    inventoryIndentData(userProfileDatas);
    setSelectedReceivedFrom("");
    setSelectedReceivedFrom1("");
    setIndentChildTableErrorError("");
    setICParticipantDetails("");
    setIndentAreaNameError("");
    setIndentFrom2Error("");
    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitButton(false);
    setAddScreenTitle(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
    handleOpen(false);
    const reset = {
      indentCategoryType: "",
      indentCategory: "",
      indentName: "",
      indentQuantity: "",
      UOM: "",
    };
    setTableData([reset]);
    setIndentNumber("ININD");
    setIndentDate("");
    setIndentAreaName("");
    setIndentFrom1("");
    setIndentFrom2("");
    setDescription("");
    setBranch("");
    setSdp("");
    setSdpId("");
    setArea("");
    setIndentNumberError("");
    setSelectedReceivedFromError("");
    setIndentChildTableErrorError("");
    setEditMode(false);
    setRowIndex("");
    setSelectedRowIndex("");
    setSelectedReceivedFrom("");
    setTableData([
      {
        indentCategoryType: "",
        indentCategory: "",
        indentName: "",
        indentQuantity: "",
        UOM: "",
      },
    ]);
  };

  const appendDataToState = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
  //  handleDeleteRow(0);
    setTableData([]);
    const formattedDataArray = newDataArray.map((data) => ({
      indentCategoryType: data.MstCommodityTypeId,
      indentCategory: data.MstCommodityCategoryId,
      indentName: data.MstCommoditiesId,
      indentQuantity: data.IndentQuantity,
      UOM: data.IndentUOM,
    }));

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };

  const editDropDownValues = () => {
    if (editMode && selectedRowIndex !== null) {
      //handleAreaDropDown(selectedRowIndex);
      handleCategoryReceivedItemDropDown(selectedRowIndex);
    }
  };

  useEffect(editDropDownValues, [editMode, selectedRowIndex]);

  const handleCategoryReceivedItemDropDown = (rowIndex) => {
    const indentData = bindInventoryIndentsData[rowIndex];

    let editCategoryReceivedItemDropDownValue;

    if (indentData.IndentFrom1Id == 3) {
      const SDPValue = bindSdpDropDown.find(
        (item) => item.value == indentData.IndentFrom2Id
      );

      editCategoryReceivedItemDropDownValue = {
        value: indentData.IndentFrom2Id,
        label: SDPValue ? SDPValue.label : "Unknown",
      };
    } else if (indentData.IndentFrom1Id === 2) {
      const eindentCategoryReceivedItemValue = bindLocationDropDown.find(
        (item) => item.value == indentData.IndentFrom2Id
      );
      editCategoryReceivedItemDropDownValue = {
        value: indentData.IndentFrom2Id,
        label: eindentCategoryReceivedItemValue
          ? eindentCategoryReceivedItemValue.label
          : "Unknown",
      };
    } else if (indentData.IndentFrom1Id === 4) {
      const Fieldvalues = bindFieldWorkerName.find(
        (item) => item.value == indentData.IndentFrom2Id
      );
      editCategoryReceivedItemDropDownValue = {
        value: indentData.IndentFrom2Id,
        label: Fieldvalues ? Fieldvalues.label : "Unknown",
      };
    } else if (indentData.IndentFrom1Id === 5) {
      const Fieldvalues = bindCBP.find(
        (item) => item.value === indentData.IndentFrom2Id
      );
      editCategoryReceivedItemDropDownValue = {
        value: indentData.IndentFrom2Id,
        label: Fieldvalues ? Fieldvalues.label : "Unknown",
      };
    }
    setIndentFrom2(editCategoryReceivedItemDropDownValue);
  };

  const handleEdit = async (rowIndex) => {
    getBranch(userProfileDatas);
    indentFrom();
    setEditMode(true);
    setSelectedRowIndex(rowIndex);

    setSubmitButton(false);
    setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    const bindIndentId = bindInventoryIndentsData[rowIndex].TxnIndentId;
    setIndentNumber(bindInventoryIndentsData[rowIndex].IndentNo);
    setIndentAreaName(bindInventoryIndentsData[rowIndex].MstAreaId);
    setIndentDate(
      moment(bindInventoryIndentsData[rowIndex].IndentDate).format("YYYY-MM-DD")
    );

    setSdp(
      visibleSdp
        ? bindInventoryIndentsData[rowIndex].SDP_en
        : bindInventoryIndentsData[rowIndex].MstSdpId
    );
    setSdpId(bindInventoryIndentsData[rowIndex].MstSdpId);
    setBranch(bindInventoryIndentsData[rowIndex].LocationName_en);
    setBranchId(bindInventoryIndentsData[rowIndex].MstBranchId);
    setDescription(bindInventoryIndentsData[rowIndex].Remarks_en);
    setTxnIndentId(bindInventoryIndentsData[rowIndex].TxnIndentId);
    // setIndentData(bindInventoryIndentsData[rowIndex]);
    setArea(bindInventoryIndentsData[rowIndex].ReceivedMstAreaId);
    setIndentFrom1(bindInventoryIndentsData[rowIndex].IndentFrom1Id);
    const IndentF = bindInventoryIndentsData[rowIndex].IndentFrom1Id;
    if (IndentF == 1) {
      setSelectedReceivedFrom1(null);
      setSelectedReceivedFrom(IndentF);
    } else if (IndentF == 2) {
      setSelectedReceivedFrom(IndentF);
      setSelectedReceivedFrom1("2");
    } else if (IndentF == 4) {
      setSelectedReceivedFrom(IndentF);
      setSelectedReceivedFrom1("4");
    } else if (IndentF == 5) {
      setSelectedReceivedFrom(IndentF);
      setSelectedReceivedFrom1("5");
    }
    setIndentFrom2(bindInventoryIndentsData[rowIndex].IndentFrom2Id);
    const ParticipantDetailDatas = Object.values(bindIndentChildDatas).filter(
      (item) => {
        return String(item.TxnIndentId) === String(bindIndentId);
      }
    );
    if (ParticipantDetailDatas.length === 0) {
      setTableData([
        {
          indentCategoryType: "",
          indentCategory: "",
          indentName: "",
          indentQuantity: "",
          UOM: "",
          indentCategoryList: [],
          indentNameList: [],
        },
      ]);
    } else {
      // Append the filtered data to your state using appendDataToState function
      appendDataToState(JSON.stringify(ParticipantDetailDatas));
    }
    // appendDataToState(JSON.stringify(ParticipantDetailDatas));
  };

  const handleReceivedFromChange = (e) => {
    setSelectedReceivedFrom(e.target.value);
    setSelectedReceivedFrom1(e.target.value);
    setIndentFrom1(e.target.value);
    setIndentFrom2Error("");
    setIndentFrom2("");
    setIndentAreaNameError("");
  };
  const indentFrom = () => {

    if (indentFrom1) {
      const organizationStructureDataDropDown = bindOrganizationStructure.find(
        (item) => item.value == indentFrom1
      );

      const testOrgani = {
        value: indentFrom1,
        label: organizationStructureDataDropDown
          ? organizationStructureDataDropDown.label
          : "Unknown",
      };
      setIndentFrom1Label(testOrgani.label);
    }
  };
  useEffect(() => {
    if (addScreenTitle || updateScreenTitle) {
      indentFrom();
    }
  }, [indentFrom1]);

  const [selectedReceivedFrom, setSelectedReceivedFrom] = useState(null);
  const [selectedReceivedFrom1, setSelectedReceivedFrom1] = useState(null);

  const getDataBasedOnReceivedFrom = () => {
    if (selectedReceivedFrom1 === "1") {
      setSelectedReceivedFrom1(null);
    } else if (selectedReceivedFrom1 === "2") {
      return bindLocationDropDown;
    } else if (selectedReceivedFrom1 === "4") {
      return bindSdpDropDown;
    } else if (selectedReceivedFrom1 === "5") {
      return bindFieldWorkerName;
    }
    // else if (selectedReceivedFrom1 === "5") {
    //   return bindCBP;
    // }

    return [];
  };

  useEffect(() => {
    indentDropDownDatas()
      .then(() => CategoryName())
      .then(() => Category())
      .then(() => inventoryIndentData(userProfileDatas))
      .catch((error) => {
        // Handle errors if any function fails
        console.error("Error in useEffect:", error);
      });
  }, []);

  const indentDropDownDatas = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      const sdpResponse = await BindAPI("SDPAPI/GetSDP");
      const areaResponse = await BindAPI("AreaAPI/GetArea");
      setBindAreaDatas(areaResponse.data.Table);
      const receivedFromResponse = await BindAPI(
        "InventoryReceiptAPI/BindInventoryReceivedFrom"
      );
      const filteredWithData = receivedFromResponse.data.Table.filter((item) =>
        [2, 4, 5].includes(item.TxnReceivedFromId)
      );
      const receivedFromData = filteredWithData.map((item) => ({
        value: item.TxnReceivedFromId,
        label: item.ReceivedFrom_en,
      }));
      const areaData = areaResponse.data.Table.map((item) => ({
        value: item.MstAreaId,
        label: item.AreaName_en,
      }));
      setBindAreaDropDown(areaData);
      const categoryResponse = await BindAPI(
        "CommoditiesAPI/GetCommoditiesCategory"
      );
      setBindCommoditiesCategory(categoryResponse.data.Table);
      const commodityTypeResponse = await BindAPI(
        "InventoryReceiptAPI/BindCommodityType"
      );
      // setCommodityType(commodityTypeResponse.data.Table);
      const nameResponse = await BindAPI("CommoditiesAPI/BindCommodities");
      setBindCommodities(nameResponse.data.Table);
      const orsNameResponse = await BindAPI("InventoryReceiptAPI/BindORSName");
      const cbpNameResponse = await BindAPI("CBPAPI/BindCBP");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      const sdpData = sdpResponse.data.Table.map((item) => ({
        value: item.MstSDPId,
        label: item.SDP_en,
      }));

      const commodityTypeData = commodityTypeResponse.data.Table.map(
        (item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        })
      );

      const categoryData = categoryResponse.data.Table.map((item) => ({
        value: item.MstCommodityCategoryId,
        label: item.CommodityCategory_en,
      }));

      const nameData = nameResponse.data.Table.map((item) => ({
        value: item.MstCommoditiesId,
        label: item.CommodityName_en,
      }));

      const orsNameData = orsNameResponse.data.Table.map((item) => ({
        value: item.MstUserId,
        label: item.FirstName_en,
      }));

      const cbpNameData = cbpNameResponse.data.Table.map((item) => ({
        value: item.MstCBPId,
        label: item.CBPName,
      }));
      setOrganizationStructure(receivedFromData);
      setBindLocationDropDown(locationData);
      setBindSdpDropDown(sdpData);
      // setBindAreaDropDown(areaData);
      setBindCategoryDropDown(categoryData);
      setBindCategoryNameDropDown(nameData);
      setBindFieldWorkerName(orsNameData);
      setBindCBP(cbpNameData);
      setBindCommodityType(commodityTypeData);
    } catch (error) {}
  };
  const handleSdpDropdownChange = (e) => {
    setSdp(e.target.value);
  };

  useEffect(() => {
    // const updateTableData = async () => {
    //   if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
    //     const updatedTableData = [...tableData]; // Clone the current table data

    //     // Iterate through each row
    //     for (const row of updatedTableData) {
    //       if (row?.(row?.indentCategoryType) ) {
    //         const getFilteredCategory = bindCommoditiesCategory
    //           .filter(
    //             (item) => item.MstCommodityTypeId === row.row.indentCategoryType
    //           )
    //           .map((item) => ({
    //             value: item.MstCommodityCategoryId,
    //             label: item.CommodityCategory_en,
    //           }));
    //         row.indentCategoryList = getFilteredCategory;
    //       }

    //       if (row?.indentCategory) {
    //         const selectedCommoditiesCategory = bindCommodities
    //           .filter(
    //             (item) => item.MstCommodityCategoryId === row.indentCategory
    //           )
    //           .map((item) => ({
    //             value: item.MstCommoditiesId,
    //             label: item.CommodityName_en,
    //             uom: item.CommodityUOM,
    //           }));
    //         row.indentNameList = selectedCommoditiesCategory;

    //         // Find the selected commodity for the row and set its unit of measure
    //         const selectedCommodity = selectedCommoditiesCategory.find(
    //           (item) => item.value === row.indentName
    //         );
    //         row.UOM = selectedCommodity?.uom || ""; // Reset if no selected commodity
    //       }
    //     }
    //     setTableData(updatedTableData);
    //   }
    // };
    // updateTableData();

    if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
      const currentTableData = [...tableData];
      currentTableData.forEach((row, index) => {
        if (row?.indentCategoryType) {
          const getFilteredCategory = bindCommoditiesCategory
            .filter((item) => item.MstCommodityTypeId == row.indentCategoryType)
            .map((items) => ({
              value: items.MstCommodityCategoryId,
              label: items.CommodityCategory_en,
            }));
          row.indentCategoryList = getFilteredCategory;
        }
        if (row?.indentCategory) {
          const selectedCommoditiesCategory = bindCommodities
            .filter((item) => item.MstCommodityCategoryId == row.indentCategory)
            .map((item) => ({
              value: item.MstCommoditiesId,
              label: item.CommodityName_en,
              uom: item.CommodityUOM,
            }));
          row.indentNameList = selectedCommoditiesCategory;
          // Check if there's a selected commodity for the current row
          const selectedCommodity = selectedCommoditiesCategory.find(
            (item) => item.value === row.indentName
          );
          // If a selected commodity is found, set the unitOfMeasure for the row
          if (selectedCommodity) {
            row.UOM = selectedCommodity.uom;
          } else {
            row.UOM = ""; // Reset if no selected commodity
          }
        }
      });
      setTableData(currentTableData);
    }
    // Iterate through all rows in tableData
  }, [tableData]);

  const validate = (flag) => {
    const errors = {};
    if (flag === 1 || flag === 2) {
      if (!selectedReceivedFrom) {
        errors.selectedReceivedFrom = "Please select Received From";
      }
      if (!indentAreaName && selectedReceivedFrom1 === "4") {
        errors.indentAreaName = "Select Area Name";
      }
      if (!indentFrom2) {
        errors.indentFrom2 =
          selectedReceivedFrom1 === "2"
            ? "Select Indent Branch/Project"
            : selectedReceivedFrom1 === "4"
            ? "Select Indent S.D.P"
            : selectedReceivedFrom1 === "5"
            ? "Select Indent Outreach Staff"
            : "Please select a valid option";
      }
    }
    setSelectedReceivedFromError(errors.selectedReceivedFrom || "");
    setIndentAreaNameError(errors.indentAreaName || "");
    setIndentFrom2Error(errors.indentFrom2 || "");

    return errors;
  };
  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    const createdBy = sessionStorage.getItem("createdBy");
    if (flag == 1 || flag == 2) {
      // validation start
      const inventoryIndentValidation = validate(flag);
      if (Object.keys(inventoryIndentValidation).length !== 0) {
        setIsOpen(true);
      }
      if (iCParticipantDetails.trim() === "") {
        setIndentChildTableErrorError("Fill the above table");
        setSubmitBtnDisabled(false);
        setIsOpen(true);
        return; // Stop further execution
      }
      if (Object.keys(inventoryIndentValidation).length === 0) {
        setSubmitBtnDisabled(true);
      } else {
        setSubmitBtnDisabled(false);
      }
      let indentData;
      try {
        indentData = JSON.parse(iCParticipantDetails);
      } catch (error) {
        console.error("Error parsing indent data:", error);
        setIndentChildTableErrorError("Error parsing indent data");
        return; // Stop further execution
      }

      if (!Array.isArray(indentData)) {
        console.error("Indent data is not an array");
        setIndentChildTableErrorError("Indent data is not an array");
        return; // Stop further execution
      }

      const indentErrors = indentData.map((indent, index) => {
        if (!indent) return `Indent at row ${index + 1} is missing.`;
        if (!indent.indentCategoryType)
          return `Indent at row ${index + 1} is missing Commodities.`;
        if (!indent.indentCategory)
          return `Indent at row ${index + 1} is missing Category.`;
        if (!indent.indentName)
          return `Indent at row ${index + 1} is missing Name.`;
        if (!indent.indentQuantity)
          return `Indent at row ${index + 1} is missing Quantity.`;
        if (!indent.UOM)
          return `Indent at row ${index + 1} is missing Unit Of Measure.`;
        return null;
      });

      const filteredIndentErrors = indentErrors.filter(
        (error) => error !== null
      );
      let inventoryIndentChildValidation;
      if (filteredIndentErrors.length > 0) {
        setSubmitBtnDisabled(false);
        inventoryIndentChildValidation = 1;
        setIndentChildTableErrorError(filteredIndentErrors.join("; "));
      } else {
        setIndentChildTableErrorError(""); // No errors found
        inventoryIndentChildValidation = 0;
      }

      if (
        Object.keys(inventoryIndentValidation).length === 0 &&
        inventoryIndentChildValidation == 0
      ) {
        setSubmitBtnDisabled(true);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedIndentFrom1Label = []; // Create an array to store translations

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            indentFrom1Label,
            i
          );
          translatedIndentFrom1Label.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const translatedIndentFrom2Label = []; // Create an array to store translations

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            indentFrom2.label,
            i
          );
          translatedIndentFrom2Label.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const translatedAreaNameLabel = []; // Create an array to store translations

        if (indentAreaName && indentAreaName.label) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(
              indentAreaName.label,
              i
            );
            translatedAreaNameLabel.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }

        const translatedDescription = []; // Create an array to store translations

        if (description) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(description, i);
            translatedDescription.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }
        const ResultRecipts = flag == 1 ? indentFrom1 : selectedReceivedFrom;
        const data = {
          txnIndentId: txnIndentId ? txnIndentId : 0,
          indentNo: indentNumber,
          indentDate: indentDate,
          mstBranchId: branchId,
          mstSdpId: visibleSdp ? sdpId : sdp,
          receivedMstAreaId: area?.value ? area.value : area || null,
          indentFrom1Id: ResultRecipts,
          indentFrom2Id: indentFrom2.value
            ? indentFrom2.value
            : selectedReceivedFrom1,
          indentAreaNameId: indentAreaName?.value,

          indentFrom1_en: indentFrom1Label,
          indentFrom1_ta: translatedIndentFrom1Label[0],
          indentFrom1_hi: translatedIndentFrom1Label[1],
          indentFrom1_mr: translatedIndentFrom1Label[2],
          indentFrom1_gu: translatedIndentFrom1Label[3],
          indentFrom1_ml: translatedIndentFrom1Label[4],
          indentFrom1_te: translatedIndentFrom1Label[5],
          indentFrom1_kn: translatedIndentFrom1Label[6],
          indentFrom1_bn: translatedIndentFrom1Label[7],
          indentFrom1_or: translatedIndentFrom1Label[8],

          indentFrom2_en: indentFrom2.label,
          indentFrom2_ta: translatedIndentFrom2Label[0],
          indentFrom2_hi: translatedIndentFrom2Label[1],
          indentFrom2_mr: translatedIndentFrom2Label[2],
          indentFrom2_gu: translatedIndentFrom2Label[3],
          indentFrom2_ml: translatedIndentFrom2Label[4],
          indentFrom2_te: translatedIndentFrom2Label[5],
          indentFrom2_kn: translatedIndentFrom2Label[6],
          indentFrom2_bn: translatedIndentFrom2Label[7],
          indentFrom2_or: translatedIndentFrom2Label[8],

          areaName_en: indentAreaName?.label,
          areaName_ta: translatedAreaNameLabel[0],
          areaName_hi: translatedAreaNameLabel[1],
          areaName_mr: translatedAreaNameLabel[2],
          areaName_gu: translatedAreaNameLabel[3],
          areaName_ml: translatedAreaNameLabel[4],
          areaName_te: translatedAreaNameLabel[5],
          areaName_kn: translatedAreaNameLabel[6],
          areaName_bn: translatedAreaNameLabel[7],
          areaName_or: translatedAreaNameLabel[8],

          indentDetails: iCParticipantDetails,

          remarks_en: description,
          remarks_ta: translatedDescription[0],
          remarks_hi: translatedDescription[1],
          remarks_mr: translatedDescription[2],
          remarks_gu: translatedDescription[3],
          remarks_kn: translatedDescription[4],
          remarks_ml: translatedDescription[5],
          remarks_te: translatedDescription[6],
          remarks_bn: translatedDescription[7],
          remarks_or: translatedDescription[8],
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [data],
          "InventoryIndentAPI/IUDInventoryIndent"
        );
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Inventory Indent added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setSubmitVisibleButton(true);
            setIndentNumber(response.data.Table1[0].IndentNo);
            setTimeout(cancel, 10000);
          } else {
            toast.error("Failed to add Inventory Indent data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Inventory Indent updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Inventory Indent data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      const data = {
        txnIndentId: bindInventoryIndentsData[items].TxnIndentId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI(
        [data],
        "InventoryIndentAPI/IUDInventoryIndent"
      );
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Inventory Indent data deleted successfully 🗑️",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to delete Inventory Indent data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            " Inventory Indent data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Inventory Indent data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Inventory Indent data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Inventory Indent data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  const HandleUserDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }
    setIndentData(bindInventoryIndentsData[rowIndex]);

    var DateOpeningPart = bindInventoryIndentsData[rowIndex].IndentDate;
    const OpeningdatePart = DateOpeningPart.split("T")[0];
    setIndentDate(OpeningdatePart);

    const ParticipantDetailDatas = Object.values(bindIndentChildDatas).filter(
      (item) =>
        item.TxnIndentId === bindInventoryIndentsData[rowIndex].TxnIndentId
    );

    setCurrentData2(ParticipantDetailDatas);

    const drpdetailsData = [
      OpeningdatePart,
      bindCommoditiesCategory,
      bindCommodities,
    ];
    setCurrentData3(drpdetailsData);
  };

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          indentCategoryType: "",
          indentCategory: "",
          indentName: "",
          indentQuantity: "",
          UOM: "",
          indentCategoryList: "",
          indentNameList: "",
        };
        updatedTableData.push(newRow); // Add a default row if the table is empty
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.indentCategoryType = "";
    newRow.indentCategory = ""; // Clear the values of the new row
    newRow.indentName = "";
    newRow.indentQuantity = "";
    newRow.UOM = "";
    newRow.indentCategoryList = [];
    newRow.indentNameList = [];

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };

  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setICParticipantDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    // let value = "";
    // if (columnName == "indentCategoryType") {
    //   value = selectedOption
    //     ? selectedOption.label
    //     : selectedOption.value || "";
    // } else {
    //   value = selectedOption
    //     ? selectedOption.value
    //     : selectedOption.label || "";
    // }
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  const Category = async () => {
    const response = await BindAPI("InventoryIndentAPI/BindCategory");
    setCategory(response.data.Table);
  };

  const CategoryName = async () => {
    const response = await BindAPI("InventoryIndentAPI/BindCategoryName");
    setCategoryName(response.data.Table);
  };

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = bindAreaDatas.find(
      (item) => item.MstSDPId == indentFrom2.value // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = bindAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));

      setBindAreaDropDown(areaSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [indentFrom2.value]);
  useEffect(() => {
    if (location.pathname == "/inventoryindent") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      inventoryIndentData(userProfileDatas);
    }
  }, [location, userProfileDatas]);

  const getBranch = (userProfileDatas) => {
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setBranch(userProfileDatas[0].LocationName_en);
      setBranchId(userProfileDatas[0].MstLocationId);
      if (userProfileDatas.length === 1) {
        setVisibleSdp(true);
        // If userProfileDatas has only one element
        setBranch(userProfileDatas[0].LocationName_en);
        setBranchId(userProfileDatas[0].MstLocationId);
        setSdp(userProfileDatas[0].SDP_en);
        setSdpId(userProfileDatas[0].MstSDPId);
      } else {
        const newSdp = userProfileDatas.map((userProfile) => ({
          value: userProfile.MstSDPId,
          label: userProfile.SDP_en,
        }));
        setSdpDD(newSdp);
        console.log("newSdp", newSdp);
      }
    } else {
      // handle else case if needed
    }
  };
  useEffect(() => {
    if (addScreenTitle || updateScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, []);
  useEffect(() => {
    // Find the selected state in getLocationDatas
    if (visibleSdp) {
      const areaSelectedValue = bindAreaDatas.find(
        (item) => item.MstSDPId == parseInt(sdpId) // Replace with the desired MstStateId
      );

      if (areaSelectedValue) {
        const areaLabels = bindAreaDatas
          .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
          .map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

        const areaSelected = areaLabels.map((AR) => ({
          value: AR.MstAreaId, // Replace with the appropriate value
          label: AR.AreaName_en,
        }));

        setBindAreaDD1(areaSelected);
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    } else {
      const areaSelectedValue = bindAreaDatas.find(
        (item) => item.MstSDPId == sdp // Replace with the desired MstStateId
      );

      if (areaSelectedValue) {
        const areaLabels = bindAreaDatas
          .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
          .map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

        const areaSelected = areaLabels.map((AR) => ({
          value: AR.MstAreaId, // Replace with the appropriate value
          label: AR.AreaName_en,
        }));

        setBindAreaDD1(areaSelected);
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    }
  }, [parseInt(sdpId) ? parseInt(sdpId) : null, sdp ? sdp : null]);
  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Inventory Indent"
          header={inventoryIndentHeaders}
          records={bindInventoryIndentsData}
          handleEdit={handleEdit}
          handleAction={HandleUserDetails}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Inventory Indent"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid5}>
                    <div>
                      <InputText
                        inputType="text"
                        title="Indent No."
                        name="IndentNo"
                        content={indentNumber}
                        classStar="text-red-500"
                        placeholder="Indent No"
                        maxLength={30}
                        disabled={true}
                        controlFunc={(e) => setIndentNumber(e.target.value)}
                      />
                      {indentNumberError && (
                        <span class="text-red-500">{indentNumberError}</span>
                      )}
                    </div>
                    <InputText
                      inputType="date"
                      title="Indent Date"
                      classStar="text-red-500"
                      name="indentDate"
                      content={indentDate}
                      controlFunc={(e) => setIndentDate(e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <InputText
                      inputType="text"
                      title="Branch"
                      name="branch"
                      disabled={true}
                      placeholder="Branch"
                      content={branch}
                      controlFunc={(e) => setBranch(e.target.value)}
                    />
                    {visibleSdp && (
                      <InputText
                        inputType="text"
                        title="S.D.P."
                        name="sdp"
                        content={sdp}
                        placeholder=""
                        disabled={true}
                        controlFunc={(e) => setSdp(e.target.value)}
                      />
                    )}
                    {!visibleSdp && (
                      <DropDown
                        title="S.D.P."
                        options={sdpDD}
                        placeholder="Select"
                        name="sdp"
                        selectedOption={sdp}
                        controlFunc={handleSdpDropdownChange}
                      />
                    )}
                    <AutoDropDown
                      title="Area"
                      options={bindAreaDD1}
                      name="area"
                      placeholder="Select"
                      Class="custom-class"
                      selectedOption={bindAreaDD1.find((x) => x.value == area)}
                      controlFunc={(value) => {
                        setArea(value);
                      }}
                    />
                  </div>
                  <div className={mainTable.gridInventory3}>
                    <div>
                      <DropDown
                        title="Indent From"
                        options={bindOrganizationStructure}
                        placeholder="Select"
                        classStar="text-red-500"
                        name="productReceivedFrom"
                        selectedOption={selectedReceivedFrom}
                        controlFunc={handleReceivedFromChange}
                        controlFuncBlur={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedReceivedFrom(selectedValue);
                          // Validation check
                          if (selectedValue.length === 0) {
                            setSelectedReceivedFromError(
                              "Please select Received From"
                            );
                          } else {
                            setSelectedReceivedFromError("");
                          }
                        }}
                      />
                      {selectedReceivedFromError && (
                        <div className="text-red-500">
                          {selectedReceivedFromError}
                        </div>
                      )}
                    </div>
                    {selectedReceivedFrom1 && (
                      <AutoDropDown
                        Class="p-0"
                        title={
                          selectedReceivedFrom1 === "2"
                            ? "Indent Branch/Project"
                            : selectedReceivedFrom1 === "4"
                            ? " Indent S.D.P"
                            : selectedReceivedFrom1 === "5"
                            ? "Indent Outreach Staff"
                            : // : selectedReceivedFrom1 === "5"
                              // ? "CBP"
                              "Indent Outreach Staff"
                        }
                        options={getDataBasedOnReceivedFrom()}
                        name="branch"
                        placeholder="Select"
                        classStar="text-red-500"
                        // selectedOption={indentFrom2}
                        // controlFunc={(value) => {
                        //   setIndentFrom2(value);
                        //   setIndentAreaName("");
                        // }}
                        selectedOption={indentFrom2 ? getDataBasedOnReceivedFrom(
                          selectedReceivedFrom1
                        ).find((x) => x.value == indentFrom2) : ""}
                        controlFunc={(value, e) => {
                          setIndentFrom2(value, e.name);
                        }}
                        controlFuncBlur={() => {
                          const errorMessage =
                            selectedReceivedFrom1 === "2"
                              ? "Select Indent Branch/Project"
                              : selectedReceivedFrom1 === "4"
                              ? "Select Indent S.D.P"
                              : selectedReceivedFrom1 === "5"
                              ? "Select Indent Outreach Staff"
                              : "Please select a valid option";
                          setIndentFrom2Error(indentFrom2 ? "" : errorMessage);
                        }}
                        errorMessage={indentFrom2Error}
                      />
                    )}
                    {selectedReceivedFrom1 === "4" && (
                      <AutoDropDown
                        title=" Area Name"
                        options={bindAreaDropDown}
                        name="areaName"
                        placeholder="Select"
                        selectedOption={bindAreaDropDown.find(
                          (x) => x.value == indentAreaName
                        )}
                        Class="custom-class"
                        classStar="text-red-500"
                        // disabled="false"
                        // tabIndex="1"
                        controlFunc={(value) => {
                          setIndentAreaName(value);
                        }}
                        controlFuncBlur={() => {
                          setIndentAreaNameError(
                            indentAreaName.value ? "" : "Select Area Name"
                          );
                        }}
                        errorMessage={indentAreaNameError}
                      />
                    )}
                  </div>
                  {/* <div className={mainTable.grid3}> */}

                  <div className="hidden lg:block col-span-3">
                    <table className="w-full border border-gray-300 mt-6">
                      <thead>
                        <tr>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                            Commodities
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                            Category
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                            Name
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-8">
                            Qty.
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                            U.O.M.
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-16">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index}>
                            <td className="border border-gray-300 p-2">
                              <AutoDropDown
                                options={bindCommodityType}
                                placeholder="Select"
                                name="Category"
                                Class="p-0"
                                selectedOption={bindCommodityType.find(
                                  (x) => x.value == row.indentCategoryType
                                )} // Use the entire object
                                controlFunc={(e) => {
                                  row.indentCategory = "";
                                  row.indentName = "";
                                  handleInputChange(
                                    e,
                                    index,
                                    "indentCategoryType"
                                  );
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <AutoDropDown
                                options={row.indentCategoryList || []}
                                placeholder="Select"
                                name="Category"
                                Class="p-0"
                                selectedOption={
                                  row.indentCategoryList && row.indentCategory
                                    ? row.indentCategoryList.find(
                                        (x) => x.value === row.indentCategory
                                      )
                                    : null
                                }
                                controlFunc={(e) => {
                                  row.indentName = "";
                                  handleInputChange(e, index, "indentCategory");
                                }}
                              />
                              {/* <DropDown
                                  options={bindCategoryDropDown}
                                  placeholder="Select"
                                  name="Category"
                                  selectedOption={row.indentCategory}
                                  controlFunc={(e) =>
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "indentCategory"
                                    )
                                  }
                                /> */}
                            </td>
                            <td className="border border-gray-300 p-2">
                              <AutoDropDown
                                options={row.indentNameList || []}
                                name="name"
                                placeholder="Select"
                                selectedOption={
                                  row.indentNameList && row.indentName
                                    ? row.indentNameList.find(
                                        (x) => x.value === row.indentName
                                      )
                                    : null
                                }
                                Class="p-0"
                                controlFunc={(e) => {
                                  handleInputChange(e, index, "indentName");
                                }}
                              />
                              {/* <DropDown
                                  options={bindCategoryNameDropDown}
                                  name="name"
                                  placeholder="Select"
                                  selectedOption={row.indentName}
                                  controlFunc={(e) =>
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "indentName"
                                    )
                                  }
                                /> */}
                            </td>
                            <td className="border border-gray-300 p-2">
                              <InputText
                                type="text"
                                className={mainTable.input}
                                content={row.indentQuantity}
                                maxLength={7}
                                controlFunc={(e) => {
                                  if (/^[0-9]*$/.test(e.target.value)) {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "indentQuantity"
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <InputText
                                type="text"
                                className={mainTable.input}
                                content={row.UOM}
                                disabled="disabled"
                                controlFunc={(e) => {
                                  handleInputChange(e.target, index, "UOM");
                                }}
                              />
                            </td>

                            <td className="border border-gray-300">
                              <div className="flex gap-4 align-baseline items-center justify-center">
                                <svg
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  class="w-6 h-6 text-black cursor-pointer"
                                  onClick={() => handleDeleteRow(index)}
                                >
                                  <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                </svg>
                                <svg
                                  viewBox="0 0 1024 1024"
                                  fill="currentColor"
                                  onClick={handleAddRow}
                                  class="w-6 h-6 text-black cursor-pointer"
                                >
                                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="hidden md:block">
                    {indentChildTableError && (
                      <div className="text-red-500">
                        {indentChildTableError}
                      </div>
                    )}
                  </div>

                  <div className={mainTable.tableMobile} role="table">
                    <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                      {tableData.length > 0 &&
                        tableData.map((row, index) => (
                          <div scope="col" class={mainTable.mobileTable}>
                            {headerData.map((keyData) => (
                              <div
                                scope="cell"
                                class={mainTable.tableData}
                                role="cell"
                              >
                                {keyData.key === "Action" ? (
                                  <div class={mainTable.tableData}>
                                    <div className="md:block hidden">
                                      <button
                                        id="dropdownMenuIconButton"
                                        data-dropdown-toggle="dropdownDots"
                                        class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                        type="button"
                                      >
                                        <svg
                                          class="w-6 h-6"
                                          aria-hidden="true"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                        </svg>
                                      </button>
                                    </div>
                                    <div className="md:hidden flex justify-center">
                                      <input
                                        type="button"
                                        className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                        onClick={handleAddRow}
                                        value="Add"
                                      />
                                      <input
                                        type="button"
                                        className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                        onClick={() => handleDeleteRow(index)}
                                        value="Delete"
                                      />
                                    </div>
                                  </div>
                                ) : keyData.key === "indentCategoryType" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={bindCommodityType}
                                      placeholder="Select"
                                      name="Category"
                                      Class="p-0"
                                      selectedOption={bindCommodityType.find(
                                        (x) => x.value == row.indentCategoryType
                                      )} // Use the entire object
                                      controlFunc={(e) => {
                                        row.indentCategory = "";
                                        row.indentName = "";
                                        handleInputChange(
                                          e,
                                          index,
                                          "indentCategoryType"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "indentCategory" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.indentCategoryList || []}
                                      placeholder="Select"
                                      name="Category"
                                      Class="p-0"
                                      selectedOption={
                                        row.indentCategoryList &&
                                        row.indentCategory
                                          ? row.indentCategoryList.find(
                                              (x) =>
                                                x.value === row.indentCategory
                                            )
                                          : null
                                      }
                                      controlFunc={(e) => {
                                        row.indentName = "";
                                        handleInputChange(
                                          e,
                                          index,
                                          "indentCategory"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "indentName" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.indentNameList || []}
                                      name="name"
                                      placeholder="Select"
                                      selectedOption={
                                        row.indentNameList && row.indentName
                                          ? row.indentNameList.find(
                                              (x) => x.value === row.indentName
                                            )
                                          : null
                                      }
                                      Class="p-0"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "indentName"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "indentQuantity" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      className={mainTable.input}
                                      content={row.indentQuantity}
                                      maxLength={7}
                                      controlFunc={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "indentQuantity"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "UOM" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      className={mainTable.input}
                                      content={row.UOM}
                                      disabled="disabled"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "UOM"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex border p-1">
                                    <div className="flex basis-1/2 font-bold">
                                      {keyData.title}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="md:hidden">
                    {indentChildTableError && (
                      <div className="text-red-500">
                        {indentChildTableError}
                      </div>
                    )}
                  </div>
                  <div class="lg:col-span-3 sm:col-span-1">
                    <TextArea
                      inputType="text"
                      title=" Description"
                      name="Description"
                      rows="4"
                      content={description}
                      maxLength={500}
                      placeholder="Description"
                      controlFunc={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  {/* </div> */}
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      Indent No. :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {indentNumber}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleUserDetails}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="Inventory Indent"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteButton}
            blockDetails={blockButton}
          />
          <Indent data={indentData} data2={currentData2} data3={currentData3} />
        </div>
        <PopUpButtons
          deleteDetails={deleteButton}
          blockDetails={blockButton}
          unBlockDetails={unBlockButton}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default InventoryIndent;
