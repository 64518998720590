import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import {
  IECSummaryHeader,
  ProfileCATClientsReportHeader,
} from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import ReportRowRowPage from "../components/ReportGridRowPage";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ReportIECTable from "../components/ReportIECTable";

const ReportIECSummary = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindIECSummaryData, setBindIECSummaryData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(
    () => {
      if (branchProject) {
        try {
          const getSelectedSDP = async () => {
            const locationResponse = await BindAPI("SDPAPI/GetSDP");
            setGetSDPDatas(locationResponse.data.Table);

            if (userProfileDatas && userProfileDatas[0]) {
              const sdpLabels = locationResponse.data.Table.filter((item) =>
                branchProject.includes(parseInt(item.MstLocationId))
              ).map((item) => ({
                MstSDPId: item.MstSDPId,
                SDP_en: item.SDP_en,
              }));

              const sdpSelected = sdpLabels.map((SD) => ({
                value: SD.MstSDPId,
                label: SD.SDP_en,
              }));

              if (
                userProfileDatas[0].MstOrganizationId == 1 ||
                userProfileDatas[0].MstOrganizationId == 2
              ) {
                setBindSDP(sdpSelected);
              } else {
                const allSDPIds = userProfileDatas[0]?.AllSDP;
                const sdpId = userProfileDatas[0]?.MstSDPId;

                if (allSDPIds && Array.isArray(allSDPIds)) {
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    allSDPIds.includes(sdp.value)
                  );
                  setBindSDP(filteredSdpSelected);
                }
                if (sdpId) {
                  console.warn("allSDPIds is undefined or not an array");
                  setBindSDP([]);
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    Array.isArray(sdpId)
                      ? sdpId.includes(sdp.value)
                      : sdpId === sdp.value
                  );
                  setBindSDP(filteredSdpSelected);
                }
              }
            }
          };

          getSelectedSDP();
        } catch (e) {
          console.log(`error fetch data ${e}`);
        }
      }
    },
    [branchProject ? branchProject : undefined],
    userProfileDatas ? userProfileDatas : undefined
  );
  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {

    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);

    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      console.log("ReportDataIEC", ReportData);
      const response = await BindPostAPI(ReportData, "IECAPI/ReportIECSummary");

      console.log("response", response.data.Table);
      setBindIECSummaryData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);
  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };
    exportData(
      IECSummaryHeader.map((column) => column.title),
      bindIECSummaryData,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };


  // const exportData = (data, dateRange, selectedDetails) => {
  //   const titleRow = [
  //     {
  //       v: "IEC Summary", 
  //       s: {
  //         font: { bold: true, sz: 16 },
  //         alignment: { horizontal: "left" },
  //       },
  //     },
  //   ];
  
  //   // Headers with proper merging
  //   const headerRow1 = [
  //     {
  //       v: "Type of topic/ subject",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center", vertical: "center", wrapText: true },
  //       },
  //     },
  //     {
  //       v: "Gender/Age",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center", vertical: "center" },
  //       },
  //     },
  //     {
  //       v: "Interpersonal contact (1-4 persons)",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center", vertical: "center", wrapText: true },
  //       },
  //     },
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //     {
  //       v: "Group discussions (5-20 persons)",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center", vertical: "center", wrapText: true },
  //       },
  //     },
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //     {
  //       v: "General / Mass (21+persons)",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center", vertical: "center", wrapText: true },
  //       },
  //     },
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //     {
  //       v: "Mass media/Supporting",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center", vertical: "center", wrapText: true },
  //       },
  //     },
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //   ];
  
  //   const headerRow2 = [
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //     { v: "", s: { fill: { fgColor: { rgb: "2D2D6E" } } } },
  //     {
  //       v: "10-14 Years",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "15-24 Years",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "25+ Years",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "Events",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "Participants",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "Events",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "Participants",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "Events",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //     {
  //       v: "Participants",
  //       s: {
  //         font: { bold: true, color: { rgb: "FFFFFF" } },
  //         fill: { fgColor: { rgb: "2D2D6E" } },
  //         alignment: { horizontal: "center" },
  //       },
  //     },
  //   ];
  
  //   // Generate data rows based on the topics and gender categories
  //   const dataRows = [];
  //   const topics = [
  //     "Abortion",
  //     "Adolescent Issues",
  //     "Common Cancer screening",
  //     "FP/MCH",
  //     "Gender based Violence / Reproductive coercion (GBV/RC)",
  //     "Gender identity & Sexual",
  //   ];
    
  //   const genderCategories = ["Boys / Men", "Girls / Women", "Other"];
  
  //   topics.forEach(topic => {
  //     genderCategories.forEach((gender, gIndex) => {
  //       const row = [
  //         { 
  //           v: gIndex === 0 ? topic : "",
  //           s: { alignment: { vertical: "center" } }
  //         },
  //         { 
  //           v: gender,
  //           s: { alignment: { vertical: "center" } }
  //         },
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // 10-14 Years
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // 15-24 Years
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // 25+ Years
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // Group Events
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // Group Participants
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // Mass Events
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // Mass Participants
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // Media Events
  //         { v: 0, s: { alignment: { horizontal: "right" } } }, // Media Participants
  //       ];
  //       dataRows.push(row);
  //     });
  //   });
  
  //   // Combine all rows
  //   const wsData = [titleRow, headerRow1, headerRow2, ...dataRows];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);
  
  //   // Define merges
  //   ws["!merges"] = [
  //     // Title merge
  //     { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
  //     // Header merges for topic groups
  //     { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } }, // Type of topic
  //     { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } }, // Gender/Age
  //     { s: { r: 1, c: 2 }, e: { r: 1, c: 4 } }, // Interpersonal contact
  //     { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } }, // Group discussions
  //     { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } }, // General/Mass
  //     { s: { r: 1, c: 9 }, e: { r: 1, c: 10 } }, // Mass media
  //     // Topic merges in data
  //     ...topics.map((_, index) => ({
  //       s: { r: 3 + (index * 3), c: 0 },
  //       e: { r: 5 + (index * 3), c: 0 }
  //     }))
  //   ];
  
  //   // Set column widths
  //   ws["!cols"] = [
  //     { width: 30 }, // Type of topic
  //     { width: 15 }, // Gender/Age
  //     { width: 12 }, // 10-14
  //     { width: 12 }, // 15-24
  //     { width: 12 }, // 25+
  //     { width: 12 }, // Events
  //     { width: 12 }, // Participants
  //     { width: 12 }, // Events
  //     { width: 12 }, // Participants
  //     { width: 12 }, // Events
  //     { width: 12 }, // Participants
  //   ];
  
  //   // Create workbook and export
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "IEC Summary");
  //   XLSX.writeFile(wb, "IEC_Summary_Report.xlsx");
  // };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "IEC Summary",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow1 = [
      {
        v: "S.No.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Branch",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "SDP",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Area",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Type of topic/ subject",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Interpersonal contact (1-4 persons)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      //   { v: "", s: {} },
      {
        v: "Group discussions (5-20 persons)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      //   { v: "", s: {} },

      {
        v: "General / Mass meetings (21+persons)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      //   { v: "", s: {} },
      {
        v: "Mass media/Supporting activities",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      // {
      //   v: "Total",
      //   s: {
      //     fill: { fgColor: { rgb: "3A3A3A" } },
      //     font: { bold: true, color: { rgb: "FFFFFF" } },
      //     alignment: { horizontal: "right" },
      //   },
      // },
    ];

    const headerRow2 = [
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Gender/Age",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "10-14 Years",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "15-24 Years",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "25+ Years",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Events",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Participants",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Events",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Participants.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },

      {
        v: "Events",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Participants",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
    ];

    const columnTotals = {
      tentoYears: 0,
      fifteentoYears: 0,
      twentyfivetoYears: 0,
      GroupDiscussionsEvents: 0,
      GDParticipants: 0,
      GeneralMassEvents: 0,
      GMMParticipants: 0,
      MassMediaEvents: 0,
      MMParticipants: 0,
    };
    // Data rows with row totals
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.tentoYears || 0) +
        Number(rowData.fifteentoYears || 0) +
        Number(rowData.twentyfivetoYears || 0) +
        Number(rowData.GroupDiscussionsEvents || 0) +
        Number(rowData.GDParticipants || 0) +
        Number(rowData.GeneralMassEvents || 0) +
        Number(rowData.GMMParticipants || 0) +
        Number(rowData.MassMediaEvents || 0) +
        Number(rowData.MMParticipants || 0);

      // Add to column totals
      columnTotals.tentoYears += Number(rowData.tentoYears || 0);
      columnTotals.fifteentoYears += Number(rowData.fifteentoYears || 0);
      columnTotals.twentyfivetoYears += Number(rowData.twentyfivetoYears || 0);
      columnTotals.GroupDiscussionsEvents += Number(
        rowData.GroupDiscussionsEvents || 0
      );
      columnTotals.GDParticipants += Number(rowData.GDParticipants || 0);
      columnTotals.GeneralMassEvents += Number(rowData.GeneralMassEvents || 0);
      columnTotals.GMMParticipants += Number(rowData.GMMParticipants || 0);
      columnTotals.MassMediaEvents += Number(rowData.MassMediaEvents || 0);
      columnTotals.MMParticipants += Number(rowData.MMParticipants || 0);

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Typeoftopicsubject,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.GenderAge,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.tentoYears || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.fifteentoYears || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.twentyfivetoYears || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.GroupDiscussionsEvents || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.GDParticipants || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.GeneralMassEvents || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        {
          v: Number(rowData.GMMParticipants || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        {
          v: Number(rowData.MassMediaEvents || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MMParticipants || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        // // Row total
        // {
        //   v: rowTotal,
        //   t: "n",
        //   s: {
        //     ...baseStyle,
        //     font: { bold: true },
        //     alignment: { horizontal: "right" },
        //     numFmt: "0",
        //   },
        // },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },

      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.tentoYears, t: "n", s: totalsRowStyle },
      { v: columnTotals.fifteentoYears, t: "n", s: totalsRowStyle },
      { v: columnTotals.twentyfivetoYears, t: "n", s: totalsRowStyle },
      { v: columnTotals.GroupDiscussionsEvents, t: "n", s: totalsRowStyle },
      { v: columnTotals.GDParticipants, t: "n", s: totalsRowStyle },
      { v: columnTotals.GeneralMassEvents, t: "n", s: totalsRowStyle },

      { v: columnTotals.GMMParticipants, t: "n", s: totalsRowStyle },
      { v: columnTotals.MassMediaEvents, t: "n", s: totalsRowStyle },
      { v: columnTotals.MMParticipants, t: "n", s: totalsRowStyle },

      // // Grand total
      // {
      //   v: grandTotal,
      //   t: "n",
      //   s: {
      //     ...totalsRowStyle,
      //     font: { bold: true, color: { rgb: "2D2D6E" } },
      //   },
      // },
    ];

    // Combine all rows

    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow1,
      headerRow2,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
      { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
      { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
      { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
      { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
      { s: { r: 4, c: 5 }, e: { r: 4, c: 8 } },
      { s: { r: 4, c: 9 }, e: { r: 4, c: 10 } },
      { s: { r: 4, c: 11 }, e: { r: 4, c: 12 } },
      { s: { r: 4, c: 13 }, e: { r: 4, c: 14 } },
      { s: { r: 4, c: 15 }, e: { r: 5, c: 15 } },


      ...dataRows.map((_, index) => ({
        s: { r: 3 + (index * 3), c: 9 },
        e: { r: 5 + (index * 3), c: 9 },
        
      })),

      ...dataRows.map((_, index) => ({
        s: { r: 3 + (index * 3), c: 10 },
        e: { r: 5 + (index * 3), c: 10 },
        
      })),

      ...dataRows.map((_, index) => ({
       s: { r: 3 + (index * 3), c: 11 },
        e: { r: 5 + (index * 3), c: 11 },
        
      })),

      ...dataRows.map((_, index) => ({
          s: { r: 3 + (index * 3), c: 12 },
        e: { r: 5 + (index * 3), c: 12 },
         
       })),


       ...dataRows.map((_, index) => ({
  
        s: { r: 3 + (index * 3), c: 13 },
        e: { r: 5 + (index * 3), c: 13 },

       
     })),

     ...dataRows.map((_, index) => ({
  
      s: { r: 3 + (index * 3), c: 14 },
      e: { r: 5 + (index * 3), c: 14 },

     
   })),



   
       
   
    ];

    // Define consistent column widths for all columns (adjust as needed)
    ws["!cols"] = [
      { width: 8 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 10 },
      { width: 16 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    ws["!rows"] = [
      { hpt: 25 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the Excel file
    XLSX.writeFile(wb, "IEC_Summary_Report.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure iecGDReportHeader is an array of objects with a title property
    // const headers = iecGDReportHeader.map((column) => column.title);
    const headers = [
      { content: "S.No.", rowSpan: 2 },
      { content: "Branch", rowSpan: 2 },
      { content: "Type of topic/ subject", rowSpan: 2 },
      { content: "Interpersonal contact (1-4 persons)", colSpan: 4 },
      { content: "Group discussions (5-20 persons)", colSpan: 2 },
      { content: "General / Mass meetings (21+persons)", colSpan: 2 },
      { content: "Mass media/Supporting activities", colSpan: 2 },
    ];
    const subHeaders = [
      "Gender/Age",
      "10-14 Years",
      "15-24 Years",
      "25+ Years",
      "Events",
      "Participants",
      "Events",
      "Participants",
      "Events",
      "Participants",
    ];
    // Map bindReportMonthlyEvents to the appropriate format for autoTable
    const data = bindIECSummaryData.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.Typeoftopicsubject,
        rowData.GenderAge,
        rowData.tentoYears,
        rowData.fifteentoYears,
        rowData.twentyfivetoYears,
        rowData.GroupDiscussionsEvents,
        rowData.GDParticipants,
        rowData.GeneralMassEvents,
        rowData.GMMParticipants,
        rowData.MassMediaEvents,
        rowData.MMParticipants,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers, subHeaders],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Age
        7: { cellWidth: "auto" }, // Gender
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
        11: { cellWidth: "auto" }, // Auto adjust width
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("IEC_Summary_Report.pdf");
  };

  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setShowTable(false);
    setSDP(updatedOptions);
    setBindAreaDD([]);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setShowTable(false);
    setArea("");
    setBindSDP([]);
    setBindAreaDD([]);
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    // setArea("");
    setShowTable(false);
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`IEC Summary`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="schoolName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>

              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => {
                  setShowTable(false);
                  setFromDate(e.target.value);
                }}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => {
                  setShowTable(false);

                  setToDate(e.target.value);
                }}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindIECSummaryData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title="INFORMATION, EDUCATION AND COMMUNICATION"
                header={IECSummaryHeader}
                records={bindIECSummaryData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ReportIECSummary;
