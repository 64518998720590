// // import React from "react";
// // import PropTypes from "prop-types";
// // import { mainTable } from "../css/Common";

// // const InputText = props => (

// //   <div className={props.classDiv ? props.classDiv : "form-group"}>
// //     <label className={props.classLabel ? props.classLabel : mainTable.label}>{props.title}<span className={props.classStar ? props.classStar : "hidden"}>*</span></label>

// //     {props.inputType === "file" ? (
// //       <input
// //         className={props.Class ? props.Class : mainTable.input}
// //         name={props.name}
// //         type="file"
// //         onChange={props.handleImageUpload}
// //         onBlur={props.handleImageUploadBlur}
// //         disabled={props.disabled}
// //         tabIndex={props.tabIndex}
// //       />
// //     ) : props.inputType === "image" ? (
// //     <img
// //       src={props.src}
// //       alt={props.alt}
// //       className={props.className}
// //     />
// //   ) : (
// //     <div>
// //       <input
// //         className={props.Class ? props.Class : mainTable.input}
// //         name={props.name}
// //         type={props.inputType}
// //         value={props.content}
// //         onChange={props.controlFunc}
// //         errorMessage={props.errorMessage}
// //         onBlur={props.controlFuncBlur}
// //         onClick={props.handleImageUpload}
// //         placeholder={props.placeholder}
// //         disabled={props.disabled}
// //         tabIndex={props.tabIndex}
// //         maxLength={props.maxLength}
// //         min={props.min}
// //         max={props.max}
// //       />
// //       {props.errorMessage &&  (
// //         <span class="text-red-500 text-sm  font-medium">{props.errorMessage}</span>
// //       )}
// //       </div>
// //     )}
// //   </div>
// // );

// // InputText.propTypes = {
// //   inputType: PropTypes.oneOf(["text", "number", "password", "email", "file","time","date"]).isRequired,
// //   title: PropTypes.string.isRequired,
// //   name: PropTypes.string.isRequired,
// //   controlFunc: PropTypes.func.isRequired,
// //   controlFuncBlur: PropTypes.func.isRequired,
// //   content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
// //   placeholder: PropTypes.string,
// //   disabled: PropTypes.oneOf(["true", "false"]).isRequired,
// //   tabIndex: PropTypes.string.isRequired,
// //   handleImageUpload: PropTypes.func,
// //   handleImageUploadBlur: PropTypes.func,
// //   src: PropTypes.string,
// //   alt: PropTypes.string,
// //   className: PropTypes.string,
// //   errorMessage: PropTypes.string,
// // };

// // export default InputText;

// import React, { forwardRef } from "react";
// import PropTypes from "prop-types";
// import { mainTable } from "../css/Common";

// const InputText = forwardRef((props, ref) => {
//   const handleNumberInputChange = (e) => {
//     const { value } = e.target;
//     if (props.inputType === "number" && props.maxLength && value.length > props.maxLength) {
//       e.target.value = value.slice(0, props.maxLength);
//     }

//     if (typeof props.controlFunc === 'function') {
//       props.controlFunc(e); // Ensure it's a function before calling
//     }
//   };

//   return (
//     <div className={props.classDiv ? props.classDiv : "form-group"}>
//       <label className={props.classLabel ? props.classLabel : mainTable.label}>
//         {props.title}
//         <span className={props.classStar ? props.classStar : "hidden"}>*</span>
//       </label>

//       {props.inputType === "file" ? (
//         <input
//           className={props.Class ? props.Class : mainTable.input}
//           name={props.name}
//           type="file"
//           onChange={props.handleImageUpload}
//           onBlur={props.handleImageUploadBlur}
//           disabled={props.disabled}
//           tabIndex={props.tabIndex}
//         />
//       ) : props.inputType === "image" ? (
//         <img src={props.src} alt={props.alt} className={props.className} />
//       ) : (
//         <div>
//           <input
//             ref={ref}
//             className={props.Class ? props.Class : mainTable.input}
//             name={props.name}
//             type={props.inputType}
//             value={props.content}
//             onChange={
//               props.inputType === "number"
//                 ? handleNumberInputChange
//                 : props.controlFunc
//             }
//             errorMessage={props.errorMessage}
//             onBlur={props.controlFuncBlur}
//             onClick={props.handleImageUpload}
//             placeholder={props.placeholder}
//             disabled={props.disabled}
//             tabIndex={props.tabIndex}
//             maxLength={props.maxLength}
//             min={props.min}
//             max={props.max}
//           />
//           {props.errorMessage && (
//             <span className="text-red-500 text-sm font-medium">
//               {props.errorMessage}
//             </span>
//           )}
//         </div>
//       )}
//     </div>
//   );
// });

// InputText.propTypes = {
//   inputType: PropTypes.oneOf([
//     "text",
//     "number",
//     "password",
//     "email",
//     "file",
//     "time",
//     "date",
//   ]).isRequired,
//   title: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   controlFunc: PropTypes.func.isRequired,
//   controlFuncBlur: PropTypes.func.isRequired,
//   content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   placeholder: PropTypes.string,
//   disabled: PropTypes.oneOf(["true", "false"]).isRequired,
//   tabIndex: PropTypes.string.isRequired,
//   handleImageUpload: PropTypes.func,
//   handleImageUploadBlur: PropTypes.func,
//   src: PropTypes.string,
//   alt: PropTypes.string,
//   className: PropTypes.string,
//   errorMessage: PropTypes.string,
//   maxLength: PropTypes.number,
//   min: PropTypes.number,
//   max: PropTypes.number,
// };

// export default InputText;



import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const InputText = forwardRef((props, ref) => {
  const handleNumberInputChange = (e) => {
    const { value } = e.target;
    if (props.inputType === "number" && props.maxLength && value.length > props.maxLength) {
      e.target.value = value.slice(0, props.maxLength);
    }

    if (typeof props.controlFunc === 'function') {
      props.controlFunc(e);
    }
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    
    if (props.inputType === "date") {
      const inputDate = new Date(value);
      const today = new Date();
      
      // Reset hours to start of day for accurate comparison
      today.setHours(0, 0, 0, 0);
      inputDate.setHours(0, 0, 0, 0);
      
      // Handle max date validation (prevent future dates)
      if (props.max && inputDate > today) {
        // If input date is in the future, reset to today's date
        e.target.value = today.toISOString().split('T')[0];
        if (typeof props.controlFunc === 'function') {
          props.controlFunc({
            ...e,
            target: {
              ...e.target,
              value: today.toISOString().split('T')[0]
            }
          });
        }
        return;
      }
      
      // If date is current or past, allow it
      if (typeof props.controlFunc === 'function') {
        props.controlFunc(e);
      }
    }
  };

  return (
    <div className={props.classDiv ? props.classDiv : "form-group"}>
      <label className={props.classLabel ? props.classLabel : mainTable.label}>
        {props.title}
        <span className={props.classStar ? props.classStar : "hidden"}>*</span>
      </label>

      {props.inputType === "file" ? (
        <input
          className={props.Class ? props.Class : mainTable.input}
          name={props.name}
          type="file"
          onChange={props.handleImageUpload}
          onBlur={props.handleImageUploadBlur}
          disabled={props.disabled}
          tabIndex={props.tabIndex}
        />
      ) : props.inputType === "image" ? (
        <img src={props.src} alt={props.alt} className={props.className} />
      ) : (
        <div>
          <input
            ref={ref}
            className={props.Class ? props.Class : mainTable.input}
            name={props.name}
            type={props.inputType}
            value={props.content}
            onChange={
              props.inputType === "number"
                ? handleNumberInputChange
                : props.inputType === "date"
                ? handleDateChange
                : props.controlFunc
            }
            errorMessage={props.errorMessage}
            onBlur={props.controlFuncBlur}
            onClick={props.handleImageUpload}
            placeholder={props.placeholder}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
            maxLength={props.maxLength}
            min={props.min}
            max={props.max}
          />
          {props.errorMessage && (
            <span className="text-red-500 text-sm font-medium">
              {props.errorMessage}
            </span>
          )}
        </div>
      )}
    </div>
  );
});

InputText.propTypes = {
  inputType: PropTypes.oneOf([
    "text",
    "number",
    "password",
    "email",
    "file",
    "time",
    "date",
  ]).isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOf(["true", "false"]).isRequired,
  tabIndex: PropTypes.string.isRequired,
  handleImageUpload: PropTypes.func,
  handleImageUploadBlur: PropTypes.func,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.string,
  max: PropTypes.string,
};

export default InputText;