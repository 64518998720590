// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import TableGrid from "../components/Table";
import MainPopup from "../popup-containers/MainPopup";
import { brandHeaders } from "../components/HeaderAttribute";
import toast, { ErrorIcon, Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import BrandPopup from "../popup-containers/BrandPopup";
import GridPage from "../components/GridPage";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
const BrandMaster = () => {
  const location = useLocation();
  // All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const navigate = useNavigate();

  //brand Master insert and edit useState hooks handling .

  const [brands, setBrands] = useState([""]);
  const [brandValidationErrors, setBrandValidationErrors] = useState([""]);
  const [commodities, setCommodities] = useState("");
  const [commoditiesCategory, setcommoditiesCategory] = useState("");
  const [name, setName] = useState("");

  //brand master Error handle useStates
  const [brandMasterError, setBrandMasterError] = useState({
    // brandsError: "",
    commoditiesError: "",
    commoditiesCategoryError: "",
    nameError: "",
  });

  const [brandFileds, setBrandFileds] = useState({
    brandCategory: "",
    brandName: "",
  });
  console.log("brandFileds", brandFileds);

  const [mstBrandId, setMstBrandId] = useState("");
  // const [brandError, setBrandError] = useState("");

  // All bind data value useState hooks handling.
  const [bindBrandData, setBindBrandData] = useState([]);
  const [brandPopupData, setBrandPopupData] = useState("");
  const [brandPopupDropDownData, setBrandPopupDropDownData] = useState({});
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);
  const [bindCommodities, setBindCommodities] = useState([]);

  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  //Auto dropdown value handle
  const [commoditiesDD, setCommoditiesDD] = useState([]);
  const [commoditiesCategoryDD, setcommoditiesCategoryDD] = useState([]);
  const [nameDD, setNameDD] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const cancel = async () => {
    callBindBrandData();
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    setCommodities("");
    setBrands([""]);
    setRowIndex("");
    setMstBrandId("");
    setBrandMasterError({
      brandsError: "",
      commoditiesError: "",
      commoditiesCategoryError: "",
      nameError: "",
    });
    setBrandValidationErrors([""]);
    setBrandFileds({
      brandCategory: "",
      brandName: "",
    });
  };

  const callBindBrandData = async () => {
    const response = await BindAPI("BrandAPI/BindBrand");
    setBindBrandData(response.data.Table);
  };
  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const categoryResponse = await BindAPI(
          "CommoditiesAPI/GetCommoditiesCategory"
        );
        const commoditiesResponse = await BindAPI(
          "CommoditiesAPI/BindCommodities"
        );
        const categoryTypeResponse = await BindAPI(
          "CommoditiesAPI/BindCommodityType"
        );

        const filteredData = categoryTypeResponse.data.Table;
        const filteredWithBlockedByNull = filteredData.filter(
          (item) => item.BlockedBy === null
        );

        // const categoryDropDown = categoryResponse.data.Table.map((item) => ({
        //   value: item.MstCommodityCategoryId,
        //   label: item.CommodityCategory_en,
        // }));

        // const commoditiesDropDown = commoditiesResponse.data.Table.map(
        //   (item) => ({
        //     value: item.MstCommoditiesId,
        //     label: item.CommodityName_en,
        //   })
        // );
        const categoryTypeData = filteredWithBlockedByNull.map((item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        }));
        setCommoditiesDD(categoryTypeData);

        const filteredData1 = categoryResponse.data.Table;
        const filteredWithBlockedByNull1 = filteredData1.filter(
          (item) => item.BlockedBy === null
        );

        const filteredData2 = commoditiesResponse.data.Table;
        const filteredWithBlockedByNull2 = filteredData2.filter(
          (item) => item.BlockedBy === null
        );

        setBindCommoditiesCategory(filteredWithBlockedByNull1);
        setBindCommodities(filteredWithBlockedByNull2);
      } catch (error) {}
    };

    callBindBrandData();
    dropDownDatas();
  }, []);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  // const handleBrandDetails = (rowIndex, flag) => {
  //   handleOpen(true);
  //   if (flag === 1) {
  //     setViewDetails(true);
  //     setDeleteDetails(false);
  //     setBlockDetails(false);
  //     setUnBlockDetails(false);
  //   } else if (flag === 3) {
  //     setViewDetails(false);
  //     setDeleteDetails(true);
  //     setBlockDetails(false);
  //     setUnBlockDetails(false);
  //     setRowIndex(rowIndex);
  //   } else if (flag === 4) {
  //     setViewDetails(false);
  //     setDeleteDetails(false);
  //     setBlockDetails(true);
  //     setRowIndex(rowIndex);
  //     setUnBlockDetails(false);
  //   } else if (flag === 5) {
  //     setViewDetails(false);
  //     setDeleteDetails(false);
  //     setBlockDetails(false);
  //     setRowIndex(rowIndex);
  //     setUnBlockDetails(true);
  //   }

  //   setBrandPopupData(bindBrandData[rowIndex]);

  //   //commodityDropDownView start

  //   const commodityDropDownView = commoditiesDD.find(
  //     (x) => x.label == bindBrandData[rowIndex].MstCommodityType
  //   );
  //   console.log("commodityDropDownView", commodityDropDownView);
  //   // const commodityViewDD = {
  //   //   value: commodityDropDownView.value ? commodityDropDownView.value : null,
  //   //   label: commodityDropDownView.label
  //   //     ? commodityDropDownView.label
  //   //     : "Unknown",
  //   // };

  //   const commodityViewDD = commodityDropDownView
  //     ? {
  //         value: commodityDropDownView.value || null,
  //         label: commodityDropDownView.label || "Unknown",
  //       }
  //     : null;

  //   //commodityDropDownView end

  //   //categoryDropDown Start

  //   const categoryDropDownView = commoditiesCategoryDD.find(
  //     (x) => x.value == bindBrandData[rowIndex].MstCommodityCategoryId
  //   );

  //   // const categoryViewDD = {
  //   //   value: bindBrandData[rowIndex].MstCommodityCategoryId
  //   //     ? bindBrandData[rowIndex].MstCommodityCategoryId
  //   //     : null,
  //   //   label: categoryDropDownView.label
  //   //     ? categoryDropDownView.label
  //   //     : "Unknown",
  //   // };

  //   const categoryViewDD = categoryDropDownView
  //     ? {
  //         value: bindBrandData[rowIndex].MstCommodityCategoryId || null,
  //         label: categoryDropDownView.label || "Unknown",
  //       }
  //     : null;

  //   console.log("categoryDropDownView", categoryDropDownView);
  //   //categoryDropDown End

  //   //NameDropDown Start

  //   const nameDropDownView = nameDD.find(
  //     (x) => x.value == bindBrandData[rowIndex].MstCommoditiesId
  //   );
  //   console.log("nameDropDownView:", nameDropDownView);

  //   // const nameViewDD = {
  //   //   value: bindBrandData[rowIndex].MstCommoditiesId
  //   //     ? bindBrandData[rowIndex].MstCommoditiesId
  //   //     : null,
  //   //   label: nameDropDownView.label ? nameDropDownView.label : "Unknown",
  //   // };

  //   const nameViewDD = nameDropDownView
  //     ? {
  //         value: bindBrandData[rowIndex].MstCommoditiesId || null,
  //         label: nameDropDownView.label || "Unknown",
  //       }
  //     : null;

  //   //NameDropDown Start

  //   const sendPopupDropDownDatas = [
  //     commodityViewDD,
  //     categoryViewDD,
  //     nameViewDD,
  //   ];
  //   console.log("sendPopupDropDownDatas", sendPopupDropDownDatas);
  //   setBrandPopupDropDownData(sendPopupDropDownDatas);
  // };

  const handleBrandDetails = (rowIndex, flag) => {
    handleOpen(true);

    setViewDetails(false);
    setDeleteDetails(false);
    setBlockDetails(false);
    setUnBlockDetails(false);

    if (flag === 1) {
      setViewDetails(true);
    } else if (flag === 3) {
      setDeleteDetails(true);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setBlockDetails(true);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }

    setBrandPopupData(bindBrandData[rowIndex]);

    const commodityDropDownView = commoditiesDD.find(
      (x) => x.value == bindBrandData[rowIndex].MstCommodityTypeId
    );
    const commodityViewDD = {
      value: commodityDropDownView.MstCommodityTypeId,
      label: commodityDropDownView ? commodityDropDownView.label : "Unknown",
    };
    setCommodities(bindBrandData[rowIndex].MstCommodityTypeId);
    // const commodityViewDD = commodityDropDownView
    //   ? {
    //       value: commodityDropDownView.value || null,
    //       label: commodityDropDownView.label || "Unknown",
    //     }
    //   : null;

    // const brandData = bindBrandData[rowIndex];

    // const categoryDropDownView = commoditiesCategoryDD.find(
    //   (x) => x.value == brandData.MstCommodityCategoryId
    // );

    // const categoryViewDD = {
    //   value: brandData.MstCommodityCategoryId,
    //   label: categoryDropDownView ? categoryDropDownView.label : "Unknown",
    // };
    const brandData = bindBrandData[rowIndex];
    const blockInfo = bindCommoditiesCategory.find(
      (SD) => SD.MstCommodityCategoryId === brandData.MstCommodityCategoryId
    );
    const categoryViewDD = {
      value: brandData.MstCommodityCategoryId,
      label: blockInfo ? blockInfo.CommodityCategory_en : "Unknown",
    };

    // const nameDropDownView = bindCommodities.find(
    //   (x) => x.value == brandData.MstCommoditiesId
    // );

    // const nameViewDD = {
    //   value: brandData.MstCommoditiesId,
    //   label: nameDropDownView ? nameDropDownView.CommodityName_en : "Unknown",
    // };
    const nameDropDownView = bindBrandData[rowIndex];
    const nameInfo = bindCommodities.find(
      (SD) => SD.MstCommoditiesId === nameDropDownView.MstCommoditiesId
    );
    const nameViewDD = {
      value: nameDropDownView.MstCommodityCategoryId,
      label: nameInfo ? nameInfo.CommodityName_en : "Unknown",
    };
    const sendPopupDropDownDatas = [
      commodityViewDD,
      categoryViewDD,
      nameViewDD,
    ];
    setBrandPopupDropDownData(sendPopupDropDownDatas);
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setMstBrandId(bindBrandData[rowIndex].MstBrandId);
    setCommodities(bindBrandData[rowIndex].MstCommodityTypeId);
    // setcommoditiesCategory(bindBrandData[rowIndex].MstCommodityCategoryId);
    // setName(bindBrandData[rowIndex].MstCommoditiesId);
    setBrands([bindBrandData[rowIndex].Brand_en]);

    setBrandFileds({
      ...brandFileds,
      brandCategory: bindBrandData[rowIndex].MstCommodityCategoryId,
      brandName: bindBrandData[rowIndex].MstCommoditiesId,
    });
  };
  console.log("brandFiledsEdit", brandFileds);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  // const validate = (flag) => {
  //   // Initialize an object to store validation errors for each field
  //   const errors = {};

  //   if (flag === 1 || flag === 2) {
  //     if (commodities == "") {
  //       errors.commodities = "Select commodities";
  //     }

  //     if (commoditiesCategory == "") {
  //       errors.commoditiesCategory = "Select Category";
  //     }

  //     if (name == "") {
  //       errors.name = "Select name";
  //     }
  //     brands.forEach((brandError, index) => {
  //       console.log("brandError index :", brandError, index);
  //       if (index) {
  //         errors.brandError = [`brand${index}Error`];
  //       }
  //     });
  //   }

  //   // setBrandMasterError({
  //   //   commoditiesError: errors.commodities || "",
  //   //   commoditiesCategoryError: errors.commoditiesCategory || "",
  //   //   nameError: errors.name || "",
  //   //   brandError: errors.brand0Error ? errors.brandError : [""],
  //   // });

  //   const brandMasterErrorObject = {
  //     commoditiesError: errors.commodities || "",
  //     commoditiesCategoryError: errors.commoditiesCategory || "",
  //     nameError: errors.name || "",
  //     brandError: errors.brandError ? errors.brandError : [""], // Update brandError property
  //   };
  //   console.log("brandMasterErrorObject", brandMasterErrorObject);

  //   setBrandMasterError(brandMasterErrorObject);

  //   return errors;
  // };

  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      if (commodities === "") {
        errors.commodities = "Select commodities";
      }

      if (brandFileds.brandCategory === "") {
        errors.commoditiesCategory = "Select Category";
      }

      if (brandFileds.brandName === "") {
        errors.name = "Select name";
      }

      const updatedValidationErrors = [];

      brands.forEach((brand, index) => {
        updatedValidationErrors[index] = isBrandValid(brand)
          ? ""
          : "Enter brand";
      });

      // setBrandValidationErrors(updatedValidationErrors);
      // errors.updatedValidationErrors = updatedValidationErrors;

      // if (brands.some(brand => brand.trim() !== "")) {
      //   setBrandValidationErrors([]);
      //   errors.updatedValidationErrors = [];
      // } else {
      //   setBrandValidationErrors(updatedValidationErrors);
      //   errors.updatedValidationErrors = updatedValidationErrors;
      // }

      if (brands.some((brand) => brand.trim() !== "")) {
        // Clear the errors if brands have data
        setBrandValidationErrors([]);
        delete errors.updatedValidationErrors;
      } else {
        setBrandValidationErrors(updatedValidationErrors);
        errors.updatedValidationErrors = updatedValidationErrors;
      }
    }

    // Create the setBrandMasterError object
    const brandMasterErrorObject = {
      commoditiesError: errors.commodities || "",
      commoditiesCategoryError: errors.commoditiesCategory || "",
      nameError: errors.name || "",
    };

    console.log("brandMasterErrorObject", brandMasterErrorObject);

    setBrandMasterError(brandMasterErrorObject);
    //setBrandValidationErrors([]);
    console.log("Errors", errors);
    return errors;
  };

  const createdBy = sessionStorage.getItem("createdBy");

  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    const validation = validate(flag);
    if (Object.keys(validation).length !== 0) {
      setIsOpen(true);
    }
    if (Object.keys(validation).length == 0) {
      setIsOpen(false);
      if (flag == 1 || flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedTextsArray = [];
        const translatedTextsArray2 = [];
        console.log("translatedTextsArray2", translatedTextsArray2);
        console.log("brands", brands);
        const validBrands = brands.filter(
          (brand) => brand && brand.trim() !== ""
        );
        let i = 0;
        for (let brand of brands) {
          if (!brand || brand.trim() === "") {
            //console.warn("Skipping empty or null brand");
            continue;
          }
          i = i + 1;

          console.log("brand forloop", brand, brands.length);
          for (let language of languageKeys) {
            const translateText = await LanguageTranslatorAPI(brand, language);
            translatedTextsArray2.push(
              translateText.data.data.translations[0].translatedText
            );
          }
          if (flag == 1) {
            const mstBrandPayload = [
              {
                mstBrandId: mstBrandId ? mstBrandId : 0,
                mstCommodityTypeId: commodities,
                mstCommodityCategoryId: brandFileds.brandCategory,
                mstCommoditiesId: brandFileds.brandName,
                brand_en: brand,
                brand_ta: translatedTextsArray2[0],
                brand_hi: translatedTextsArray2[1],
                brand_mr: translatedTextsArray2[2],
                brand_gu: translatedTextsArray2[3],
                brand_kn: translatedTextsArray2[4],
                brand_ml: translatedTextsArray2[5],
                brand_te: translatedTextsArray2[6],
                brand_bn: translatedTextsArray2[7],
                brand_or: translatedTextsArray2[8],
                createdBy: createdBy,
                flag: flag,
              },
            ];

            console.log("mstBrandPayload", mstBrandPayload);
            const response = await IUDAPI(mstBrandPayload, "BrandAPI/IUDBrand");
            if (i == validBrands.length) {
              if (response.data.Table[0].Result === "S") {
                toast.success("brand added successfully 👍", {
                  duration: 3000,
                  position: "top-center",
                });
                setTimeout(cancel, 3000);
              } else {
                toast.error("Failed to add brand data 👎", {
                  duration: 2000,
                  position: "top-center",
                });
                // setTimeout(cancel, 2000);
              }
            }
          } else if (flag == 2) {
            const mstBrandPayload = [
              {
                mstBrandId: mstBrandId ? mstBrandId : 0,
                mstCommodityTypeId: commodities,
                mstCommodityCategoryId: brandFileds.brandCategory,
                mstCommoditiesId: brandFileds.brandName,
                brand_en: brand,
                brand_ta: translatedTextsArray2[0],
                brand_hi: translatedTextsArray2[1],
                brand_mr: translatedTextsArray2[2],
                brand_gu: translatedTextsArray2[3],
                brand_kn: translatedTextsArray2[4],
                brand_ml: translatedTextsArray2[5],
                brand_te: translatedTextsArray2[6],
                brand_bn: translatedTextsArray2[7],
                brand_or: translatedTextsArray2[8],
                createdBy: createdBy,
                flag: flag,
              },
            ];
            console.log("mstBrandPayloadUpdate", mstBrandPayload);
            const response = await IUDAPI(mstBrandPayload, "BrandAPI/IUDBrand");
            console.log("resposeBrand", response);
            if (i == validBrands.length) {
              if (response.data.Table[0].Result === "M") {
                toast.success("brand updated successfully 👍", {
                  duration: 3000,
                  position: "top-center",
                });
                setTimeout(cancel, 3000);
              } else {
                toast.error("Failed to update brand data 👎", {
                  duration: 2000,
                  position: "top-center",
                });
                setTimeout(cancel, 2000);
              }
            }
          }
          translatedTextsArray2.length = 0;
        }
      } else {
        const data = {
          mstBrandId: bindBrandData[items].MstBrandId,
          createdBy: createdBy,
          flag: flag,
        };

        const response = await IUDAPI([data], "BrandAPI/IUDBrand");

        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success("brand data deleted successfully 🗑️", 5000, cancel());
            cancel();
          } else {
            toast.error("Failed to delete brand data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success("brand data blocked successfully 🚫", 5000, cancel());
            cancel();
          } else {
            toast.error("Failed to block brand data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success(
              "brand data unblocked successfully ✅",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to unblock brand data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const addBrandInput = () => {
    setBrands([...brands, ""]);
  };

  const removeBrandInput = (index) => {
    if (brands.length > 1) {
      // Ensure at least one brand remains
      const newBrands = [...brands];
      newBrands.splice(index, 1);
      setBrands(newBrands);
    }
  };

  // const removeBrandInput = (index) => {
  //   const newBrands = [...brands];
  //   newBrands.splice(index, 1);
  //   setBrands(newBrands);
  // };

  const handleBrandChange = (index, value) => {
    const newBrands = [...brands];
    newBrands[index] = value;
    setBrands(newBrands);
  };

  const handleCategoryDropDown = (value) => {
    setBrandFileds({
      ...brandFileds,
      brandCategory: "",
      brandName: "",
    });
    setCommodities(value.value);
  };

  useEffect(() => {
    if (commodities) {
      const getFilteredCategory = bindCommoditiesCategory
        .filter((item) => item.MstCommodityTypeId == commodities)
        .map((items) => ({
          value: items.MstCommodityCategoryId,
          label: items.CommodityCategory_en,
        }));

      setcommoditiesCategoryDD(getFilteredCategory);
    } else {
      setcommoditiesCategoryDD([]);
      console.log("categoryDD");
    }

    if (brandFileds.brandCategory) {
      const selectedCommoditiesCategory = bindCommodities
        .filter(
          (item) => item.MstCommodityCategoryId == brandFileds.brandCategory
        )
        .map((items) => ({
          value: items.MstCommoditiesId,
          label: items.CommodityName_en,
        }));

      setNameDD(selectedCommoditiesCategory);
    } else {
      setNameDD([]);
      console.log("nameDD");
    }
  }, [
    commodities ? commodities : null,
    brandFileds.brandCategory ? brandFileds.brandCategory : null,
  ]);

  const isBrandValid = (brand) => {
    // You can add your validation logic here
    return brand.length > 0; // Example: Check if the brand is not empty
  };
  const handleBrandBlur = (index) => {
    const updatedValidationErrors = [...brandValidationErrors];
    updatedValidationErrors[index] = isBrandValid(brands[index])
      ? ""
      : "Enter brand";
    setBrandValidationErrors(updatedValidationErrors);
    console.log("brandValidation", brandValidationErrors);
  };

  const handleBrandFieldsChange = (value, name) => {
    //
    console.log("Values:", value, name);
    setBrandFileds({
      ...brandFileds,
      [name]: value,
    });
  };
  useEffect(() => {
    if (location.pathname == "/brandmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // callBindBrandData();
      cancel();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={`Brand`}
          header={brandHeaders}
          records={bindBrandData}
          handleEdit={handleEdit}
          handleAction={handleBrandDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full">
          <div class=" border-black  ">
            <div class="text-black text-2xl font-bold my-2">
              {addScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Brand Master - Add"}
                </div>
              )}

              {updateScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Brand Master - Edit"}
                </div>
              )}
            </div>
          </div>

          <div class="mx-auto flex  border-black">
            <div class="relative w-full h-full  md:h-auto">
              <div class="relative bg-white rounded-lg  ">
                <div class="px-6 py-6 lg:px-8">
                  <form className={mainTable.grid3}>
                    <AutoDropDown
                      Class="Custom-class"
                      title="Commodities"
                      options={commoditiesDD}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="commodities"
                      selectedOption={commoditiesDD.find(
                        (x) => x.value == commodities
                      )}
                      controlFunc={handleCategoryDropDown}
                      controlFuncBlur={() => {
                        setBrandMasterError(() => ({
                          ...brandMasterError,
                          commoditiesError: commodities
                            ? ""
                            : "Select commodities",
                        }));
                      }}
                      errorMessage={brandMasterError.commoditiesError}
                    />

                    <AutoDropDown
                      Class="Custom-class"
                      title="Category"
                      options={commoditiesCategoryDD}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="brandCategory"
                      selectedOption={
                        brandFileds.brandCategory
                          ? commoditiesCategoryDD.find(
                              (x) => x.value == brandFileds.brandCategory
                            )
                          : ""
                      }
                      //controlFunc={(value) => setcommoditiesCategory(value)}
                      controlFunc={(value, e) => {
                        handleBrandFieldsChange(value.value, e.name);
                        setBrandFileds({
                          ...brandFileds,
                          brandCategory: value.value,
                          brandName: "",
                        });
                      }}
                      controlFuncBlur={() => {
                        setBrandMasterError(() => ({
                          ...brandMasterError,
                          commoditiesCategoryError: brandFileds.brandCategory
                            ? ""
                            : "Select category",
                        }));
                      }}
                      errorMessage={brandMasterError.commoditiesCategoryError}
                    />

                    <AutoDropDown
                      Class="Custom-class"
                      title="Name"
                      options={nameDD}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="brandName"
                      selectedOption={
                        brandFileds.brandName
                          ? nameDD.find((x) => x.value == brandFileds.brandName)
                          : ""
                      }
                      //controlFunc={(value) => setName(value)}
                      controlFunc={(value, e) => {
                        handleBrandFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setBrandMasterError(() => ({
                          ...brandMasterError,
                          nameError: brandFileds.brandName ? "" : "Select name",
                        }));
                      }}
                      errorMessage={brandMasterError.nameError}
                    />
                    {/* <div className={mainTable.grid3}> */}
                    {brands.map((brand, index) => (
                      <div key={index} className="flex items-center">
                        <InputText
                          inputType={"text"}
                          title={`Brand ${index + 1}`}
                          name={`brand${index}`}
                          content={brand}
                          maxLength={200}
                          classDiv={"w-full"}
                          classStar="text-red-500"
                          controlFunc={(e) =>
                            handleBrandChange(index, e.target.value)
                          }
                          // controlFuncBlur={() => {
                          //   setBrandMasterError(() => ({
                          //     ...brandMasterError,
                          //     brandsError: brand.length==0 ? [""] : "Enter brand",
                          //   }));
                          // }}
                          controlFuncBlur={() => handleBrandBlur(index)}
                          errorMessage={
                            brandValidationErrors &&
                            brandValidationErrors[index]
                          }
                        />
                        {addScreenTitle && (
                          <div className="ml-2 flex flex-col align-baseline gap-1 mt-3">
                            <svg
                              viewBox="0 0 1024 1024"
                              fill="currentColor"
                              onClick={addBrandInput}
                              className="w-5 h-5 text-black cursor-pointer"
                              role="button"
                              aria-label="Add Brand"
                            >
                              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                            </svg>
                            <svg
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5 text-black cursor-pointer"
                              role="button"
                              aria-label="Remove Brand"
                              onClick={() => removeBrandInput(index)}
                            >
                              <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                            </svg>
                          </div>
                        )}
                      </div>
                    ))}
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    disabledBtn={submitBtnDisabled ? true : false}
                    cancel={cancel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <PopUpScreenTitle
            title="Brand"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <BrandPopup data={brandPopupData} data2={brandPopupDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          disabledBtn={submitBtnDisabled ? true : false}
          cancel={cancel}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default BrandMaster;
