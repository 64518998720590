import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import { DropoutsReportHeader } from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import ReportRowRowPage from "../components/ReportGridRowPage";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ReportGridPage from "../components/ReportGridPage";

const DropOutsReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindDropoutData, setBindDropoutData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(
    () => {
      if (branchProject) {
        try {
          const getSelectedSDP = async () => {
            const locationResponse = await BindAPI("SDPAPI/GetSDP");
            setGetSDPDatas(locationResponse.data.Table);

            if (userProfileDatas && userProfileDatas[0]) {
              const sdpLabels = locationResponse.data.Table.filter((item) =>
                branchProject.includes(parseInt(item.MstLocationId))
              ).map((item) => ({
                MstSDPId: item.MstSDPId,
                SDP_en: item.SDP_en,
              }));

              const sdpSelected = sdpLabels.map((SD) => ({
                value: SD.MstSDPId,
                label: SD.SDP_en,
              }));

              if (
                userProfileDatas[0].MstOrganizationId == 1 ||
                userProfileDatas[0].MstOrganizationId == 2
              ) {
                setBindSDP(sdpSelected);
              } else {
                const allSDPIds = userProfileDatas[0]?.AllSDP;
                const sdpId = userProfileDatas[0]?.MstSDPId;

                if (allSDPIds && Array.isArray(allSDPIds)) {
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    allSDPIds.includes(sdp.value)
                  );
                  setBindSDP(filteredSdpSelected);
                }
                if (sdpId) {
                  console.warn("allSDPIds is undefined or not an array");
                  setBindSDP([]);
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    Array.isArray(sdpId)
                      ? sdpId.includes(sdp.value)
                      : sdpId === sdp.value
                  );
                  setBindSDP(filteredSdpSelected);
                }
              }
            }
          };

          getSelectedSDP();
        } catch (e) {
          console.log(`error fetch data ${e}`);
        }
      }
    },
    [branchProject ? branchProject : undefined],
    userProfileDatas ? userProfileDatas : undefined
  );
  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);

    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "NotificationAPI/ReportDropOuts"
      );

      console.log("response", response.data.Table);
      setBindDropoutData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);
  const handleExport = () => {

    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };

    exportData(
      DropoutsReportHeader.map((column) => column.title),
      bindDropoutData,
      dateRange,
      selectedOptionsData
    );
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "Drop outs",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    const formatDate = (dateStr) => {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      return `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`;
    };

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow1 = [
      {
        v: "S.No.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Branch",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "SDP",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Area",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Contraception Methods",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Reasons for Drop Outs",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Switched Method",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Total",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Row Total",
        s: {
          fill: { fgColor: { rgb: "3A3A3A" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];

    const headerRow2 = [
      { v: "", s: {} }, // Empty cell for alignment
      { v: "", s: {} }, // Empty cell for alignment
      { v: "", s: {} }, // Empty cell for alignment
      { v: "", s: {} }, // Empty cell for alignment
      { v: "", s: {} }, // Empty cell for alignment
      {
        v: "Complication / Side-effects",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Failure (Method /User)/ Confirmed Pregnancy",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Personal Reasons-Fears, Difficult to Use, Objection",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Supplies related Reason-Difficult to Get Another Source",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Planned Pregnancy / Want child",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Lost to Follow-up",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Sterilisation (M+F)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "IUD",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "LNG-IUS",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Implants",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Oral Pill",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Condom (M+F)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Injectables",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
    ];

    const columnTotals = {
      RemarksDiscontinued1: 0,
      RemarksDiscontinued2: 0,
      RemarksDiscontinued3: 0,
      RemarksDiscontinued4: 0,
      RemarksDiscontinued5: 0,
      RemarksDiscontinued6: 0,
      RemarksDiscontinued7: 0,
      RemarksDiscontinued8: 0,
      RemarksDiscontinued9: 0,
      RemarksDiscontinued10: 0,
      RemarksDiscontinued11: 0,
      RemarksDiscontinued12: 0,
      RemarksDiscontinued13: 0,
      Total: 0,
    };

    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.RemarksDiscontinued1 || 0) +
        Number(rowData.RemarksDiscontinued2 || 0) +
        Number(rowData.RemarksDiscontinued3 || 0) +
        Number(rowData.RemarksDiscontinued4 || 0) +
        Number(rowData.RemarksDiscontinued5 || 0) +
        Number(rowData.RemarksDiscontinued6 || 0) +
        Number(rowData.RemarksDiscontinued7 || 0) +
        Number(rowData.RemarksDiscontinued8 || 0) +
        Number(rowData.RemarksDiscontinued9 || 0) +
        Number(rowData.RemarksDiscontinued10 || 0) +
        Number(rowData.RemarksDiscontinued11 || 0) +
        Number(rowData.RemarksDiscontinued12 || 0) +
        Number(rowData.RemarksDiscontinued13 || 0) +
        Number(rowData.Total || 0);

      // Add to column totals
      columnTotals.RemarksDiscontinued1 += Number(
        rowData.RemarksDiscontinued1 || 0
      );
      columnTotals.RemarksDiscontinued2 += Number(
        rowData.RemarksDiscontinued2 || 0
      );
      columnTotals.RemarksDiscontinued3 += Number(
        rowData.RemarksDiscontinued3 || 0
      );
      columnTotals.RemarksDiscontinued4 += Number(
        rowData.RemarksDiscontinued4 || 0
      );
      columnTotals.RemarksDiscontinued5 += Number(
        rowData.RemarksDiscontinued5 || 0
      );
      columnTotals.RemarksDiscontinued6 += Number(
        rowData.RemarksDiscontinued6 || 0
      );
      columnTotals.RemarksDiscontinued7 += Number(
        rowData.RemarksDiscontinued7 || 0
      );
      columnTotals.RemarksDiscontinued8 += Number(
        rowData.RemarksDiscontinued8 || 0
      );
      columnTotals.RemarksDiscontinued9 += Number(
        rowData.RemarksDiscontinued9 || 0
      );
      columnTotals.RemarksDiscontinued10 += Number(
        rowData.RemarksDiscontinued10 || 0
      );
      columnTotals.RemarksDiscontinued11 += Number(
        rowData.RemarksDiscontinued11 || 0
      );
      columnTotals.RemarksDiscontinued12 += Number(
        rowData.RemarksDiscontinued12 || 0
      );
      columnTotals.RemarksDiscontinued13 += Number(
        rowData.RemarksDiscontinued13 || 0
      );
      columnTotals.Total += Number(rowData.Total || 0);

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Method,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: Number(rowData.RemarksDiscontinued1 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued2 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued3 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued4 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued5 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued6 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        {
          v: Number(rowData.RemarksDiscontinued7 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        {
          v: Number(rowData.RemarksDiscontinued8 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued9 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued10 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued11 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued12 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.RemarksDiscontinued13 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Total || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      {
        v: "Totals",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued1, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued2, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued3, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued4, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued5, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued6, t: "n", s: totalsRowStyle },

      { v: columnTotals.RemarksDiscontinued7, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued8, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued9, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued10, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued11, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued12, t: "n", s: totalsRowStyle },
      { v: columnTotals.RemarksDiscontinued13, t: "n", s: totalsRowStyle },

      { v: columnTotals.Total, t: "n", s: totalsRowStyle },

      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    // Combine all rows
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow1,
      headerRow2,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // Add merge cells configuration

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
      { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
      { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
      { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
      { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },

      { s: { r: 4, c: 5 }, e: { r: 4, c: 10 } },
      { s: { r: 4, c: 11 }, e: { r: 4, c: 17 } },
      { s: { r: 4, c: 18 }, e: { r: 5, c: 18 } },
      { s: { r: 4, c: 19 }, e: { r: 5, c: 19 } },
    ];

    // Define consistent column widths for all columns (adjust as needed)
    ws["!cols"] = [
      { width: 5 }, // Index
      { width: 38 }, // Dataname
      ...columns.slice(2).map(() => ({ width: 30 })), // Data columns
      { width: 15 }, // Total column
    ];

    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header rows
    // [headerRow1, headerRow2].forEach((headerRow, headerIndex) => {
    //   headerRow.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: headerIndex + 1,
    //       c: colIndex,
    //     });
    //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
    //   });
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 3,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 3,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "Drop_outs.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    }); // Use A3 format
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure DropoutsReportHeader is an array of objects with a title property
    //  const headers = DropoutsReportHeader.map((column) => column.title);
    const headers = [
      { content: "S.No.", rowSpan: 2 },
      { content: "Branch", rowSpan: 2 },
      { content: "Contraception Methods", rowSpan: 2 },
      { content: "Reasons for Drop Outs", colSpan: 6 },
      { content: "Switched Method", colSpan: 7 },
      { content: "Total", rowSpan: 2 },
    ];
    const subHeaders = [
      "Complication / Side-effects",
      "Failure (Method /User)/ Confirmed Pregnancy",
      "Personal Reasons-Fears, Difficult to Use, Objection",
      "Supplies related Reason-Difficult to Get Another Source",
      "Planned Pregnancy / Want child",
      "Lost to Follow-up",
      "Sterilisation (M+F)",
      "IUD",
      "LNG-IUS",
      "Implants",
      "Oral Pill",
      "Condom (M+F)",
      "Injectables",
      "Total",
    ];
    // Map bindDropoutData to the appropriate format for autoTable
    const data = bindDropoutData.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.Method,
        rowData.RemarksDiscontinued1,
        rowData.RemarksDiscontinued2,
        rowData.RemarksDiscontinued3,
        rowData.RemarksDiscontinued4,
        rowData.RemarksDiscontinued5,
        rowData.RemarksDiscontinued6,
        rowData.RemarksDiscontinued7,
        rowData.RemarksDiscontinued8,
        rowData.RemarksDiscontinued9,
        rowData.RemarksDiscontinued10,
        rowData.RemarksDiscontinued11,
        rowData.RemarksDiscontinued12,
        rowData.RemarksDiscontinued13,
        rowData.Total,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers, subHeaders],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Age
        7: { cellWidth: "auto" }, // Gender
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
        11: { cellWidth: "auto" }, // Auto adjust width
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("Drop_outs.pdf");
  };

  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setShowTable(false);
    setSDP(updatedOptions);
    setBindAreaDD([]);

    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
  };
  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };
  const brangeChange = (updatedOptions) => {
    setSDP("");
    setShowTable(false);
    setArea("");
    setBindSDP([]);
    setBindAreaDD([]);
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);

    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    setArea("");
    setShowTable(false);
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };
  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };
  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Drop outs`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="schoolName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>

              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => {
                  setShowTable(false);
                  setFromDate(e.target.value);
                }}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => {
                  setShowTable(false);
                  setToDate(e.target.value);
                }}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindDropoutData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={DropoutsReportHeader}
                records={bindDropoutData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
                // isGenderBased={true}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default DropOutsReport;
