import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import {
  indentDetailsHeader,
  inventoryDirectIssueHeaders,
  inventoryIssueDetailsHeaders,
  inventoryIssueHeaders,
  issueDetailsEditHeader,
  issueDetailsHeader,
  issueIndentDetailsEditHeader,
  issueIndentDetailsHeader,
  pendingIndentHeader,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import InventoryIssuePopup from "../popup-containers/InventoryIssuePopup";
import moment from "moment/moment";

import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import toast, { Toaster } from "react-hot-toast";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import StudentTableGrid from "../components/StudentTable";
import TableGrid from "../components/Table";
import RadioGroup from "../ReusableComponents/RadioButton";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import UsePrevious from "../Hook/UsePrevious";
import { useUserCapability } from "../components/ConstantReducerProvider";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function InventoryIssue() {
  const { userProfileState } = useUserCapability();
  const [sdp, setSdp] = useState([]);

  const navigate = useNavigate();
  const userProfileDatas = userProfileState.userProfile;
  const [issueFields, setIssueFields] = useState({
    txnInventoryIssueId: "",
    indentId: "",
    indentNo: "",
    indentDate: "",
    issueNo: "INISS",
    issueDate: new Date(),
    issueTo_en: "",
    issueTo: "",
    receiver: "",
    areaName: "",
    issueArea: "",
    mstOrwId: "",
    directIssueArea: "",
    receiverId: 0,
    areaNameId: 0,
    remarks_en: "",
    remarks_ta: "",
    remarks_te: "",
    remarks_or: "",
    remarks_mr: "",
    remarks_ml: "",
    remarks_kn: "",
    remarks_hi: "",
    remarks_gu: "",
    remarks_bn: "",
    branch: "",
    branchId: "",
    sdp: "",
    sdpId: "",
    issuedThrough: "",
  });
  const [issueFieldsError, setIssueFieldsError] = useState({
    issueNoError: "",
    issueToError: "",
    receiverError: "",
    areaNameError: "",
    issueAreaError: "",
    issuedThroughError: "",
  });

  const [bindReceivedFromDD, setBindReceivedFromDD] = useState([
    { value: "1", label: "CBP" },
    { value: "2", label: "Associated clinics" },
  ]);
  const [bindFieldWorkerName, setBindFieldWorkerName] = useState([]);
  const [bindAreaDropDown, setBindAreaDropDown] = useState([]);
  const [bindAreaDD1, setBindAreaDD1] = useState([]);
  const [bindAreaDatas, setBindAreaDatas] = useState([]);
  const handleFromDropdownChange = (e) => {
    setIssueFields({
      ...issueFields,
      issueTo: e.target.value,
    });
    if (e.target.value.length === 0) {
      setIssueFieldsError({
        ...issueFieldsError,
        issueToError: "Select issued to",
      });      
    } else {
      setIssueFieldsError({
        ...issueFieldsError,
        issueToError: "",
      }); 
    }
  };
  const handleSdpDropdownChange = (e) => {
    setIssueFields({
      ...issueFields,
      sdpId: e.target.value,
    });
  };
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [addView, setAddView] = useState(false);
  const [indentError, setIndentError] = useState("");
  const [issueError, setIssueError] = useState("");
  const [visibleIssueDetailsTable, setVisibleIssueDetailsTable] =
    useState(false);
  const [visibleIssueTable, setVisibleIssueTable] = useState(false);
  const [visibleSdp, setVisibleSdp] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [visiblePendingIndent, setVisiblePendingIndent] = React.useState(true);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [commoditiesDD, setCommoditiesDD] = useState([]);
  const [bindCommodityDD, setBindCommodityDD] = useState([]);
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);
  const [bindCommodities, setBindCommodities] = useState([]);
  const [bindCommodityNameDD, setBindCommodityNameDD] = useState([]);
  const [selectedRowTableData, setSelectedRowTableData] = useState([]);
  const [pendingIndentDatas, setPendingIndentDatas] = useState([]);
  const [pendingIndentTableDatas, setPendingIndentTableDatas] = useState([]);
  const [bindIssueDetailsDatas, setbindIssueDetailsDatas] = useState([]);
  const [issueDetailsData, setIssueDetailsData] = useState([]);
  const [indentDetailsData, setIndentDetailsData] = useState([]);
  const [issueDetailsDataEdit, setIssueDetailsDataEdit] = useState([]);
  const [issueDetailsDataEdit1, setIssueDetailsDataEdit1] = useState([]);
  const [getBrandDatas, setGetBrandDatas] = useState([]);
  const [edit, setEdit] = useState(false);
  useEffect(() => {

    const fetchData = async () => {
      if (checkBoxStatus) {
        if (selectedRowData.length > 0) {
          try {
            // Map over selectedRowData to create issue details
            const issueDetails = await Promise.all(
              selectedRowData.map((issue, index) => {
                const brandOptions = getBrandDatas
                  .filter(
                    (item) =>
                      item.MstCommodityCategoryId ===
                      issue.MstCommodityCategoryId
                  )
                  .map((items) => ({
                    value: items.MstBrandId,
                    label: items.Brand_en,
                  }));

                return {
                  key: `${index}`,
                  MstCommodityCategoryId: `${issue.MstCommodityCategoryId}`,
                  MstCommoditiesId: `${issue.MstCommoditiesId}`,
                  MstCommodityTypeId: `${issue.MstCommodityTypeId}`,
                  IssuedBefore: `${issue.IssuedBefore}`,
                  MstIndentChildId: `${issue.MstIndentChildId}`,
                  IndentQuantity: `${issue.IndentQuantity}`,
                  IndentPendingQuantity: `${issue.IndentPendingQuantity}`,
                  CommodityCategory_en: `${issue.CommodityCategory_en}`,
                  CommodityName_en: `${issue.CommodityName_en}`,
                  CommodityCategoryType: `${issue.CommodityCategoryType}`,
                  MstIndentChildId: `${issue.MstIndentChildId}`,
                  brandOptions: brandOptions,
                  IssueBrandId: "",
                  IssueNow: "",
                  BalanceStock: "",
                  AvailableStock: "",
                  BatchNo: "",
                  DateOfExpiry: "",
                  MstOrganizationStructureId:
                    userProfileDatas[0]?.MstOrganizationId,
                  MstUserId: userProfileDatas[0]?.MstUserId,
                  MstRoleId: userProfileDatas[0]?.MstRoleId,
                  MstBranchProjectId: userProfileDatas[0]?.MstLocationId,
                  MstSDPId: issueFields.sdpId,
                  MstAreaId: issueFields.issueArea ? issueFields.issueArea : 0,
                  MstORWId: issueFields.mstOrwId ? issueFields.mstOrwId : 0,
                  Flag:1,
                };
              })
            );

            // Update the issueDetailsData state
            setIssueDetailsData((prevData) => [...prevData, ...issueDetails]);
          } catch (error) {
            console.error("Error fetching issue details:", error);
          }
        } else {
          setIssueDetailsData([]);
        }
      } else {
        // const filteredTable = issueDetailsData.filter(
        //   (x) =>
        //     parseInt(x.MstCommoditiesId, 10) !==
        //     parseInt(selectedRowData[0]?.MstCommoditiesId || 0, 10)
        // );
        // if (!filteredTable.length) {
        //   setIssueDetailsData([]);
        //   setIssueDetailsDataEdit1([]);
        // } else {
        //   setIssueDetailsData(filteredTable);
        //   setVisibleIssueTable(true);
        // }
      }
    };
    if (!edit) {
      fetchData();
    }
  }, [selectedRowData, checkBoxStatus]);

  const goFetchData = async () => {

    if (selectedRowData.length > 0) {
      setVisibleIssueTable(true);
      try {
        // Map over selectedRowData to create issue details
        const issueDetails = await Promise.all(
          selectedRowData.map((issue, index) => {
            const brandOptions = getBrandDatas
              .filter(
                (item) =>
                  item.MstCommodityCategoryId == issue.MstCommodityCategoryId
              )
              .map((items) => ({
                value: items.MstBrandId,
                label: items.Brand_en,
              }));
            const filteredItems = issue.issueNameList.filter(
              (item) => item.value == issue.MstCommoditiesId
            );
            const filteredCategory = issue.issueCategoryList.filter(
              (item) => item.value == issue.MstCommodityCategoryId
            );
            return {
              key: `${index}`,
              MstCommodityCategoryId: `${issue.MstCommodityCategoryId}`,
              MstCommoditiesId: `${issue.MstCommoditiesId}`,
              MstCommodityTypeId: `${issue.issueCommodities}`,
              CommodityCategory_en: `${filteredCategory[0].label}`,
              CommodityName_en: `${filteredItems[0].label}`,
              CommodityCategoryType: `${issue.issueCommodities}`,
              MstIndentChildId: `${0}`,
              brandOptions: brandOptions,
              IssueBrandId: "",
              IssueNow: "",
              BalanceStock: "",
              AvailableStock: "",
              BatchNo: "",
              DateOfExpiry: "",
              MstOrganizationStructureId:
                userProfileDatas[0]?.MstOrganizationId,
              MstUserId: userProfileDatas[0]?.MstUserId,
              MstRoleId: userProfileDatas[0]?.MstRoleId,
              MstBranchProjectId: userProfileDatas[0]?.MstLocationId,
              MstSDPId: issueFields.sdpId,
              MstAreaId: issueFields.issueArea ? issueFields.issueArea : 0,
              MstORWId: issueFields.mstOrwId ? issueFields.mstOrwId : 0,
              Flag:1,
            };
          })
        );

        // Update the issueDetailsData state
        setIssueDetailsData((prevData) => [...prevData, ...issueDetails]);
      } catch (error) {
        console.error("Error fetching issue details:", error);
      }
    } else {
      setIssueDetailsData([]);
    }
  };

  // useEffect(() => {
  //   // debugger;
  //   if (issueDetailsData.length > 0) {
  //     const txnReceiptChildIdChildIds = issueDetailsDataEdit.map(
  //       (data) => data.TxnReceiptChildId
  //     );
  //     const filterIssueId = issueDetailsData.filter((x) =>
  //       txnReceiptChildIdChildIds.includes(x.TxnReceiptChildId)
  //     );

  //     // Create a map for easy lookup of TxnReceiptChildId and corresponding data in issueDetailsDataEdit
  //     const editDataMap = new Map();
  //     filterIssueId.forEach((data) => {
  //       editDataMap.set(data.TxnReceiptChildId, data);
  //     });

  //     // Update Balance and Quantity in filterIssueId if there's a match in issueDetailsDataEdit
  //     const updatedFilterIssueId = filterIssueId.map((item) => {
  //       const correspondingEditItem = editDataMap.get(item.TxnReceiptChildId);
  //       if (
  //         correspondingEditItem &&
  //         correspondingEditItem.Balance !== item.Balance
  //       ) {
  //         // Update Balance and Quantity if Balance does not match
  //         return {
  //           ...item,
  //           Balance: correspondingEditItem.Balance,
  //           Quantity: correspondingEditItem.IssueQuantity,
  //         };
  //       }
  //       return item; // Return original item if no update is needed
  //     });
  //     let issueEdit = issueDetailsData;
  //     setIssueDetailsDataEdit(updatedFilterIssueId);
  //     for (let i = 0; i < issueDetailsData.length; i++) {
  //       const txnReceiptChildId = issueDetailsData[i].TxnReceiptChildId;
  //       // Find corresponding data in filterIssueId
  //       const correspondingData = issueDetailsDataEdit.find(
  //         (data) => data.TxnReceiptChildId === txnReceiptChildId
  //       );
  //       if (correspondingData) {
  //         // Update issueDetailsData with correspondingData
  //         issueEdit[i].CommodityType = correspondingData.IndentNo;
  //         issueEdit[i].CommodityType = correspondingData.CommodityType_en;
  //         issueEdit[i].CommodityCategoryName =
  //           issueEdit.CommodityCategoryName_en;
  //         issueEdit[i].CommodityName_en = correspondingData.CommodityName_en;
  //         issueEdit[i].Quantity = correspondingData.IssuesQuantity;
  //         issueEdit[i].IssueQuantity = correspondingData.IssuedNow;
  //         issueEdit[i].Balance = correspondingData.Balance;
  //       }
  //     }
  //     setIssueDetailsData(issueEdit);
  //     setIssueDetailsDataEdit1(issueEdit);
  //     setEdit(false);
  //   }
  // }, [edit, issueDetailsData]);
  const [bindInventoryIssueDatas, setBindInventoryIssueDatas] = useState([]);
  const [bindInventoryIssueChildDatas, setBindInventoryIssueChildDatas] =
    useState([]);
  const [bindInventoryDirectIssueDatas, setBindInventoryDirectIssueDatas] =
    useState([]);

  const handleIssueDetailslView = async (rowIndex) => {
    setIssueFields([]);
    const data = {
      TxnInventoryIssueId:
        bindInventoryIssueDatas[rowIndex].TxnInventoryIssueId,
      TxnIndentId: bindInventoryIssueDatas[rowIndex].IndentId,
    };
    const response = await BindPostAPI(
      [data],
      "InventoryIssuesAPI/GetInventoryIssue"
    );

    setbindIssueDetailsDatas(response.data.Table);
    setIssueFields({
      ...issueFields,
      indentNo: bindInventoryIssueDatas[rowIndex].IndentNo,
      indentDate: bindInventoryIssueDatas[rowIndex].IndentDate,
      issueNo: bindInventoryIssueDatas[rowIndex].IssueNo,
      issueDate: bindInventoryIssueDatas[rowIndex].IssueDate,
    });
    setVisibleIssueDetailsTable(true);
  };

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [currentData, setCurrentData] = useState({});
  const [currentData3, setCurrentData3] = useState({});
  const [currentData1, setCurrentData1] = useState({});

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [rowIndex, setRowIndex] = useState("");
  const [editDate, setEditDate] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);
  const location1 = useLocation();
  const issueAdd = location1?.state?.isAdd;
  const issueData = location1?.state?.isData;
  useEffect(() => {
    if (issueAdd) {
      if (userProfileDatas.length == 1) {
        setVisibleSdp(true);
        setIssueFields({
          ...issueFields,
          indentId: issueData.TxnIndentId,
          indentNo: issueData.IndentNo,
          issueDate: getCurrentDate(),
          issueNo: "INISS",
          issueTo: issueData.IndentFrom1Id,
          issueTo_en: issueData.IndentFrom1_en,
          receiver: issueData.IndentFrom2_en,
          areaName: issueData.AreaName_en,
          receiverId: issueData.IndentFrom2Id,
          areaNameId: issueData.MstAreaId,
          remarks_en: issueData.Remarks_en,
          branch: userProfileDatas[0]?.LocationName_en ?? "",
          branchId: userProfileDatas[0]?.MstLocationId,
          sdp: userProfileDatas[0]?.SDP_en,
          sdpId: userProfileDatas[0]?.MstSDPId,
        });
      } else {
        const newSdp = userProfileDatas.map((userProfile) => ({
          value: userProfile.MstSDPId,
          label: userProfile.SDP_en,
        }));
        setSdp(newSdp);

        setIssueFields({
          ...issueFields,
          indentId: issueData.TxnIndentId,
          indentNo: issueData.IndentNo,
          issueDate: getCurrentDate(),
          issueNo: "INISS",
          issueTo: issueData.IndentFrom1Id,
          issueTo_en: issueData.IndentFrom1_en,
          receiver: issueData.IndentFrom2_en,
          areaName: issueData.AreaName_en,
          receiverId: issueData.IndentFrom2Id,
          areaNameId: issueData.MstAreaId,
          remarks_en: issueData.Remarks_en,
          branch: userProfileDatas[0]?.LocationName_en ?? "",
          branchId: userProfileDatas[0]?.MstLocationId,
        });
      }

      setAddScreenTitle(true);
      issueIndentTableData(issueData.TxnIndentId);
      setVisibleAdd(true);
      setVisibleTable(false);
    }
  }, [issueAdd, issueData]);
  const issueIndentTableData = async (indentId) => {
    const data = {
      TxnIndentId: indentId,
    };
    const response = await BindPostAPI(
      [data],
      "InventoryIssuesAPI/BindIndentTableData"
    );
    setIndentDetailsData(response.data.Table);
  };
  useEffect(() => {
    if (addScreenTitle) {
      setIssueFields({
        ...issueFields,
        mstOrwId:userProfileDatas[0].MstRoleId === 7
        ? userProfileDatas[0].MstUserId
        : null,
      });
    }
  }, [addScreenTitle ? addScreenTitle : false]);
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    if (userProfileDatas.length == 1) {
      setVisibleSdp(true);
      setIssueFields({
        ...issueFields,
        issueNo: "INISS",
        issueDate: getCurrentDate(),
        branch: userProfileDatas[0]?.LocationName_en ?? "",
        sdp: userProfileDatas[0]?.SDP_en,
        branchId: userProfileDatas[0]?.MstLocationId,
        sdpId: userProfileDatas[0]?.MstSDPId,
        mstOrwId:userProfileDatas[0].MstRoleId == 7
        ? userProfileDatas[0].MstUserId
        : null,
      });
    } else {
      const newSdp = userProfileDatas.map((userProfile) => ({
        value: userProfile.MstSDPId,
        label: userProfile.SDP_en,
      }));
      setSdp(newSdp);

      setIssueFields({
        ...issueFields,
        branch: userProfileDatas[0]?.LocationName_en ?? "",
        branchId: userProfileDatas[0]?.MstLocationId,
        mstOrwId:userProfileDatas[0].MstRoleId === 7
        ? userProfileDatas[0].MstUserId
        : null,
      });
    }
  };
  const filterIssueId = [];
  const handleEdit = async (rowIndex) => {

    setCheckBoxStatus(true);

    const data = {
      txnInventoryIssueId:
        issueType == 2
          ? bindInventoryDirectIssueDatas[rowIndex].TxnInventoryIssueId
          : bindInventoryIssueDatas[rowIndex].TxnInventoryIssueId,
      txnIndentId:
        issueType == 2
          ? bindInventoryDirectIssueDatas[rowIndex].IndentId
          : bindInventoryIssueDatas[rowIndex].IndentId,
    };
    const response = await BindPostAPI(
      [data],
      "InventoryIssuesAPI/GetInventoryIssue"
    );
    // const issueChild = Object.values(response?.data?.Table[0]).filter(
    //   (item) =>
    //     item.TxnIndentId === bindInventoryIssueDatas[rowIndex].IndentId
    // );
    const table = response?.data?.Table || [];

    // Check if table is an array
    const updatedTableData = Array.isArray(table)
      ? table.map((data) => {
          // Process each item in the array
          const brandOptions = getBrandDatas
            .filter(
              (item) => item.MstCommodityCategoryId == data.IssuesCategory
            )
            .map((items) => ({
              value: items.MstBrandId,
              label: items.Brand_en,
            }));

          return {
            ...data,
            MstCommodityCategoryId: `${data.IssuesCategory}`,
            MstCommoditiesId: `${data.IssuesName}`,
            MstCommodityTypeId: `${data.CategoryType}`,
            IssuedBefore: `${data.IssuedBefore}`,
            MstIndentChildId: `${data.MstIndentChildId}`,
            IndentQuantity: `${data.IndentQuantity}`,
            IndentPendingQuantity: `${data.IndentPendingQuantity}`,
            CommodityCategory_en: `${data.CommodityCategory_en}`,
            CommodityName_en: `${data.CommodityName_en}`,
            CommodityCategoryType: `${data.CategoryType}`,
            brandOptions: brandOptions,
            IssueBrandId: `${data.MstBrandId}`,
            IssueNow: `${data.IssuesQuantity}`,
            BalanceStock: `${data.Balance}`,
            AvailableStock: `${data.Available}`,
            BatchNo: `${data.IssuesBatch}`,
            DateOfExpiry: `${data.ExpiryDate}`,
            MstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
            MstUserId: userProfileDatas[0]?.MstUserId,
            MstRoleId: userProfileDatas[0]?.MstRoleId,
            MstBranchProjectId:
              bindInventoryIssueDatas[rowIndex]?.MstBranchProjectId,
            MstSDPId: bindInventoryIssueDatas[rowIndex]?.MstSDPId,
            MstAreaId: bindInventoryIssueDatas[rowIndex]?.MstAreaId,
            MstORWId: bindInventoryIssueDatas[rowIndex]?.MstORWId
              ? bindInventoryIssueDatas[rowIndex]?.MstORWId
              : 0,
              Flag:2,
              IssueDate:editDate,
              IssueTypeId:bindInventoryIssueDatas[rowIndex]?.IssueNo,
          };
        })
      : table; // If it's not an array, just use the table as is.

    setIssueDetailsData(updatedTableData);

    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    let parts = response?.data?.Table[0]?.IssueDate?.split("-");
    setEditDate(response?.data?.Table[0]?.IssueDateEdit);
    let dateObject = new Date(
      parseInt(parts[2]),
      parseInt(parts[1]) - 1,
      parseInt(parts[0])
    );

    if (userProfileDatas.length == 1) {
      setVisibleSdp(true);
      setIssueFields({
        ...issueFields,
        txnInventoryIssueId: response.data.Table[0].TxnInventoryIssueId,
        indentId: response.data.Table[0].IndentId,
        indentNo: response.data.Table[0].IndentNo,
        issueNo: response.data.Table[0].IssueNo,
        issueDate: moment(dateObject).format("YYYY-MM-DD"),
        issueTo: response.data.Table[0].IssueTo,
        issueTo_en: response.data.Table[0].ReceivedFrom_en,
        receiver: response.data?.Table?.[0]?.LocationName_en ?? "",
        areaName: response.data.Table[0].AreaName_en,
        receiverId: response.data.Table[0].Receiver,
        areaNameId: response.data.Table[0].AreaName,
        mstOrwId: response.data.Table[0].MstORWId
          ? response.data.Table[0].MstORWId
          : null,
        remarks_en: response.data.Table[0].IssueRemarks_en,
        branch: response.data.Table[0].BranchProject_en,
        branchId: response.data.Table[0].MstBranchProjectId,
        sdp: response.data.Table[0].SDP_en,
        sdpId: response.data.Table[0].MstSDPId,
        issueArea: response.data.Table[0].MstAreaId,
        issuedThrough: response.data.Table[0].IssuedThrough,
      });
    } else {
      const newSdp = userProfileDatas.map((userProfile) => ({
        value: userProfile.MstSDPId,
        label: userProfile.SDP_en,
      }));
      setSdp(newSdp);

      setIssueFields({
        ...issueFields,
        txnInventoryIssueId: response.data.Table[0].TxnInventoryIssueId,
        indentId: response.data.Table[0].IndentId,
        indentNo: response.data.Table[0].IndentNo,
        issueNo: response.data.Table[0].IssueNo,
        issueDate: moment(dateObject).format("YYYY-MM-DD"),
        issueTo: response.data.Table[0].IssueTo,
        receiver: response.data?.Table?.[0]?.LocationName_en ?? "",
        areaName: response.data.Table[0].AreaName_en,
        receiverId: response.data.Table[0].Receiver,
        areaNameId: response.data.Table[0].AreaName,
        remarks_en: response.data.Table[0].IssueRemarks_en,
        branch: response.data.Table[0].BranchProject_en,
        branchId: response.data.Table[0].MstBranchProjectId,
        sdp: response.data.Table[0].SDP_en,
        sdpId: response.data.Table[0].MstSDPId,
        issueArea: response.data.Table[0].MstAreaId,
        issuedThrough: response.data.Table[0].IssuedThrough,
      });
    }
    const processTableData = (tableData) => {
      const uniqueDataMap = new Map();
    
      tableData.forEach((item) => {
        // Ignore entries where MstCommoditiesId is an empty string
        if (item.MstCommoditiesId !== "") {
          // Add only unique MstCommoditiesId entries to the map
          if (!uniqueDataMap.has(item.MstCommoditiesId)) {
            uniqueDataMap.set(item.MstCommoditiesId, item);
          }
        }
      });
    
      // Convert the map back to an array
      return Array.from(uniqueDataMap.values());
    };
    
    // Process the data and set it to state
    const processedData = processTableData(response.data.Table1);
    setIndentDetailsData(processedData);
    const filterIssueId = response.data.Table.filter((x) => {
      if (
        rowIndex < bindInventoryDirectIssueDatas.length ||
        rowIndex < bindInventoryIssueDatas.length
      ) {
        return (x.TxnInventoryIssueId == issueType) == 2
          ? bindInventoryDirectIssueDatas[rowIndex].TxnInventoryIssueId
          : bindInventoryIssueDatas[rowIndex].TxnInventoryIssueId;
      }
      console.error("Invalid rowIndex:", rowIndex);
      return false;
    });

    // setIssueDetailsDataEdit(filterIssueId);

    if (filterIssueId.length > 0) {
      setIndentError("");
      setVisibleIssueTable(true);
    } else setIndentError("Select atleast one indent");

    const IMstIndentChildIds = response.data.Table.map(
      (data) => data.MstIndentChildId
    );
    const filterIssueIdIndex = response.data.Table1.reduce(
      (indices, obj, index) => {
        if (IMstIndentChildIds.includes(obj.MstIndentChildId)) {
          indices.push(index);
        }
        return indices;
      },
      []
    );
    setEdit(true);
    setVisibleIssueTable(true);
    setSelectedRowTableData(filterIssueIdIndex);
    appendDataToState(JSON.stringify(response.data.Table));
  };
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    // Submit functionality

    if (flag == 1 || flag == 2) {
      // validation start
      const isFormError = {
        ...issueFieldsError,

        issuedThroughError:
          issueType == 2
            ? (issueFields.issuedThrough && issueFields.issuedThrough) ||
              "".length > 0
              ? /^[A-Za-z\/,\-\s]+$/.test(issueFields.issuedThrough)
                ? ""
                : "Only Alphabets allowed"
              : "Enter Issued Through"
            : "",
            issueToError:
            issueType == 2 ?
            (issueFields.issueTo.length > 0 ? "": "Select issued to") : "",
            areaNameError: issueType == 2 ? (issueFields.areaNameId
            ? ""
            : "Select Area Name") : "",   

      };
      const indentTableValidation =
        selectedRowData.length > 0 || selectedRowTableData.length > 0
          ? ""
          : "Select atleast one indent";
      const hasValidQuantity = (array) =>
        array.some((item) => item.Quantity && item.Quantity > 0);

      setIssueFieldsError(isFormError);
      setIndentError(indentTableValidation);
      const isFormValid = Object.values(isFormError).every(
        (error) => error === ""
      );
      const issueDataFilter = issueDetailsData.filter(
        (x) => parseInt(x.IssueNow) > 0
      );
      let issueErrorSet = "";
      if (issueDataFilter.length == 0) {
        issueErrorSet = "Enter atleast one issue";
        setIssueError(issueErrorSet);
      }

      if (!isFormValid) {
        setIsOpen(true);
      }

      if (isFormValid && !indentTableValidation && issueErrorSet == "") {
        setSubmitBtnDisabled(true);
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedRemarksTexts = [];

        if (issueFields.remarks_en) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(
              issueFields?.remarks_en,
              i
            );
            translatedRemarksTexts.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }
        issueDataFilter.map((x) => {
          x.ReceiptDateOfExpiry = moment(
            x.ReceiptDateOfExpiry,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
        });

        const inventoryIssue = [
          {
            txnInventoryIssueId: issueFields.txnInventoryIssueId
              ? issueFields.txnInventoryIssueId
              : 0,
            txnIndentId: issueFields.indentId ? issueFields.indentId : 0,
            indentNo: issueFields.indentNo,
            issueNo: issueFields.issueNo,
            issueDate: flag == 1 ? issueFields.issueDate : editDate,
            mstBranchProjectId: issueFields.branchId,
            mstSDPId: issueFields.sdpId,
            mstAreaId: issueFields.issueArea ? issueFields.issueArea : 0,
            mstOrwId: issueFields.mstOrwId ? issueFields.mstOrwId : 0,
            mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
            mstRoleId: userProfileDatas[0]?.MstRoleId,
            issueTo: issueFields.issueTo,
            receiver: issueFields.receiverId,
            areaName: issueFields.areaNameId ? issueFields.areaNameId : null,
            issueType: issueType,
            issuedThrough: issueFields.issuedThrough
              ? issueFields.issuedThrough
              : null,
            issueDetails: JSON.stringify(issueDataFilter),
            issueRemarks_en: issueFields.remarks_en,
            issueRemarks_ta: translatedRemarksTexts[0],
            issueRemarks_te: translatedRemarksTexts[1],
            issueRemarks_or: translatedRemarksTexts[2],
            issueRemarks_mr: translatedRemarksTexts[3],
            issueRemarks_ml: translatedRemarksTexts[4],
            issueRemarks_kn: translatedRemarksTexts[5],
            issueRemarks_hi: translatedRemarksTexts[6],
            issueRemarks_gu: translatedRemarksTexts[7],
            issueRemarks_bn: translatedRemarksTexts[8],
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(
          inventoryIssue,
          "InventoryIssuesAPI/IUDInventoryIssue"
        );

        if (flag == 1) {
          // Submit

          if (response.data.Table[0].result === "S") {
            toast.success("Inventory issue data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setSubmitVisibleButton(true);
            setIssueFields({
              ...issueFields,
              issueNo: response.data.Table1[0].IssueNo,
            });
            setTimeout(cancel, 10000);
          } else {
            toast.error("Failed to add inventory issue data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].result === "M") {
            toast.success("Inventory issue data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update inventory issue data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitVisibleButton(true);
      const inventoryIssue = [
        {
          txnInventoryIssueId:
            bindInventoryIssueDatas[rowIndex].TxnInventoryIssueId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(
        inventoryIssue,
        "InventoryIssuesAPI/IUDInventoryIssue"
      );
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].result === "D") {
          toast.success("Issued data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete inventory issue data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          alert("Issued data Deleted Failed");
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].result === "B") {
          toast.success("Inventory issue data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block inventory issue data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          alert("Issued data Blocked Failed");
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("Inventory issue data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock inventory issue data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          alert("Issued data UnBlocked Failed");
        }
      } else {
        setSubmitVisibleButton(false);
      }
    }
  };

  const handleIssueDetails = async (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const data = {
      TxnInventoryIssueId:
        bindInventoryIssueDatas[rowIndex].TxnInventoryIssueId,
      TxnIndentId: bindInventoryIssueDatas[rowIndex].IndentId,
    };
    const response = await BindPostAPI(
      [data],
      "InventoryIssuesAPI/GetInventoryIssue"
    );

    var DateOpeningPart = bindInventoryIssueDatas[rowIndex].IssueDate;
    const OpeningdatePart = DateOpeningPart.split("T")[0];

    // Assuming the OpeningdatePart has value "17-06-2024"
    const dateParts = OpeningdatePart.split("-");
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    const filterIssueId = response.data.Table.filter(
      (x) =>
        x.TxnInventoryIssueId ==
        bindInventoryIssueDatas[rowIndex].TxnInventoryIssueId
    );
    const drpdetailsData = [formattedDate];
    setCurrentData3(drpdetailsData);
    setCurrentData(bindInventoryIssueDatas[rowIndex]);

    const updatedTableData = filterIssueId.map((data, rowIndex) => ({
      ...data,
      CommodityType: data.CommodityType_en,
      CommodityCategoryName: data.CommodityCategory_en,
      CommodityName_en: data.CommodityName_en,
      Brand_en: data.Brand_en,
      Available: data.Available,
      // Available: parseInt(parseInt(data.IssueQuantity) + parseInt(data.ReceiptBalance)),
      ReceiptBatchNo: data.IssuesBatch,
      ReceiptDateOfExpiry: moment(data.ExpiryDate).format("DD-MM-YYYY"),
      ReceiptQuantity: data.IssuesCategory,
      Quantity: data.IssuesQuantity,
      Balance: data.Balance,
    }));
    setCurrentData1(updatedTableData);
  };
  const handleIssueDetails1 = async (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const data = {
      TxnInventoryIssueId:
        bindInventoryDirectIssueDatas[rowIndex].TxnInventoryIssueId,
      TxnIndentId: 0,
    };
    const response = await BindPostAPI(
      [data],
      "InventoryIssuesAPI/GetInventoryIssue"
    );

    var DateOpeningPart = bindInventoryDirectIssueDatas[rowIndex].IssueDate;
    const OpeningdatePart = DateOpeningPart.split("T")[0];

    // Assuming the OpeningdatePart has value "17-06-2024"
    const dateParts = OpeningdatePart.split("-");
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    const filterIssueId = response.data.Table.filter(
      (x) =>
        x.TxnInventoryIssueId ==
        bindInventoryDirectIssueDatas[rowIndex].TxnInventoryIssueId
    );
    const drpdetailsData = [formattedDate];
    setCurrentData3(drpdetailsData);
    setCurrentData(bindInventoryDirectIssueDatas[rowIndex]);
    const updatedTableData = filterIssueId.map((data, rowIndex) => ({
      ...data,
      CommodityType: data.CommodityType_en,
      CommodityCategoryName: data.CommodityCategory_en,
      CommodityName_en: data.CommodityName_en,
      Brand_en: data.Brand_en,
      Available: data.Available,
      // Available: parseInt(parseInt(data.IssueQuantity) + parseInt(data.ReceiptBalance)),
      ReceiptBatchNo: data.IssuesBatch,
      ReceiptDateOfExpiry: moment(data.ExpiryDate).format("DD-MM-YYYY"),
      ReceiptQuantity: data.IssuesCategory,
      Quantity: data.IssuesQuantity,
      Balance: data.Balance,
    }));
    setCurrentData1(updatedTableData);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, "0");
    setIssueFields({ ...issueFields, issueDate: `${year}-${month}-${day}` });
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    bindIndentData();
    inventoryIssue(userProfileDatas);
    IssueDropDownDatas();
  }, [userProfileDatas]);

  const inventoryIssue = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
        mstBranchId: userProfileDatas[0]?.MstLocationId,
        mstSdpId: userProfileDatas[0]?.MstSDPId
          ? userProfileDatas[0]?.MstSDPId
          : 0,
        mstUserId: userProfileDatas[0]?.MstUserId,
        mstRoleId: userProfileDatas[0]?.MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "InventoryIssuesAPI/BindInventoryIssue"
      );
      const filteredWithIssueData = response.data.Table.filter(
        (item) => item.IssueType == 1
      );
      const filteredWithDirectIssueData = response.data.Table.filter(
        (item) => item.IssueType == 2
      );
      setBindInventoryIssueDatas(filteredWithIssueData);
      setBindInventoryIssueChildDatas(response.data.Table1);
      setBindInventoryDirectIssueDatas(filteredWithDirectIssueData);
    }
  };

  const bindIndentData = async () => {
    const response = await BindAPI("InventoryIssuesAPI/BindPendingIndent");
    setPendingIndentDatas(response.data.Table);
  };
  const bindIndentTableData = async () => {
    const response = await BindAPI("InventoryIssuesAPI/BindPendingIndentTable");
    setPendingIndentTableDatas(response.data.Table);
  };
  const IssueDropDownDatas = async () => {
    try {
      const categoryResponse = await BindAPI(
        "CommoditiesAPI/GetCommoditiesCategory"
      );
      setBindCommoditiesCategory(categoryResponse.data.Table);
      const nameResponse = await BindAPI("CommoditiesAPI/BindCommodities");
      setBindCommodities(nameResponse.data.Table);

      const categoryTypeResponse = await BindAPI(
        "InventoryReceiptAPI/BindCommodityType"
      );
      const areaResponse = await BindAPI("AreaAPI/GetArea");
      const orsNameResponse = await BindAPI("InventoryReceiptAPI/BindORSName");
      setBindAreaDatas(areaResponse.data.Table);
      const commoditiesBrands = await BindAPI("BrandAPI/BindBrand");

      const filteredData3 = commoditiesBrands.data.Table;
      const filteredWithBlockedByNull3 = filteredData3.filter(
        (item) => item.BlockedBy === null
      );
      setGetBrandDatas(filteredWithBlockedByNull3);
      const categoryData = categoryResponse.data.Table.map((item) => ({
        value: item.MstCommodityCategoryId,
        label: item.CommodityCategory_en,
      }));

      const nameData = nameResponse.data.Table.map((item) => ({
        value: item.MstCommoditiesId,
        label: item.CommodityName_en,
      }));

      const categoryTypeData = categoryTypeResponse.data.Table.map((item) => ({
        value: item.MstCommodityTypeId,
        label: item.CommodityType_en,
      }));
      const locationId = userProfileDatas[0]?.MstLocationId;
      const filteredAreaData = areaResponse.data.Table.filter(
        (area) => area.MstLocationId == locationId
      );

      const areaData = filteredAreaData.map((item) => ({
        value: item.MstAreaId,
        label: item.AreaName_en,
      }));

      const filteredData = orsNameResponse.data.Table.filter(
        (record) => record.MstLocationId === 21
      );

      const orsNameData = filteredData.map((item) => ({
        value: item.MstUserId,
        label: item.FirstName_en,
      }));

      setBindCommodityDD(categoryData);
      setBindCommodityNameDD(nameData);
      setCommoditiesDD(categoryTypeData);
      setBindAreaDropDown(areaData);
      setBindFieldWorkerName(orsNameData);
    } catch (error) {}
  };
  useEffect(() => {
    if (visibleSdp) {
      const areaSelectedValue = bindAreaDatas.find(
        (item) => item.MstSDPId == parseInt(issueFields.sdpId) // Replace with the desired MstStateId
      );

      if (areaSelectedValue) {
        const areaLabels = bindAreaDatas
          .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
          .map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

        const areaSelected = areaLabels.map((AR) => ({
          value: AR.MstAreaId, // Replace with the appropriate value
          label: AR.AreaName_en,
        }));

        setBindAreaDD1(areaSelected);
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    } else {
      const areaSelectedValue = bindAreaDatas.find(
        (item) => item.MstSDPId == issueFields.sdp // Replace with the desired MstStateId
      );

      if (areaSelectedValue) {
        const areaLabels = bindAreaDatas
          .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
          .map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

        const areaSelected = areaLabels.map((AR) => ({
          value: AR.MstAreaId, // Replace with the appropriate value
          label: AR.AreaName_en,
        }));

        setBindAreaDD1(areaSelected);
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    }
  }, [
    parseInt(issueFields.sdpId) ? parseInt(issueFields.sdpId) : null,
    issueFields.sdp ? issueFields.sdp : null,
  ]);
  const cancel = () => {
    navigate("/inventoryissue", {
      state: {
        menuId: 41,
      },
    });
    setEdit(false);
    setCurrentData("");
    setCurrentData1("");
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    setCurrentData3("");
    setIssueFields({
      txnInventoryIssueId: "",
      indentId: "",
      indentNo: "",
      issueNo: "INISS",
      issueDate: "",
      issueTo: "",
      receiver: "",
      areaName: "",
      mstOrwId: "",
      remarks_en: "",
      remarks_ta: "",
      remarks_te: "",
      remarks_or: "",
      remarks_mr: "",
      remarks_ml: "",
      remarks_kn: "",
      remarks_hi: "",
      remarks_gu: "",
      remarks_bn: "",
    });
    setIssueFieldsError({
      issueNoError: "",
      issueToError: "",
      issueToError: "",
      receiverError: "",
      areaNameError: "",
    });
    setTableData([
      {
        issueCommodities: "",
        MstCommodityCategoryId: "",
        MstCommoditiesId: "",
        issueCategoryList: [],
        issueNameList: [],
      },
    ]);
    setIndentError("");
    setIssueError("");
    inventoryIssue(userProfileDatas);
    bindIndentData();
    setVisibleIssueTable(false);
    setIssueDetailsData([]);
    setIssueDetailsDataEdit([]);
    setIssueDetailsDataEdit1([]);
    setIndentDetailsData([]);
    setbindIssueDetailsDatas([]);
    setSelectedRowTableData([]);
    setpopUpOpen(false);
    setVisibleAdd(false);
    setVisibleTable(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setVisibleIssueDetailsTable(false);
  };
  const HandleDelete = (rowIndex) => {


    // Validate the issueDetailsData and rowIndex
    if (!issueDetailsData || !Array.isArray(issueDetailsData)) {
      console.error("issueDetailsData is invalid or not an array");
      return;
    }

    if (rowIndex < 0 || rowIndex >= issueDetailsData.length) {
      console.error("Invalid rowIndex:", rowIndex);
      return;
    }

    // Filter out the row at the specified index
    const updatedTableData = issueDetailsData.filter(
      (_, index) => index !== rowIndex
    );

    // Update the state with the filtered data
    setIssueDetailsData(updatedTableData);

    console.log("Updated Table Data:", updatedTableData);
  };

  // const HandleAdd = (rowIndex, count = 1) => {
  //   debugger;

  //   // Ensure issueDetailsData exists and rowIndex is valid
  //   if (
  //     !issueDetailsData ||
  //     !Array.isArray(issueDetailsData) ||
  //     rowIndex < 0 ||
  //     rowIndex >= issueDetailsData.length
  //   ) {
  //     console.error("Invalid data or rowIndex");
  //     return;
  //   }

  //   // Clone the existing data
  //   const updatedTableData = [...issueDetailsData];

  //   // Get the selected row to duplicate
  //   const duplicateRow = { ...updatedTableData[rowIndex] };

  //   // Insert multiple duplicates at the desired index (after the original row)
  //   for (let i = 0; i < count; i++) {
  //     updatedTableData.splice(rowIndex + 1, 0, { ...duplicateRow }); // Always insert at rowIndex + 1
  //   }

  //   // Update the state with the modified table data
  //   setIssueDetailsData(updatedTableData);
  // };
  const HandleAdd = (rowIndex, count = 1) => {

  
    // Ensure issueDetailsData exists and rowIndex is valid
    if (
      !issueDetailsData ||
      !Array.isArray(issueDetailsData) ||
      rowIndex < 0 ||
      rowIndex >= issueDetailsData.length
    ) {
      console.error("Invalid data or rowIndex");
      return;
    }
  
    // Clone the existing data
    const updatedTableData = [...issueDetailsData];
  
    // Get the selected row to duplicate
    const duplicateRow = { ...updatedTableData[rowIndex] };
  
    // Reset IssueNow and BalanceStock to empty strings for the duplicate rows
    duplicateRow.IssueNow = "";
    duplicateRow.BalanceStock = "";
    duplicateRow.IssueBrandId = "";
    duplicateRow.BalanceStock = "";
    duplicateRow.BatchNo = "";
    duplicateRow.DateOfExpiry = "";
    duplicateRow.Flag = 1;
    // Insert multiple duplicates at the desired index (after the original row)
    for (let i = 0; i < count; i++) {
      updatedTableData.splice(rowIndex + 1, 0, { ...duplicateRow }); // Always insert at rowIndex + 1
    }
  
    // Update the state with the modified table data
    setIssueDetailsData(updatedTableData);
  };
  
  const handleReferralGrid = (updatedData) => {
    // Update the state in the parent component
    setIssueDetailsData(updatedData);
  };
  const handleIssueDetailsPrint = async (rowIndex) => {
    // setShowLoader(true);
    handleIssueDetailslView(rowIndex);

    const timeoutId = setTimeout(() => {
      const content = document.getElementById("printable").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      document.body.innerHTML = originalContents;

      // setShowLoader(false);
    }, 2000);
    // Restore button functionality after printing
    setVisibleIssueDetailsTable(false);
    setVisibleTable(true);
  };
  // Define a function to be executed after the timeout
  const myFunction = () => {
    const content = document.getElementById("printable").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = content;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const [issueTypeRadioBtn, setIssueTypeRadioBtn] = useState([
    { value: 1, label: "Issued with indent" },
    { value: 2, label: "Directly issued" },
  ]);
  const [issueType, setIssueType] = useState(1);
  const handleIssueChange = (selectedOptions) => {
    setIssueType(selectedOptions);

    if (selectedOptions == 1) {
      setVisiblePendingIndent(true);
    } else {
      setVisiblePendingIndent(false);
    }
  };
  const [commoditiesDetails, setCommoditiesDetails] = useState("");
  const headerData = [
    {
      title: "Commodities",
      key: "commoditiesCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Category",
      key: "commoditiesCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "commoditiesName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  const [tableData, setTableData] = useState([
    {
      issueCommodities: "",
      MstCommodityCategoryId: "",
      MstCommoditiesId: "",
      issueCategoryList: [],
      issueNameList: [],
    },
  ]);
  const appendDataToState = (newDataString) => {

    const newDataArray = JSON.parse(newDataString);
    handleDeleteRow(0);

    // Format new data
    const formattedDataArray = newDataArray.map((data) => {
      return {
        issueCommodities: parseInt(data.CategoryType),
        MstCommodityCategoryId: parseInt(data.Category),
        MstCommoditiesId: parseInt(data.Name),
      };
    });

    // Ensure MstCommoditiesId is unique
    setTableData((prevData) => {
      // Combine previous and new data
      const combinedData = [...prevData, ...formattedDataArray];

      // Create a map to filter out duplicate MstCommoditiesId
      const uniqueDataMap = new Map();
      combinedData.forEach((item) => {
        // Ignore entries where MstCommoditiesId is an empty string
        if (item.MstCommoditiesId !== "") {
          // Add only unique MstCommoditiesId entries to the map
          if (!uniqueDataMap.has(item.MstCommoditiesId)) {
            uniqueDataMap.set(item.MstCommoditiesId, item);
          }
        }
      });

      // Convert map values back to array
      const uniqueData = Array.from(uniqueDataMap.values());

      return uniqueData;
    });

    convertArrayToJSONString(formattedDataArray);
  };

  const previousTableData = UsePrevious(tableData);
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.issueCommodities = "";
    newRow.MstCommodityCategoryId = "";
    newRow.MstCommoditiesId = "";
    newRow.issueCategoryList = [];
    newRow.issueNameList = [];

    setTableData((prevData) => [...prevData, newRow]);
  };

  // Inventory Receipt issue Table Row Delete Functionaity .
  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          issueCommodities: "",
          MstCommodityCategoryId: "",
          MstCommoditiesId: "",
          issueCategoryList: [],
          issueNameList: [],
        };

        updatedTableData.push(newRow); // Add a default row if the table is empty
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const convertArrayToJSONString = (array) => {


    // Create a Map to filter out duplicate MstCommoditiesId
    const uniqueDataMap = new Map();

    array.forEach((item) => {
      // Only add the first occurrence of each MstCommoditiesId
      if (!uniqueDataMap.has(item.MstCommoditiesId)) {
        uniqueDataMap.set(item.MstCommoditiesId, item);
      }
    });

    // Convert Map values back to an array
    const uniqueArray = Array.from(uniqueDataMap.values());

    // Set unique data to state
    if (!visiblePendingIndent) {
      setSelectedRowData(uniqueArray);
    }

    try {
      const jsonString = JSON.stringify(uniqueArray);
      setCommoditiesDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON", error);
      return null;
    }
  };

  const appendDataToCommoditiesTable = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    handleDeleteRow(0);
    const formattedDataArray = newDataArray.map((data) => {
      const receivedQuantityVisible =
        data.ReceiptQuantity > data.ReceiptBalance;
      return {
        issueCommodities: data.MstCommoditiesId,
        MstCommodityCategoryId: data.MstCategoryId,
        MstCommoditiesId: data.MstNameId,
        receivedQuantityVisible: receivedQuantityVisible,
      };
    });

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };
  useEffect(() => {
    if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
      const currentTableData = [...tableData];
      currentTableData.forEach((row, index) => {
        if (row?.issueCommodities) {
          const getFilteredCategory = bindCommoditiesCategory
            .filter((item) => item.MstCommodityTypeId == row.issueCommodities)
            .map((items) => ({
              value: items.MstCommodityCategoryId,
              label: items.CommodityCategory_en,
            }));
          row.issueCategoryList = getFilteredCategory;
        }
        if (row?.MstCommodityCategoryId) {
          const selectedCommoditiesCategory = bindCommodities
            .filter(
              (item) =>
                item.MstCommodityCategoryId == row.MstCommodityCategoryId
            )
            .map((item) => ({
              value: item.MstCommoditiesId,
              label: item.CommodityName_en,
            }));
          row.issueNameList = selectedCommoditiesCategory;
        }
      });
      setTableData(currentTableData);
    }

    // Iterate through all rows in tableData
  }, [tableData]);

  const handleIssueFieldsChange = (value, name) => {
    setIssueFields({
      ...issueFields,
      [name]: value,
    });
  };

  return (
    <MainLayout pageName="3">
      <div className={mainTable.headerTitle}>
        <div className={mainTable.gridTableHeader}>{`Inventory Issued`}</div>
      </div>

      {visibleTable && (
        <div className={mainTable.grid4}>
          <div></div>
          <div className="col-span-2">
            <RadioGroup
              type="radio"
              selectedOptions={issueType}
              options={issueTypeRadioBtn}
              controlFunc={handleIssueChange}
            />
          </div>
          <div></div>
        </div>
      )}
      {visibleTable && (
        <div>
          {visiblePendingIndent && (
            <GridPage
              title="Pending Indents"
              blockView={false}
              addView={true}
              header={pendingIndentHeader}
              records={pendingIndentDatas}
              tableHeight="h-56"
              showAdd={showAdd}
            />
          )}
          {visiblePendingIndent && (
            <hr class={mainTable.servicesHorizontalLine} />
          )}
          {/* {showLoader && <div className="loader">Loading...</div>} */}

          {visiblePendingIndent && (
            <GridPage
              title="Issued With Indent"
              addView={true}
              header={inventoryIssueHeaders}
              records={bindInventoryIssueDatas}
              handleEdit={handleEdit}
              handleAction={handleIssueDetails}
              handleSchoolView={handleIssueDetailslView}
              handlePrint={handleIssueDetailsPrint}
              blockView={true}
            />
          )}
          {!visiblePendingIndent && (
            <GridPage
              title="Directly Issued"
              // addView={true}
              header={inventoryDirectIssueHeaders}
              records={bindInventoryDirectIssueDatas}
              handleEdit={handleEdit}
              showAdd={showAdd}
              handleAction={handleIssueDetails1}
              handleSchoolView={handleIssueDetailslView}
              handlePrint={handleIssueDetailsPrint}
            />
          )}
          {visibleIssueDetailsTable && (
            <div id="printable">
              <hr class={mainTable.servicesHorizontalLine}></hr>
              <div className={mainTable.headerTitle}>
                <div className="my-2 mx-12 lg:mx-0">
                  {" "}
                  {visiblePendingIndent
                    ? "Issued With Indent"
                    : "Directly Issued"}{" "}
                  Details
                </div>
              </div>
              <div className={mainTable.headerTitle}>
                <div className="my-2 text-sm mx-2 sm:my-2 sm:mx-12 md:my-2 md:mx-0 lg:mx-0 sm:ml-12">{`Indent No : ${issueFields.indentNo} > Indent Date : ${issueFields.indentDate} > Issued No. : ${issueFields.issueNo} > Issued Date : ${issueFields.issueDate} `}</div>
              </div>
              <GridPage
                header={inventoryIssueDetailsHeaders}
                records={bindIssueDetailsDatas}
                addView={true}
                tableView={true}
              />
            </div>
          )}
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title={
                visiblePendingIndent ? "Issued With Indent" : "Directly Issued"
              }
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={"overflow-auto p-5 min-h-[60vh] max-h-[72vh]"}>
                  <div className={mainTable.grid5}>
                    <InputText
                      classStar="text-red-500"
                      inputType="text"
                      title="Issued No."
                      name="issueNo"
                      content={issueFields.issueNo}
                      placeholder="Issued No"
                      controlFunc={(e) =>
                        setIssueFields({
                          ...issueFields,
                          issueNo: e.target.value,
                        })
                      }
                      disabled={true}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType="date"
                      title="issue Date"
                      name="issueDate"
                      content={issueFields.issueDate}
                      controlFunc={(e) =>
                        setIssueFields({
                          ...issueFields,
                          issueDate: e.target.value,
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />

                    <InputText
                      classStar="text-red-500"
                      inputType="text"
                      title="Branch"
                      name="branch"
                      content={issueFields.branch}
                      placeholder=""
                      disabled={true}
                      controlFunc={(e) =>
                        setIssueFields({
                          ...issueFields,
                          branch: e.target.value,
                        })
                      }
                    />
                    {visibleSdp && (
                      <InputText
                        inputType="text"
                        title="S.D.P."
                        name="sdp"
                        content={issueFields.sdp}
                        placeholder=""
                        disabled={true}
                        controlFunc={(e) =>
                          setIssueFields({
                            ...issueFields,
                            sdp: e.target.value,
                          })
                        }
                      />
                    )}
                    {!visibleSdp && (
                      <DropDown
                        title="S.D.P."
                        options={sdp}
                        placeholder="Select"
                        name="sdp"
                        selectedOption={issueFields.sdpId}
                        controlFunc={handleSdpDropdownChange}
                      />
                    )}
                    <AutoDropDown
                      title="Area"
                      options={
                        userProfileDatas[0]?.MstOrganizationId == 2
                          ? []
                          : bindAreaDD1
                      }
                      placeholder="Select"
                      name="issueArea"
                      selectedOption={bindAreaDD1.find(
                        (x) => x.value == issueFields.issueArea
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value, e) => {
                        handleIssueFieldsChange(value.value, e.name);
                      }}
                    />
                  </div>
                  <div className={mainTable.grid4}>
                    <AutoDropDown
                      title="Outreach staff"
                      options={bindFieldWorkerName}
                      name="mstOrwId"
                      placeholder="Select"
                      Class="custom-class"
                      selectedOption={bindFieldWorkerName.find(
                        (x) => x.value == issueFields.mstOrwId
                      )}
                      controlFunc={(value, e) => {
                        handleIssueFieldsChange(value.value, e.name);
                      }}
                      disabled={true}
                    />
                    {visiblePendingIndent && (
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title="Issued To"
                        name="issueTo"
                        content={issueFields.issueTo_en}
                        placeholder="100"
                        disabled={true}
                        controlFunc={(e) =>
                          setIssueFields({
                            ...issueFields,
                            issueTo: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setIssueFieldsError({
                            ...issueFieldsError,
                            issueToError:
                              issueFields.issueTo.length > 0
                                ? /^[A-Za-z\/,\-\s]+$/.test(issueFields.issueTo)
                                  ? ""
                                  : "Only Alphabets allowed"
                                : "Enter Issued To",
                          });
                        }}
                      />
                    )}
                    {visiblePendingIndent && (
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title={"Receiver"}
                        name="receiver"
                        content={issueFields.receiver}
                        placeholder={"Receiver Name"}
                        disabled={true}
                        controlFunc={(e) =>
                          setIssueFields({
                            ...issueFields,
                            receiver: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setIssueFieldsError({
                            ...issueFieldsError,
                            receiverError:
                              issueFields.receiver.length > 0
                                ? /^[A-Za-z\/,\-\s]+$/.test(
                                    issueFields.receiver
                                  )
                                  ? ""
                                  : "Only Alphabets allowed"
                                : "Enter Receiver",
                          });
                        }}
                      />
                    )}

                    {!visiblePendingIndent && (
                      <div>
                      <DropDown
                        title="Issued To"
                        classStar="text-red-500"
                        options={bindReceivedFromDD}
                        placeholder="Select"
                        name="productReceivedFrom"
                        selectedOption={issueFields.issueTo}
                        controlFunc={handleFromDropdownChange}
                        controlFuncBlur={() => {
                          setIssueFieldsError({
                            ...issueFieldsError,
                            issueToError: issueFields.issueTo
                              ? ""
                              : "Select issued to",
                          });
                        }}
                      />
                      {issueFieldsError.issueToError && (
                        <div className="text-red-500 text-sm font-medium">
                          {issueFieldsError.issueToError}
                        </div>
                      )}
                      </div>
                    )}
                    {!visiblePendingIndent && (
                      <AutoDropDown
                        title="Area"
                        classStar="text-red-500"
                        options={bindAreaDropDown}
                        placeholder="Select"
                        name="areaNameId"
                        selectedOption={bindAreaDropDown.find(
                          (x) => x.value == issueFields.areaNameId
                        )}
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleIssueFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setIssueFieldsError({
                            ...issueFieldsError,
                            areaNameError: issueFields.areaNameId
                              ? ""
                              : "Select Area Name",
                          });
                        }}
                        errorMessage={issueFieldsError.areaNameError}
                      />
                    )}

                    {visiblePendingIndent && (
                      <InputText
                        inputType="text"
                        title="Area"
                        name="areaName"
                        content={issueFields.areaName}
                        disabled={visiblePendingIndent ? true : false}
                        controlFunc={(e) =>
                          setIssueFields({
                            ...issueFields,
                            areaName: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setIssueFieldsError({
                            ...issueFieldsError,
                            areaNameError:
                              issueFields.areaName.length > 0
                                ? /^[A-Za-z\/,\-\s]+$/.test(
                                    issueFields.areaName
                                  )
                                  ? ""
                                  : "Only Alphabets allowed"
                                : "Enter Area Name",
                          });
                        }}
                      />
                    )}
                    {!visiblePendingIndent && (
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title={"Issued Through"}
                        name="receiver"
                        content={issueFields.issuedThrough}
                        placeholder={"Issued Through"}
                        disabled={false}
                        controlFunc={(e) =>
                          setIssueFields({
                            ...issueFields,
                            issuedThrough: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setIssueFieldsError({
                            ...issueFieldsError,
                            issuedThroughError:
                              issueFields.issuedThrough || "".length > 0
                                ? /^[A-Za-z\/,\-\s]+$/.test(
                                    issueFields.issuedThrough
                                  )
                                  ? ""
                                  : "Only Alphabets allowed"
                                : "Enter Receiver",
                          });
                        }}
                        errorMessage={issueFieldsError.issuedThroughError}
                      />
                    )}
                  </div>
                  {!visiblePendingIndent && (
                    <div className="hidden md:block  max-h-max ">
                      <table className="w-full border border-gray-300 mb-4">
                        <thead>
                          <tr>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 ">
                              Commodities
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 ">
                              Category
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 ">
                              Name
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 ">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={commoditiesDD}
                                  placeholder="Select"
                                  name="commodities"
                                  Class="p-0"
                                  selectedOption={commoditiesDD.find(
                                    (x) => x.value == row.issueCommodities
                                  )}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "issueCommodities"
                                    );
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={row.issueCategoryList || []}
                                  placeholder="Select"
                                  name="Category"
                                  Class="p-0"
                                  selectedOption={
                                    row.issueCategoryList
                                      ? row.issueCategoryList.find(
                                          (x) =>
                                            x.value ===
                                            row.MstCommodityCategoryId
                                        )
                                      : null
                                  }
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "MstCommodityCategoryId"
                                    );
                                  }}
                                />
                              </td>
                              <td
                                key={index}
                                className="border border-gray-300 p-2"
                              >
                                <AutoDropDown
                                  options={row.issueNameList || []}
                                  name="name"
                                  placeholder="Select"
                                  selectedOption={
                                    row.issueNameList
                                      ? row.issueNameList.find(
                                          (x) =>
                                            x.value === row.MstCommoditiesId
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "MstCommoditiesId"
                                    );
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300">
                                <div className="flex gap-4 align-baseline items-center justify-center">
                                  <svg
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    class="w-6 h-6 text-black cursor-pointer"
                                    onClick={() => handleDeleteRow(index)}
                                  >
                                    <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                  </svg>
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="currentColor"
                                    onClick={handleAddRow}
                                    class="w-6 h-6 text-black cursor-pointer"
                                  >
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {!visiblePendingIndent && (
                    <div className={mainTable.tableMobile} role="table">
                      <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                        {tableData.length > 0 &&
                          tableData.map((row, index) => (
                            <div scope="col" class={mainTable.mobileTable}>
                              {headerData.map((keyData) => (
                                <div
                                  scope="cell"
                                  class={mainTable.tableData}
                                  role="cell"
                                >
                                  {keyData.key === "Action" ? (
                                    <div class={mainTable.tableData}>
                                      <div className="md:block hidden">
                                        <button
                                          id="dropdownMenuIconButton"
                                          data-dropdown-toggle="dropdownDots"
                                          class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                          type="button"
                                        >
                                          <svg
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                      <div className="md:hidden flex justify-center">
                                        <input
                                          type="button"
                                          className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                          onClick={handleAddRow}
                                          value="Add"
                                        />
                                        <input
                                          type="button"
                                          className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                          onClick={() => handleDeleteRow(index)}
                                          value="Delete"
                                        />
                                      </div>
                                    </div>
                                  ) : keyData.key ===
                                    "commoditiesCategoryType" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <AutoDropDown
                                        options={commoditiesDD}
                                        placeholder="Select"
                                        name="Category"
                                        Class="p-0"
                                        selectedOption={commoditiesDD.find(
                                          (x) => x.value == row.issueCommodities
                                        )} // Use the entire object
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e,
                                            index,
                                            "issueCommodities"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "commoditiesCategory" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <AutoDropDown
                                        options={row.issueCategoryList || []}
                                        placeholder="Select"
                                        name="Category"
                                        Class="p-0"
                                        selectedOption={
                                          row.issueCategoryList
                                            ? row.issueCategoryList.find(
                                                (x) =>
                                                  x.value ===
                                                  row.MstCommodityCategoryId
                                              )
                                            : null
                                        }
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e,
                                            index,
                                            "MstCommodityCategoryId"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "commoditiesName" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <AutoDropDown
                                        options={row.issueNameList || []}
                                        name="name"
                                        placeholder="Select"
                                        selectedOption={
                                          row.issueNameList
                                            ? row.issueNameList.find(
                                                (x) =>
                                                  x.value ===
                                                  row.MstCommoditiesId
                                              )
                                            : null
                                        }
                                        Class="p-0"
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e,
                                            index,
                                            "MstCommoditiesId"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex border p-1">
                                      <div className="flex basis-1/2 font-bold">
                                        {keyData.title}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {visiblePendingIndent && (
                    <StudentTableGrid
                      headerData={indentDetailsHeader}
                      tableData={indentDetailsData ? indentDetailsData : []}
                      tableHeight="h-44"
                      onSelectedGrid={setSelectedRowData}
                      selectedRowTableData={selectedRowTableData}
                      checkBoxStatus={setCheckBoxStatus}
                    />
                  )}
                  <label className="text-red-500 text-sm font-medium">
                    {indentError && indentError !== "" ? indentError : ""}
                  </label>
                  {visiblePendingIndent && (
                    <div className="flex justify-center my-2">
                      <input
                        type="button"
                        value="GO"
                        className={mainTable.addButton}
                        onClick={() => {
                          if (
                            selectedRowData.length > 0 ||
                            selectedRowTableData.length > 0
                          ) {
                            if (
                              issueDetailsData.length > 0 ||
                              issueDetailsDataEdit1.length > 0
                            ) {
                              setIndentError("");
                              setVisibleIssueTable(true);
                            } else {
                              setIndentError("Out of stock.");
                              setVisibleIssueTable(false);
                            }
                          } else {
                            setIndentError("Select at least one indent.");
                            setVisibleIssueTable(false);
                          }
                        }}
                      />
                    </div>
                  )}
                  {!visiblePendingIndent && (
                    <div className="flex justify-center my-2">
                      <input
                        type="button"
                        value="GO"
                        className={mainTable.addButton}
                        onClick={goFetchData}
                      />
                    </div>
                  )}
                  {visibleIssueTable && (
                    <div>
                      <TableGrid
                        headerData={
                          visiblePendingIndent
                            ? updateScreenTitle ? issueIndentDetailsEditHeader :issueIndentDetailsHeader
                            : updateScreenTitle ? issueDetailsEditHeader :issueDetailsHeader
                        }
                        tableData={issueDetailsData}
                        xScroll="overflow-x-auto max-md:w-fit w-full"
                        tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
                        itemsPerPage={100}
                        currentPage={1}
                        onDeleteGrid={HandleDelete}
                        onEditGrid={HandleAdd}
                        onReferralGrid={handleReferralGrid} // Pass the callback function to the child component
                      />
                      <label className="text-red-600">
                        {issueError && issueError != "" ? issueError : ""}
                      </label>
                    </div>
                  )}
                  <TextArea
                    inputType="text"
                    title="Remarks"
                    name="remarks_en"
                    rows="4"
                    content={issueFields.remarks_en}
                    placeholder="Remarks"
                    controlFunc={(e) =>
                      setIssueFields({
                        ...issueFields,
                        remarks_en: e.target.value,
                      })
                    }
                  />
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      Issued No. :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {issueFields.issueNo}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title={
              visiblePendingIndent ? "Issued With Indent" : "Direct Issued"
            }
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <InventoryIssuePopup
            data={currentData}
            data1={currentData1}
            data3={currentData3}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitVisibleButton ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}
export default InventoryIssue;
