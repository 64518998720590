// Code review - In Progress
// Documentation - In Progress
import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { healthCareHeaders } from "../components/HeaderAttribute";
import TextArea from "../ReusableComponents/TextArea";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import HealthCareProviderPopup from "../popup-containers/HealthCareProviderPopup";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  // ImageAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import Buttons from "../components/Buttons";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import FileUpload from "../ReusableComponents/FileUpload";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function HealthCareMaster() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [hcpFields, setHcpFields] = useState({
    date: new Date(),
    healthCareProviderName: "",
    healthCareProviderSpeciality: "",
    healthCareProviderEmail: "",
    healthCareProviderPhoneNumber: "",
    healthCareProviderState: "",
    healthCareProviderLocation: "",
    healthCareProviderSdp: "",
    healthCareProviderArea: "",
    healthCareProviderPincode: "",
    healthCareProviderAddress: "",
    showImage: "",
  });

  console.log("hcpFieldsEdit", hcpFields);

  useEffect(() => {
    if (location.pathname == "/healthcaremaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // getHealthCareProviders();
      cancel();
    }
  }, [location]);
  const [hcpFieldsError, setHcpFieldsError] = useState({
    healthCareProviderNameError: "",
    healthCareProviderSpecialityError: "",
    healthCareProviderEmailError: "",
    healthCareProviderPhoneNumberError: "",
    healthCareProviderStateError: "",
    healthCareProviderLocationError: "",
    healthCareProviderSdpError: "",
    healthCareProviderAreaError: "",
    healthCareProviderPincodeError: "",
    healthCareProviderAddressError: "",
    imageUploadedError: "",
  });
  console.log("hcpFieldsError123", hcpFieldsError);
  console.log("HCP Fields:", hcpFields);
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setHcpFields({ ...hcpFields, date: formattedDate });
    };

    fetchData();
  }, []);

  const handleHcpFieldsChange = (value, name) => {
    console.log("Values:", value, name);
    setHcpFields({
      ...hcpFields,
      [name]: value,
    });
  };
  const [
    bindHealthCareProviderMasterDatas,
    setBindHealthCareProviderMasterDatas,
  ] = useState([]);
  const [bindSpecialityDropDown, setBindSpecialityDropDown] = useState([]);
  const [bindCountry, setBindCountry] = useState([
    { value: "1", label: "America" },
    { value: "2", label: "India" },
    { value: "3", label: "Korea" },
  ]);
  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindLocationDropDown, setBindLocationDropDown] = useState([]);
  const [bindAreaDropDown, setBindAreaDropDown] = useState([]);
  const [bindSdpDropDown, setBindSdpDropDown] = useState([]);
  const [bindLocationDropDown1, setBindLocationDropDown1] = useState([]);
  const [bindAreaDropDown1, setBindAreaDropDown1] = useState([]);
  const [bindSdpDropDown1, setBindSdpDropDown1] = useState([]);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState([]);

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  // const [viewScreenTitle, setViewScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [rowIndex, setRowIndex] = useState("");

  const [mstHealthCareProviderId, setMstHealthCareProviderId] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [popUpDropDownData, setPopUpDropDownData] = useState({});

  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const specialitiesResponse = await BindAPI(
          "HealthCareProviderAPI/GetHealthCareSpeciality"
        );
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");
        const sdpResponse = await BindAPI("SDPAPI/GetSDP");
        const areaResponse = await BindAPI("AreaAPI/GetArea");

        const specialitiesData = specialitiesResponse.data.Table.map(
          (item) => ({
            value: item.MstHealthcareprovidersSpecialityId,
            label: item.Speciality_en,
          })
        );
        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));

        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));

        const sdpData = sdpResponse.data.Table.map((item) => ({
          value: item.MstSDPId,
          label: item.SDP_en,
        }));
        const areaData = areaResponse.data.Table.map((item) => ({
          value: item.MstAreaId,
          label: item.AreaName_en,
        }));

        setBindSpecialityDropDown(specialitiesData);
        setBindStateDropDown(statesData);
        setBindLocationDropDown(locationData);
        setBindSdpDropDown(sdpData);
        setBindAreaDropDown(areaData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    getHealthCareProviders(userProfileDatas);
    dropDownDatas();
  }, []);
  const getHealthCareProviders = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
      };
      const response = await BindPostAPI(
        [data],
        "HealthCareProviderAPI/BindHealthCareProvider"
      );
      setBindHealthCareProviderMasterDatas(response.data.Table);
    }
  };
  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    const getSelectedArea = async () => {
      const locationResponse = await BindAPI("AreaAPI/GetArea");
      setGetAreaDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    getSelectedSDP();
    getSelectedArea();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == hcpFields.healthCareProviderState // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown1(locationSelected);
    } else {
    }
  }, [hcpFields.healthCareProviderState]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == hcpFields.healthCareProviderLocation // Replace with the desired Location
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId, // Replace with the appropriate value
        label: SD.SDP_en,
      }));
      setBindSdpDropDown1(sdpSelected);
    } else {
    }
  }, [hcpFields.healthCareProviderLocation]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = getAreaDatas.find(
      (item) => item.MstSDPId == hcpFields.healthCareProviderSdp // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = getAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));
      setBindAreaDropDown1(areaSelected);
    } else {
    }
  }, [hcpFields.healthCareProviderSdp]);

  useEffect(() => {
    const sortedHealthCareProviderSpeciality = [...bindSpecialityDropDown].sort(
      (a, b) => a.label.localeCompare(b.label)
    );
    setBindSpecialityDropDown(sortedHealthCareProviderSpeciality);
    const sortedStates = [...bindStateDropDown].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindStateDropDown(sortedStates);
    const sortedLocation = [...bindLocationDropDown].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindLocationDropDown(sortedLocation);
    const sortedArea = [...bindAreaDropDown].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindAreaDropDown(sortedArea);
  }, []);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setHcpFields({
      ...hcpFields,
      date: formattedDate,
      healthCareProviderState: userProfileDatas[0].MstStateId,
      healthCareProviderLocation: userProfileDatas[0].MstLocationId,
      healthCareProviderSdp: userProfileDatas[0].MstSDPId,
    });
  };
  const handleClose = () => {
    setpopUpOpen(false);
  };
  const cancel = () => {
    getHealthCareProviders(userProfileDatas);
    setSubmitBtnDisabled(false);
    setHcpFields({
      date: new Date(),
      healthCareProviderName: "",
      healthCareProviderSpeciality: "",
      healthCareProviderEmail: "",
      healthCareProviderPhoneNumber: "",
      healthCareProviderState: "",
      healthCareProviderLocation: "",
      healthCareProviderSdp: "",
      healthCareProviderArea: "",
      healthCareProviderPincode: "",
      healthCareProviderAddress: "",
      showImage: "",
    });

    setHcpFieldsError({
      healthCareProviderNameError: "",
      healthCareProviderSpecialityError: "",
      healthCareProviderEmailError: "",
      healthCareProviderPhoneNumberError: "",
      healthCareProviderStateError: "",
      healthCareProviderLocationError: "",
      healthCareProviderSdpError: "",
      healthCareProviderAreaError: "",
      healthCareProviderPincodeError: "",
      healthCareProviderAddressError: "",
      imageUploadedError: "",
    });
    // setCurrentPage(1);
    // setResult(10);
    setVisibleAdd(false);
    setVisibleTable(true);
    // setSubmitButton(false);
    setAddScreenTitle(false);
    // setUpdateButton(false);
    setUpdateScreenTitle(false);
    setpopUpOpen(false);
  };

  const handleEdit = async (rowIndex) => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    var hcpDate =
      bindHealthCareProviderMasterDatas[rowIndex].HealthcareProviderDate;
    const hcpDatePart = hcpDate.split("T")[0];

    setHcpFields({
      ...hcpFields,
      date: hcpDatePart,
      healthCareProviderName:
        bindHealthCareProviderMasterDatas[rowIndex].HealthcareProviderName_en,
      healthCareProviderSpeciality:
        bindHealthCareProviderMasterDatas[rowIndex]
          .MstHealthcareprovidersSpecialityId,
      healthCareProviderEmail:
        bindHealthCareProviderMasterDatas[rowIndex].HealthcareProviderEmail,
      healthCareProviderPhoneNumber:
        bindHealthCareProviderMasterDatas[rowIndex].HealthcareProviderPhoneNo,
      healthCareProviderState:
        bindHealthCareProviderMasterDatas[rowIndex].MstCountryId,
      healthCareProviderState:
        bindHealthCareProviderMasterDatas[rowIndex].MstStateId,
      healthCareProviderLocation:
        bindHealthCareProviderMasterDatas[rowIndex].MstLocationId,
      healthCareProviderSdp:
        bindHealthCareProviderMasterDatas[rowIndex].MstSDPId,
      healthCareProviderArea:
        bindHealthCareProviderMasterDatas[rowIndex].MstAreaId,
      healthCareProviderPincode:
        bindHealthCareProviderMasterDatas[rowIndex].HealthCarePinCode,
      healthCareProviderAddress:
        bindHealthCareProviderMasterDatas[rowIndex]
          .HealthCareProviderAddress_en,
      showImage:
        bindHealthCareProviderMasterDatas[rowIndex].HealthCareProviderPhoto,
    });
    setMstHealthCareProviderId(
      bindHealthCareProviderMasterDatas[rowIndex].MstHealthCareProviderId
    );
  };
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      const isFormError = {
        ...hcpFieldsError,
        healthCareProviderNameError:
          hcpFields.healthCareProviderName &&
          hcpFields.healthCareProviderName.length > 0
            ? /^[A-Za-z .]+$/.test(hcpFields.healthCareProviderName)
              ? ""
              : "Only Alphabet and space allowed"
            : "Enter Healthcare Provider Name",
        healthCareProviderSpecialityError:
          hcpFields.healthCareProviderSpeciality ? "" : "Select Speciality",
        healthCareProviderEmailError:
          hcpFields.healthCareProviderEmail &&
          hcpFields.healthCareProviderEmail.length > 0
            ? /^[a-z0-9._%-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
                hcpFields.healthCareProviderEmail
              )
              ? ""
              : "Enter Valid Email Id"
            : "Enter Email",
        healthCareProviderPhoneNumberError:
          hcpFields.healthCareProviderPhoneNumber &&
          hcpFields.healthCareProviderPhoneNumber.length > 0
            ? /^[\d\s]+$/.test(hcpFields.healthCareProviderPhoneNumber)
              ? ""
              : "Only numbers allowed"
            : "Enter Phone Number",
        healthCareProviderStateError: hcpFields.healthCareProviderState
          ? ""
          : "Select State",
        healthCareProviderLocationError: hcpFields.healthCareProviderLocation
          ? ""
          : "Select Branch/Project",
        healthCareProviderSdpError: hcpFields.healthCareProviderSdp
          ? ""
          : "Select S.D.P.",
        healthCareProviderAreaError: hcpFields.healthCareProviderArea
          ? ""
          : "Select Area",
        healthCareProviderPincodeError: hcpFields.healthCareProviderPincode
          ? /^[0-9]+$/.test(hcpFields.healthCareProviderPincode)
            ? ""
            : "Enter a valid Zip/Pincode"
          : "Enter Zip/Pincode",
        healthCareProviderAddressError:
          // hcpFields.healthCareProviderAddress &&
          hcpFields.healthCareProviderAddress ? "" : "Enter Address",
        // imageUploadedError: hcpFields.showImage ? "" : "Please Upload an Image",
      };
      setHcpFieldsError(isFormError);
      const isFormValid = Object.values(isFormError).every(
        (error) => error === ""
      );

      if (!isFormValid) {
        setIsOpen(true);
      }

      if (isFormValid) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedTexts = []; // Create an array to store translations
        const translatedAddressTexts = [];

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            hcpFields.healthCareProviderName,
            i
          );
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            hcpFields.healthCareProviderAddress,
            i
          );
          translatedAddressTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const providerPayload = [
          {
            mstHealthCareProviderId: mstHealthCareProviderId
              ? mstHealthCareProviderId
              : 0,
            healthcareProviderNameEn: hcpFields.healthCareProviderName
              ? hcpFields.healthCareProviderName
              : 0,
            healthcareProviderNameTa: translatedTexts[0],
            healthcareProviderNameHi: translatedTexts[1],
            healthcareProviderNameMr: translatedTexts[2],
            healthcareProviderNameGu: translatedTexts[3],
            healthcareProviderNameKn: translatedTexts[4],
            healthcareProviderNameMl: translatedTexts[5],
            healthcareProviderNameTe: translatedTexts[6],
            healthcareProviderNameBn: translatedTexts[7],
            healthcareProviderNameOr: translatedTexts[8],
            mstHealthcareprovidersSpecialityId:
              hcpFields.healthCareProviderSpeciality,
            healthcareProviderEmail: hcpFields.healthCareProviderEmail,
            healthcareProviderPhoneNo: hcpFields.healthCareProviderPhoneNumber,
            mstCountryId: 1,
            mstStateId: hcpFields.healthCareProviderState,
            mstLocationId: hcpFields.healthCareProviderLocation,
            mstSDPId: hcpFields.healthCareProviderSdp,
            mstAreaId: hcpFields.healthCareProviderArea,
            healthCareProviderAddressEn: hcpFields.healthCareProviderAddress,
            healthCareProviderAddressTa: translatedAddressTexts[0],
            healthCareProviderAddressHi: translatedAddressTexts[1],
            healthCareProviderAddressGu: translatedAddressTexts[2],
            healthCareProviderAddressMr: translatedAddressTexts[3],
            healthCareProviderAddressKn: translatedAddressTexts[4],
            healthCareProviderAddressMl: translatedAddressTexts[5],
            healthCareProviderAddressTe: translatedAddressTexts[6],
            healthCareProviderAddressBn: translatedAddressTexts[7],
            healthCareProviderAddressOr: translatedAddressTexts[8],
            healthCarePinCode: hcpFields.healthCareProviderPincode,
            healthCareProviderPhoto: hcpFields.showImage,
            healthcareProviderDate: hcpFields.date,
            createdBy: createdBy,
            flag: flag,
          },
        ];

        const response = await IUDAPI(
          providerPayload,
          "HealthCareProviderAPI/IUDHealthCareProvider"
        );
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Healthcare provider added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Healthcare provider data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Healthcare provider updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Healthcare provider data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else if (flag == 3 || flag == 4 || flag == 5) {
      const providerPayload = [
        {
          mstHealthCareProviderId:
            bindHealthCareProviderMasterDatas[items].MstHealthCareProviderId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(
        providerPayload,
        "HealthCareProviderAPI/IUDHealthCareProvider"
      );
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Healthcare provider data deleted successfully 🗑️",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to delete Healthcare provider data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            "Healthcare provider data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Healthcare provider data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Healthcare provider data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Healthcare provider data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    } else {
      console.log("Enter all mandatory fields");
      // return false;
    }
  };

  const handleHCPDetails = (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setCurrentData(bindHealthCareProviderMasterDatas[rowIndex]);
    // setShowImage(bindHealthCareProviderMasterDatas[rowIndex].HealthCareProviderPhoto);

    const healthCareProviderData = bindHealthCareProviderMasterDatas[rowIndex];
    const healthCareProviderSpecialityDropDownValue =
      bindSpecialityDropDown.find(
        (item) =>
          item.value ==
          healthCareProviderData.MstHealthcareprovidersSpecialityId
      );
    const editHealthcareProviderSpecialityDropDownValue = {
      value: healthCareProviderData.MstHealthcareprovidersSpecialityId,
      label: healthCareProviderSpecialityDropDownValue
        ? healthCareProviderSpecialityDropDownValue.label
        : "Unknown",
    };

    //State DropDown Value Start
    const healthCareProviderStateDropDownValue = bindStateDropDown.find(
      (item) => item.value === healthCareProviderData.MstStateId
    );
    const editHealthCareProviderStateDropDownValue = {
      value: healthCareProviderData.MstStateId,
      label: healthCareProviderStateDropDownValue
        ? healthCareProviderStateDropDownValue.label
        : "Unknown",
    };

    //State DropDown Value End

    //Location DropDown Start
    const healthCareProviderLocationDropDownValue = bindLocationDropDown.find(
      (item) => item.value === healthCareProviderData.MstLocationId
    );

    const editHealthcareProviderLocationDropDownvalue = {
      value: healthCareProviderData.MstLocationId,
      label: healthCareProviderLocationDropDownValue
        ? healthCareProviderLocationDropDownValue.label
        : "Unknown",
    };

    //Location DropDown End

    //SDP DropDown Start

    const healthCareProviderSdpDropDownValue = bindSdpDropDown.find(
      (item) => item.value === healthCareProviderData.MstSDPId
    );

    const editHealthcareProviderSdpDropDownValue = {
      value: healthCareProviderData.MstSDPId,
      label: healthCareProviderSdpDropDownValue
        ? healthCareProviderSdpDropDownValue.label
        : "Unknown",
    };

    //SDP DropDown End

    //Area DropDown Start

    const healthCareProviderAreaDropDown = bindAreaDropDown.find(
      (item) => item.value === healthCareProviderData.MstAreaId
    );

    const editHealthCareProviderAreaDropDownValue = {
      value: healthCareProviderData.MstAreaId,
      label: healthCareProviderAreaDropDown
        ? healthCareProviderAreaDropDown.label
        : "Unknown",
    };
    var hcpDate =
      bindHealthCareProviderMasterDatas[rowIndex].HealthcareProviderDate;
    const hcpDatePart = hcpDate.split("T")[0];

    const autoDropDownValues = [
      editHealthcareProviderSpecialityDropDownValue,
      editHealthCareProviderStateDropDownValue,
      editHealthcareProviderLocationDropDownvalue,
      editHealthcareProviderSdpDropDownValue,
      editHealthCareProviderAreaDropDownValue,
      hcpDatePart,
    ];
    setPopUpDropDownData(autoDropDownValues);
  };

  const handleShowImage = (showImage) => {
    //
    console.log("image", showImage);
    
      setHcpFields({
        ...hcpFields,
        showImage: showImage || "",
      });
    setHcpFieldsError({ ...hcpFieldsError, imageUploadedError: "" });
  };
  console.log("pageRefresed");
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Healthcare provider"
          header={healthCareHeaders}
          records={bindHealthCareProviderMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleHCPDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Healthcare provider"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <InputText
                      inputType="date"
                      title="Date"
                      classStar="text-red-500"
                      name="date"
                      content={hcpFields.date}
                      controlFunc={(e) =>
                        setHcpFields({ ...hcpFields, date: e.target.value })
                      }
                    />
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Healthcare Provider Name"
                        name="healthCareProviderName"
                        content={hcpFields.healthCareProviderName}
                        maxLength={50}
                        controlFunc={
                          (e) =>
                            setHcpFields({
                              ...hcpFields,
                              healthCareProviderName: e.target.value,
                            })
                          // setHealthCareProviderName(e.target.value)
                        }
                        controlFuncBlur={() => {
                          setHcpFieldsError((hcpFieldsError) => ({
                            ...hcpFieldsError,
                            healthCareProviderNameError:
                              hcpFields.healthCareProviderName &&
                              hcpFields.healthCareProviderName.length > 0
                                ? /^[A-Za-z . ]+$/.test(
                                    hcpFields.healthCareProviderName
                                  )
                                  ? ""
                                  : "Only Alphabet and space allowed"
                                : "Enter Healthcare Provider Name",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderNameError
                        }
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="Speciality"
                        classStar="text-red-500"
                        options={bindSpecialityDropDown}
                        placeholder="Select"
                        name="healthCareProviderSpeciality"
                        selectedOption={bindSpecialityDropDown.find(
                          (x) =>
                            x.value == hcpFields.healthCareProviderSpeciality
                        )}
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleHcpFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setHcpFieldsError(() => ({
                            ...hcpFieldsError,
                            healthCareProviderSpecialityError:
                              hcpFields.healthCareProviderSpeciality
                                ? ""
                                : "Select Speciality",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderSpecialityError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Email"
                        name="healthCareProviderEmail"
                        content={hcpFields.healthCareProviderEmail}
                        controlFunc={
                          (e) =>
                            setHcpFields({
                              ...hcpFields,
                              healthCareProviderEmail: e.target.value,
                            })
                          // setHealthCareProviderEmail(e.target.value)
                        }
                        controlFuncBlur={(e) => {
                          setHcpFieldsError((hcpFieldsError) => ({
                            ...hcpFieldsError,
                            healthCareProviderEmailError:
                              hcpFields.healthCareProviderEmail &&
                              hcpFields.healthCareProviderEmail.length > 0
                                ? /^[a-z0-9._%-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
                                    hcpFields.healthCareProviderEmail
                                  )
                                  ? ""
                                  : "Enter Valid Email Id"
                                : "Enter Email",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderEmailError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Phone Number"
                        name="healthCareProviderPhoneNumber"
                        maxLength={11}
                        content={hcpFields.healthCareProviderPhoneNumber}
                        controlFunc={
                          (e) =>
                            setHcpFields({
                              ...hcpFields,
                              healthCareProviderPhoneNumber: e.target.value,
                            })
                          // setHealthCareProviderPhoneNumber(e.target.value)
                        }
                        controlFuncBlur={(e) => {
                          setHcpFieldsError((hcpFieldsError) => ({
                            ...hcpFieldsError,
                            healthCareProviderPhoneNumberError:
                              hcpFields.healthCareProviderPhoneNumber &&
                              hcpFields.healthCareProviderPhoneNumber.length > 0
                                ? /^[\d\s]+$/.test(
                                    hcpFields.healthCareProviderPhoneNumber
                                  )
                                  ? ""
                                  : "Enter a valid phone number"
                                : "Enter Phone Number",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderPhoneNumberError
                        }
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="State"
                        classStar="text-red-500"
                        options={bindStateDropDown}
                        placeholder="Select"
                        Class="p-0"
                        name="healthCareProviderState"
                        selectedOption={bindStateDropDown.find(
                          (x) => x.value == hcpFields.healthCareProviderState
                        )}
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleHcpFieldsChange(value.value, e.name);
                          setHcpFields({
                            ...hcpFields,
                            healthCareProviderState: value.value,
                            healthCareProviderLocation: "",
                            healthCareProviderSdp: "",
                            healthCareProviderArea: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setHcpFieldsError(() => ({
                            ...hcpFieldsError,
                            healthCareProviderStateError:
                              hcpFields.healthCareProviderState
                                ? ""
                                : "Select State",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderStateError
                        }
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="Branch/Project"
                        classStar="text-red-500"
                        options={bindLocationDropDown && bindLocationDropDown1}
                        placeholder="Select"
                        name="healthCareProviderLocation"
                        selectedOption={
                          hcpFields.healthCareProviderLocation
                            ? bindLocationDropDown.find(
                                (x) =>
                                  x.value ==
                                  hcpFields.healthCareProviderLocation
                              )
                            : ""
                        }
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleHcpFieldsChange(value.value, e.name);
                          setHcpFields({
                            ...hcpFields,
                            healthCareProviderLocation: value.value,
                            healthCareProviderSdp: "",
                            healthCareProviderArea: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setHcpFieldsError({
                            ...hcpFieldsError,
                            healthCareProviderLocationError:
                              hcpFields.healthCareProviderLocation
                                ? ""
                                : "Select Branch/Project",
                          });
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderLocationError
                        }
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="S.D.P."
                        classStar="text-red-500"
                        options={bindSdpDropDown && bindSdpDropDown1}
                        placeholder="Select"
                        name="healthCareProviderSdp"
                        selectedOption={
                          hcpFields.healthCareProviderSdp
                            ? bindSdpDropDown.find(
                                (x) =>
                                  x.value == hcpFields.healthCareProviderSdp
                              )
                            : ""
                        }
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleHcpFieldsChange(value.value, e.name);
                          setHcpFields({
                            ...hcpFields,
                            healthCareProviderSdp: value.value,
                            healthCareProviderArea: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setHcpFieldsError({
                            ...hcpFieldsError,
                            healthCareProviderSdpError:
                              hcpFields.healthCareProviderSdp
                                ? ""
                                : "Select S.D.P.",
                          });
                        }}
                        errorMessage={hcpFieldsError.healthCareProviderSdpError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="Area"
                        classStar="text-red-500"
                        options={bindAreaDropDown && bindAreaDropDown1}
                        placeholder="Select"
                        name="healthCareProviderArea"
                        selectedOption={
                          hcpFields.healthCareProviderArea
                            ? bindAreaDropDown.find(
                                (x) =>
                                  x.value == hcpFields.healthCareProviderArea
                              )
                            : ""
                        }
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleHcpFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setHcpFieldsError({
                            ...hcpFieldsError,
                            healthCareProviderAreaError:
                              hcpFields.healthCareProviderArea
                                ? ""
                                : "Select Area",
                          });
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderAreaError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Zip/Pincode"
                        name="healthCareProviderPincode"
                        content={hcpFields.healthCareProviderPincode}
                        maxLength={6}
                        controlFunc={(e) =>
                          setHcpFields({
                            ...hcpFields,
                            healthCareProviderPincode: e.target.value,
                          })
                        }
                        controlFuncBlur={() => {
                          setHcpFieldsError(() => ({
                            ...hcpFieldsError,
                            healthCareProviderPincodeError:
                              hcpFields.healthCareProviderPincode &&
                              hcpFields.healthCareProviderPincode.length > 0
                                ? /^[0-9]+$/.test(
                                    hcpFields.healthCareProviderPincode
                                  )
                                  ? ""
                                  : "Enter a valid Zip/Pincode"
                                : "Enter Zip/Pincode",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderPincodeError
                        }
                      />
                    </div>
                    <div>
                      <TextArea
                        inputType="text"
                        classStar="text-red-500"
                        title="Address"
                        maxLength={500}
                        name="healthCareProviderAddress"
                        content={hcpFields.healthCareProviderAddress}
                        controlFunc={(e) =>
                          setHcpFields({
                            ...hcpFields,
                            healthCareProviderAddress: e.target.value,
                          })
                        }
                        controlFuncBlur={() => {
                          setHcpFieldsError(() => ({
                            ...hcpFieldsError,
                            healthCareProviderAddressError:
                              hcpFields.healthCareProviderAddress &&
                              hcpFields.healthCareProviderAddress
                                ? ""
                                : "Enter Address",
                          }));
                        }}
                        errorMessage={
                          hcpFieldsError.healthCareProviderAddressError
                        }
                      />
                    </div>
                  </div>
                  <FileUpload
                    title="Photo"
                    apiCall="HealthCareProviderAPI/HealthCareProviderImageUpload"
                    modalName="HealthCareProviderImage"
                    showImage={hcpFields.showImage}
                    disabledBtn={updateScreenTitle}
                    showImageData={handleShowImage}
                    imageUploadedError={hcpFieldsError.imageUploadedError}
                    limitMassage={
                      "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                    }
                    MAX_FILE_SIZE_MB={2}
                    ALLOWED_FORMATS={["image/jpeg", "image/jpg", "image/png"]}
                  />
                </form>

                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleHCPDetails}
      >
        <div>
          <PopUpScreenTitle
            title="Healthcare provider"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <HealthCareProviderPopup
            data={currentData}
            data2={popUpDropDownData}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default HealthCareMaster;
