// Code review - In Progress
// Documentation - In Progress
import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import { SingleSessionHeader } from "../components/HeaderAttribute";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import FileUpload from "../ReusableComponents/FileUpload";
import RadioGroup from "../ReusableComponents/RadioButton";
import SingleSeassionPopup from "../popup-containers/SinglesessionPopup";
import { useUserCapability } from "../components/ConstantReducerProvider";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function SingleSession() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const [bindSEOData, setBindSEOData] = useState([]);
  const [bindResourcePersonName, setBindResourcePersonName] = useState([]);
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);
  const [editMode, setEditMode] = useState([]);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const userProfileDatas = userProfileState.userProfile;
  const [SSOFields, setSSOFields] = useState({
    date: new Date(),
    startTime: getCurrentTime(),
    endTime: getCurrentTime(),
    totalParticipants: "",
    remarks: "",
    resourcePersonName1: "",
    resourcePersonName2: "",
    showImage: "",
    maleChild: "0",
    femaleChild: "",
    otherChild: "",
    venue: "",
    eventNo: "CSESS",
    singleSessionType: "",
    branch: "",
  });
  console.log("singleSessionType", SSOFields.singleSessionType);

  const [SSOFieldsError, setSSOFieldsError] = useState({
    eventNoError: "",
    resourcePersonNameError: "",
    totalParticipantsError: "",
    imageUploadedError: "",
    singleSessionTypeError: "",
    ageError: "",
  });
  const handleInSchoolFieldsChange = (value, name) => {
    setSSOFields({
      ...SSOFields,
      [name]: value,
    });
  };
  const [editMstSingleseassionOnlineId, setEditMstSingleseassionOnlineId] =
    useState("");
  const [currentData, setCurrentData] = useState({});
  const [currentData2, setCurrentData2] = useState({});

  const handleOpen = (isOpen) => {
    // Popup open
    setpopUpOpen(isOpen);
  };

  const handleClose = () => {
    // Popup close
    setpopUpOpen(false);
  };
  const getBranch = (userProfileDatas) => {
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setSSOFields({
        ...SSOFields,
        branch: userProfileDatas[0].MstLocationId,
      });
    } else {
      // handle else case if needed
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, []);
  const showadd = () => {
    getBranch(userProfileDatas);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setSSOFields((SSOFields) => ({
      ...SSOFields,
      startTime: getCurrentTime(),
    }));
    setSSOFields((SSOFields) => ({
      ...SSOFields,
      endTime: getCurrentTime(),
    }));
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setSSOFields((SSOFields) => ({
      ...SSOFields,
      date: formattedDate,
    }));
    setAlertsinglesessionvalue("");
  };

  const cancel = () => {
    BindSEOGridData(userProfileDatas);
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    handleOpen(false);
    setSSOFields({
      date: "",
      startTime: "",
      endTime: "",
      totalParticipants: "",
      remarks: "",
      resourcePersonName1: "",
      resourcePersonName2: "",
      showImage: "",
      maleChild: "",
      femaleChild: "",
      otherChild: "",
      venue: "",
      eventNo: "CSESS",
      singleSessionType: "",
      branch: "",
    });

    setSSOFieldsError({
      eventNoError: "",
      resourcePersonNameError: "",
      totalParticipantsError: "",
      imageUploadedError: "",
      singleSessionTypeError: "",
    });
  };

  useEffect(() => {
    BindSEOGridData(userProfileDatas);
    bindDropdownData();
    cancel();
  }, []);
  const bindDropdownData = async () => {
    try {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        const data = {
          mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          mstBranchId: userProfileDatas[0].MstLocationId,
        };
        const response = await BindPostAPI(
          [data],
          "ResourcesAPI/BindResources"
        );

        if (response.data) {
          const ResourcePersonNameDD =
            response.data.Table.length > 0
              ? response.data.Table.map((m) => ({
                  value: m.MstResourcesId,
                  label: m.ResourcesName,
                }))
              : [];
          setBindResourcePersonName(ResourcePersonNameDD);
        } else {
          setBindResourcePersonName([]);
        }
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      // Handle error cases for each dropdown if needed
    }
  };
  const BindSEOGridData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        // setMstBranchId(userProfileDatas[0].MstLocationId);

        console.log("branch", userProfileDatas[0]);
        // setLocation(userProfileDatas[0].MstLocationId);
      } else {
        // handle else case if needed
      }
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "SingleSessionAPI/BindSingleSessionData"
      );
      if (response && response.data && response.data.Table) {
        const GridDatafilteredData = Object.values(response.data.Table);
        setBindSEOData(GridDatafilteredData);
      } else {
        setBindSEOData([]);
      }
    }
  };

  //   const BindSEOGridData = async () => {
  //     try {
  //       const response = await BindAPI("SingleSessionAPI/BindSingleSessionData");
  //       const GridDatafilteredData = Object.values(response.data.Table);
  //       setBindSEOData(GridDatafilteredData);
  //       console.log("GridDatafilteredData", GridDatafilteredData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // Handle the error as needed
  //     }
  //   };

  const handleEdit = (rowIndex) => {
    setEditMode(true);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    const bindSEOId = bindSEOData[rowIndex].txn_singlesessionId;
    setEditMstSingleseassionOnlineId(bindSEOData[rowIndex].txn_singlesessionId);
    handleFromDropdownChangeValue(bindSEOData[rowIndex].SessionType);
    const currentDate = bindSEOData[rowIndex].SEODate;
    const formattedDate = currentDate.split("T")[0];
    setSSOFields({
      ...SSOFields,
      date: formattedDate,
      startTime: bindSEOData[rowIndex].SEOStartTime,
      endTime: bindSEOData[rowIndex].SEOEndTime,
      branch: bindSEOData[rowIndex].MstBranchId,
      totalParticipants: bindSEOData[rowIndex].SEONoOfParticipants.toString(),
      remarks: bindSEOData[rowIndex].SEORemarks,
      resourcePersonName1: bindSEOData[rowIndex].SEOResourcesPerson1,
      resourcePersonName2: bindSEOData[rowIndex].SEOResourcesPerson2,
      showImage: bindSEOData[rowIndex].SEOPhoto,
      maleChild: bindSEOData[rowIndex].SEOMaleChild.toString(),
      femaleChild: bindSEOData[rowIndex].SEOFemaleChild.toString(),
      otherChild: bindSEOData[rowIndex].SEOOtherChild.toString(),
      venue: bindSEOData[rowIndex].SEOVenue,
      eventNo: bindSEOData[rowIndex].EventNo,
      singleSessionType: bindSEOData[rowIndex].SessionType,
    });

    const filterRadiotype = singleSessionTypeDD.filter(
      (x) => x.value == bindSEOData[rowIndex].SessionType
    );
    if (filterRadiotype.length > 0) {
      const filterRadiotypelabel = filterRadiotype[0].label;
      setAlertsinglesessionvalue(filterRadiotypelabel);
    } else {
      // Handle the case where no match is found, if necessary
      setAlertsinglesessionvalue("");
    }
  };

  const HandleICPDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(true);
      setRowIndex(rowIndex);
    }

    setCurrentData(bindSEOData[rowIndex]);
    const EbindSEOId = bindSEOData[rowIndex].txn_singlesessionId;
    setEditMstSingleseassionOnlineId(EbindSEOId);
    let SEODates = "";
    var SDate = bindSEOData[rowIndex]?.SEODate; // Add nullish coalescing operator
    if (SDate !== null && SDate !== undefined) {
      SEODates = SDate.split("T")[0];
    } else {
      SEODates = "";
    }
    const filterRadiotype = singleSessionTypeDD.filter(
      (x) => x.value == bindSEOData[rowIndex].SessionType
    );
    if (filterRadiotype.length > 0) {
      const filterRadiotypelabel = filterRadiotype[0].label;
      setAlertsinglesessionvalue(filterRadiotypelabel);
    } else {
      // Handle the case where no match is found, if necessary
      setAlertsinglesessionvalue("");
    }

    const SingleSeassionPopupData2 = [SEODates];
    console.log("SEODatespopup", SEODates);
    setCurrentData2(SingleSeassionPopupData2);
  };

  const [alertsinglesessionvalue, setAlertsinglesessionvalue] = useState("");

  const handleSubmit = async (rowIndex, flag) => {

    var createdBy = sessionStorage.getItem("createdBy");
    if (flag == 1 || flag == 2) {
      const male = parseInt(SSOFields.maleChild || "0", 10);
      const female = parseInt(SSOFields.femaleChild || "0", 10);
      const other = parseInt(SSOFields.otherChild || "0", 10);
      const totalParticipants = parseInt(
        SSOFields.totalParticipants || "0",
        10
      );

      const ageTotal = male + female + other;

      const isFormError = {
        ...SSOFieldsError,
        resourcePersonNameError: SSOFields.resourcePersonName1
          ? ""
          : "Select Resource Person Name1",

        // ageError:
        //   SSOFields.maleChild != "" && SSOFields.femaleChild != "" && SSOFields.otherChild != ""
        //     ? parseInt(SSOFields.maleChild,10) || 0 +
        //         parseInt(SSOFields.femaleChild,10) || 0 +
        //         parseInt(SSOFields.otherChild,10) || 0 ===
        //       parseInt(SSOFields.totalParticipants)
        //       ? ""
        //       : "Age (estimated) total (Estimated) and Total No. Of Participants (Estimated) be equal"
        //     : "Enter Age (Estimated)",
        ageError:
          ageTotal === totalParticipants
            ? ""
            : "Age (Estimated) total and Total No. Of Participants must be equal",

        // totalParticipantsError: SSOFields.totalParticipants
        //   ? /^[0-9]+$/.test(SSOFields.totalParticipants)
        //     ? parseInt(SSOFields.maleChild,10)  || 0 +
        //         parseInt(SSOFields.femaleChild,10)  || 0 +
        //         parseInt(SSOFields.otherChild ,10)  || 0  ===
        //       parseInt(SSOFields.totalParticipants,10)
        //       ? ""
        //       : "Total No. Of Participants (Estimated) and Age (estimated) total be equal"
        //     : "Only Number allowed"
        //   : "Enter Total No. Of Participants",

        totalParticipantsError: SSOFields.totalParticipants
          ? /^[0-9]+$/.test(SSOFields.totalParticipants.trim())
            ? ""
            : "Only numbers are allowed"
          : "Enter Total No. Of Participants",

        singleSessionTypeError:
          SSOFields.singleSessionType && SSOFields.singleSessionType.length > 0
            ? ""
            : "Enter Single Session Type",

        imageUploadedError: SSOFields.showImage ? "" : "Please Upload an Image",
      };
      setSSOFieldsError(isFormError);
      const isFormValid = Object.values(isFormError).every(
        (error) => error === ""
      );

      if (!isFormValid) {
        setIsOpen(true);
      }

      if (isFormValid) {
        setIsOpen(false);
        setSubmitBtnDisabled(true);
        const currentDate = new Date().toISOString().split("T")[0];

        var SessionFdate = "2023-10-22";
        var fromTimes = SessionFdate + "T" + SSOFields.startTime;
        var toTimes = SessionFdate + "T" + SSOFields.endTime;

        const payload = {
          txn_singlesessionId: editMstSingleseassionOnlineId
            ? editMstSingleseassionOnlineId
            : 0,
          mstBranchId: SSOFields.branch ? SSOFields.branch : 0,
          eventNo: SSOFields.eventNo,
          seoDate: SSOFields.date,
          seoStartTime: fromTimes,
          seoEndTime: toTimes,
          seoResourcesPerson1: SSOFields.resourcePersonName1,
          seoResourcesPerson2: SSOFields.resourcePersonName2
            ? SSOFields.resourcePersonName2
            : null,
          seoNoOfParticipants: SSOFields.totalParticipants,
          maleChild: SSOFields.maleChild ? SSOFields.maleChild : "0",
          femaleChild: SSOFields.femaleChild ? SSOFields.femaleChild : "0",
          otherChild: SSOFields.otherChild ? SSOFields.otherChild : "0",
          sessionType: SSOFields.singleSessionType,
          seoVenue: SSOFields.venue,
          singleSessionPhoto: SSOFields.showImage,
          seoRemarks: SSOFields.remarks,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [payload],
          "SingleSessionAPI/IUDSingleSession"
        );
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success(
              `Single Session ${alertsinglesessionvalue} data added successfully 👍`,
              {
                duration: 5000,
                position: "top-center",
              }
            );
            setSubmitVisibleButton(true);
            setSSOFields({
              ...SSOFields,
              eventNo: response.data.Table1[0].EventNo,
            });
            setTimeout(cancel, 10000);
          } else {
            toast.error(
              `Failed to add Single Session ${alertsinglesessionvalue} data 👎`,
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "U") {
            toast.success(
              `Single Session ${alertsinglesessionvalue} data updated successfully 👍`,
              {
                duration: 5000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 5000);
          } else {
            toast.error(
              `Failed to update Single Session ${alertsinglesessionvalue} data 👎`,
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(true);
      const payload = {
        txn_singlesessionId: bindSEOData[rowIndex].txn_singlesessionId
          ? bindSEOData[rowIndex].txn_singlesessionId
          : editMstSingleseassionOnlineId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI(
        [payload],
        "SingleSessionAPI/IUDSingleSession"
      );
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            `Single Session ${alertsinglesessionvalue} data deleted successfully 👍`,
            {
              duration: 5000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 5000);
        } else {
          toast.error(
            `Failed to delete Single Session ${alertsinglesessionvalue} data 👎`,
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            `Single Session ${alertsinglesessionvalue} data blocked successfully 👍`,
            {
              duration: 5000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 5000);
        } else {
          toast.error(
            `Failed to block Single Session ${alertsinglesessionvalue} data 👎`,
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            `Single Session ${alertsinglesessionvalue} data unblocked successfully 👍`,
            {
              duration: 5000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 5000);
        } else {
          toast.error(
            `Failed to unblock Single Session ${alertsinglesessionvalue} data 👎`,
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else {
        setSubmitBtnDisabled(true);
      }
    }
  };

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const [singleSessionTypeDD, setConsultedDoctorDD] = useState([
    { value: "1", label: "Online" },
    { value: "2", label: "Offline" },
  ]);

  const [venueofflineType, setVenueofflineType] = useState(false);

  const handleFromDropdownChangeValue = (selectedValue) => {
    if (selectedValue == "2") {
      setVenueofflineType(true);
      setSSOFields({
        ...SSOFields,
        singleSessionType: selectedValue,
      });
    } else {
      setVenueofflineType(false);
      setSSOFields({
        ...SSOFields,
        singleSessionType: selectedValue,
      });
    }

    const filterRadiotype = singleSessionTypeDD.filter(
      (x) => x.value == selectedValue
    );
    if (filterRadiotype.length > 0) {
      const filterRadiotypelabel = filterRadiotype[0].label;
      setAlertsinglesessionvalue(filterRadiotypelabel);
    } else {
      // Handle the case where no match is found, if necessary
      setAlertsinglesessionvalue("");
    }
  };

  const handleShowImage = (showImage) => {
    console.log("image", showImage);
    // showImage &&
      setSSOFields({
        ...SSOFields,
        showImage: showImage || "",
      });
    setSSOFieldsError({ ...SSOFieldsError, imageUploadedError: "" });
  };

  useEffect(() => {
    if (location.pathname == "/singlesession") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      BindSEOGridData(userProfileDatas);
    }
  }, [location, userProfileDatas]);

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title={`Single session`}
          header={SingleSessionHeader}
          records={bindSEOData}
          handleEdit={handleEdit}
          handleAction={HandleICPDetails}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title={`Single session ${alertsinglesessionvalue}`}
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form class="space-y-6" action="#">
                  <div>
                    <div className={mainTable.grid3}>
                      <div>
                        <InputText
                          inputType="text"
                          title="Event no."
                          classStar="text-red-500"
                          name="pincode"
                          placeholder="MUM/CSEOO/2024/001"
                          content={SSOFields.eventNo}
                          controlFunc={(e) =>
                            setSSOFields({
                              ...SSOFields,
                              eventNo: e.target.value,
                            })
                          }
                          disabled={true}
                        />
                      </div>
                      <div>
                        <InputText
                          inputType="date"
                          title="Date"
                          classStar="text-red-500"
                          name="date"
                          content={SSOFields.date}
                          controlFunc={(e) =>
                            setSSOFields({
                              ...SSOFields,
                              date: e.target.value,
                            })
                          }
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                      <div>
                        <InputText
                          inputType="time"
                          title="Start Time"
                          classStar="text-red-500"
                          name="startTime"
                          content={SSOFields.startTime}
                          controlFunc={(e) =>
                            setSSOFields({
                              ...SSOFields,
                              startTime: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <InputText
                          inputType="time"
                          title="End Time"
                          classStar="text-red-500"
                          name="endTime"
                          content={SSOFields.endTime}
                          controlFunc={(e) =>
                            setSSOFields({
                              ...SSOFields,
                              endTime: e.target.value,
                            })
                          }
                        />
                      </div>
                      <AutoDropDown
                        Class="Custom-class"
                        title={`Resource Person Name1`}
                        options={bindResourcePersonName}
                        classStar="text-red-500"
                        placeholder="Select"
                        name="resourcePersonName1"
                        selectedOption={bindResourcePersonName.find(
                          (x) => x.value == SSOFields.resourcePersonName1
                        )}
                        controlFunc={(value, e) =>
                          handleInSchoolFieldsChange(value.value, e.name)
                        }
                        controlFuncBlur={() => {
                          setSSOFieldsError(() => ({
                            ...SSOFieldsError,
                            resourcePersonNameError:
                              SSOFields.resourcePersonName1
                                ? ""
                                : "Select Resource Person Name1",
                          }));
                        }}
                        errorMessage={SSOFieldsError.resourcePersonNameError}
                      />
                      <AutoDropDown
                        Class="Custom-class"
                        title={`Resource Person Name2`}
                        options={bindResourcePersonName}
                        placeholder="Select"
                        name="resourcePersonName2"
                        selectedOption={bindResourcePersonName.find(
                          (x) => x.value == SSOFields.resourcePersonName2
                        )}
                        controlFunc={(value, e) =>
                          handleInSchoolFieldsChange(value.value, e.name)
                        }
                      />
                      {/* <div>
                        <InputText
                          title="Resource Person Name"
                          type="text"
                          classStar="text-red-500"
                          name="resourceName"
                          maxLength={500}
                          placeholder="Resource Person Name"
                          content={SSOFields.resourceName}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            setSSOFields({
                              ...SSOFields,
                              resourceName: value,
                            });
                            setSSOFieldsError((SSOFieldsError) => ({
                              ...SSOFieldsError,
                              resourceNameError:
                                value.length > 0
                                  ? /^[A-Za-z,]+(?:\s[A-Za-z,]+)*$/.test(
                                      value.trim()
                                    )
                                    ? ""
                                    : "Only Alphabet, single space and , allowed"
                                  : "Enter Resource Person Name",
                            }));
                          }}
                          controlFuncBlur={(e) => {
                            const trimmedValue = e.target.value.trim();
                            setSSOFieldsError((SSOFieldsError) => ({
                              ...SSOFieldsError,
                              resourceNameError:
                                trimmedValue.length > 0
                                  ? /^[A-Za-z,]+(?:\s[A-Za-z,]+)*$/.test(
                                      trimmedValue
                                    )
                                    ? ""
                                    : "Only Alphabet, single space and , allowed"
                                  : "Enter Resource Person Name",
                            }));
                          }}
                          errorMessage={SSOFieldsError.resourceNameError}
                        />
                      </div> */}
                      <div>
                        <InputText
                          inputType="text"
                          title="Total No. Of Participants (Estimated)"
                          classStar="text-red-500"
                          name="totalParticipants"
                          placeholder="1"
                          maxLength={5}
                          content={SSOFields.totalParticipants}
                          controlFunc={(e) =>
                            setSSOFields({
                              ...SSOFields,
                              totalParticipants: e.target.value.trim(),
                            })
                          }
                          // controlFuncBlur={(e) => {
                          //   setSSOFieldsError((SSOFieldsError) => ({
                          //     ...SSOFieldsError,
                          //     totalParticipantsError:
                          //       SSOFields.totalParticipants
                          //         ? /^[0-9]+$/.test(
                          //             SSOFields.totalParticipants || "".trim()
                          //           )
                          //           ? ""
                          //           : "Only Number allowed"
                          //         : "Enter Total No.Of Participants",
                          //   }));
                          // }}
                          controlFuncBlur={(e) => {
                            const totalParticipants = parseInt(
                              SSOFields.totalParticipants || "0",
                              10
                            );
                            const male = parseInt(
                              SSOFields.maleChild || "0",
                              10
                            );
                            const female = parseInt(
                              SSOFields.femaleChild || "0",
                              10
                            );
                            const other = parseInt(
                              SSOFields.otherChild || "0",
                              10
                            );

                            const isValid =
                              male + female + other === totalParticipants;

                            setSSOFieldsError((prevErrors) => ({
                              ...prevErrors,
                              totalParticipantsError: isValid
                                ? ""
                                : "Age (Estimated) total and Total No. Of Participants must be equal",
                              ageError: isValid ? "" : prevErrors.ageError,
                            }));
                          }}
                          errorMessage={SSOFieldsError.totalParticipantsError}
                        />
                      </div>
                      <div className="col-span-1">
                        <label for="client_name" class={mainTable.label}>
                          Age (estimated)
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="grid gap-2 md:grid-cols-3 flex items-center bg-gray-50 p-1 border border-gray-300 rounded-lg dark:border-gray-500">
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title=" 10-14"
                            maxLength={5}
                            name={"maleChild"}
                            content={SSOFields.maleChild}
                            controlFunc={(e) =>
                              setSSOFields({
                                ...SSOFields,
                                maleChild: e.target.value.trim(),
                              })
                            }
                            // controlFuncBlur={(e) => {
                            //   setSSOFieldsError((SSOFieldsError) => ({
                            //     ...SSOFieldsError,
                            //     ageError: SSOFields.maleChild.length > 0,
                            //   }));
                            // }}
                            controlFuncBlur={(e) => {
                              const totalParticipants = parseInt(
                                SSOFields.totalParticipants || "0",
                                10
                              );
                              const male = parseInt(
                                SSOFields.maleChild || "0",
                                10
                              );
                              const female = parseInt(
                                SSOFields.femaleChild || "0",
                                10
                              );
                              const other = parseInt(
                                SSOFields.otherChild || "0",
                                10
                              );

                              const isValid =
                                male + female + other === totalParticipants;

                              setSSOFieldsError((prevErrors) => ({
                                ...prevErrors,
                                ageError: isValid
                                  ? ""
                                  : "Age (Estimated) total and Total No. Of Participants must be equal",
                                totalParticipantsError: isValid
                                  ? ""
                                  : prevErrors.totalParticipantsError,
                              }));
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title="15-19"
                            maxLength={5}
                            name={"femaleChild"}
                            content={SSOFields.femaleChild}
                            controlFunc={(e) =>
                              setSSOFields({
                                ...SSOFields,
                                femaleChild: e.target.value.trim(),
                              })
                            }
                            // controlFuncBlur={(e) => {
                            //   setSSOFieldsError((SSOFieldsError) => ({
                            //     ...SSOFieldsError,
                            //     ageError: SSOFields.femaleChild.length > 0,
                            //   }));
                            // }}

                            controlFuncBlur={(e) => {
                              const totalParticipants = parseInt(
                                SSOFields.totalParticipants || "0",
                                10
                              );
                              const male = parseInt(
                                SSOFields.maleChild || "0",
                                10
                              );
                              const female = parseInt(
                                SSOFields.femaleChild || "0",
                                10
                              );
                              const other = parseInt(
                                SSOFields.otherChild || "0",
                                10
                              );

                              const isValid =
                                male + female + other === totalParticipants;

                              setSSOFieldsError((prevErrors) => ({
                                ...prevErrors,
                                ageError: isValid
                                  ? ""
                                  : "Age (Estimated) total and Total No. Of Participants must be equal",
                                totalParticipantsError: isValid
                                  ? ""
                                  : prevErrors.totalParticipantsError,
                              }));
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title="20-24"
                            maxLength={5}
                            name={"otherChild"}
                            content={SSOFields.otherChild}
                            controlFunc={(e) =>
                              setSSOFields({
                                ...SSOFields,
                                otherChild: e.target.value.trim(),
                              })
                            }
                            // controlFuncBlur={(e) => {
                            //   setSSOFieldsError((SSOFieldsError) => ({
                            //     ...SSOFieldsError,
                            //     ageError: SSOFields.otherChild.length > 0,
                            //   }));
                            // }}

                            controlFuncBlur={(e) => {
                              const totalParticipants = parseInt(
                                SSOFields.totalParticipants || "0",
                                10
                              );
                              const male = parseInt(
                                SSOFields.maleChild || "0",
                                10
                              );
                              const female = parseInt(
                                SSOFields.femaleChild || "0",
                                10
                              );
                              const other = parseInt(
                                SSOFields.otherChild || "0",
                                10
                              );

                              const isValid =
                                male + female + other === totalParticipants;

                              setSSOFieldsError((prevErrors) => ({
                                ...prevErrors,
                                ageError: isValid
                                  ? ""
                                  : "Age (Estimated) total and Total No. Of Participants must be equal",
                                totalParticipantsError: isValid
                                  ? ""
                                  : prevErrors.totalParticipantsError,
                              }));
                            }}
                          />
                        </div>
                        {SSOFieldsError.ageError && (
                          <span class="text-red-500 text-sm font-medium">
                            {SSOFieldsError.ageError}
                          </span>
                        )}
                      </div>
                      <div>
                        <RadioGroup
                          classStar="text-red-500"
                          title="Items Provided"
                          type="radio"
                          setName="itemsProvided"
                          selectedOptions={SSOFields.singleSessionType}
                          options={singleSessionTypeDD}
                          disabled={false}
                          controlFunc={handleFromDropdownChangeValue}
                          controlFuncBlur={(e) => {
                            setSSOFieldsError((SSOFieldsError) => ({
                              ...SSOFieldsError,
                              singleSessionTypeError:
                                SSOFields.singleSessionType.length > 0
                                  ? ""
                                  : "Choosed the Single Session Type",
                            }));
                          }}
                          errorMessage={SSOFieldsError.singleSessionTypeError}
                        />
                      </div>
                      {venueofflineType && (
                        <InputText
                          type="text"
                          title="Venue"
                          //classStar={"text-red-500"}
                          content={SSOFields.venue}
                          controlFunc={(e) =>
                            setSSOFields({
                              ...SSOFields,
                              venue: e.target.value,
                            })
                          }
                        />
                      )}
                    </div>
                    <FileUpload
                      title="File Upload"
                      // classDiv="col-span-2"
                      classStar="text-red-500"
                      apiCall="HealthCareProviderAPI/HealthCareProviderImageUpload"
                      modalName="HealthCareProviderImage"
                      showImage={SSOFields.showImage}
                      showImageData={handleShowImage}
                      disabledBtn={updateScreenTitle}
                      imageUploadedError={SSOFieldsError.imageUploadedError}
                      limitMassage={
                        "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                      }
                      MAX_FILE_SIZE_MB={2}
                      ALLOWED_FORMATS={["image/jpeg", "image/jpg", "image/png"]}
                    />

                    <div class="lg:col-span-5 sm:col-span-1">
                      <TextArea
                        inputType="text"
                        title="Remarks"
                        name="remarks"
                        rows="4"
                        maxLength={500}
                        content={SSOFields.remarks}
                        placeholder="Description"
                        controlFunc={(e) =>
                          setSSOFields({
                            ...SSOFields,
                            remarks: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      Event no. :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {SSOFields.eventNo}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleICPDetails}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title={`Single session ${alertsinglesessionvalue}`}
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteButton}
            blockDetails={blockButton}
            unBlockDetails={unBlockButton}
          />
          <SingleSeassionPopup
            data={currentData}
            data2={currentData2}
            //data4={popUpDropDownData}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteButton}
          blockDetails={blockButton}
          unBlockDetails={unBlockButton}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default SingleSession;
