// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { userHeaders } from "../components/HeaderAttribute";
import UserDetails from "../popup-containers/UserDetails";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import InputPassword from "../ReusableComponents/InputPassword";
import UserCapabilityTable from "../components/UserCapabilityTable";
import toast, { Toaster } from "react-hot-toast";
import MultiSelect from "../ReusableComponents/MultiSelect";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import FileUpload from "../ReusableComponents/FileUpload";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function UserMaster() {
  const location = useLocation();
  //table Grid Datas State
  const [bindUserMasterDatas, setBindUserMasterDatas] = useState([]);
  const [bindUserEmailPhoneDatas, setBindUserEmailPhoneDatas] = useState([]);

  const [bindUserMastersSDPDatas, setBindUserMastersSDPDatas] = useState([]);
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  //visible States
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [editMode, setEditMode] = useState([]);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [rowIndex, setRowIndex] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [result, setResult] = useState(10);

  //Value store States
  const [userFields, setUserFields] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    phone: "",
    email: "",
    showImage: "",
    designation: "",
    roleGroup: "",
    state: "",
    location: "",
    country: "",
    sdp: [],
    organizationStructure: "",
  });

  // const [userAutoFields, setUserAutoFields] = useState({
  //   sdp: [],
  // });

  const [userFieldsError, setUserFieldsError] = useState({
    firstNameError: "",
    lastNameError: "",
    passwordError: "",
    emailError: "",
    mobileError: "",
    designationError: "",
    roleGroupError: "",
    stateError: "",
    branchProjectError: "",
    sdpError: "",
    imageUploadedError: "",
    organizationStructureError: "",
  });

  const [designationDropDown, setDesignationDropDown] = useState([]);

  const [roleDropDown, setRoleDropDown] = useState([]);
  const [bindCountry, setBindCountry] = useState([
    { value: "1", label: "India" },
    { value: "2", label: "America" },
    { value: "3", label: "Korea" },
  ]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [locationDropDown, setLocationDropDown] = useState([]);
  const [sdpDropDown, setSdpDropDown] = useState([]);

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [mstUserId, setMstUserId] = useState("");
  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  const [multiSelectPopupDropDown, setMultiSelectPopupDropDown] = useState([]);
  const [multiSelectSDPPopupDropDown, setMultiSelectSDPPopupDropDown] =
    useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [viewMode, setViewMode] = useState(false);

  //User Capability State data start
  const [bindMenuMasterDatas, setBindMenuMasterDatas] = useState([]);
  const [userBulkUploadData, setUserBulkUploadData] = useState("");
  const [mstRoleId, setMstRoleId] = useState(0);

  //handle edit state

  const [bindLocationDropDown1, setBindLocationDropDown1] = useState([]);
  const [sdpDropDown1, setSdpDropDown1] = useState([]);

  // const handleUserMultiFieldsChange = (value, name) => {
  //   console.log("sdp:", value, name);
  //   setUserAutoFields({
  //     ...userAutoFields,
  //     [name]: value,
  //   });
  // };

  // const handleSDPChange = (updatedOptions) => {
  //   setSdp(updatedOptions);
  //
  //   setUserFields({ sdp: updatedOptions });
  // };

  const handleUserFieldsChange = (value, name) => {
    //
    setUserFields({
      ...userFields,
      [name]: value,
    });
  };

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const designationResponse = await BindAPI(
          "DesignationAPI/GetDesignation"
        );
        const roleResponse = await BindAPI("RoleAPI/GetRole");
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");
        const sdpResponse = await BindAPI("SDPAPI/GetSDP");

        const designationData = designationResponse.data.Table.map((item) => ({
          value: item.MstDesignationId,
          label: item.Designation,
        }));

        const roleData = roleResponse.data.Table.map((item) => ({
          value: item.MstRoleId,
          label: item.RoleName,
        }));
        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));
        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));
        const sdpData = sdpResponse.data.Table.map((item) => ({
          value: item.MstSDPId,
          label: item.SDP_en,
        }));

        setDesignationDropDown(designationData);
        setRoleDropDown(roleData);
        setStateDropDown(statesData);
        setLocationDropDown(locationData);
        setSdpDropDown(sdpData);
      } catch (error) {}
    };

    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    getSelectedSDP();
    dropDownDatas();
    // getUserMasterData();
  }, []);

  useEffect(() => {
    getUserMasterData();
  }, []);

  const getUserMasterData = async () => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
        mstBranchId: userProfileDatas[0]?.MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0]?.MstUserId,
        mstRoleId: userProfileDatas[0]?.MstRoleId,
      };
      const response = await BindPostAPI(data, "UserAPI/BindUser");
      setBindUserMasterDatas(response.data.Table);
      setBindUserMastersSDPDatas(response.data.Table1);
      setBindUserEmailPhoneDatas(response.data.Table2);
    }
  };

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == userFields.state // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown1(locationSelected);
    } else {
    }
  }, [userFields.state ? userFields.state : null]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == userFields.location // Replace with the desired MstStateId
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId, // Replace with the appropriate value
        label: SD.SDP_en,
      }));
      setSdpDropDown1(sdpSelected);
    } else {
    }
  }, [userFields.location]);

  useEffect(() => {
    if (userFields.location && viewMode) {
      const SDPSelectedValue = getSDPDatas.find(
        (item) => item.MstLocationId == userFields.location
      );

      if (SDPSelectedValue) {
        const sdpLabels = getSDPDatas
          .filter(
            (item) => item.MstLocationId == SDPSelectedValue.MstLocationId
          )
          .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

        setMultiSelectPopupDropDown(sdpLabels);
      } else {
      }
    }
  }, [userFields.location, viewMode]);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setEditMode(null);
  };

  const cancel = async () => {
    //

    //call table Grid data
    getUserMasterData();

    // setCurrentPage(1);
    setResult(10);
    setVisibleAdd(false);
    setVisibleTable(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setSubmitBtnDisabled(false);
    //Clear User data
    // setUserFields({});
    setUserFields({
      firstName: "",
      middleName: "",
      lastName: "",
      password: "",
      phone: "",
      email: "",
      showImage: "",
      designation: "",
      roleGroup: "",
      state: "",
      location: "",
      country: "",
      organizationStructure: "",
    });

    //clear Error
    setUserFieldsError({
      firstNameError: "",
      lastNameError: "",
      passwordError: "",
      emailError: "",
      mobileError: "",
      designationError: "",
      roleGroupError: "",
      stateError: "",
      branchProjectError: "",
      sdpError: "",
      imageUploadedError: "",
      organizationStructureError: "",
    });

    // setUserAutoFields({
    //   sdp: [],
    // });

    //clear popup
    setpopUpOpen(false);

    //clear dropdown datas
    setSdpDropDown1([]);

    setSelectedRowIndex(null);
    setEditMode(null);
    setViewMode(null);
    setMultiSelectSDPPopupDropDown([]);
    setMultiSelectPopupDropDown([]);
  };
  const handleEdit = async (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    const getUserSDPDatas = Object.values(bindUserMastersSDPDatas).filter(
      (item) => item.MstUserId === bindUserMasterDatas[rowIndex].MstUserId
    );

    const sdps = getUserSDPDatas.map((item) => item.MstSDPId);
    setUserFields({
      ...userFields,
      firstName: bindUserMasterDatas[rowIndex].FirstName_en,
      middleName: bindUserMasterDatas[rowIndex].MiddleName_en,
      lastName: bindUserMasterDatas[rowIndex].LastName_en,
      email: bindUserMasterDatas[rowIndex].UserEmail,
      phone: bindUserMasterDatas[rowIndex].UserMobile,
      showImage: bindUserMasterDatas[rowIndex].UserProfileImg,
      designation: bindUserMasterDatas[rowIndex].MstDesignationId,
      roleGroup: bindUserMasterDatas[rowIndex].MstRoleId,
      state: bindUserMasterDatas[rowIndex].MstStateId,
      location: bindUserMasterDatas[rowIndex].MstLocationId,
      organizationStructure: bindUserMasterDatas[rowIndex].MstOrganizationId,
      // sdp: bindUserMasterDatas[rowIndex].MstSDPId.split(",").map((item) =>
      //   parseInt(item)
      // ),
      sdp: sdps,
    });

    setMstUserId(bindUserMasterDatas[rowIndex].MstUserId);
  };
  useEffect(() => {
    if (location.pathname == "/usermaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      getUserMasterData();
    }
  }, [location]);
  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the userFields.firstName input
      if (!userFields.firstName) {
        errors.firstName = "Enter First Name";
      } else if (!/^[A-Za-z ]+$/.test(userFields.firstName)) {
        errors.firstName = "Only alphabet and space allowed";
      }

      // Validation logic for the lastName input
      if (!userFields.lastName.trim()) {
        errors.lastName = "Enter Last Name";
      } else if (!/^[A-Za-z ]+$/.test(userFields.lastName)) {
        errors.lastName = "Only alphabet and space allowed";
      }

      // Validation logic for the email input
      if (!userFields.email.trim()) {
        errors.email = "Enter Email";
      } else if (
        !/^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
          userFields.email
        )
      ) {
        errors.email = "Enter a valid Email Address";
      } else if (
        !updateScreenTitle &&
        bindUserEmailPhoneDatas.find(
          (x) => x.UserEmail.toLowerCase() === userFields.email.toLowerCase()
        )
      ) {
        errors.email = "Email Address already available";
      }

      // Validation logic for the phone input
      if (!userFields.phone.trim()) {
        errors.phone = "Enter Mobile Number";
      } else if (!/^[+\d\s]{10,}$/.test(userFields.phone)) {
        errors.phone = "Enter a valid Mobile Number";
      } else if (
        !updateScreenTitle &&
        bindUserEmailPhoneDatas.find(
          (x) => x.UserMobile.toLowerCase() === userFields.phone.toLowerCase()
        )
      ) {
        errors.phone = "Mobile Number already available";
      }

      // Validation logic for the designation input
      if (userFields.designation == "") {
        errors.designation = "Select Designation";
      }

      // Validation logic for the role group input
      if (userFields.roleGroup == "") {
        errors.roleGroup = "Select Role/Groups";
      }

      if (userFields.organizationStructure == "") {
        errors.organizationStructure = "Select Organization Structure";
      }

      // Validation logic for the state input
      if (
        userFields.organizationStructure == 2 ||
        userFields.organizationStructure == 3
      ) {
        if (userFields.state == "") {
          errors.state = "Select State";
        }
      }

      // Validation logic for the location input
      if (
        userFields.organizationStructure == 2 ||
        userFields.organizationStructure == 3
      ) {
        if (userFields.location == "") {
          errors.location = "Select Branch/Project";
        }
      }

      // Validation logic for the state input
      if (userFields.organizationStructure == 3) {
        if (!userFields.sdp || userFields.sdp.length === 0) {
          errors.sdp = "Select S.D.P.";
        }
      }

      // if (userFields.showImage == "") {
      //   errors.showImage = "Please Upload an Image";
      // }
      if (userFields.password == "") {
        errors.password = "Enter Password";
      }
    }

    setUserFieldsError({
      ...userFieldsError,
      firstNameError: errors.firstName || "",
      lastNameError: errors.lastName || "",
      passwordError: errors.password || "",
      emailError: errors.email || "",
      mobileError: errors.phone || "",
      designationError: errors.designation || "",
      roleGroupError: errors.roleGroup || "",
      stateError: errors.state || "",
      branchProjectError: errors.location || "",
      sdpError: errors.sdp || "",
      // imageUploadedError: errors.showImage || "",
      organizationStructureError: errors.organizationStructure || "",
    });

    return errors; // Return the errors object containing validation errors (if any)
  };

  const handleSubmit = async (items, flag) => {

    setSubmitBtnDisabled(true);
    const firstNameValidation = validate(flag);
    if (Object.keys(firstNameValidation).length !== 0) {
      setIsOpen(true);
    }
    if (Object.keys(firstNameValidation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedFirstNameTexts = []; // Create an array to store translations
        const translatedMiddleNameTexts = [];
        const translatedLastNameTexts = [];

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            userFields.firstName,
            i
          );
          translatedFirstNameTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            userFields.middleName,
            i
          );
          translatedMiddleNameTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            userFields.lastName,
            i
          );
          translatedLastNameTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const userPayload = [
          {
            firstNameEn: userFields.firstName,
            firstNameTa: translatedFirstNameTexts[0],
            firstNameHi: translatedFirstNameTexts[1],
            firstNameMr: translatedFirstNameTexts[2],
            firstNameGu: translatedFirstNameTexts[3],
            firstNameKn: translatedFirstNameTexts[4],
            firstNameMl: translatedFirstNameTexts[5],
            firstNameTe: translatedFirstNameTexts[6],
            firstNameBn: translatedFirstNameTexts[7],
            firstNameOr: translatedFirstNameTexts[8],
            middleNameEn: userFields.middleName,
            middleNameTa: translatedMiddleNameTexts[0],
            middleNameHi: translatedMiddleNameTexts[1],
            middleNameMr: translatedMiddleNameTexts[2],
            middleNameGu: translatedMiddleNameTexts[3],
            middleNameKn: translatedMiddleNameTexts[4],
            middleNameMl: translatedMiddleNameTexts[5],
            middleNameTe: translatedMiddleNameTexts[6],
            middleNameBn: translatedMiddleNameTexts[7],
            middleNameOr: translatedMiddleNameTexts[8],
            lastNameEn: userFields.lastName,
            lastNameTa: translatedLastNameTexts[0],
            lastNameHi: translatedLastNameTexts[1],
            lastNameMr: translatedLastNameTexts[2],
            lastNameGu: translatedLastNameTexts[3],
            lastNameKn: translatedLastNameTexts[4],
            lastNameMl: translatedLastNameTexts[5],
            lastNameTe: translatedLastNameTexts[6],
            lastNameBn: translatedLastNameTexts[7],
            lastNameOr: translatedLastNameTexts[8],
            userEmail: userFields.email,
            userMobile: userFields.phone,
            mstDesignationId: userFields.designation,
            mstRoleId: userFields.roleGroup,
            mstOrganizationId: parseInt(userFields.organizationStructure),
            mstCountryId: 1,
            mstStateId: userFields.state ? userFields.state : 0,
            mstLocationId: userFields.location ? userFields.location : 0,
            mstSDPId: userFields.sdp
              ? JSON.stringify(userFields.sdp).slice(1, -1)
              : "",
            userProfileImg:
              userFields.showImage == "" ? null : userFields.showImage,
            userPassword: userFields.password,
            createdBy: createdBy,
            bulkUpload: userBulkUploadData,
            flag: flag,
          },
        ];
        console.log("userPayload", userPayload);
        const response = await IUDAPI(userPayload, "UserAPI/IUDUser");
        if (response.data.Table1[0].Result === "S") {
          toast.success("User added successfully! 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error(
            " User add failed. Please review the details and try again. 👎",
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else if (flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedFirstNameTexts = []; // Create an array to store translations
        const translatedMiddleNameTexts = [];
        const translatedLastNameTexts = [];

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            userFields.firstName,
            i
          );
          translatedFirstNameTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            userFields.middleName,
            i
          );
          translatedMiddleNameTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            userFields.lastName,
            i
          );
          translatedLastNameTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const userPayload = [
          {
            mstUserId: mstUserId,
            firstNameEn: userFields.firstName,
            firstNameTa: translatedFirstNameTexts[0],
            firstNameHi: translatedFirstNameTexts[1],
            firstNameMr: translatedFirstNameTexts[2],
            firstNameGu: translatedFirstNameTexts[3],
            firstNameKn: translatedFirstNameTexts[4],
            firstNameMl: translatedFirstNameTexts[5],
            firstNameTe: translatedFirstNameTexts[6],
            firstNameBn: translatedFirstNameTexts[7],
            firstNameOr: translatedFirstNameTexts[8],
            middleNameEn: userFields.middleName,
            middleNameTa: translatedMiddleNameTexts[0],
            middleNameHi: translatedMiddleNameTexts[1],
            middleNameMr: translatedMiddleNameTexts[2],
            middleNameGu: translatedMiddleNameTexts[3],
            middleNameKn: translatedMiddleNameTexts[4],
            middleNameMl: translatedMiddleNameTexts[5],
            middleNameTe: translatedMiddleNameTexts[6],
            middleNameBn: translatedMiddleNameTexts[7],
            middleNameOr: translatedMiddleNameTexts[8],
            lastNameEn: userFields.lastName,
            lastNameTa: translatedLastNameTexts[0],
            lastNameHi: translatedLastNameTexts[1],
            lastNameMr: translatedLastNameTexts[2],
            lastNameGu: translatedLastNameTexts[3],
            lastNameKn: translatedLastNameTexts[4],
            lastNameMl: translatedLastNameTexts[5],
            lastNameTe: translatedLastNameTexts[6],
            lastNameBn: translatedLastNameTexts[7],
            lastNameOr: translatedLastNameTexts[8],
            userEmail: userFields.email,
            userMobile: userFields.phone,
            mstDesignationId: userFields.designation,
            mstRoleId: userFields.roleGroup,
            mstOrganizationId: parseInt(userFields.organizationStructure),
            mstCountryId: 1,
            mstStateId: userFields.state ? userFields.state : 0,
            mstLocationId: userFields.location ? userFields.location : 0,
            mstSDPId: JSON.stringify(
              userFields.sdp ? userFields.sdp : "0"
            ).slice(1, -1),
            userProfileImg: userFields.showImage,
            userPassword: userFields.password,
            createdBy: createdBy,
            bulkUpload: userBulkUploadData,
            flag: flag,
          },
        ];
        const response = await IUDAPI(userPayload, "UserAPI/IUDUser");

        if (response.data.Table1[0].Result === "M") {
          toast.success("Modified complete! 👍", {
            duration: 4000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        } else {
          toast.error(
            "  User modify failed. Please double-check the information and try again. 👎",
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else if (flag == 3 || flag == 4 || flag == 5) {
        const userPayload = [
          {
            mstUserId: bindUserMasterDatas[items].MstUserId,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(userPayload, "UserAPI/IUDUser");
        if (response.data.Table[0].Result === "D") {
          toast.success("Deletion successful! 🗑️", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else if (response.data.Table[0].Result === "B") {
          toast.success("User blocked successfully!. 🔒", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else if (response.data.Table[0].Result === "UB") {
          toast.success("User activated successfully! 🔓", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("failed!", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else {
        return false;
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const handleUserDetails = (rowIndex, flag) => {
    setpopUpOpen(true);
    setViewMode(true);
    // setLocation(bindUserMasterDatas[rowIndex].MstLocationId);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }
    setCurrentData(bindUserMasterDatas[rowIndex]);
    setUserFields({
      ...userFields,
      location: bindUserMasterDatas[rowIndex].MstLocationId,
    });
    //Designation DropDown Value Start
    const userData = bindUserMasterDatas[rowIndex];
    const userDesignationDropDownValue = designationDropDown.find(
      (item) => item.value === userData.MstDesignationId
    );

    const editDesignationDropDownValue = {
      value: userData.MstDesignationId,
      label: userDesignationDropDownValue
        ? userDesignationDropDownValue.label
        : "Unknown",
    };
    //Designation DropDown Value end

    //RoleGroup DropDown Value Start

    const userRoleGroupDropDownValue = roleDropDown.find(
      (item) => item.value === userData.MstRoleId
    );

    const editUserRoleDropDownValue = {
      value: userData.MstRoleId,
      label: userRoleGroupDropDownValue
        ? userRoleGroupDropDownValue.label
        : "Unknown",
    };

    //RoleGroup DropDown Value End;

    //State DropDown Value Start

    const userStateDropDownValue = stateDropDown.find(
      (item) => item.value === userData.MstStateId
    );
    const editUserStateDropDownValue = {
      value: userData.MstStateId,
      label: userStateDropDownValue ? userStateDropDownValue.label : "Unknown",
    };

    //State DropDown Value End

    //Location DropDown Start

    const userLocationDropDownValue = locationDropDown.find(
      (item) => item.value === userData.MstLocationId
    );

    const editUserLocationDropDownvalue = {
      value: userData.MstLocationId,
      label: userLocationDropDownValue
        ? userLocationDropDownValue.label
        : "Unknown",
    };

    //Location DropDown End

    //sdp dropdown start

    const getUserSDPDatas = Object.values(bindUserMastersSDPDatas).filter(
      (item) => item.MstUserId === bindUserMasterDatas[rowIndex].MstUserId
    );

    const sdps = getUserSDPDatas.map((item) => item.MstSDPId);

    const autoDropDownValues = [
      editDesignationDropDownValue,
      editUserRoleDropDownValue,
      editUserStateDropDownValue,
      editUserLocationDropDownvalue,
      sdps,
      //bindUserMasterDatas[rowIndex].MstSDPId,
      // editUserAreaDropDownValue,
    ];
    setPopUpDropDownData(autoDropDownValues);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let rightsResponse;
  //       if (
  //         editMode &&
  //         userFields.roleGroup ==
  //           bindUserMasterDatas[selectedRowIndex].MstRoleId
  //       ) {
  //         rightsResponse = await BindAPI("GetUserCapability");
  //       } else {
  //         rightsResponse = await BindAPI("BindRoleVsCapability");
  //       }

  //       const response = await BindAPI("BindScreenMenu");
  //       const organizedData = {};

  //       response.data.Table.forEach((item) => {
  //         const menuHeading = item.MenuHeading;
  //         //const menuHeadingId = item.MstMenuHeadingId;

  //         if (!organizedData[menuHeading]) {
  //           organizedData[menuHeading] = [];
  //         }

  //         const arr = rightsResponse.data.Table.filter(
  //           (e) =>
  //             e.MstMenuId == item.MstMenuId &&
  //             e.MstRoleId == userFields.roleGroup
  //         );

  //         if (arr.length > 0) {
  //           item.checked = {
  //             add: arr[0].AddPermission == 1,
  //             view: arr[0].ViewPermission == 1,
  //             inActive: arr[0].BlockPermission == 1,
  //             delete: arr[0].DeletePermission == 1,
  //             edit: arr[0].ModifyPermission == 1,
  //             service: arr[0].ProvideServicePermission == 1,
  //             title:
  //               arr[0].AddPermission == 1 &&
  //               arr[0].ViewPermission == 1 &&
  //               arr[0].BlockPermission == 1 &&
  //               arr[0].DeletePermission == 1 &&
  //               arr[0].ModifyPermission == 1 &&
  //               arr[0].ProvideServicePermission == 1,
  //           };
  //         } else {
  //           item.checked = {
  //             title: false,
  //             add: false,
  //             view: false,
  //             inActive: false,
  //             delete: false,
  //             edit: false,
  //             service: false,
  //           };
  //         }

  //         organizedData[menuHeading].push(item);
  //       });

  //       setBindMenuMasterDatas(organizedData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [editMode, userFields.roleGroup, mstUserId, mstRoleId]);

  useEffect(() => {
    const getMenuTableDatas = async () => {
      let rightsResponse;
      try {
        if (
          editMode &&
          userFields.roleGroup ==
            bindUserMasterDatas[selectedRowIndex].MstRoleId
        ) {
          rightsResponse = await IUDAPI(
            {
              mstUserId: mstUserId,
              mstRoleId: bindUserMasterDatas[selectedRowIndex].MstRoleId,
            },
            "UserAPI/GetUserCapability"
          );
        } else {
          rightsResponse = await BindAPI(
            "RoleVsCapabilityAPI/BindRoleVsCapability"
          );
        }
        console.log("rightsResponse", rightsResponse);
        const response = await BindAPI("RoleVsCapabilityAPI/BindScreenMenu");
        const organizedData = {};

        const processMenuData = (menuHeadingId) => {
          const filteredData = response.data.Table.filter(
            (item) => item.MstMenuHeadingId === menuHeadingId
          );

          const menuList = {};

          filteredData.forEach((item) => {
            if (!menuList[item.MenuName]) {
              menuList[item.MenuName] = {
                MenuName: item.MenuName,
                MstMenuId: item.MainMenuId ? null : item.MstMenuId,
                MainMenuId: item.MainMenuId,
                MstMenuHeadingId: item.MstMenuHeadingId,
                checked:
                  item.MainMenuId && item.SubMenu && item.SubMenu.length > 0
                    ? null
                    : [],
                SubMenu: [],
                View: item.View,
                Add: item.Add,
                Edit: item.Edit,
                Delete: item.Delete,
                Inactive: item.Inactive,
                Service: item.Service,
              };
            }

            const submenuFiltered = response.data.Table.filter(
              (subItem) =>
                subItem.MenuName === item.MenuName &&
                subItem.MainMenuId &&
                subItem.MstMenuHeadingId === menuHeadingId
            ).map((subItem) => ({
              SubMenu: subItem.SubMenu,
              MstMenuId: subItem.MstMenuId,
              MainMenuId: subItem.MainMenuId,
              MstMenuHeadingId: subItem.MstMenuHeadingId,
              checked: [],
              View: item.View,
              Add: item.Add,
              Edit: item.Edit,
              Delete: item.Delete,
              Inactive: item.Inactive,
              Service: item.Service,
            }));

            const arr = rightsResponse.data.Table.filter(
              (e) =>
                e.MstMenuId == menuList[item.MenuName].MstMenuId &&
                e.MstRoleId == userFields.roleGroup
            );
            const checkedData =
              arr.length > 0
                ? {
                    add: arr[0].AddPermission === 1,
                    view: arr[0].ViewPermission === 1,
                    inActive: arr[0].BlockPermission === 1,
                    delete: arr[0].DeletePermission === 1,
                    edit: arr[0].ModifyPermission === 1,
                    service: arr[0].ProvideServicePermission === 1,
                    title:
                      arr[0].AddPermission === 1 &&
                      arr[0].ViewPermission === 1 &&
                      arr[0].BlockPermission === 1 &&
                      arr[0].DeletePermission === 1 &&
                      arr[0].ModifyPermission === 1 &&
                      arr[0].ProvideServicePermission === 1,
                  }
                : {
                    title: false,
                    add: false,
                    view: false,
                    inActive: false,
                    delete: false,
                    edit: false,
                    service: false,
                  };
            if (menuList[item.MenuName].checked !== null) {
              menuList[item.MenuName].checked.push(checkedData);
            }

            submenuFiltered.forEach((subItem) => {
              const subMenuItemExists = menuList[item.MenuName].SubMenu.some(
                (existingSubMenu) => existingSubMenu.SubMenu == subItem.SubMenu
              );
              if (!subMenuItemExists) {
                const arr = rightsResponse.data.Table.filter(
                  (e) =>
                    e.MstMenuId == subItem.MstMenuId &&
                    e.MstRoleId == userFields.roleGroup
                );
                subItem.checked =
                  arr.length > 0
                    ? {
                        add: arr[0].AddPermission === 1,
                        view: arr[0].ViewPermission === 1,
                        inActive: arr[0].BlockPermission === 1,
                        delete: arr[0].DeletePermission === 1,
                        edit: arr[0].ModifyPermission === 1,
                        service: arr[0].ProvideServicePermission === 1,
                        title:
                          arr[0].AddPermission === 1 &&
                          arr[0].ViewPermission === 1 &&
                          arr[0].BlockPermission === 1 &&
                          arr[0].DeletePermission === 1 &&
                          arr[0].ModifyPermission === 1 &&
                          arr[0].ProvideServicePermission === 1,
                      }
                    : {
                        title: false,
                        add: false,
                        view: false,
                        inActive: false,
                        delete: false,
                        edit: false,
                        service: false,
                      };

                if (!subMenuItemExists) {
                  menuList[item.MenuName].SubMenu.push(subItem);
                }
              }
            });
          });

          return Object.values(menuList);
        };

        organizedData.Dashboard = processMenuData(1); // Dashboard
        organizedData.Administrator = processMenuData(2); // Administrator
        organizedData.Transaction = processMenuData(3); // Transaction
        organizedData.Reports = processMenuData(4); // Reports

        setBindMenuMasterDatas(organizedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMenuTableDatas();
  }, [
    editMode ? editMode : null,
    userFields.roleGroup ? userFields.roleGroup : null,
    mstUserId ? mstUserId : null,
    mstRoleId ? mstRoleId : null,
  ]);

  //Main code

  const handleUserCapabilityData = (userData) => {
    const convertJSONString = JSON.stringify(userData);
    setUserBulkUploadData(convertJSONString);
  };

  const handleShowImage = (showImage) => {
    //
    // showImage &&
      setUserFields({
        ...userFields,
        showImage: showImage,
      });
    setUserFieldsError({ ...userFieldsError, imageUploadedError: "" });
  };
  const [bindOrganizationStructure, setBindOrganizationStructure] = useState(
    []
  );
  const [bindOrganizationStructure1, setBindOrganizationStructure1] = useState([
    { value: 1, label: "Head Quarters" },
    { value: 2, label: "Branch/Project" },
    { value: 3, label: "S.D.P." },
  ]);

  useEffect(() => {

    if (userProfileDatas[0]?.MstOrganizationId == 1) {
      setBindOrganizationStructure(bindOrganizationStructure1);
    } else {
      const filteredData = bindOrganizationStructure1.filter(
        (item) => item.value !== 1
      );
      setBindOrganizationStructure(filteredData);
    }
  }, []);
  useEffect(() => {

    if (userProfileDatas[0]?.MstOrganizationId != 1) {
      setUserFields({
        ...userFields,
        state: userProfileDatas[0]?.MstStateId,
        location: userProfileDatas[0]?.MstLocationId,
      });
    }
  }, [userFields.organizationStructure]);

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="User Master"
          header={userHeaders}
          records={bindUserMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleUserDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="User"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <div>
                      <InputText
                        inputType="text"
                        classStar={"text-red-500"}
                        title="First Name"
                        name="firstName"
                        maxLength={50}
                        content={userFields.firstName}
                        controlFunc={(e) =>
                          setUserFields({
                            ...userFields,
                            firstName: e.target.value,
                          })
                        }
                        controlFuncBlur={() => {
                          setUserFieldsError((userFieldsError) => ({
                            ...userFieldsError,
                            firstNameError:
                              userFields.firstName &&
                              userFields.firstName.length > 0
                                ? /^[A-Za-z ]+$/.test(userFields.firstName)
                                  ? ""
                                  : "Only Alphabet and space allowed"
                                : "Enter First Name",
                          }));
                        }}
                        errorMessage={userFieldsError.firstNameError}
                      />
                    </div>

                    <InputText
                      inputType="text"
                      title="Middle Name"
                      name="middleName"
                      maxLength={50}
                      content={userFields.middleName}
                      controlFunc={(e) =>
                        setUserFields({
                          ...userFields,
                          middleName: e.target.value,
                        })
                      }
                    />
                    <div>
                      <InputText
                        inputType="text"
                        classStar={"text-red-500"}
                        title="Last Name"
                        name="lastName"
                        maxLength={50}
                        content={userFields.lastName}
                        controlFunc={(e) =>
                          setUserFields({
                            ...userFields,
                            lastName: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setUserFieldsError((userFieldsError) => ({
                            ...userFieldsError,
                            lastNameError:
                              userFields.lastName &&
                              userFields.lastName.length > 0
                                ? /^[A-Za-z ]+$/.test(userFields.lastName)
                                  ? ""
                                  : "Only Alphabet and space allowed"
                                : "Enter Last Name",
                          }));
                        }}
                        errorMessage={userFieldsError.lastNameError}
                      />
                    </div>
                    <div>
                      <InputPassword
                        inputType="password"
                        classStar={"text-red-500"}
                        title="Password"
                        name="password"
                        placeholder="XXXXXXXXXX"
                        maxLength={30}
                        content={userFields.password}
                        controlFunc={(e) =>
                          setUserFields({
                            ...userFields,
                            password: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setUserFieldsError((userFieldsError) => ({
                            ...userFieldsError,
                            passwordError:
                              userFields.password &&
                              userFields.password.length > 0
                                ? ""
                                : "Enter Password",
                          }));
                        }}
                        errorMessage={userFieldsError.passwordError}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="email"
                        classStar={"text-red-500"}
                        title="Email"
                        name="email"
                        placeholder=""
                        maxLength={150}
                        disabled={editMode ? editMode : false}
                        content={userFields.email}
                        controlFunc={(e) =>
                          setUserFields({
                            ...userFields,
                            email: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setUserFieldsError((userFieldsError) => ({
                            ...userFieldsError,
                            emailError:
                              userFields.email && userFields.email.length > 0
                                ? /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
                                    userFields.email
                                  )
                                  ? !updateScreenTitle &&
                                    bindUserEmailPhoneDatas.find(
                                      (x) =>
                                        x.UserEmail.toLowerCase() ==
                                        userFields.email.toLowerCase()
                                    )
                                    ? "Email address already available"
                                    : ""
                                  : "Enter a valid email address "
                                : "Enter Email",
                          }));
                        }}
                        errorMessage={userFieldsError.emailError}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        classStar={"text-red-500"}
                        title="Mobile Number"
                        name="phone"
                        placeholder=""
                        maxLength={11}
                        content={userFields.phone}
                        controlFunc={(e) =>
                          setUserFields({
                            ...userFields,
                            phone: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setUserFieldsError((userFieldsError) => ({
                            ...userFieldsError,
                            mobileError:
                              userFields.phone && userFields.phone.length > 0
                                ? /^[+\d\s]{10,}$/.test(userFields.phone)
                                  ? !updateScreenTitle &&
                                    bindUserEmailPhoneDatas.find(
                                      (x) =>
                                        x.UserMobile.toLowerCase() ==
                                        userFields.phone.toLowerCase()
                                    )
                                    ? "Mobile Number already available"
                                    : ""
                                  : "Enter a valid Mobile Number"
                                : "Enter Mobile Number",
                          }));
                        }}
                        errorMessage={userFieldsError.mobileError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="Designation"
                        classStar={"text-red-500"}
                        options={designationDropDown}
                        placeholder={"Select"}
                        disabled={updateScreenTitle}
                        name="designation"
                        selectedOption={designationDropDown.find(
                          (x) => x.value == userFields.designation
                        )}
                        controlFunc={(value, e) => {
                          // setUserFieldsError({
                          //   ...userFieldsError,
                          //   designationError: value ? "" : "Select designation",
                          // });
                          handleUserFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setUserFieldsError(() => ({
                            ...userFieldsError,
                            designationError: userFields.designation
                              ? ""
                              : "Select Designation",
                          }));
                        }}
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                        errorMessage={userFieldsError.designationError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        title="Role/Groups"
                        classStar={"text-red-500"}
                        options={roleDropDown}
                        placeholder="Select"
                        name="roleGroup"
                        selectedOption={roleDropDown.find(
                          (x) => x.value == userFields.roleGroup
                        )}
                        controlFunc={(value, e) => {
                          // setUserFieldsError({
                          //   ...userFieldsError,
                          //   roleGroupError: value ? "" : "Select role",
                          // });
                          handleUserFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setUserFieldsError({
                            ...userFieldsError,
                            roleGroupError: userFields.roleGroup
                              ? ""
                              : "Select Role/Groups",
                          });
                        }}
                        errorMessage={userFieldsError.roleGroupError}
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                      />
                    </div>
                    <div>
                      <DropDown
                        title="Organization Structure"
                        options={bindOrganizationStructure}
                        placeholder="Select"
                        classStar="text-red-500"
                        name="organizationStructure"
                        selectedOption={userFields.organizationStructure}
                        controlFunc={(e) => {
                          setUserFields({
                            ...userFields,
                            organizationStructure: e.target.value,
                          });
                          setUserFields({
                            ...userFields,
                            organizationStructure: e.target.value,
                            state: "",
                            location: "",
                            sdp: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setUserFields({
                            ...userFields,
                            // state: "",
                            // location: "",
                            // sdp: "",
                          });
                          setUserFieldsError(() => ({
                            ...userFieldsError,
                            organizationStructureError:
                              userFields.organizationStructure
                                ? ""
                                : "Select Organization Structure",
                          }));
                        }}
                      />
                      {userFieldsError.organizationStructureError && (
                        <span className="error-message text-red-500">
                          {userFieldsError.organizationStructureError}
                        </span>
                      )}
                    </div>
                    <DropDown
                      classDiv="hidden"
                      title="Country"
                      options={bindCountry}
                      placeholder="Select"
                      name="country"
                      selectedOption={userFields.country}
                      controlFunc={(value, e) => {
                        handleUserFieldsChange(value, e);
                      }}
                    />
                    {(userFields.organizationStructure == 2 ||
                      userFields.organizationStructure == 3) && (
                      <AutoDropDown
                        title="State"
                        classStar={"text-red-500"}
                        options={stateDropDown}
                        placeholder="Select"
                        name="state"
                        selectedOption={stateDropDown.find(
                          (x) => x.value == userFields.state
                        )}
                        controlFunc={(value, e) => {
                          handleUserFieldsChange(value.value, e.name);
                          setUserFields({
                            ...userFields,
                            state: value.value,
                            location: "",
                            sdp: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setUserFieldsError({
                            ...userFieldsError,
                            stateError: userFields.state ? "" : "Select State",
                          });
                        }}
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                        disabled={userProfileDatas[0]?.MstStateId}
                        errorMessage={userFieldsError.stateError}
                      />
                    )}

                    {(userFields.organizationStructure == 2 ||
                      userFields.organizationStructure == 3) && (
                      <AutoDropDown
                        title="Branch/Project"
                        classStar={"text-red-500"}
                        options={locationDropDown && bindLocationDropDown1}
                        placeholder="Select"
                        disabled={userProfileDatas[0]?.MstLocationId}
                        name="location"
                        selectedOption={
                          userFields.location
                            ? locationDropDown.find(
                                (x) => x.value == userFields.location
                              )
                            : ""
                        }
                        controlFunc={(value, e) => {
                          handleUserFieldsChange(value.value, e.name);
                          setUserFields({
                            ...userFields,
                            location: value.value,
                            sdp: [],
                          });
                          // setUserFieldsError({
                          //   ...userFieldsError,
                          //   branchProjectError: value ? "" : "Select location",
                          // });
                        }}
                        controlFuncBlur={() => {
                          setUserFieldsError({
                            ...userFieldsError,
                            branchProjectError: userFields.location
                              ? ""
                              : "Select Branch/Project",
                          });
                        }}
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                        errorMessage={userFieldsError.branchProjectError}
                      />
                    )}
                    {userFields.organizationStructure == 3 && (
                      <div class="flex flex-col">
                        <MultiSelect
                          label="S.D.P."
                          classStar={"text-red-400"}
                          filteredOptions={sdpDropDown && sdpDropDown1}
                          classFilterLabel="p-1 flex gap-4"
                          class="custom-class"
                          placeholder={
                            userFields?.sdp?.length > 0
                              ? "More S.D.P. Selected"
                              : "Select..."
                          }
                          name="sdp"
                          selectedOptions={userFields.sdp}
                          onChange={(e) => {
                            handleUserFieldsChange(e, "sdp");
                          }}
                          controlFuncBlur={() => {
                            setUserFieldsError({
                              ...userFieldsError,
                              sdpError:
                                userFields?.sdp?.length === 0
                                  ? "Select S.D.P."
                                  : null,
                            });
                          }}
                          errorMessage={userFieldsError.sdpError}
                        />
                        {/* {sdpError && <span class="text-red-500">{sdpError}</span>} */}
                      </div>
                    )}
                    {/* <div>
                      <InputText
                        inputType="text"
                        classStar={"text-red-500"}
                        title="User Id"
                        placeholder="001"
                        name="userId"
                        maxLength={50}
                        content={userFields.userId}
                        controlFunc={(e) =>
                          setUserFields({
                            ...userFields,
                            userId: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setUserFieldsError((userFieldsError) => ({
                            ...userFieldsError,
                            userIdError:
                              userFields.userId.length > 0
                                ? ""
                                : "Enter User Id",
                          }));
                        }}
                        errorMessage={userFieldsError.userIdError}
                      />
                    </div> */}
                  </div>

                  <FileUpload
                    title="File Upload"
                    // classDiv="col-span-2"
                    // classStar="text-red-500"
                    apiCall="UserAPI/UserImageUpload"
                    modalName="UserImage"
                    showImage={userFields.showImage}
                    disabledBtn={updateScreenTitle}
                    showImageData={handleShowImage}
                    imageUploadedError={userFieldsError.imageUploadedError}
                    limitMassage={
                      "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                    }
                    MAX_FILE_SIZE_MB={2}
                    ALLOWED_FORMATS={["image/jpeg", "image/jpg", "image/png"]}
                  />
                  <div class="w-full">
                    <UserCapabilityTable
                      menuData={bindMenuMasterDatas}
                      handleChange={
                        setBindMenuMasterDatas.length > 0
                          ? setBindMenuMasterDatas
                          : setBindMenuMasterDatas
                      }
                      handleRoleChange={userFields.roleGroup}
                      handleUserCapability={handleUserCapabilityData}
                    />
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <PopUpScreenTitle
            title="User"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <UserDetails
            data={currentData}
            data2={popUpDropDownData}
            data3={multiSelectPopupDropDown}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default UserMaster;
